import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Box, Paper, Grid, CircularProgress, Fade } from "@mui/material";
import { availableDays, getServiceById, reserveService } from "../../services/marketplace/API";
import { formatCurrency } from "../../utils/utils";
import { toast } from "sonner";
import ToastError from "../../components/Toasts/Error/ToastError";
import ToastSuccess, { toastStyle } from "../../components/Toasts/Success/ToastSuccess";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const COLORS = {
    light: {
        black: "#1E1E1E",
        primaryDark: "#ff5722",
        primaryLight: "#FF7354",
        primaryExtraLight: "#FFE9E4",
        secondaryBlue: "#5993FF",
        borderLight: "#EAEAEA",
        border: "#D4D4D4",
        borderDark: "#747474",
        warning: "#FFBE18",
        availableGreen: "#beffbe",
        unavailableRed: "#ffbebe",
        hoverGreen: "#45A049",
        hoverRed: "#E53935",
        background: "#F2F2F2"
    },
    dark: {
        black: "#232323",
        primaryDark: "#E44826",
        primaryLight: "#F9CDC4",
        primaryExtraLight: "#494949",
        secondaryBlue: "#5993FF",
        borderLight: "#595959",
        border: "#797979",
        borderDark: "#CBCBCB",
        warning: "#FFBE18",
        availableGreen: "#45A049",
        unavailableRed: "#E53935",
        hoverGreen: "#45A049",
        hoverRed: "#E53935",
        background: "#333333"
    }
};

const STATUS_COLORS = {
    Complet: COLORS.light.unavailableRed,
    Disponible: COLORS.light.availableGreen,
    Selected: COLORS.light.unavailableRed,
    default: "transparent",
};

const calendarStyles = {
    '.fc': {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    '.fc-day-today': {
        backgroundColor: 'rgba(66, 165, 245, 0.05) !important',
        borderRadius: '4px'
    },
    '.fc-day-header': {
        backgroundColor: COLORS.light.primaryExtraLight,
        padding: '12px 0',
        fontWeight: '500',
        color: COLORS.light.primaryDark,
        borderBottom: `2px solid ${COLORS.light.primaryLight}`
    },
    '.fc-button-primary': {
        backgroundColor: `${COLORS.light.secondaryBlue} !important`,
        borderColor: `${COLORS.light.secondaryBlue} !important`,
        borderRadius: '4px !important',
        textTransform: 'capitalize',
        boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
        transition: 'all 0.3s ease',
        padding: '4px 8px !important',
        fontSize: '0.8rem !important'
    },
    '.fc-button-primary:hover': {
        backgroundColor: `${COLORS.light.primaryLight} !important`,
        borderColor: `${COLORS.light.primaryLight} !important`,
        transform: 'translateY(-1px)',
        boxShadow: '0 2px 4px rgba(0,0,0,0.15)'
    },
    '.fc-button-primary:not(:disabled):active, .fc-button-primary:not(:disabled).fc-button-active': {
        backgroundColor: `${COLORS.light.primaryLight} !important`,
        borderColor: `${COLORS.light.primaryLight} !important`,
        transform: 'translateY(1px)'
    },
    '.fc-toolbar-title': {
        fontSize: '1.2rem !important',
        fontWeight: '500 !important'
    },
    '.fc-day': {
        border: `1px solid ${COLORS.light.border} !important`,
        transition: 'all 0.2s ease'
    },
    '.fc-day:hover': {
        backgroundColor: 'rgba(0,0,0,0.02) !important',
        cursor: 'pointer'
    },
    '.fc-day-other': {
        backgroundColor: '#fafafa !important'
    },
    '.fc-event': {
        borderRadius: '4px',
        border: 'none',
        transition: 'transform 0.2s ease'
    },
    '.fc-event:hover': {
        transform: 'scale(1.02)'
    },
    '.fc-event-main': {
        padding: '4px',
        fontSize: {
            xs: '10px',
            sm: '12px',
            md: '14px'
        },
        lineHeight: {
            xs: 1.2,
            sm: 1.4,
            md: 1.6
        },
        textAlign: 'center',
        wordBreak: 'break-word',
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: {
            xs: 2,
            sm: 3
        },
        WebkitBoxOrient: 'vertical'
    },
    '.available-day .fc-event-main': {
        position: 'relative',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    '.calendar-event': {
        margin: '2px',
        padding: '4px',
        borderRadius: '4px',
        minHeight: {
            xs: '30px',
            sm: '40px',
            md: '50px'
        }
    }
};

const INITIAL_RESERVATION_DATA = {
    reservation_date: "",
    service_id: "",
    status: "not paid",
    payment: "",
    rest: "",
    price: "",
};

export default function Schedule() {
    const { t } = useTranslation();
    const [reservations, setReservations] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [serviceData, setServiceData] = useState(null);
    const [reservationData, setReservationData] = useState(INITIAL_RESERVATION_DATA);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [actionLoading, setActionLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const { serviceId } = useParams();
    const [isClient, setIsClient] = useState(false);
    const [userReservations, setUserReservations] = useState([]);
    const [lastFetchTime, setLastFetchTime] = useState(0);
    const FETCH_COOLDOWN = 5000; // 5 seconds cooldown between fetches

    useEffect(() => {
        try{
            const userCookie = Cookies.get("user");
            if (userCookie) {
                const user = JSON.parse(userCookie);
                setIsClient(user?.role_id === 4);
            }
        }catch (e){
            setIsClient(false);
        }})

    // Loading progress simulation
    useEffect(() => {
        if (!isLoading) return;

        const timer = setInterval(() => {
            setLoadingProgress((prev) => Math.min(prev + Math.random() * 10, 90));
        }, 500);

        return () => clearInterval(timer);
    }, [isLoading]);

    // API calls with cooldown check
    const fetchAvailableDays = useCallback(async (id) => {
        const now = Date.now();
        if (now - lastFetchTime < FETCH_COOLDOWN) {
            console.log("Skipping fetch - cooldown period");
            return;
        }

        try {
            const { status, data } = await availableDays(id);
            if (status === 200 && Array.isArray(data.available_dates)) {
                const userReservedDates = serviceData?.data?.reservations?.map(res => res.reservation_date) || [];

                const availableDates = data.available_dates.filter(day =>
                    !userReservedDates.includes(day.date)
                ).map(day => ({
                    reservation_date: day.date,
                    maxReservations: day.available_cars,
                    status: day.available_cars > 0 ? "Disponible" : "Complet",
                    price: serviceData?.data?.price || "0",
                }));

                setReservations(availableDates);
                setLastFetchTime(now);
            }
        } catch (error) {
            console.error("Failed to fetch available days:", error);
            toast.error(<ToastError message={t('schedule.errors.fetchAvailableDays')} />,
                { duration: 3000, toastStyle });
        }
    }, [t, serviceData, lastFetchTime]);

    const fetchService = useCallback(async (id) => {
        const now = Date.now();
        if (now - lastFetchTime < FETCH_COOLDOWN) {
            console.log("Skipping fetch - cooldown period");
            return;
        }

        try {
            const { status, data } = await getServiceById(id);
            if (status === 200 && data) {
                setServiceData(data);
                const userCookie = Cookies.get("user");
                if (userCookie) {
                    const user = JSON.parse(userCookie);
                    console.log("User:", user);
                    const userReservs = data.data.reservations?.filter(res => res.client.user_id === user.id) || [];
                    setUserReservations(userReservs.map(res => res.reservation_date));
                }
                setLastFetchTime(now);
            }
        } catch (error) {
            console.error("Failed to fetch service details:", error);
            toast.error(<ToastError message={t('schedule.errors.fetchServiceDetails')} />,
                { duration: 3000, toastStyle });
        }
    }, [t, lastFetchTime]);

    // Initial data fetch
    useEffect(() => {
        if (!serviceId) {
            console.error("Service ID is missing in the URL");
            toast.error(<ToastError message={t('schedule.errors.missingServiceId')} />,
                { duration: 3000, toastStyle});
            setIsLoading(false);
            return;
        }

        Promise.all([fetchService(serviceId)])
            .then(() => fetchAvailableDays(serviceId))
            .finally(() => {
                setLoadingProgress(100);
                setTimeout(() => setIsLoading(false), 500);
            });
    }, [serviceId, fetchAvailableDays, fetchService, t]);

    // Event handlers
    const handleDateClick = useCallback(({ dateStr }) => {
        const selectedDateData = reservations.find((res) => res.reservation_date === dateStr);

        if (userReservations.includes(dateStr)) {
            toast.error(<ToastError message={t('schedule.errors.dateNotAvailable')} />,
                { duration: 2000, toastStyle});
            return;
        }

        if (selectedDateData?.status === "Disponible" && !userReservations.includes(dateStr)) {
            setSelectedDate(dateStr);
            setReservationData(prev => ({
                ...prev,
                reservation_date: dateStr,
                service_id: serviceId
            }));
            setIsDialogOpen(true);
        } else {
            toast.error(<ToastError message={t('schedule.errors.dateNotAvailable')} />,
                { duration: 2000, toastStyle});
        }
    }, [reservations, serviceId, t, userReservations]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setActionLoading(true);

        const payment = parseFloat(reservationData.payment);
        if (!payment || payment <= 0) {
            toast.error(<ToastError message={t('schedule.errors.enterValidAmount')} />,
                { duration: 2000, toastStyle });
            setActionLoading(false);
            return;
        }

        const restAmount = serviceData?.data.price - payment;
        const newReservation = {
            ...reservationData,
            rest: restAmount.toString(),
            status: restAmount === 0 ? "paid" : "not paid",
        };

        try {
            const { status, data } = await reserveService(newReservation);

            if (status === 201) {
                setUserReservations(prev => [...prev, reservationData.reservation_date]);

                // Wait for cooldown before refreshing data
                const now = Date.now();
                const timeToWait = Math.max(0, FETCH_COOLDOWN - (now - lastFetchTime));

                setTimeout(async () => {
                    await Promise.all([
                        fetchAvailableDays(serviceId),
                        fetchService(serviceId)
                    ]);
                }, timeToWait);

                toast.success(
                    <ToastSuccess message={data?.message || t('schedule.success.reservationSuccess')} />,
                    { duration: 3000, toastStyle }
                );
                setSelectedEvent(newReservation);
                setIsDialogOpen(false);
            }
        } catch (error) {
            const errorMessage = error.response?.data?.error || t('schedule.errors.reservationError');
            toast.error(<ToastError message={errorMessage} />,
                { duration: 3000, toastStyle });
            console.error("Failed to reserve the service:", error);
        } finally {
            setActionLoading(false);
        }
    };

    const handleInputChange = useCallback(({ target: { name, value } }) => {
        setReservationData(prev => ({ ...prev, [name]: value }));
    }, []);

    // Memoized calendar events
    const events = useMemo(() => {
        const formattedEvents = reservations.map(reservation => {
            const isReservedByUser = userReservations.includes(reservation.reservation_date);
            return {
                title: "",
                start: reservation.reservation_date,
                extendedProps: {
                    status: selectedDate === reservation.reservation_date ? "Selected" :
                        isReservedByUser ? "Complet" :
                            reservation.status,
                    maxReservations: reservation.maxReservations,
                    price: reservation.price,
                },
                display: 'background',
                backgroundColor: selectedDate === reservation.reservation_date ? STATUS_COLORS.Selected :
                    isReservedByUser ? STATUS_COLORS.Complet :
                        STATUS_COLORS[reservation.status] || STATUS_COLORS.default,
                color: '#fff',
                classNames: ['calendar-event'],
                allDay: true,
            };
        });

        const nonAvailableDays = [];
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        for (let i = 0; i < 30; i++) {
            const date = new Date();
            date.setDate(date.getDate() + i);
            const formattedDate = date.toISOString().split("T")[0];

            if (date < today || !reservations.find((res) => res.reservation_date === formattedDate)) {
                nonAvailableDays.push({
                    start: formattedDate,
                    display: "background",
                    backgroundColor: COLORS.light.unavailableRed,
                });
            }
        }

        return [...formattedEvents, ...nonAvailableDays];
    }, [reservations, selectedDate, userReservations]);

    if (isLoading) {
        return (
            <div className="flex flex-col justify-center items-center h-screen gap-2">
                <CircularProgress
                    variant="determinate"
                    value={loadingProgress}
                    className="text-primaryDark dark:text-primaryDark"
                />
                <Typography variant="body2" className="text-gray-600 dark:text-gray-400">
                    Chargement... {Math.round(loadingProgress)}%
                </Typography>
            </div>
        );
    }

    return (
        <Fade in={!isLoading}>
            <div className="p-4 md:p-8 bg-[#F2F2F2] dark:bg-[#333333] min-h-screen">
                <Grid container spacing={4}>
                    <Grid item xs={12} lg={8}>
                        <div className="p-6 rounded-lg shadow-md hover:-translate-y-1 transition-transform duration-300 bg-white dark:bg-[#232323]">
                            <Typography variant="h5" gutterBottom className="font-medium text-black dark:text-white">
                                {t('schedule.reservationCalendar')}
                            </Typography>
                            <div className="my-5">
                                <FullCalendar
                                    plugins={[dayGridPlugin, interactionPlugin]}
                                    initialView="dayGridMonth"
                                    headerToolbar={{
                                        right: "prev,next",
                                        center: "title",
                                        left: "today",
                                    }}
                                    events={events}
                                    dateClick={handleDateClick}
                                    height="auto"
                                    eventContent={eventInfo => {
                                        if (eventInfo.event.extendedProps.status === "Disponible" && !userReservations.includes(eventInfo.event.start.toISOString().split('T')[0])) {
                                            return (
                                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-3 py-1.5 text-center font-medium rounded bg-opacity-10 backdrop-blur-sm transition-transform duration-200 hover:scale-105 text-white whitespace-nowrap">
                                                    {eventInfo.event.title}
                                                </div>
                                            );
                                        }
                                        return null;
                                    }}
                                    eventClassNames={arg =>
                                        arg.event.extendedProps.status === 'Disponible' && !userReservations.includes(arg.event.start.toISOString().split('T')[0])
                                            ? 'available-day'
                                            : 'unavailable-day'
                                    }
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <div className="p-6 rounded-lg shadow-md hover:-translate-y-1 transition-transform duration-300 bg-white dark:bg-[#232323]">
                            <Typography variant="h5" gutterBottom className="font-medium text-black dark:text-white">
                                {t('schedule.serviceDetails')}
                            </Typography>
                            <div className="my-6 py-4 border-t border-b border-[#EAEAEA] dark:border-[#494949]">
                                <ServiceDetails serviceData={serviceData} selectedEvent={selectedEvent}/>
                            </div>

                            {isClient ? (
                                <div className="cursor-pointer flex px-4 py-3 max-h-[40px] min-h-[40px] flex-row items-center justify-center gap-2 rounded-lg bg-[#FFE9E4] dark:bg-[#494949] border-2 border-[#FF7354] dark:border-[#E44826]">
                                    <img src="/resources/card.svg" alt="card" className="primary_filter dark:white_filter" />
                                    <p className="font-medium text-[13px] text-[#ff5722] dark:text-[#F9CDC4] text-ellipsis whitespace-nowrap">
                                        {t('schedule.payNow')}
                                    </p>
                                </div>
                            ) : (
                                <div className="cursor-pointer flex px-4 py-3 max-h-[40px] min-h-[40px] flex-row items-center justify-center gap-2 rounded-lg bg-gray-500 dark:bg-[#333333] border-2 border-gray-400 dark:border-[#595959]">
                                    <img src="/resources/card.svg" alt="card" className="white_filter" />
                                    <p className="font-medium text-[13px] text-white text-ellipsis whitespace-nowrap">
                                        {t('reservedForClients')}
                                    </p>
                                </div>
                            )}
                        </div>
                    </Grid>
                </Grid>

                <ReservationDialog
                    isOpen={isDialogOpen}
                    onClose={() => {
                        setIsDialogOpen(false);
                        setSelectedDate(null);
                    }}
                    reservationData={reservationData}
                    onInputChange={handleInputChange}
                    onSubmit={handleFormSubmit}
                    actionLoading={actionLoading}
                    serviceData={serviceData}
                    availableCars={reservations.find(r => r.reservation_date === selectedDate)?.maxReservations}
                />
            </div>
        </Fade>
    );
}

const ServiceDetails = React.memo(({ serviceData, selectedEvent }) => {
    const { t } = useTranslation();
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
        try{
            const userCookie = Cookies.get("user");
            if (userCookie) {
                const user = JSON.parse(userCookie);
                setIsClient(user?.role_id === 4);
            }
        }catch (e){
            setIsClient(false);
        }})

    return (
        <Grid container spacing={2}>
            {[
                {label: t('schedule.serviceInfo.serviceName'), value: serviceData?.data.name},
                {label: t('schedule.serviceInfo.reservationDate'), value: selectedEvent?.reservation_date || t('schedule.serviceInfo.na')},
                {label: t('schedule.serviceInfo.amountPaid'), value: selectedEvent ? formatCurrency(selectedEvent.payment) : "0 DA"},
                {label: t('schedule.serviceInfo.remaining'), value: selectedEvent ? formatCurrency(selectedEvent.rest) : "0 DA"},
                {label: t('schedule.serviceInfo.price'), value: formatCurrency(serviceData?.data.price)},
            ].map(({label, value}) => (
                <React.Fragment key={label}>
                    <Grid item xs={6}>
                        <Typography className="text-gray-600 dark:text-[#CBCBCB] font-medium">
                            {label}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle1"
                            className={`font-semibold ${
                                label === t('schedule.serviceInfo.remaining') && selectedEvent?.rest > 0
                                    ? 'text-[#FFBE18] dark:text-[#FFBE18]'
                                    : 'text-black dark:text-white'
                            }`}
                        >
                            {value}
                        </Typography>
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    );
});

const ReservationDialog = React.memo(({
                                          isOpen,
                                          onClose,
                                          reservationData,
                                          onInputChange,
                                          onSubmit,
                                          actionLoading,
                                          serviceData,
                                          availableCars
                                      }) => {
    const { t } = useTranslation();
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            PaperProps={{
                className: "rounded-lg min-w-[90%] sm:min-w-[400px] bg-white dark:bg-[#232323]"
            }}
        >
            <DialogTitle className="border-b border-[#e0e0e0] dark:border-[#494949] pb-4 text-black dark:text-white">
                {t('schedule.dialog.title')}
            </DialogTitle>
            <DialogContent className="mt-4">
                <div className="mb-6 p-4 bg-[#FFE9E4] dark:bg-[#494949] rounded">
                    <Typography variant="subtitle1" className="text-[#ff5722] dark:text-[#F9CDC4] font-medium mb-2">
                        {t('schedule.dialog.availableCars')}: {availableCars}
                    </Typography>
                </div>
                <TextField
                    margin="dense"
                    label={t('schedule.dialog.reservationDate')}
                    type="text"
                    fullWidth
                    value={reservationData.reservation_date}
                    InputProps={{
                        readOnly: true,
                        className: "text-black dark:text-white"
                    }}
                    className="mb-4"
                />
                <TextField
                    margin="dense"
                    label={t('schedule.dialog.amountPaid')}
                    type="number"
                    fullWidth
                    name="payment"
                    value={reservationData.payment}
                    onChange={onInputChange}
                    required
                    helperText={`${t('schedule.dialog.totalPrice')}: ${formatCurrency(serviceData?.data.price)}`}
                    InputProps={{
                        endAdornment: <Typography variant="caption" className="text-black dark:text-white">DA</Typography>,
                        className: "text-black dark:text-white"
                    }}
                />
            </DialogContent>
            <DialogActions className="p-4 border-t border-[#e0e0e0] dark:border-[#494949]">
                <Button
                    onClick={onClose}
                    className="text-[#747474] dark:text-[#CBCBCB] hover:bg-opacity-5"
                >
                    {t('schedule.dialog.cancel')}
                </Button>
                <Button
                    onClick={onSubmit}
                    disabled={actionLoading}
                    className={`bg-[#ff5722] dark:bg-[#E44826] text-white px-6 hover:bg-[#FF7354] dark:hover:bg-[#F9CDC4] disabled:bg-[#EAEAEA] dark:disabled:bg-[#494949] disabled:text-[#747474] dark:disabled:text-[#797979]`}
                >
                    {actionLoading ? (
                        <div className="flex items-center gap-2">
                            <CircularProgress size={20} className="text-current" />
                            <span>{t('schedule.dialog.reserving')}</span>
                        </div>
                    ) : (
                        t('schedule.dialog.reserve')
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
});