import React from 'react';
import PropTypes from 'prop-types';
import {Check, Minus, Plus} from 'lucide-react';

export default function Checkbox({
                                     checkedValueState,
                                     handleCheck,
                                     indeterminate = false,
                                     disabled = false
                                 }) {
    const [checkedState, setCheckedState] = checkedValueState;

    const handleCheckboxChange = (event) => {
        if (disabled) return;

        if (handleCheck instanceof Function) {
            handleCheck(event);
            return;
        }
        setCheckedState(!checkedState);
    };

    const baseStyles = `
    cta
    min-w-[20px] max-w-[20px] min-h-[20px] max-h-[20px] 
    rounded flex justify-center items-center transition-all duration-200
    ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:brightness-95'}
    ${checkedState || indeterminate
        ? 'bg-[#FF7354] dark:bg-[#E44826] border-transparent'
        : 'bg-white dark:bg-[#333333] border border-zinc-300 dark:border-[#595959]'}
  `;

    const iconStyles = "w-3.5 h-3.5 text-white dark:text-[#F2F2F2]";

    return (
        <div
            role="checkbox"
            aria-checked={checkedState}
            aria-disabled={disabled}
            onClick={handleCheckboxChange}
            className={baseStyles}
        >
            <input
                type="checkbox"
                checked={checkedState}
                onChange={() => {
                }}
                disabled={disabled}
                className="hidden cta"
            />
            {indeterminate ? (
                <Minus className={iconStyles}/>
            ) : checkedState ? (
                <Check className={iconStyles}/>
            ) : (
                <Plus className={`${iconStyles} opacity-0`}/>
            )}
        </div>
    );
}

Checkbox.propTypes = {
    checkedValueState: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.func
        ])
    ).isRequired,
    handleCheck: PropTypes.func,
    indeterminate: PropTypes.bool,
    disabled: PropTypes.bool
};