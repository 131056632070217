import {useTranslation} from "react-i18next";

function ConditionsTable({ conditions = [] }) {
    console.log("conditions", conditions);
    const { t } = useTranslation();
    // Check if conditions is an array and has elements
    if (!Array.isArray(conditions) || conditions.length === 0) {
        return <p className="text-center text-gray-500 dark:text-[#797979] py-4">{t("noConditionAvailable")}</p>;
    }

    return (
        <div className="w-full overflow-x-auto">
            <table className="min-w-full w-full table-auto border-collapse border border-gray-300 dark:border-[#494949]">
                <thead>
                <tr className="bg-gray-200 dark:bg-[#333333]">
                    <th className="px-4 py-2 sm:px-6 sm:py-3 text-left font-semibold text-gray-800 dark:text-[#F2F2F2]">
                        {t("conditionName")}
                    </th>
                    <th className="px-4 py-2 sm:px-6 sm:py-3 text-left font-semibold text-gray-800 dark:text-[#F2F2F2]">
                        {t("description")}
                    </th>
                </tr>
                </thead>
                <tbody>
                {conditions.map((item) => (
                    <tr key={item.id} className="hover:bg-gray-100 dark:hover:bg-[#494949] transition duration-200">
                        <td className="border-t border-gray-300 dark:border-[#494949] px-4 py-2 sm:px-6 sm:py-4 dark:text-[#CBCBCB]">
                            {item.condition?.name || "N/A"}
                        </td>
                        <td className="border-t border-gray-300 dark:border-[#494949] px-4 py-2 sm:px-6 sm:py-4 dark:text-[#CBCBCB]">
                            {item.condition?.condition_description || "N/A"}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default ConditionsTable;