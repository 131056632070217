import {useEffect, useState} from "react";
import {useFavoriteContext} from "../../../hooks/context/useFavoriteContext.ts";
import PropTypes from "prop-types";


export default function AddToFavorite({id, ElementType}) {
    const {addFavorite, favoriteList, removeFavorite} = useFavoriteContext();
    const [active, setActive] = useState(favoriteList.some(favorite => favorite.item === Number(id) && favorite.elementType === ElementType));
    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        setActive(favoriteList.some(favorite => favorite.item === Number(id) && favorite.elementType === ElementType));
    }, [ElementType, favoriteList, id]);

    useEffect(() => {
        // Initial dark mode check
        const isDark = localStorage.getItem('darkMode') === 'true';
        setDarkMode(isDark);

        // Listen for dark mode changes
        const handleDarkModeChange = (e) => {
            if (e.key === 'darkMode') {
                setDarkMode(e.newValue === 'true');
            }
        };

        window.addEventListener('storage', handleDarkModeChange);

        // Watch for DOM class changes to detect dark mode toggle
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {
                    setDarkMode(document.documentElement.classList.contains('dark'));
                }
            });
        });

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['class']
        });

        // Cleanup listeners
        return () => {
            window.removeEventListener('storage', handleDarkModeChange);
            observer.disconnect();
        };
    }, []);

    return (
        <div
            onClick={() => {
                if (favoriteList.some(favorite => favorite.item === Number(id) && favorite.elementType === ElementType)) {
                    removeFavorite(Number(id), ElementType);
                } else {
                    addFavorite(Number(id),ElementType);
                }
            }}
            className="wishlist h-[42px] w-[42px] bg-white dark:bg-[#595959] rounded-full border-border dark:border-[#494949] border cursor-pointer flex items-center justify-center ">
            {
                active ?
                    <img src="/resources/heart-active.svg" alt="heart-filled" className="wishlist"/>
                    :
                    <img src="/resources/heart.svg" alt="heart" className={`wishlist ${darkMode ? 'white_filter' : ''}`}/>
            }
        </div>
    )
}

AddToFavorite.propTypes = {
    id: PropTypes.number.isRequired,
    ElementType: PropTypes.oneOf(["product","service","vehicle","boat"]).isRequired
};