import {api} from '../instance'

export const Conversations = () => {
    return api.get(`/api/conversations` )
}
export const editConversation=(id,name) => {
    return api.put(`/api/conversations/${id}`, name)
}
export const StoreConversation = (client_id,seller_id) => {
    return api.post(`/api/conversations`, {client_id,seller_id})
}

export const ConversationMessages = (id,pages) => {
    return api.get(`api/conversations/${id}/messages?page=${pages}`)
}

export const StoreMessages = (data) => {
    return api.post(`api/messages`, data)
}

export const getAllShops =() => {
    return api.get(`api/allShops`)
}