import React, { useEffect, useState } from "react";
import { useAdminSpaceContext } from "../hooks/context";
import Dashboard from "../components/Dashboard/pages/dashboard";
import Users from "../components/Users/users";
import Soumission from "../components/soumissions/soumissions";
import ProductConfig from "../components/platformConfig/ProductConfig";
import VehicleConfig from "../components/platformConfig/config";
import Reports from "../components/reports"
import Subscription from "../components/Subscription/subscriptions";
import ConfigService from "../components/platformConfig/ConfigSevice";
import Promotions from "../components/offers/offers";
import EnginConfig from "../components/platformConfig/EnginConfig";
import VehicleSubmissions from "../components/soumissions/VehicleSubmissions";
import MotoConfig from "../components/platformConfig/MotoConfig";
import CamionConfig from "../components/platformConfig/CamionConfig";

export default function AdminSpace() {
    const { selectedTab } = useAdminSpaceContext();
    const [content, updateContent] = useState(null);

    useEffect(() => {
        switch (selectedTab) {
            case 0:
                updateContent(<Dashboard />);
                break;
            case 1:
                updateContent(<Users />);
                break;
            case 2:
                updateContent(<Promotions />);
                break;
            case 3:
                updateContent(<Soumission />);
                break;
            case 4:
                updateContent(<VehicleSubmissions />);
                break;
            case 5:
                updateContent(<Subscription />);
                break;
            case 6:
                updateContent(<Reports />);
                break;
            case 7:
                updateContent(<ProductConfig />);
                break;
            case 8:
                updateContent(<ConfigService />);
                break;
            case 9:
                updateContent(<VehicleConfig />);
                break;
            case 10:
                updateContent(<MotoConfig />);
                break;
            case 11:
                updateContent(<CamionConfig />);
                break;
            case 12:
                updateContent(<EnginConfig />);
                break;
            default:
                updateContent(<Users />);
                break;
        }
    }, [selectedTab]);

    return (
        <div className="flex-1 overflow-auto">
            {content}
        </div>
    );
}
