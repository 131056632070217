import { useTranslation } from 'react-i18next';
import { usePersonalInfoContext } from "../../Pages/SignUp";
import { useNavigate } from 'react-router-dom';

export default function AuthSideBarContent() {
    const { currentStep } = usePersonalInfoContext();
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <aside
            className="m-2 flex-1 hidden md:flex flex-col items-center justify-between bg-gradient-to-b from-primaryLight to-black text-white p-4 xl:p-8 2xl:p-12 rounded-xl"
        >
            <header className="w-full flex justify-between">
                <button onClick={() => navigate("/")}>
                    <img src="/resources/white_Logo.svg" alt="MecaNext Logo" className="w-28 xl:w-32" />
                </button>
                <button
                    onClick={()=>navigate("/")}
                    className="flex flex-row px-2 py-1 gap-1 md:gap-2 items-center rounded bg-white bg-opacity-30">
                    <img src="/resources/left-line.svg" alt="left" className="w-4 white_filter"/>
                    <p className='text-sm font-medium'>{t("back_to_marketplace")}</p>
                </button>
            </header>

            <div className="text-center space-y-6">
                <h1 className="text-2xl xl:text-3xl 2xl:text-4xl font-bold leading-tight">
                    {t('welcomeTitle')}
                </h1>
                <p className="text-sm xl:text-normal 2xl:text-lg font-light">
                    {t('welcomeDescription')}
                </p>
                <p className="text-xs xl:text-sm">
                    {t('welcomeCallToAction')}
                </p>
            </div>

            <div className="w-full max-w-md md:space-y-2 lg:space-y-3 xl:space-y-5 2xl:space-y-6">
                <h2 className="text-sm xl:text-lg 2xl:text-xl font-semibold text-center">
                    {t('stepsTitle')}
                </h2>
                {[
                    { step: 1, title: t('steps.1'), active: currentStep === 1 },
                    { step: 2, title: t('steps.2'), active: currentStep === 2 },
                    { step: 3, title: t('steps.3'), active: currentStep === 3 }
                ].map(({ step, title, active }) => (
                    <div
                        key={step}
                        className={`overflow-hidden ${
                            active
                                ? "[background:linear-gradient(45deg,#172033,theme(colors.slate.800)_50%,#172033)_padding-box,conic-gradient(from_var(--border-angle),theme(colors.slate.600/.48)_80%,_theme(colors.indigo.500)_86%,_theme(colors.indigo.300)_90%,_theme(colors.indigo.500)_94%,_theme(colors.slate.600/.48))_border-box] rounded-2xl border-[3px] border-transparent animate-border"
                                : ""
                        }`}
                    >
                        <div
                            className={`flex w-full h-full items-center gap-4 p-2 xl:p-3 2xl:p-4 rounded-lg xl:rounded-xl ${
                                active ? "bg-[#f1f1f1] text-black" : "bg-gray-800 text-gray-300"
                            }`}
                        >
                            <div
                                className={`w-8 h-8 xl:w-10 xl:h-10 flex items-center justify-center rounded-full ${
                                    active ? "bg-primaryLight text-white" : "bg-gray-600"
                                }`}
                            >
                                {step}
                            </div>
                            <p className="md:text-xs lg:text-sm xl:text-normal font-medium">{title}</p>
                        </div>
                    </div>
                ))}
            </div>

            <footer className="text-sm text-center mt-8">
                <p>
                    {t('footerText')}{' '}
                    <a href="/about-us" className="text-primaryLight underline">
                        {t('footerLink')}
                    </a>
                </p>
            </footer>
        </aside>
    );
}
