import React, {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import CarBanner from "./components/CarBanner";
import ProductCardSearchItem from "./components/ProductCardSearchItem/ProductCardSearchItem";
import PackCard from "../Marketplace/components/Cards/PackCard";
import {getEnginBrands, getEnginCategories, globalSearch, productSearch} from "../../services/marketplace/API";
import Carousel from "../Marketplace/components/Carousel/Carousel";
import Pagination from "../../components/PaginationComps/Pagination";
import PriceRangeFilter from "./components/Filters/Price/PriceRangeFilter";
import MultiOptionFilter from "./components/Filters/MultiOptionFilter/MultiOptionFilter";
import AvisClient from "./components/Filters/AvisClient/AvisClient";
import {getProductBrands} from "../../services/SellerSpace/API";
import CarsCardSearchItem from "./components/CarsCardSearchItem/CarsCardSearchItem";
import BoatsCardSearchItem from "./components/BoatsCardSearchItem/BoatsCardSearchItem";
import {create} from "zustand";
import ConditionFilter from "./components/Filters/ConditionFilter/ConditionFilter";
import StockAvailability from "./components/Filters/StockAvailability/StockAvailability";
import GenericSelectFilter from "./components/Filters/GenericSelectFilter/GenericSelectFilter";
import NumberInputFilter from "./components/Filters/YearFilter/NumberInputFilter";
import {MileageRange} from "./components/Filters/MileageRange/MileageRange";
import {useCompatibleVehicleContext} from "../../components/CompatibleVehicules/CompatibleVehicles";
import {useNavigate} from "react-router";
import EnginsCardSearchItem from "./components/EnginsCardSearchItem/EnginsCardSearchItem";
import {RangeFilter} from "./components/Filters/RangeFilter/RangeFilter";
import Logo from "../../components/Logo/Logo";
import {useMarketplaceContext} from "../Marketplace";
import {useNonMarketPlaceRoutesContext} from "../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import MapTile, {useGetCurrentLocationName} from "../../components/MapTiles/MapTile";
import {MapPinOff, MapPin,Filter} from 'lucide-react';
import LocationFilter from "./components/Filters/LocationFilter/LocationFilter";
import RadiusRangeFilter from "./components/Filters/RadiusRangeFilter/RadiusRangeFilter";


export default function SearchPage() {
    const [packs, setPacks] = useState([]);
    const ref = useRef();
    const [currentPage, updateCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(10);
    const [filters, updateFilters] = useState(null);
    const [filtersOptions, updateFiltersOptions] = useState(null);
    const [filtersVisible, updateFiltersVisible] = useState(false);
    const [width, updateWidth] = useState(window.innerWidth);

    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);
    const [results, setResults] = useState([]); // Original full list of results
    const [filteredResults, setFilteredResults] = useState([]); // Results after filtering

    const {minRating, condition, stockAvailability} = useFilterContext();


    const [priceRange, setPriceRange] = useState({min: 0, max: 0}); // Example price range filter
    const [selectedBrands, setSelectedBrands] = useState([]); // Selected brands for filtering
    const [selectedCategory, setSelectedCategory] = useState([]);

    const location = useLocation();
    const {category, term: searchTerm} = location.state || {};

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.addEventListener('resize', () => {
            updateWidth(window.innerWidth);
        });
        return () => {
            window.removeEventListener('resize', () => {
                updateWidth(window.innerWidth);
            });
        };
    }, []);

    useEffect(() => {
        const handleFilterClick = () => {
            if (width < 815) {
                updateFiltersVisible(true);
            }
        };

        updateFilters(
            <div
                className="flex flex-col sm:min-w-[15.6vw] sm:max-w-[15.6vw] px-4 gap-2 border border-[#eaeaea] dark:border-[#494949] rounded-lg pb-4">
                <button
                    style={{cursor: width > 640 ? "default" : "pointer"}}
                    onClick={handleFilterClick}
                    className="h-14 flex flex-row items-center justify-between border-b border-b-borderLight dark:border-b-[#494949] w-full gap-4"
                >
                    <div className="flex flex-row items-center gap-2">
                        <Filter  className="w-4"/>
                        <p className="font-medium">Filtres</p>
                    </div>
                </button>
                {width > 815 && filtersOptions}
            </div>
        );
    }, [filtersOptions, filtersVisible, width]);

    const {brand, model, motorisation} = useCompatibleVehicleContext();

    const {
        category_id,
        sub_category_id
    } = useMarketplaceContext();

    const [shops, setShops] = useState([]);

    const [currentCoordinates, setCurrentCoordinates] = useState([]);
    const [coordinatesRangeRadius, setCoordinatesRangeRadius] = useState(10);

    useEffect(() => {
        if (category_id === null && sub_category_id === null && (searchTerm || brand || model || motorisation)) {
            setPacks([]);
            setLoading(true);
            globalSearch(currentPage, category, searchTerm, brand?.id, model?.id, motorisation?.id, currentCoordinates?.[0], currentCoordinates?.[1], coordinatesRangeRadius).then((response) => {
                    if (response.status === 200) {
                        if (category === "products") {
                            const products = response.data.products.data;
                            setTotalPages(response.data.products.last_page);
                            setResults(products);
                            setFilteredResults(products);
                            setShops(response.data.shops);
                            setPacks(response.data.offers)
                            setLoading(false);
                        } else if (category === "cars") {
                            const cars = response.data.cars.data;
                            setTotalPages(response.data.cars.last_page);
                            setResults(cars);
                            setFilteredResults(cars);
                            setLoading(false);
                        } else if (category === "boats") {
                            console.log(response.data.boats.data);
                            const boats = response.data.boats.data;
                            setTotalPages(response.data.boats.last_page);
                            setResults(boats);
                            setFilteredResults(boats);
                            setLoading(false);
                        } else if (category === "engins") {
                            console.warn(response.data)
                            const engins = response.data.engins.data;
                            setTotalPages(response.data.engins.last_page);
                            setResults(engins);
                            setFilteredResults(engins);
                            setLoading(false);
                        }
                    }
                }
            ).catch((e) => {
                setLoading(false);
                console.log(e);
            });
        } else {

            productSearch(currentPage, null, category_id, sub_category_id, motorisation?.id, model?.id, brand?.id).then((response) => {
                if (response.status === 200) {
                    setResults(response.data.data);
                    setTotalPages(response.data.last_page)
                    setFilteredResults(response.data.data);
                    setLoading(false);
                }
            }).catch((e) => {

            })
        }
    }, [brand, category, category_id, coordinatesRangeRadius, currentCoordinates, currentPage, model, motorisation, searchTerm, sub_category_id]);

    const [positions, setPositions] = useState([]);
    useEffect(() => {
        setPositions(
            shops?.map((shop) => {
                return {
                    lat: shop?.localization?.latitude,
                    lng: shop?.localization?.longitude
                }
            })
        )
    }, [shops]);

    useEffect(() => {
        if (category === "products" || category_id !== null || sub_category_id !== null) {
            getProductBrands().then((response) => {
                setBrands(response.data);
            }).catch((e) => {
                console.log(e);
            });
        }
    }, [category, category_id, sub_category_id]);

    useEffect(() => {
        if (category === "engins") {
            getEnginBrands().then((response) => {
                setBrands(response.data);
            }).catch((e) => {
                console.log(e);
            })
            getEnginCategories().then((response) => {
                setCategories(response.data);
            }).catch((e) => {
                console.log(e);
            })
        }
    }, [category]);

    const {
        updateGearbox,
        gearbox,
        updateEnergy,
        energy,
        updatePapers,
        papers,
        mileage,
        updateMileage,
        year,
        updateYear,
        width: boatWidth,
        length,
        boatType,
        motorType,
        material,
        updateBoatType,
        updateLength,
        updateWidth: updateBoatWidth,
        updateMaterial,
        updateMotorType,
        traction,
        weight,
        power,
        engin_options,
        updatePower,
        height,
        updateHeight,
        updateWeight,
        updateTraction,
        updateEnginOptions,
        updateFilterFlyOutMenuVisible
    } = useFilterContext();

    useEffect(() => {
        if (category === "products" || category_id != null || sub_category_id != null) {
            updateFiltersOptions(
                <>
                    <PriceRangeFilter value={priceRange} onChange={setPriceRange}/>
                    <LocationFilter onChange={setCurrentCoordinates}/>
                    <RadiusRangeFilter value={coordinatesRangeRadius} onChange={setCoordinatesRangeRadius}/>
                    <MultiOptionFilter title="Marque" options={brands.map((brand) => brand.brand_name)}
                                       selectedOptions={selectedBrands} onChange={setSelectedBrands}/>
                    <AvisClient/>
                    <ConditionFilter title="Condition" options={["Neuf", "Occasion"]}/>
                    <StockAvailability/>
                </>
            );
        } else if (category === "cars") {
            updateFiltersOptions(
                <>
                    <PriceRangeFilter value={priceRange} onChange={setPriceRange} unit={"Million"}/>
                    <MileageRange/>
                    <GenericSelectFilter title={"Boite de vitesse"} onChange={updateGearbox}
                                         options={
                                             [
                                                 {value: "Automatic", label: "Automatique"},
                                                 {value: "Manual", label: "Manuelle"},
                                                 {value: "Semi-Automatic", label: "Semi-Automatique"}
                                             ]}
                    />
                    <GenericSelectFilter title={"Energie"} onChange={updateEnergy}
                                         options={[
                                             {value: 'essence', label: 'Essence'},
                                             {value: 'diesel', label: 'Diesel'},
                                             {value: 'hybride', label: 'Hybride'},
                                             {value: 'GPL', label: 'GPL'}
                                         ]}
                    />
                    <GenericSelectFilter title={"Papier"} onChange={updatePapers}
                                         options={[
                                             {value: "Carte Grise / safia", label: "Carte Grise / safia"},
                                             {value: "Carte Jaune", label: "Carte Jaune"},
                                             {value: "Licence moudjahid", label: "Licence moudjahid"}
                                         ]}
                    />
                    <NumberInputFilter onChange={updateYear} value={year} min={1960} max={new Date().getFullYear()}
                                       placeholder={new Date().getFullYear()} titre={"Année"}/>
                </>
            );
        } else if (category === "boats") {
            updateFiltersOptions(
                <>
                    <PriceRangeFilter value={priceRange} onChange={setPriceRange} unit={"Million"}/>
                    <GenericSelectFilter title={"Type de bateau"} onChange={updateBoatType}
                                         options={[
                                             {value: "Jet Ski", label: "Jet Ski"},
                                             {value: "Voilier", label: "Voilier"},
                                             {value: "Yacht", label: "Yacht"},
                                             {value: "Catamaran", label: "Catamaran"},
                                             {
                                                 value: "Bateau de pêche en mer (gros)",
                                                 label: "Bateau de pêche en mer (gros)"
                                             },
                                             {value: "Chalutier", label: "Chalutier"},
                                             {value: "Dory-flouka", label: "Dory-flouka"}
                                         ]}
                    />
                    <GenericSelectFilter title={"Type de moteur"} onChange={updateMotorType}
                                         options={
                                             [{value: "hors-bord", label: "hors-bord"},
                                                 {value: "in-bord", label: "in-bord"}
                                             ]}
                    />
                    <GenericSelectFilter title={"Matériel"} onChange={updateMaterial}
                                         options={[
                                             {value: "Résine", label: "Résine"},
                                             {value: "Bois", label: "Bois"},
                                             {value: "Aluminium", label: "Aluminium"},
                                             {
                                                 value: "composite(melange de matériaux)",
                                                 label: "composite(melange de matériaux)"
                                             },
                                             {value: "carbon", label: "carbon"},
                                             {value: "injection plastique", label: "injection plastique"}
                                         ]}
                    />
                    <RangeFilter value={boatWidth} onChange={updateBoatWidth} title="Largeur" unit={"m"}/>
                    <NumberInputFilter onChange={updateLength} value={length} min={0} titre={"Longueur"}
                                       placeholder={0}/>
                    <NumberInputFilter onChange={updateYear} value={year} min={1900} max={new Date().getFullYear()}
                                       titre={"Année"} placeholder={new Date().getFullYear()}/>
                </>
            )
        } else if (category === "engins") {
            updateFiltersOptions(
                <>
                    <PriceRangeFilter value={priceRange} onChange={setPriceRange} unit={"Million"}/>
                    <GenericSelectFilter title={"Système de traction"} onChange={updateTraction}
                                         options={[
                                             {value: "Chenilles", label: "Chenilles"},
                                             {value: "Roues", label: "Roues"},
                                         ]}
                    />
                    <MultiOptionFilter title="Catégorie d'engins"
                                       options={categories.map((category) => category.category_name)}
                                       selectedOptions={selectedCategory} onChange={setSelectedCategory}/>
                    <MultiOptionFilter title="Marque" options={brands.map((brand) => brand.brand_name)}
                                       selectedOptions={selectedBrands} onChange={setSelectedBrands}/>

                    <GenericSelectFilter title={"Options"} onChange={updateEnginOptions}
                                         options={[
                                             {value: "Climatisation", label: "Climatisation"},
                                             {value: "Cabine fermée", label: "Cabine fermée"},
                                             {value: "Caméra de recul", label: "Caméra de recul"},
                                             {value: "Système GPS", label: "Système GPS"},
                                             {
                                                 value: "Système hydraulique avancé ",
                                                 label: "Système hydraulique avancé "
                                             }
                                         ]}
                    />
                    <RangeFilter value={weight} onChange={updateWeight} title="Poids" unit={"tonnes"}/>
                    <RangeFilter value={height} onChange={updateHeight} title="Hauteur" unit={"m"}/>
                    <RangeFilter value={length} onChange={updateLength} title="Longueur" unit={"m"}/>
                    <RangeFilter value={boatWidth} onChange={updateBoatWidth} title="Largeur" unit={"m"}/>
                    <RangeFilter value={power} onChange={updatePower} title="Puissance" unit={"kW/HP"}/>
                    <GenericSelectFilter title={"Boite de vitesse"} onChange={updateGearbox}
                                         options={
                                             [
                                                 {value: "Automatic", label: "Automatique"},
                                                 {value: "Manual", label: "Manuelle"},
                                                 {value: "Semi-Automatic", label: "Semi-Automatique"}
                                             ]}
                    />
                    <GenericSelectFilter title={"Energie"} onChange={updateEnergy}
                                         options={[
                                             {value: 'Essence', label: 'Essence'},
                                             {value: 'Diesel', label: 'Diesel'},
                                             {value: 'Hybride', label: 'Hybride'},
                                             {value: 'GPL', label: 'GPL'}
                                         ]}
                    />
                    <NumberInputFilter onChange={updateYear} value={year} min={1900} max={new Date().getFullYear()}
                                       titre={"Année"} placeholder={new Date().getFullYear()}/>
                </>
            )
        }
    }, [boatWidth, brands, categories, category, category_id, coordinatesRangeRadius, height, length, mileage, power, priceRange, selectedBrands, selectedCategory, sub_category_id, updateBoatType, updateBoatWidth, updateEnergy, updateEnginOptions, updateGearbox, updateHeight, updateLength, updateMaterial, updateMileage, updateMotorType, updatePapers, updatePower, updateTraction, updateWeight, updateYear, weight, width, year]);

    useEffect(() => {
        const applyFilters = () => {
            let filtered = [...results];

            if (priceRange.min > 0) {
                filtered = filtered.filter(item => item.price >= priceRange.min);
            }

            if (priceRange.max > 0) {
                filtered = filtered.filter(item => item.price <= priceRange.max);
            }

            if (category === "products" || category_id !== null || sub_category_id !== null) {
                if (selectedBrands.length > 0) {
                    filtered = filtered.filter(item => selectedBrands.includes(item.brand.brand_name));
                }

                // Condition Filter
                if (condition) {
                    filtered = filtered.filter(item => condition === item.characteristics.Etat);
                }

                // Rating Filter
                if (minRating > 0) {
                    filtered = filtered.filter(item => item.average_rating >= minRating);
                }

                if (stockAvailability) {
                    filtered = filtered.filter(item => item.stock_quantity > 0);
                }
            } else if (category === "cars") {
                if (gearbox) {
                    filtered = filtered.filter(item => item.gearbox === gearbox);
                }
                if (energy) {
                    filtered = filtered.filter(item => item.energy === energy);
                }
                if (papers) {
                    filtered = filtered.filter(item => item.papers === papers);
                }
                if (mileage.min > 0) {
                    console.log("min mileage" + mileage.min)
                    filtered = filtered.filter(item => item.mileage >= mileage.min);
                }
                if (mileage.max > 0) {
                    console.log("max mileage" + mileage.max)
                    filtered = filtered.filter(item => item.mileage <= mileage.max);
                }
            } else if (category === "boats") {
                console.log("boat type" + boatType)
                if (boatType) {
                    filtered = filtered.filter(item => item.type === boatType);
                }
                if (motorType) {
                    filtered = filtered.filter(item => item.characteristics.motor_type === motorType);
                }
                if (material) {
                    filtered = filtered.filter(item => item.characteristics.material === material);
                }
                if (boatWidth > 0) {
                    filtered = filtered.filter(item => item.width >= boatWidth);
                }
                if (length > 0) {
                    filtered = filtered.filter(item => item.length >= length);
                }
                if (year > 0) {
                    filtered = filtered.filter(item => item.characteristics.year >= Number(year));
                }
                if (priceRange.max > 0) {
                    filtered = filtered.filter(item => item.price <= priceRange.max);
                }
                if (priceRange.min > 0) {
                    filtered = filtered.filter(item => item.price >= priceRange.min);
                }
            } else if (category === "engins") {
                if (traction) {
                    filtered = filtered.filter(item => item.characteristics.traction === traction);
                }
                if (weight.min > 0) {
                    filtered = filtered.filter(item => item.characteristics.weight >= weight.min);
                }
                if (weight.max > 0) {
                    filtered = filtered.filter(item => item.characteristics.weight <= weight.max);
                }
                if (power.min > 0) {
                    filtered = filtered.filter(item => item.characteristics.power >= power.min);
                }
                if (power.max > 0) {
                    filtered = filtered.filter(item => item.characteristics.power <= power.max);
                }
                if (height.min > 0) {
                    filtered = filtered.filter(item => item.characteristics.height >= height.min);
                }
                if (height.max > 0) {
                    filtered = filtered.filter(item => item.characteristics.height <= height.max);
                }
                if (selectedCategory.length > 0) {
                    filtered = filtered.filter(item => selectedCategory.includes(item.category.category_name));
                }
                if (selectedBrands.length > 0) {
                    filtered = filtered.filter(item => selectedBrands.includes(item.brand.brand_name));
                }
                if (engin_options) {
                    filtered = filtered.filter(item => item.characteristics?.engin_options?.split(",")?.includes(engin_options));
                }
                if (year > 0) {
                    filtered = filtered.filter(item => item.characteristics.year >= year);
                }
                if (length.min > 0) {
                    filtered = filtered.filter(item => item.characteristics.length >= length.min);
                }
                if (length.max > 0) {
                    filtered = filtered.filter(item => item.characteristics.length <= length.max);
                }
                if (boatWidth.min > 0) {
                    filtered = filtered.filter(item => item.characteristics.width >= boatWidth.min);
                }
                if (boatWidth.max > 0) {
                    filtered = filtered.filter(item => item.characteristics.width <= boatWidth.max);
                }
                if (gearbox) {
                    filtered = filtered.filter(item => item.characteristics.gearbox === gearbox);
                }
                if (energy) {
                    filtered = filtered.filter(item => item.characteristics.energie === energy);
                }

            }
            setFilteredResults(filtered); // Update the filtered results
        };

        applyFilters();
    }, [results, priceRange, selectedBrands, condition, minRating, stockAvailability, category, gearbox, energy, papers, mileage.min, mileage.max, boatType, motorType, material, boatWidth, length, year, traction, weight, power, height, engin_options, selectedCategory, category_id, sub_category_id]);

    const [visibleMap, setVisibleMap] = useState(!window.matchMedia("(max-width: 768px)").matches);

    const navigate = useNavigate();

    const {updateName} = useGetCurrentLocationName();

    useEffect(() => {
        updateName(null)
    }, [updateName]);

    return (
        <div className="flex flex-col h-fit">
            <CarBanner/>
            <div className="flex flex-col md:flex-row w-screen h-fit overflow-hidden md:relative">
                {
                    (category === "products" || category_id !== null || sub_category_id !== null)
                    &&
                    <div
                        className={`bg-black w-screen md:w-[50vw] z-[130] md:z-[110] h-screen absolute top-0 transition-all duration-500 ease-in-out ${visibleMap ? "right-0" : "-right-full translate-x-16"}`}>
                        <MapTile handleClose={() => {
                            setVisibleMap(false)
                        }}
                                 clickable={false} showClose={true} positions={positions}
                                 shops={shops} currentLocationCoordinates={currentCoordinates}
                                 currentLocationRangeRadius={coordinatesRangeRadius}
                        />
                    </div>

                }
                <div
                    className={`p-3 sm:px-6 sm:py-8 overflow-x-hidden flex flex-col items-start gap-8 h-screen overflow-y-auto w-screen transition-all duration-500 ease-in-out ${((category === "products" || category_id !== null || sub_category_id !== null) ) ? visibleMap ? "md:w-[50vw]" : "md:w-screen md:px-[8.8vw]" : "md:w-screen md:px-[8.8vw]"}`}>
                    <div className="flex flex-row items-center justify-between w-full">
                        <div className="flex flex-row items-center gap-3">
                            <button
                                onClick={() => updateFilterFlyOutMenuVisible(true)}
                                className="2lg:hidden w-10 h-10 flex items-center justify-center">
                            <img src="/resources/filter.svg" alt="filter"/>
                            </button>
                            {searchTerm && (
                                <p className="text-xl font-medium">
                                    Résultats de recherche pour "{searchTerm}"
                                </p>
                            )}
                        </div>
                        <button
                            onClick={() => setVisibleMap(!visibleMap)}
                            className={`p-3 border-border border rounded-lg transition-all duration-500 ease-in-out ${!visibleMap ? "bg-primaryLight border-none" : "bg-white"}`}
                        >
                            {
                                !visibleMap ?
                                    <MapPin className="white_filter dark:invert dark:bg[#333]"/>
                                    :
                                    <MapPinOff className=" dark:invert"/>
                            }
                        </button>
                    </div>
                    <div className="flex flex-row w-full h-full gap-4">
                        <div className="hidden 2lg:block 2lg:min-w-[15.6vw]">
                            {filters}
                        </div>
                        {loading ?
                            <div className="w-full h-full min-h-full flex items-center justify-center">
                                <div className="w-5">
                                    <div className="loader"></div>
                                </div>
                            </div>
                            :
                            filteredResults.length > 0 ? (
                                <div className="w-full normal:w-[65.83vw] h-full flex flex-col gap-4 pr-2">
                                    {category === "products" && (
                                        <>
                                            {filteredResults.length > 0 && (
                                                <div className="grid grid-cols-1 gap-4">
                                                    {filteredResults.slice(0, 3).map((item) => (
                                                        <ProductCardSearchItem
                                                            {...item}
                                                            product={item}
                                                            key={item?.id}
                                                            serial_number={item?.serial_number}
                                                            image={item?.photos ? item?.photos[0]?.link : item?.first_photo?.link}
                                                            category={item?.sub_category?.name}
                                                            brandLogo={item?.brand?.logo}
                                                        />
                                                    ))}
                                                </div>
                                            )}

                                            {packs?.length > 0 && (
                                                <>
                                                    <p className="font-semibold">Packs Spéciaux</p>
                                                    <div ref={ref} className="w-full">
                                                        <Carousel
                                                            ref={ref}
                                                            items={packs}
                                                            renderItem={(pack) => <PackCard {...pack} />}
                                                            itemsWidth={440}
                                                        />
                                                    </div>
                                                </>
                                            )}

                                            {filteredResults.length > 3 && (
                                                <div className="grid grid-cols-1 gap-4">
                                                    {filteredResults.slice(3).map((item) => (
                                                        <ProductCardSearchItem
                                                            {...item}
                                                            product={item}
                                                            key={item?.id}
                                                            serial_number={item?.serial_number}
                                                            image={item?.photos ? item?.photos[0]?.link : item?.first_photo?.link}
                                                            category={item?.sub_category?.name}
                                                            brandLogo={item?.brand?.logo}
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        </>
                                    )}

                                    {category === "cars" &&
                                        filteredResults.map((item) => (
                                            <CarsCardSearchItem
                                                key={item?.id}
                                                description={item?.description}
                                                id={item?.id}
                                                name={item?.name}
                                                image={item?.photos?.[0]?.link || ""}
                                                price={item?.price}
                                                tags={item?.tags}
                                                energie={item?.energie}
                                                mileage={item?.mileage}
                                                papers={item?.papers}
                                                brand={item?.vehicle?.vehicle_brand}
                                                year={item?.year}
                                                created_at={item?.created_at}
                                            />
                                        ))
                                    }

                                    {category === "boats" &&
                                        filteredResults.map((item) => (
                                            <BoatsCardSearchItem
                                                key={item?.id}
                                                id={item?.id}
                                                description={item?.description}
                                                name={item?.name}
                                                image={item?.photos?.[0]?.link || item?.first_photo?.link}
                                                price={item?.price}
                                                created_at={item?.created_at}
                                                characteristics={item?.characteristics}
                                            />
                                        ))
                                    }
                                    {category === "engins" &&
                                        filteredResults.map((item) => (
                                            <EnginsCardSearchItem
                                                key={item?.id}
                                                id={item?.id}
                                                description={item?.description}
                                                name={item?.name}
                                                image={item?.first_photo?.link}
                                                price={item?.price}
                                                created_at={item?.created_at}
                                                characteristics={item?.characteristics}
                                            />
                                        ))
                                    }

                                    {(category_id != null || sub_category_id != null) &&
                                        filteredResults.length > 0 && (
                                            <div className="grid grid-cols-1 gap-4">
                                                {filteredResults.map((item) => (
                                                    <ProductCardSearchItem
                                                        {...item}
                                                        product={item}
                                                        key={item?.id}
                                                        serial_number={item?.serial_number}
                                                        image={item?.photos ? item?.photos[0]?.link : item?.first_photo?.link}
                                                        category={item?.sub_category?.name}
                                                        brandLogo={item?.brand?.logo}
                                                    />
                                                ))}
                                            </div>
                                        )
                                    }

                                    <div className="w-full flex items-center justify-center">
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={updateCurrentPage}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="flex flex-col gap-4 w-full h-full items-center justify-center">
                                    <img
                                        src="/images/undraw_no_data.svg"
                                        alt="No data found illustration"
                                        className="w-[450px] h-auto"
                                        loading="lazy"
                                    />
                                    <h1 className="font-semibold text-2xl text-center">
                                        Aucun Résultat Trouvé
                                    </h1>
                                    <h2 className="text-gray-600 text-center">
                                        Désolé, nous n'avons pas trouvé de résultats
                                        pour <strong>"{searchTerm}"</strong>.
                                        <br/>
                                        Veuillez essayer d'autres mots-clés ou vérifier votre saisie.
                                    </h2>
                                    <button
                                        className="border border-gray-300 rounded-lg min-h-10 px-6 py-3 hover:bg-primaryExtraLight hover:text-primaryLight transition duration-200 ease-in-out"
                                        onClick={() => navigate("/")}
                                        aria-label="Retour au Marketplace"
                                    >
                                        <p className="font-medium text-center">Retourner au Marketplace</p>
                                    </button>
                                </div>
                            )}
                    </div>
                    <FilterFlyOutMenu filterContent={filtersOptions}/>
                </div>
            </div>
        </div>
    );
}

const FilterFlyOutMenu = ({filterContent}) => {
    const {updateFilterFlyOutMenuVisible, filterFlyOutMenuVisible} = useFilterContext();
    const {updatePopupVisible: updateMarketplaceVisibility} = useMarketplaceContext();
    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();
    useEffect(() => {
        updatePopUpVisible(filterFlyOutMenuVisible);
        updateMarketplaceVisibility(filterFlyOutMenuVisible);
    }, [filterFlyOutMenuVisible, updateMarketplaceVisibility, updatePopUpVisible]);
    return (
        <div
            style={{
                left: filterFlyOutMenuVisible ? 0 : '-100%',
            }}
            className="fixed top-0 h-screen bg-white z-[1000] w-full sm:w-[75vw] md:w-[60vw] lg:w-[50vw] xl:w-[40vw] transition-all duration-500 ease-in-out flex flex-col "
        >
            <div className="w-full h-14 flex items-center justify-between px-6 border-b border-gray-200">
                <div className="w-[130px]">
                    <Logo/>
                </div>
                <button
                    onClick={() => {
                        updatePopUpVisible(false)
                        updateFilterFlyOutMenuVisible(false)
                        updateMarketplaceVisibility(false)
                    }}
                    className="w-10 h-10 flex items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200"
                >
                    <img src="/resources/close.svg" alt="close" className="w-3"/>
                </button>
            </div>
            <div className="flex flex-col p-4 h-full max-h-[calc(100vh-40px)] overflow-y-auto">
                {filterContent}
            </div>
        </div>
    );
};

export const useFilterContext = create((set) => ({
    filterFlyOutMenuVisible: false,
    updateFilterFlyOutMenuVisible: (visible) => set({filterFlyOutMenuVisible: visible}),
    minRating: 0,
    updateMinRating: (value) => set({minRating: value}),
    condition: null,
    updateCondition: (value) => set({condition: value}),
    stockAvailability: false,
    updateStockAvailability: (value) => set({stockAvailability: value}),
    gearbox: null,
    year: null,
    energy: null,
    mileage: {min: 0, max: 0},
    papers: null,
    updateGearbox: (value) => set({gearbox: value}),
    updateYear: (value) => set({year: value}),
    updateEnergy: (value) => set({energy: value}),
    updateMileage: (min, max) => set({mileage: {min, max}}),
    updatePapers: (value) => set({papers: value}),
    width: {min: 0, max: 0},
    length: {min: 0, max: 0},
    boatType: null,
    motorType: null,
    material: null,
    updateWidth: (value) => set({width: value}),
    updateLength: (value) => set({length: value}),
    updateBoatType: (value) => set({boatType: value}),
    updateMotorType: (value) => set({motorType: value}),
    updateMaterial: (value) => set({material: value}),
    traction: null,
    power: {min: 0, max: 0},
    weight: {min: 0, max: 0},
    engin_options: null,
    height: {min: 0, max: 0},
    updateHeight: (value) => set({height: value}),
    updateTraction: (value) => set({traction: value}),
    updatePower: (value) => set({power: value}),
    updateWeight: (value) => set({weight: value}),
    updateEnginOptions: (value) => set({engin_options: value}),

}))