import React, { useEffect, useState } from 'react';
import { usePersonalInfoContext } from "../../Pages/SignUp";
import Confetti from "react-confetti";
import { CheckCircle, ChevronRight, Store, ShieldCheck, Layout} from 'lucide-react';
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";

const AccountCreationSuccess = () => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const { personalInfo, updateCurrentStep } = usePersonalInfoContext();
    const [showConfetti, setShowConfetti] = useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation(); // use t() for translations

    useEffect(() => {
        updateCurrentStep(3);
        const confettiTimeout = setTimeout(() => setShowConfetti(false), 5000);
        return () => clearTimeout(confettiTimeout);
    }, []);

    const isSeller = personalInfo.role_id === 3;

    const sellerSteps = [
        {
            icon: <ShieldCheck className="w-5 h-5" />,
            title: t('verification'),
            text: t('verification_text'),
        },
        {
            icon: <Layout className="w-5 h-5" />,
            title: t('setup'),
            text: t('setup_text'),
        },
        {
            icon: <Store className="w-5 h-5" />,
            title: t('launch'),
            text: t('launch_text'),
        },
    ];

    return (
        <div className={`w-full min-h-screen flex items-center justify-center bg-gradient-to-b from-orange-50 to-white dark:from-[#232323] dark:to-[#333333] text-slate-900 dark:text-[#F2F2F2]`}>
            {showConfetti && <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                colors={['#f97316', '#fb923c', '#fdba74', '#fff7ed']}
                numberOfPieces={200}
            />}

            <div className="max-w-2xl mx-auto p-8 space-y-4 xl:space-y-8 flex flex-col items-center text-center">

                <div className="relative">
                    <div className="w-16 h-16 xl:w-24 xl:h-24 rounded-full bg-orange-100 dark:bg-[#494949] flex items-center justify-center">
                        <CheckCircle className="w-10 h-10 xl:w-12 xl:h-12 text-orange-500 dark:text-[#F9CDC4]" />
                    </div>
                    <div className="absolute -top-2 -right-2 w-8 h-8 rounded-full bg-green-500 dark:bg-[#E44826] flex items-center justify-center animate-bounce">
                        <CheckCircle className="w-4 h-4 xl:w-5 xl:h-5 text-white dark:text-[#F2F2F2]" />
                    </div>
                </div>

                <div className="space-y-4">
                    <h1 className="text-normal lg:text-xl xl:text-3xl 2xl:text-4xl font-extrabold bg-gradient-to-r from-orange-600 to-red-600 dark:from-[#F9CDC4] dark:to-[#E44826] bg-clip-text text-transparent">
                        {t('congratulations')},
                    </h1>
                    <p className="text-sm xl:text-normal 2xl:text-lg max-w-sm xl:max-w-lg text-slate-600 dark:text-[#CBCBCB]">
                        {isSeller
                            ? t('seller_account_created')
                            : t('account_created_successfully')}
                    </p>
                </div>

                <button
                    onClick={() => navigate("/")}
                    className="group px-3 xl:px-4 2xl:px-8 py-2 2xl:py-3 rounded-lg border border-borderLight dark:border-[#595959] bg-white dark:bg-[#333333] text-sm xl:text-normal 2xl:text-lg font-medium hover:bg-primaryXLight dark:hover:bg-[#494949] transition-all duration-300 flex items-center gap-2"
                >
                    {t('back_to_marketplace')}
                    <ChevronRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </button>

                {isSeller && (
                    <div className="w-full p-2 xl:p-4 2xl:p-6 rounded-lg xl:rounded-xl bg-white/90 dark:bg-[#333333]/90 backdrop-blur-sm border border-slate-200 dark:border-[#494949]">
                        <h3 className="font-medium mb-4 xl:mb-6 2xl:mb-8 text-sm xl:text-normal 2xl:text-xl text-orange-500 dark:text-[#F9CDC4]">
                            {t('journey_begins')}
                        </h3>
                        <div className="grid gap-2 xl:gap-4 2xl:gap-6">
                            {sellerSteps.map((step, index) => (
                                <div key={index}
                                     className="flex items-start gap-4 text-left group transition-all duration-300 p-3 rounded-lg hover:bg-orange-50/50 dark:hover:bg-[#494949]/50">
                                    <div className="flex-shrink-0 p-2 rounded-lg transition-colors bg-orange-100 dark:bg-[#232323]">
                                        <div className="text-orange-500 dark:text-[#F9CDC4]">
                                            {step.icon}
                                        </div>
                                    </div>
                                    <div>
                                        <h4 className="text-sm xl:text-normal font-medium xl:mb-1 text-slate-800 dark:text-[#F2F2F2]">
                                            {step.title}
                                        </h4>
                                        <p className="text-xs xl:text-sm max-w-sm xl:max-w-lg text-slate-600 dark:text-[#797979]">
                                            {step.text}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AccountCreationSuccess;