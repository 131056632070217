import React, { useState, useMemo, useEffect } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    flexRender,
    getFilteredRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    filterFns,
} from '@tanstack/react-table';
import debounce from 'lodash/debounce';
import TablePaginationNavigation from "../TablePaginationNavigation/TablePaginationNavigation";
import { getSellerReservations } from "../../services/SellerSpace/API";
import { formatCurrency } from "../../utils/utils";
import { useNavigate } from "react-router";
import {getClientReservations} from "../../services/ClientServices/API";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";

const SellerServiceReservation = ({ maxHeight }) => {
    const [loading, setLoading] = useState(true);
    const [fetchedData, setFetchedData] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sort, setSort] = useState([]);
    const [pageSize, setPageSize] = useState(calculatePageSize(maxHeight));
    const [error, setError] = useState(null);
    const { t } = useTranslation();
    const userCookie = Cookies.get("user");
    const userData = JSON.parse(userCookie);

    const navigate = useNavigate();

    useEffect(() => {
        setPageSize(calculatePageSize(maxHeight));
    }, [maxHeight]);

    const fetchData = () => {
        setError(null);
        setLoading(true);

        if (userData?.role_id === 4) {
            getClientReservations()
                .then((response) => {
                    console.log("Client Reservations:", response.data?.reservation);
                    if (response.status === 201 && Array.isArray(response.data?.reservation)) {
                        setFetchedData(response.data?.reservation);
                    } else {
                        setError("Invalid data format");
                        setFetchedData([]);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                        navigate('/login');
                    }
                    setError(error.message || "An error occurred");
                    setLoading(false);
                });
        } else {
            getSellerReservations()
                .then((response) => {
                    console.log("Seller Reservations:", response.data?.reservations);
                    if (response.status === 200 && Array.isArray(response.data?.reservations)) {
                        setFetchedData(response.data?.reservations);
                    } else {
                        setError("Invalid data format");
                        setFetchedData([]);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                        navigate('/login');
                    }
                    setError(error.message || "An error occurred");
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        fetchData(); // Trigger fetch on component mount
    }, []);

    const data = useMemo(() => {
        if (!Array.isArray(fetchedData)) {
            return [];
        }

        if (userData?.role_id === 4) {
            // Client reservations table structure
            return fetchedData.map(reservation => ({
                id: reservation?.service?.id,
                service_name: reservation?.service?.name || 'Service inconnu',
                reservation_date: reservation?.reservation_date || 'N/A',
                payment: formatCurrency(reservation?.payment || 0),
                rest: formatCurrency(reservation?.rest || 0),
                status: reservation?.status === 'paid' ? 'Payé' : 'Non payé',
            }));
        } else {
            // Seller reservations table structure
            return fetchedData.map(reservation => ({
                id: reservation?.service_id,
                reservation_date: reservation?.reservation_date,
                client_name: reservation?.client?.full_name || 'Client inconnu',
                contact: reservation?.client?.phone || 'Contact inconnu',
                payment: formatCurrency(reservation?.payment || 0),
                rest: formatCurrency(reservation?.rest || 0),
                service_name: reservation?.service_name,
            }));
        }
    }, [fetchedData, userData?.role_id]);

    const columns = useMemo(() => {
        if (userData?.role_id === 4) {
            // Client reservations columns
            return [
                {
                    accessorKey: 'service_name',
                    header: t('reservationTable.tableHeaders.service_name'),
                    cell: ({ getValue }) => <div>{getValue()}</div>,
                },
                {
                    accessorKey: 'reservation_date',
                    header: t('reservationTable.tableHeaders.reservation_date'),
                    cell: ({ getValue }) => <div>{getValue()}</div>,
                },
                {
                    accessorKey: 'payment',
                    header: t('reservationTable.tableHeaders.payment'),
                    cell: ({ getValue }) => <div>{getValue()}</div>,
                },
                {
                    accessorKey: 'rest',
                    header: t('reservationTable.tableHeaders.rest'),
                    cell: ({ getValue }) => <div>{getValue()}</div>,
                },
                {
                    accessorKey: 'status',
                    header: t('reservationTable.tableHeaders.status'),
                    cell: ({ getValue }) => <div>{getValue()}</div>,
                },
            ];
        } else {
            // Seller reservations columns
            return [
                {
                    accessorKey: 'service_name',
                    header: t('reservationTable.tableHeaders.service_name'),
                    cell: ({ getValue }) => <div>{getValue()}</div>,
                },
                {
                    accessorKey: 'reservation_date',
                    header: t('reservationTable.tableHeaders.reservation_date'),
                    cell: ({ getValue }) => <div>{getValue()}</div>,
                },
                {
                    accessorKey: 'client_name',
                    header: t('reservationTable.tableHeaders.client_name'),
                    cell: ({ getValue }) => <div>{getValue()}</div>,
                },
                {
                    accessorKey: 'contact',
                    header: t('reservationTable.tableHeaders.contact'),
                    cell: ({ getValue }) => <div>{getValue()}</div>,
                },
                {
                    accessorKey: 'payment',
                    header: t('reservationTable.tableHeaders.payment'),
                    cell: ({ getValue }) => <div>{getValue()}</div>,
                },
                {
                    accessorKey: 'rest',
                    header: t('reservationTable.tableHeaders.rest'),
                    cell: ({ getValue }) => <div>{getValue()}</div>,
                },
            ];
        }
    }, [userData?.role_id, t]);

    const handleFilterChange = useMemo(() => debounce((value) => {
        setGlobalFilter(value);
    }, 300), []);

    const table = useReactTable({
        data,
        columns,
        state: {
            globalFilter: globalFilter,
            sorting: sort
        },
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onSortingChange: setSort,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: filterFns.includesString,
        initialState: {
            pagination: { pageSize: pageSize }
        }
    });

    if (loading) {
        return (
            <div className="w-full min-h-[calc(100vh-380px)] flex items-center justify-center dark:bg-[#232323]">
                <div className="loader"></div>
            </div>
        );
    }
    return (
        <div className="relative flex flex-col items-center w-full dark:bg-[#232323]">
            {error !== null ? (
                <div style={{ minHeight: maxHeight }} className="w-full h-full flex flex-col gap-2 items-center justify-center dark:bg-[#232323]">
                    <div className="p-4 text-center text-gray-500 dark:text-[#CBCBCB]">
                        {t('reservationTable.tableError')}
                    </div>
                    <button onClick={fetchData} className="bg-primaryLight min-h-10 text-white px-6 py-2 rounded hover:bg-primaryDark transition duration-200 dark:bg-[#E44826] dark:hover:bg-[#F9CDC4]">
                        {t('reservationTable.tableRetry')}
                    </button>
                </div>
            ) : (
                data.length === 0 ? (
                    <div style={{ minHeight: maxHeight }} className="w-full h-full flex flex-col items-center justify-center dark:bg-[#232323]">
                        <img src="/images/undraw_no_data.svg" alt="empty" className="w-[250px] mb-8" />
                        <p className="p-4 text-center text-gray-500 dark:text-[#CBCBCB]">{t('reservationTable.noneReservations')}</p>
                    </div>
                ) : (
                    <div style={{ minHeight: maxHeight }} className="relative flex flex-col items-center gap-1 w-full dark:bg-[#232323]">
                        <div className="flex flex-col w-full h-full">
                            <div className="flex flex-row items-center justify-end gap-4 border-b-border border-b px-4 min-h-16 w-full bg-gray-50 dark:bg-[#333333] dark:border-[#494949]">
                                <div className="w-[30vw] sm:w-[15vw] h-10 bg-white border-[#D4D4D4] border px-4 flex flex-row items-center rounded-lg dark:bg-[#494949] dark:border-[#595959]">
                                    <input
                                        onChange={(e) => handleFilterChange(e.target.value)}
                                        className="w-[25vw] sm:w-[10vw] lg:w-[10vw] h-9 bg-white border-none focus:outline-none dark:bg-[#494949] dark:text-[#F2F2F2] dark:placeholder-[#797979]"
                                        placeholder={t('reservationTable.tableSearch')}
                                    />
                                    <img src="/resources/search.svg" alt="search icon" />
                                </div>
                            </div>
                            <div className="w-full">
                                <table className="min-w-full bg-white border border-gray-200 rounded-lg dark:bg-[#333333] dark:border-[#494949]">
                                    <thead className="bg-gray-200 sticky top-0 z-10 min-h-14 h-14 border-b border-border max-h-14 dark:bg-[#494949] dark:border-[#595959]">
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <tr key={headerGroup.id}>
                                            {headerGroup.headers.map(header => (
                                                <th
                                                    key={header.id}
                                                    onClick={header.column.getToggleSortingHandler()}
                                                    className="px-6 py-3 text-[#4A4A4A] text-sm font-semibold border-b border-gray-300 text-left dark:text-[#F2F2F2] dark:border-[#595959]"
                                                >
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                    {{ asc: " ↑", desc: " ↓" }[header.column.getIsSorted() ?? null]}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                    </thead>
                                    <tbody>
                                    {table.getRowModel().rows.map(row => (
                                        <tr key={row.id} className="hover:bg-gray-100 dark:hover:bg-[#494949]">
                                            {row.getVisibleCells().map(cell => (
                                                <td key={cell.id}
                                                    className="cursor-pointer px-6 py-4 border-b border-gray-200 font-semibold max-h-14 dark:text-[#F2F2F2] dark:border-[#494949]">
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <TablePaginationNavigation table={table} />
                    </div>
                )
            )}
        </div>
    );
};

export default SellerServiceReservation;


// Calculate the number of rows that can fit based on max height
const calculatePageSize = (maxHeight) => {
    const ROW_HEIGHT = 81; // Set row height
    if (!maxHeight) return 6;
    if (maxHeight.endsWith("vh")) {
        const vhValue = parseFloat(maxHeight);
        maxHeight = (vhValue / 100) * window.innerHeight;
    }
    return Math.floor((maxHeight - ROW_HEIGHT) / ROW_HEIGHT);
};
