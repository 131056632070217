import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import {FiPlus, FiUpload, FiDownload, FiFilter} from 'react-icons/fi';
import {CSVLink} from 'react-csv';
import {IconButton, Tooltip, Button, useMediaQuery, useTheme, TablePagination} from '@mui/material';
import {BanIcon, CheckIcon, Trash2} from "lucide-react";
import {useTranslation} from 'react-i18next';
import TableBody from './Components/TableBody';
import TableHeader from './Components/TableHeader';
import FilterModal from './Components/FilterModal';
import SearchBar from './Components/SearchBar';
import PropTypes from "prop-types";
import {create} from "zustand";
import {handleLocalStorage} from "../../../../utils/utils";


const DataTable = ({
                       rows,
                       columns,
                       onAddRow,
                       showAddButton = true,
                       showExportImport = true,
                       showFilters = true,
                       actions = [],
                       actionsDisabled = false,
                       link,
                       handleApproveAll,
                       handleRejectAll,
                       handleDeleteAll,
                       onRowClick,
                       showActions = true,
                       showApproveRejectButtons = true,
                       showDeleteButton = false,
                       onSelectionChange,
                       handleFileUpload,
                       onPaginationChange,
                       totalCount
                   }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {t} = useTranslation();
    const [openRow, setOpenRow] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredRows, setFilteredRows] = useState(rows);
    const [sortConfig, setSortConfig] = useState({key: null, direction: 'asc'});
    const [selectedFilters, setSelectedFilters] = useState({});
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const csvLinkRef = useRef(null);
    const [columnVisibility, ] = useState(
        columns.reduce((visibility, column) => {
            visibility[column.field] = true;
            return visibility;
        }, {})
    );
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const {page,rowsPerPage,setPage,setRowsPerPage} = useDataTablePaginationContext();
    const [darkMode, setDarkMode] = useState(false);
    const [paginatedRows, setPaginatedRows] = useState([]);

    useEffect(() => {
        // Initial dark mode check
        const isDark = localStorage.getItem('darkMode') === 'true';
        setDarkMode(isDark);

        // Listen for dark mode changes
        const handleDarkModeChange = (e) => {
            if (e.key === 'darkMode') {
                setDarkMode(e.newValue === 'true');
            }
        };

        window.addEventListener('storage', handleDarkModeChange);

        // Watch for DOM class changes to detect dark mode toggle
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {
                    setDarkMode(document.documentElement.classList.contains('dark'));
                }
            });
        });

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['class']
        });

        // Cleanup listeners
        return () => {
            window.removeEventListener('storage', handleDarkModeChange);
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        let filteredData = [...rows];
        if (searchQuery) {
            filteredData = filteredData.filter((row) =>
                columns.some((col) => String(row[col.field]).toLowerCase().includes(searchQuery.toLowerCase()))
            );
        }

        // Filter with unique values only
        Object.keys(selectedFilters).forEach((filterKey) => {
            if (selectedFilters[filterKey].length > 0) {
                const uniqueFilterValues = [...new Set(selectedFilters[filterKey])];
                filteredData = filteredData.filter((row) =>
                    uniqueFilterValues.includes(String(row[filterKey]))
                );
            }
        });

        if (sortConfig.key) {
            filteredData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
                if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
        }
        setFilteredRows(filteredData);
    }, [columns, rows, searchQuery, selectedFilters, setPage, sortConfig.direction, sortConfig.key]);

    useEffect(() => {
        if (onPaginationChange) {
            onPaginationChange({page, rowsPerPage});
        }

        // Handle client-side pagination when no totalCount is provided
        if (!totalCount) {
            const startIndex = page * rowsPerPage;
            const endIndex = startIndex + rowsPerPage;
            setPaginatedRows(filteredRows.slice(startIndex, endIndex));
        } else {
            setPaginatedRows(filteredRows);
        }
    }, [onPaginationChange, page, rowsPerPage, filteredRows, totalCount]);

    useEffect(()=>{
        console.log("ROWS :",rows)
    },[rows])

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({key, direction});
    };
    const toggleRowDetails = (rowId) => {
        setOpenRow((prevOpenRow) => (prevOpenRow === rowId ? null : rowId));
    };
    const handleOpenMenu = (event, rowId) => {
        setMenuAnchor({element: event.currentTarget, id: rowId});
    };
    const handleCloseMenu = () => {
        setMenuAnchor(null);
    };
    const handleExportCSV = () => {
        csvLinkRef.current.link.click();
    };
    const handleSelectAll = () => {
        const newSelectedRows = !selectAll ? filteredRows.map((row) => row.id) : [];
        setSelectedRows(newSelectedRows);
        setSelectAll(!selectAll);
        if (onSelectionChange) {
            onSelectionChange(newSelectedRows);
        }
    };
    const handleRowSelect = (rowId) => {
        const newSelectedRows = selectedRows.includes(rowId)
            ? selectedRows.filter((id) => id !== rowId)
            : [...selectedRows, rowId];
        setSelectedRows(newSelectedRows);
        if (onSelectionChange) {
            onSelectionChange(newSelectedRows);
        }
    };
    const handleChangePage = (event, newPage) => {
        console.log("[IMPORTANT] Change Page : ",newPage)
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        console.log("[IMPORTANT] Change rows per page : ",newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset to first page when changing rows per page
    };

    return (
        <div className="w-full p-3 dark:bg-[#333]">
            <div className={`${isMobile ? 'flex flex-col space-y-4' : 'flex justify-between items-center'} mb-4`}>
                <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery}/>
                <div className={`flex ${isMobile ? 'flex-wrap justify-center' : 'space-x-4'} gap-2`}>
                    {showExportImport && (
                        <>
                            <Tooltip title={t('adminPages.dataTable.import')}>
                                <IconButton
                                    aria-label={t('adminPages.dataTable.import')}
                                    component="label"
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleFileUpload()
                                    }}
                                >
                                    <FiUpload className={darkMode ? 'text-white' : ''}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('adminPages.dataTable.export')}>
                                <IconButton
                                    aria-label={t('adminPages.dataTable.export')}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleExportCSV();
                                    }}
                                    size="small"
                                >
                                    <FiDownload className={darkMode ? 'text-white' : ''}/>
                                </IconButton>
                            </Tooltip>
                            <CSVLink ref={csvLinkRef} data={rows} filename="export.csv" className="hidden"/>
                        </>
                    )}
                    {showFilters && (
                        <Tooltip title={t('adminPages.dataTable.filter')}>
                            <IconButton
                                aria-label={t('adminPages.dataTable.filter')}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setFiltersOpen(true);
                                }}
                                size="small"
                            >
                                <FiFilter className={darkMode ? 'text-white' : ''}/>
                            </IconButton>
                        </Tooltip>
                    )}
                    {showApproveRejectButtons && selectedRows.length > 0 && (
                        <>
                            {isMobile ? (
                                <>
                                    <Tooltip title={t('adminPages.dataTable.reject')}>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRejectAll(selectedRows);
                                            }}
                                            size="small"
                                        >
                                            <BanIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t('adminPages.dataTable.approve')}>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleApproveAll(selectedRows);
                                            }}
                                            size="small"
                                            sx={{
                                                color: '#E44826',
                                                '&:hover': {color: '#F9CDC4'}
                                            }}
                                        >
                                            <CheckIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </>
                            ) : (
                                <>
                                    <Button
                                        variant="text"
                                        sx={{color: '#E44826'}}
                                        startIcon={<BanIcon/>}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleRejectAll(selectedRows);
                                        }}
                                    >
                                        {t('adminPages.dataTable.reject')}
                                    </Button>
                                    <Button
                                        variant="text"
                                        sx={{
                                            backgroundColor: '#E44826',
                                            color: 'white',
                                            '&:hover': {backgroundColor: '#F9CDC4'}
                                        }}
                                        startIcon={<CheckIcon/>}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleApproveAll(selectedRows);
                                        }}
                                    >
                                        {t('adminPages.dataTable.approve')}
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                    {showDeleteButton && selectedRows.length > 0 && (
                        <>
                            {isMobile ? (
                                <Tooltip title={t('adminPages.dataTable.delete')}>
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteAll(selectedRows);
                                        }}
                                        size="small"
                                    >
                                        <Trash2/>
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <Button
                                    variant="text"
                                    sx={{color: '#E44826'}}
                                    startIcon={<Trash2/>}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteAll(selectedRows);
                                    }}
                                >
                                    {t('adminPages.dataTable.delete')}
                                </Button>
                            )}
                        </>
                    )}
                    {showAddButton && (
                        <Link to={link}>
                            {isMobile ? (
                                <Tooltip title={t('adminPages.dataTable.add')}>
                                    <IconButton
                                        size="small"
                                        sx={{
                                            color: '#E44826',
                                            '&:hover': {color: '#F9CDC4'}
                                        }}
                                        onClick={() => {
                                            if (onAddRow) onAddRow();
                                        }}
                                    >
                                        <FiPlus/>
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#E44826',
                                        color: 'white',
                                        '&:hover': {backgroundColor: '#F9CDC4'}
                                    }}
                                    startIcon={<FiPlus/>}
                                    onClick={() => {
                                        if (onAddRow) onAddRow();
                                    }}
                                >
                                    {t('adminPages.dataTable.add')}
                                </Button>
                            )}
                        </Link>
                    )}
                </div>
            </div>
            <div>
                <table className="min-w-full bg-white dark:bg-[#333333]">
                    <  TableHeader
                        columns={columns}
                        handleSort={handleSort}
                        sortConfig={sortConfig}
                        showActions={showActions}
                        columnVisibility={columnVisibility}
                        selectAll={selectAll}
                        onSelectAll={handleSelectAll}
                        showApproveRejectButtons={showApproveRejectButtons}
                        showDeleteButton={showDeleteButton}
                    />
                    < TableBody
                        rows={totalCount ? filteredRows : paginatedRows}
                        columns={columns}
                        openRow={openRow}
                        toggleRowDetails={toggleRowDetails}
                        handleOpenMenu={handleOpenMenu}
                        menuAnchor={menuAnchor}
                        actions={actions}
                        actionsDisabled={actionsDisabled}
                        handleCloseMenu={handleCloseMenu}
                        onRowClick={onRowClick}
                        showActions={showActions}
                        columnVisibility={columnVisibility}
                        selectedRows={selectedRows}
                        handleRowSelect={handleRowSelect}
                        showApproveRejectButtons={showApproveRejectButtons}
                        showDeleteButton={showDeleteButton}
                    />
                </table>
            </div>
            <TablePagination
                component="div"
                count={totalCount || filteredRows.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[2, 5, 10, 25, 50]}
                labelRowsPerPage={t('adminPages.dataTable.rowsPerPage')}
                labelDisplayedRows={({from, to, count}) => `${from}-${to} sur ${count}`}
                sx={{
                    color: 'inherit',
                    '.MuiTablePagination-select': {
                        color: 'inherit'
                    },
                    '.MuiTablePagination-selectIcon': {
                        color: 'inherit'
                    },
                    '.MuiTablePagination-displayedRows': {
                        color: 'inherit'
                    },
                    '.MuiTablePagination-actions': {
                        color: 'inherit'
                    }
                }}
            />
            < FilterModal
                columns={columns}
                rows={rows}
                filtersOpen={filtersOpen}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                handleCloseFilters={() => setFiltersOpen(false)}
            />
        </div>
    );
};
export default DataTable;

const isLocalStorageAvailable = () => {
    try {
        const testKey = '__test__';
        localStorage.setItem(testKey, 'test');
        localStorage.removeItem(testKey);
        return true;
    } catch (e) {
        return false;
    }
};

export const useDataTablePaginationContext = create((set) => ({
    page: parseInt(handleLocalStorage('tablePage', 1), 10),
    rowsPerPage: parseInt(handleLocalStorage('tableRowsPerPage', 50), 10),
    setPage: (newPage) => {
        if (isLocalStorageAvailable()) {
            localStorage.setItem('tablePage', JSON.stringify(newPage));
        }
        set({ page: newPage });
    },
    setRowsPerPage: (newRowsPerPage) => {
        if (isLocalStorageAvailable()) {
            localStorage.setItem('tableRowsPerPage', JSON.stringify(newRowsPerPage));
        }
        set({ rowsPerPage: newRowsPerPage });
    },
}));

DataTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object).isRequired, // Array of row objects
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string.isRequired,
            headerName: PropTypes.string,
            sortable: PropTypes.bool,
            width: PropTypes.number,
        })
    ).isRequired,
    onAddRow: PropTypes.func,                   // Callback for adding a new row
    showAddButton: PropTypes.bool,              // Whether to show the "Add" button
    showExportImport: PropTypes.bool,           // Whether to show export/import buttons
    showFilters: PropTypes.bool,                // Whether to show filters
    actions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,               // Label for the action
        icon: PropTypes.element,               // Icon for the action
        onClick: PropTypes.func,               // Callback function for the onclick event
    })),
    actionsDisabled: PropTypes.bool,            // Whether actions are disabled
    link: PropTypes.string,                     // Link URL for the "Add" button
    handleApproveAll: PropTypes.func,           // Callback for approving selected rows
    handleRejectAll: PropTypes.func,            // Callback for rejecting selected rows
    handleDeleteAll: PropTypes.func,            // Callback for deleting selected rows
    onRowClick: PropTypes.func,                 // Callback for row click
    showActions: PropTypes.bool,                // Whether to show row actions
    showApproveRejectButtons: PropTypes.bool,   // Whether to show approve/reject buttons
    showDeleteButton: PropTypes.bool,           // Whether to show delete button
    onSelectionChange: PropTypes.func,          // Callback when row selection changes
    handleFileUpload: PropTypes.func,           // Callback for file upload
    onPaginationChange: PropTypes.func,         // Callback for pagination changes
    totalCount: PropTypes.number,               // Total number of rows (for pagination)
};

DataTable.defaultProps = {
    showAddButton: true,
    showExportImport: true,
    showFilters: true,
    actions: [],
    actionsDisabled: false,
    showActions: true,
    showApproveRejectButtons: true,
    showDeleteButton: false,
};
