import HeroSection from "./components/HeroSection/HeroSection";
import ItemListing from "./components/ItemListing/ItemListing";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    getAllBoats, getAllEngins,
    getMarketPlaceData, getNewBoats, getNewCars, getNewEngins, GetNewServices, GetTopServices,
} from "../../services/marketplace/API";
import ProductCard from "./components/Cards/ProductCard";
import ShopCard from "./components/Cards/ShopCard";
import PackCard from "./components/Cards/PackCard";
import CategoryListing from "./components/CategoryListing/CategoryListing";
import EquipmentCard from "./components/Cards/EquipmentCard";
import Footer from "./components/Footer/Footer";
import {create} from "zustand";
import {getProductBrands} from "../../services/SellerSpace/API";
import {getVehicles} from "../../services/Cars/API";
import {useCompatibleVehicleContext} from "../../components/CompatibleVehicules/CompatibleVehicles";
import Pagination from "../../components/PaginationComps/Pagination";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import FloatingButton from "../ShopPage/components/chatFloatingButton";
import Chat from "../ShopPage/components/chat";
import Cookies from "js-cookie";
import ProductCardSkeleton from "../../components/ProductCardSkeleton/ProductCardSkeleton";
import PackCardSkeleton from "../../components/PackCardSkeleton/PackCardSkeleton";
import ShopCardSkeleton from "../../components/ShopCardSkeleton/ShopCardSkeleton";
import EquipmentCardSkeleton from "../../components/EquipementCardSkeleton/EquipementCardSkeleton";
import PropTypes from "prop-types";


export default function Marketplace() {
    const {t} = useTranslation();
    const {currentSelected: currentlyDisplayed, setCurrentSelected: setCurrentlyDisplayed} = useMarketplaceContext();

    // Manage loading state for different data categories
    const [loading, setLoading] = useState({
        productTop: false,
        productNew: false,
        serviceTop: false,
        serviceNew: false,
        carsNew: false,
        boatsNew: false,
        enginsNew: false,
        equipment: false,
        shops: false,
        packs: false,
        categories: false,
        allCars: false,
        allBoats: false,
        allMachines: false,
    });
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [userId] = useState(() => {
        const user = Cookies.get('user');
        return user ? JSON.parse(user)?.role_id : null;
    });

    // Store fetched data for re-use
    const [productData, setProductData] = useState({
        top: null,
        new: null,
        shops: null,
        packs: null,
        categories: null,
        equipment: null
    });

    const [serviceData, setServiceData] = useState({top: null, new: null});
    const [vehicleData, setVehicleData] = useState({
        newCars: null,
        newBoats: null,
        newEngin: null,
        allCars: null,
        allBoats: null,
        allMachines: null
    });

    const {brand, model, motorisation} = useCompatibleVehicleContext();
    const oldBrand = useRef(brand);
    const oldModel = useRef(model);
    const oldMotorisation = useRef(motorisation);

    const fetchDataForProducts = useCallback(() => {
        if (currentlyDisplayed === 0) {
            if (!loading.productNew || !loading.productTop || !loading.shops || !loading.packs || !loading.categories) {
                if (
                    (oldBrand.current?.id !== brand?.id || oldModel.current?.id !== model?.id || oldMotorisation.current?.id !== motorisation?.id)
                    ||
                    (productData.new === null && productData.top === null && productData.shops === null && productData.packs === null && productData.categories === null)
                ) {
                    oldBrand.current = brand;
                    oldModel.current = model;
                    oldMotorisation.current = motorisation;
                    setLoading((prev) => ({
                        ...prev,
                        productTop: true,
                        productNew: true,
                        shops: true,
                        packs: true,
                        categories: true
                    }));
                    getMarketPlaceData({
                        vehicle_brand_id: brand?.id,
                        vehicle_model_id: model?.id,
                        motorisation_id: motorisation?.id
                    }).then((response) => {
                        if (response.status === 200) {
                            setProductData({
                                top: response.data.top_products,
                                new: response.data.newest_products,
                                shops: response.data.top_shops,
                                packs: response.data.best_offers,
                                categories: response.data.top_categories_products
                            });
                        }
                    }).catch((error) => {
                        if (error?.response?.status !== 429) {
                            setProductData({
                                top: [],
                                new: [],
                                shops: [],
                                packs: [],
                                categories: []
                            })
                        }
                    }).finally(() => {
                        setLoading((prev) => ({
                            ...prev,
                            productTop: false,
                            productNew: false,
                            shops: false,
                            packs: false,
                            categories: false
                        }));
                    });
                }
            }
        }
    }, [currentlyDisplayed, brand?.id, model?.id, motorisation?.id, productData, loading.productNew, loading.productTop, loading.shops, loading.packs, loading.categories]);

    const fetchVehicleData = useCallback(() => {
        if (currentlyDisplayed === 1) {
            if (vehicleData.newCars === null && !loading.carsNew) {
                setLoading((prev) => ({...prev, carsNew: true}));
                getNewCars().then((response) => {
                    if (response.status === 200) {
                        setVehicleData((prev) => ({...prev, newCars: response.data}));
                    }
                }).catch((error) => {
                    if (error?.response?.status !== 429) {
                        setVehicleData((prev) => ({
                            ...prev,
                            newCars: [],
                        }))
                    }
                }).finally(() => {
                    setLoading((prev) => ({...prev, carsNew: false}));
                });
            }

            if (vehicleData.newBoats === null && !loading.boatsNew) {
                setLoading((prev) => ({...prev, boatsNew: true}));
                getNewBoats().then((response) => {
                    if (response.status === 200) {
                        setVehicleData((prev) => ({...prev, newBoats: response.data}));
                    }
                }).catch((error) => {
                    if (error?.response?.status !== 429) {
                        setVehicleData((prev) => ({
                            ...prev,
                            newBoats: [],
                        }))
                    }
                }).finally(() => {
                    setLoading((prev) => ({...prev, boatsNew: false}));
                })
            }

            if (vehicleData.newEngin === null && !loading.enginsNew) {
                setLoading((prev) => ({...prev, enginsNew: true}));
                getNewEngins().then((response) => {
                    if (response.status === 200) {
                        setVehicleData((prev) => ({...prev, newEngin: response.data}));
                    }
                }).catch((error) => {
                    if (error?.response?.status !== 429) {
                        setVehicleData((prev) => ({
                            ...prev,
                            newEngin: [],
                        }))
                    }
                }).finally(() => {
                    setLoading((prev) => ({...prev, enginsNew: false}));
                })
            }

            if (vehicleData.allCars === null && !loading.allCars) {
                setLoading((prev) => ({...prev, allCars: true}));
                getVehicles()
                    .then((response) => {
                        if (response.status === 200) {
                            setVehicleData((prev) => ({...prev, allCars: response.data}));
                        }
                    })
                    .catch((error) => {
                        if (error?.response?.status !== 429) {
                            setVehicleData((prev) => ({
                                ...prev,
                                allCars: [],
                            }))
                        }
                    })
                    .finally(() => {
                        setLoading((prev) => ({...prev, allCars: false}));
                    })
            }

            if (vehicleData.allBoats === null && !loading.allBoats) {
                setLoading((prev) => ({...prev, allBoats: true}));

                getAllBoats().then((response) => {
                    if (response.status === 200) {
                        setVehicleData((prev) => ({...prev, allBoats: response.data}));
                    }
                }).catch((error) => {
                    if (error?.response?.status !== 429) {
                        setVehicleData((prev) => ({
                            ...prev,
                            allBoats: [],
                        }))
                    }
                }).finally(() => {
                    setLoading((prev) => ({...prev, allBoats: false}));
                });
            }
            if (vehicleData.allMachines === null && !loading.allMachines) {
                setLoading((prev) => ({...prev, allMachines: true}));

                getAllEngins().then((response) => {
                    if (response.status === 200) {
                        setVehicleData((prev) => ({...prev, allMachines: response.data}));
                    }
                }).catch((error) => {
                    if (error?.response?.status !== 429) {
                        setVehicleData((prev) => ({
                            ...prev,
                            allMachines: [],
                        }))
                    }
                }).finally(() => {
                    setLoading((prev) => ({...prev, allMachines: false}));
                });
            }
        }
    }, [currentlyDisplayed, vehicleData, loading.allCars, loading.allBoats, loading.allMachines, loading.new]);

    const fetchServiceData = useCallback(() => {
        if (currentlyDisplayed === 2) {
            if (serviceData.top === null || serviceData.new === null) {

                if (serviceData.top === null && !loading.serviceTop) {
                    setLoading((prev) => ({...prev, serviceTop: true}));
                    GetTopServices().then((topResponse) => {
                        if (topResponse.status === 200) {
                            setServiceData((prev) => ({
                                ...prev,
                                top: topResponse.data.services.map((service) => ({
                                    id: service.id,
                                    name: service.name,
                                    first_photo: service.photos?.[0],
                                    price: service.price
                                }))
                            }));
                        }
                    }).catch((error) => {
                        if (error?.response?.status !== 429) {
                            setServiceData((prev) => ({
                                ...prev,
                                top: [],
                            }))
                        }
                    }).finally(() => {
                        setLoading((prev) => ({...prev, serviceTop: false}));
                    });
                }

                if (serviceData.new === null && !loading.serviceNew) {
                    setLoading((prev) => ({...prev, serviceNew: true}));
                    GetNewServices().then((newResponse) => {
                        if (newResponse.status === 200) {
                            setServiceData((prev) => ({
                                ...prev,
                                new: newResponse.data.services.map((service) => ({
                                    id: service.id,
                                    name: service.name,
                                    first_photo: service.photos?.[0],
                                    price: service.price
                                }))
                            }));
                        }
                    }).catch((error) => {
                        if (error?.response?.status !== 429) {
                            setServiceData((prev) => ({
                                ...prev,
                                new: [],
                            }))
                        }
                    }).finally(() => {
                        setLoading((prev) => ({...prev, serviceNew: false}));
                    });
                }
            }
        }
    }, [currentlyDisplayed, loading.serviceTop, loading.serviceNew, serviceData]);

    useEffect(() => {
        fetchDataForProducts();
        fetchVehicleData();
        fetchServiceData();
    }, [currentlyDisplayed, fetchDataForProducts, fetchVehicleData, fetchServiceData]);

    // Window resizing logic for responsiveness (debounced)
    const [width, setWidth] = useState(window.innerWidth);
    const [shopWidth, updateShopWidth] = useState(0);

    const handleResize = useCallback(() => {
        setWidth(window.innerWidth);
    }, []);

    useEffect(() => {
        const debounceResize = () => setTimeout(handleResize, 200);  // Debounce resizing to prevent too many state updates
        window.addEventListener('resize', debounceResize);

        return () => {
            clearTimeout(debounceResize);
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    useEffect(() => {
        if (width < 640) {
            updateShopWidth(150);
        } else {
            updateShopWidth(300);
        }
    }, [width]);

    useEffect(() => {
        if (currentlyDisplayed === 0 && productData.equipment === null) {
            setLoading((prev) => ({
                ...prev,
                equipment: true
            }))
            const fetchBrands = async () => {
                try {
                    const response = await getProductBrands();
                    if (response.status === 200) {
                        setProductData((prev) => ({
                            ...prev,
                            equipment: response.data
                        }));
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    setLoading((prev) => ({
                        ...prev,
                        equipment: false
                    }))
                }
            };
            fetchBrands();
        }
    }, [currentlyDisplayed, productData.equipement]);

    const {updateCategoryId} = useMarketplaceContext();
    useEffect(() => {
        updateCategoryId(null);
    }, [updateCategoryId]);

    return (
        <div className="w-screen h-full">
            <Helmet>
                <title>Achetez et Vendez des Pièces Auto, Véhicules & Services en Algérie - MecaNext</title>
                <meta name="description"
                      content={"Profitez des meilleures offres sur pièces auto, véhicules neufs et d'occasion, avec service client dédié, livraison rapide et entretien fiable partout en Algérie."}/>
                <meta property="og:title" content={"Mecanext"}/>
                <meta property="og:description"
                      content={"Profitez des meilleures offres sur pièces auto, véhicules neufs et d'occasion, avec service client dédié, livraison rapide et entretien fiable partout en Algérie."}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={window.location.origin}/>
                <meta property="og:image" content={window.location.origin + "/resources/logo.svg"}/>
            </Helmet>
            <HeroSection currentSelected={[currentlyDisplayed, setCurrentlyDisplayed]}/>

            {currentlyDisplayed !== 1 ?
                <section aria-labelledby="top items">
                    {
                        ((currentlyDisplayed === 0 && loading.productTop) || (currentlyDisplayed === 2 && loading.serviceTop)) ?
                            <ItemListing
                                title={currentlyDisplayed === 0 ? t('marketplace.sections.topItems.title') : t('marketplace.sections.topItems.titleServices')}
                                items={[1, 2, 3, 4, 5, 6]}
                                renderItems={() => (
                                    <ProductCardSkeleton/>
                                )}
                            />
                            :
                            <ItemListing
                                title={currentlyDisplayed === 0 ? t('marketplace.sections.topItems.title') : t('marketplace.sections.topItems.titleServices')}
                                items={currentlyDisplayed === 0 ? productData.top : serviceData.top}
                                renderItems={(product) => (<ProductCard{...product}
                                                                       itemType={currentlyDisplayed === 0 ?
                                                                           "product" : currentlyDisplayed === 1 ?
                                                                               "vehicle" : "service"}/>)}
                            />
                    }
                </section>
                :
                null
            }

            {/*NEW ITEMS*/}
            {(currentlyDisplayed === 0 || currentlyDisplayed === 2) &&
                <section aria-labelledby="new items">
                    {(currentlyDisplayed === 0 && loading.productNew) || (loading.serviceNew && currentlyDisplayed === 2) ?
                        <ItemListing
                            title={currentlyDisplayed === 0 ? t('marketplace.sections.topItems.title') : t('marketplace.sections.topItems.titleServices')}
                            items={[1, 2, 3, 4, 5, 6]}
                            renderItems={() => (
                                <ProductCardSkeleton/>
                            )}
                        />
                        :
                        <ItemListing
                            title={
                                currentlyDisplayed === 0 ? t('marketplace.sections.newItems.titleProducts') :
                                    currentlyDisplayed === 2 ?
                                        t('marketplace.sections.newItems.titleServices')
                                        :
                                        null
                            }
                            items={currentlyDisplayed === 0 ? productData.new : currentlyDisplayed === 1 ? vehicleData.new : serviceData.new}
                            renderItems={(product) => (<ProductCard{...product}
                                                                   itemType={
                                                                       currentlyDisplayed === 0 ? "product" : currentlyDisplayed === 1 ? "vehicle" : "service"
                                                                   }
                            />)}
                        />
                    }
                </section>
            }

            {
                currentlyDisplayed === 1 &&
                <>
                    <NewVehicles data={vehicleData.newCars} loading={loading.carsNew}
                                 title={"New Cars"} type={"vehicle"}/>
                    <NewVehicles data={vehicleData.newBoats} loading={loading.boatsNew}
                                 title={"New Boats"} type={"boat"}/>
                    <NewVehicles data={vehicleData.newEngin} loading={loading.enginsNew}
                                 title={"New Industrial Machines"} type={"engin"}/>
                </>
            }

            <CarsCatalogue allCars={vehicleData.allCars} currentlyDisplayed={currentlyDisplayed}
                           loading={loading.allCars}
                           type="vehicle"/>
            <CarsCatalogue allCars={vehicleData.allBoats} currentlyDisplayed={currentlyDisplayed}
                           loading={loading.allBoats}
                           type={"boat"}/>
            <CarsCatalogue allCars={vehicleData.allMachines} currentlyDisplayed={currentlyDisplayed}
                           loading={loading.allMachines}
                           type={"engin"}/>

            {/*BEST SELLER*/}
            {currentlyDisplayed === 0
                ?
                <section aria-labelledby="best seller">
                    {loading.shops ?
                        <ItemListing title={t('marketplace.sections.bestSellers.title')}
                                     itemWidth={shopWidth}
                                     items={[1, 2, 3, 4, 5, 6]}
                                     renderItems={(shop) => (<ShopCardSkeleton/>)}
                        />
                        :
                        <ItemListing title={t('marketplace.sections.bestSellers.title')}
                                     itemWidth={shopWidth}
                                     items={productData.shops}
                                     renderItems={(shop) => (<ShopCard{...shop}/>)}
                        />
                    }
                </section>
                :
                null
            }

            {/*PACKS*/}
            {
                currentlyDisplayed === 0 ?
                    <section aria-labelledby="special packs">
                        {
                            loading.packs ?
                                <ItemListing title={t('marketplace.sections.specialPacks.title')}
                                             itemWidth={440}
                                             items={[1, 2, 3, 4, 5]}
                                             renderItems={() => (<PackCardSkeleton/>)}
                                />
                                :
                                <ItemListing title={t('marketplace.sections.specialPacks.title')}
                                             itemWidth={440}
                                             items={productData.packs}
                                             renderItems={(pack) => (<PackCard{...pack}/>)}
                                />
                        }
                    </section>
                    :
                    null
            }

            {/*Category*/}
            {currentlyDisplayed === 0 && productData.categories?.map((category) => (
                <CategoryListing key={category.id} id={category.id} img={category.img}
                                 category_name={category.category_name} type={currentlyDisplayed}/>
            ))}

            {/*OUR EQUIPEMENT*/}
            {(currentlyDisplayed === 0 && loading.equipment) ?
                <ItemListing title={t('marketplace.sections.equipment.title')}
                             items={[1, 2, 3, 4, 5, 6]}
                             itemWidth={190}
                             renderItems={() => (
                                 <EquipmentCardSkeleton/>)}/>
                :
                <ItemListing title={t('marketplace.sections.equipment.title')}
                             items={productData.equipment}
                             itemWidth={190}
                             renderItems={(equipment) => (
                                 <EquipmentCard{...equipment}/>)}/>
            }

            {/*Footer*/}
            <Footer/>
            {<>
                {userId === 4 && (
                    <Chat
                        setIsChatOpen={() => setIsChatOpen(true)}
                        onClose={() => setIsChatOpen(false)}

                    />
                )}

            </>}
        </div>

    )
}

const getCartFromLocalStorage = () => {
    const storedCart = localStorage.getItem('cart');
    return storedCart ? JSON.parse(storedCart) : [];
};

const CarsCatalogue = ({currentlyDisplayed, allCars = [], type, loading}) => {
    const {t} = useTranslation()

    const carsPerPage = 8; // Set how many cars to display per page
    const [currentPage, setCurrentPage] = useState(1);

    // Calculate the range of cars to display
    const indexOfLastCar = currentPage * carsPerPage;
    const indexOfFirstCar = indexOfLastCar - carsPerPage;
    const currentCars = allCars?.length > 0 ? allCars?.slice(indexOfFirstCar, indexOfLastCar) : [];

    // Function to handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    if (loading) {
        return (
            currentlyDisplayed === 1 ?
                <div className="px-[6vw] lg:px-[10vw] py-4 flex flex-col gap-2">
                    <div className="flex flex-row justify-start items-center py-2 border-b-border border-b">
                        <p className="font-semibold">{type === "boats" ? t('marketplace.sections.catalog.boat') : type === "engin" ? t('marketplace.sections.catalog.engin') : t('marketplace.sections.catalog.vehicles')}</p>
                    </div>
                    <div
                        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 justify-items-center">
                        {[1, 2, 3, 4, 5].map((car) => (
                            <ProductCardSkeleton key={car}/>
                        ))}
                    </div>
                </div>
                :
                null
        );
    } else {
        return (
            currentlyDisplayed === 1 ?
                allCars?.length > 0 && (
                    <div className="px-[6vw] lg:px-[10vw] py-4 flex flex-col gap-2">
                        <div className="flex flex-row justify-start items-center py-2 border-b-border border-b">
                            <p className="font-semibold">{type === "boats" ? t('marketplace.sections.catalog.boat') : type === "engin" ? t('marketplace.sections.catalog.engin') : t('marketplace.sections.catalog.vehicles')}</p>
                        </div>
                        <div
                            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 justify-items-center">
                            {currentCars.map((car) => (
                                <ProductCard
                                    key={car.id}
                                    first_photo={car?.first_photo ? car.first_photo : car.photos?.[0]}
                                    {...car}
                                    itemType={type}
                                />
                            ))}
                        </div>

                        {/* Pagination Component */}
                        <div className="mt-6 flex justify-center">
                            <Pagination
                                currentPage={currentPage}
                                totalPages={Math.ceil(allCars?.length / carsPerPage)}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )
                :
                null
        );
    }
};

CarsCatalogue.propTypes = {
    currentlyDisplayed: PropTypes.number.isRequired,
    allCars: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
}

const NewVehicles = ({loading, title, data, type}) => {
    return (
        <section aria-labelledby={"new vehicles"}>
            {loading ?
                <ItemListing
                    title={title}
                    items={[1, 2, 3, 4, 5]}
                    renderItems={() => (<ProductCardSkeleton itemType={type}/>)}
                />
                :
                <ItemListing
                    title={title}
                    items={data}
                    renderItems={(product) => (<ProductCard{...product} itemType={type}/>)}
                />

            }
        </section>
    )
}

NewVehicles.propTypes = {
    loading: PropTypes.bool,
    title: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired
}


export const useMarketplaceContext = create((set) => ({
    category_id: null,
    updateCategoryId: (id) => set({category_id: id}),
    sub_category_id: null,
    updateSubCategoryId: (id) => set({sub_category_id: id}),
    editShop: false,
    updateEditShop: (visible) => set({editShop: visible}),
    showAd: false,
    updateShowAd: (visible) => set({showAd: visible}),
    showSearchBar: false,
    updateShowSearchBar: (visible) => set({showSearchBar: visible}),
    popupVisible: true,
    updatePopupVisible: (visible) => set(() => ({popupVisible: visible})),
    currentSelected: 0,
    setCurrentSelected: (index) => set({currentSelected: index}),

    cart: getCartFromLocalStorage(),

    removePromoCodeFromCartProduct: (code) => set((state) => {
        const updatedCart = state.cart.map((product) => {
            if (product.code !== null && product.code !== undefined && product.code === code) {
                return {
                    ...product,
                    code: null, // remove promo code
                    description: null,
                    discount_percentage: 0, // reset discount
                };
            }
            return product;
        });

        localStorage.setItem('cart', JSON.stringify(updatedCart)); // Update localStorage

        return {
            ...state,
            cart: updatedCart,
        };
    }),

    addPromoCodeToCartProduct: (product, promoCode) => set((state) => {
        const updatedCart = state.cart.map((p) => {
            if ((p?.offer === null || p?.offer === undefined) && Number(p.id) === Number(product.id)) {
                console.log('Promo code added to product in CART');
                return {
                    ...p,
                    discount_percentage: promoCode.discount_percentage,
                    code: promoCode.code,
                    description: promoCode.description
                };
            }
            return p;
        });

        localStorage.setItem('cart', JSON.stringify(updatedCart));

        return {
            ...state,
            cart: updatedCart,
        };
    }),

    addProductToCart: (product, offer = null) => set((state) => {
        const isProductInCart = state.cart.some((p) => {
            if (p?.offer != null && offer != null) {
                return p.offer.id === offer.id && Number(p.id) === Number(product.id);
            } else if (p?.offer == null && offer == null) {
                return Number(p.id) === Number(product.id);
            } else {
                return false;
            }
        });

        if (isProductInCart) {
            console.warn('Product is already in the cart');
            return state;
        }

        const updatedCart = [...state.cart, {...product, offer}];
        localStorage.setItem('cart', JSON.stringify(updatedCart));

        return {
            ...state,
            cart: updatedCart,
        };
    }),

    removeProductFromCart: (product, offer = null) => set((state) => {
        console.warn('Removing product from cart:', product);
        const updatedCart = state.cart.filter((p) => {
            if (p?.offer != null && offer != null) {
                return !(p.offer.id === offer.id);
            } else if (p?.offer == null && offer == null) {
                return Number(p.id) !== Number(product.id);
            } else {
                return true;
            }
        });

        localStorage.setItem('cart', JSON.stringify(updatedCart));

        return {
            ...state,
            cart: updatedCart,
        };
    }),

    emptyCart: () => set((state) => {
        localStorage.setItem('cart', JSON.stringify([]));
        return {
            ...state,
            cart: [],
        };
    }),
}));