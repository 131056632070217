import {useTranslation} from "react-i18next";

export default function WidgetElement({title = "Commandes d'aujourd'hui", amount = "0", percentage = "0.00%",weekRange=null}) {
    const {t} = useTranslation();
    return (
        <div
            className='w-full min-h-[150px] bg-white dark:bg-[#333333] dark:border-0 border border-border rounded-lg p-4 flex flex-col items-start justify-between'>
            <div className="flex flex-row items-center justify-between w-full">
                <p className="text-[#797979] dark:text-[#CBCBCB] text-sm">{title}</p>
                {weekRange === null ? <p
                    className="text-sm font-medium dark:text-[#F2F2F2]">{new Date().toLocaleDateString('fr-FR', {
                    day: 'numeric',
                    month: 'long'
                })}</p> : <p className="text-sm font-medium dark:text-[#F2F2F2]">{weekRange}</p>}

            </div>
            <div className="flex flex-col gap-2 items-start w-full">
                <p className="text-3xl font-medium dark:text-[#F2F2F2]">{amount}</p>
                <div className="flex flex-row items-center gap-2">
                    {
                        percentage === "0.00%" ?
                            <p className="font-medium text-gray-500 dark:text-[#797979]">--</p>
                            :
                            percentage.includes('-') ?
                                <img src="/resources/graph-down.svg" alt="graph" className="red_filter"/>
                                :
                                <img src="/resources/graph-up.svg" alt="graph" className="yellow_green_filter"/>
                    }
                    <span style={{color:percentage.includes("-") ? "red" : "yellowgreen"}} className="font-medium"> {percentage}
                        <span className="font-medium text-black dark:text-[#F2F2F2]"> {t("sellerSpace.dashboard.yesterday")}</span>
                    </span>
                </div>
            </div>
        </div>
    )
}