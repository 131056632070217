import React from 'react';
import { FiSearch} from 'react-icons/fi';
import {useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';

const SearchBar = ({searchQuery, setSearchQuery}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {t} = useTranslation();
    return (
        <div className={`relative w-full ${isMobile ? 'max-w-full' : 'max-w-xs'}`}>
            <input
                type="text"
                placeholder={t('adminPages.dataTable.search')}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-[#494949] rounded-lg focus:outline-none focus:ring focus:border-[#E44826] dark:bg-[#333333] dark:text-[#F2F2F2]"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <FiSearch className="absolute left-3 top-2.5 text-gray-400 dark:text-[#CBCBCB] w-5 h-5"/>
        </div>
    );
};
export default SearchBar;