import {useEffect, useState} from "react";
import PropTypes from "prop-types";
export const ImageWithFallback = ({
                                      src = "",
                                      alt,
                                      className = '',
                                      style = {},
                                      priority = false,
                                      local = false
                                  }) => {
    const [imgSrc, setImgSrc] = useState(src);

    useEffect(() => {
        if (!/^https?:\/\//.test(src)) {
            let url;
            if (!local){
                if (process.env.REACT_APP_API_URL === "/") {
                    url = window.location.origin + src;
                } else {
                    url = `${process.env.REACT_APP_API_URL.replace(/\/+$/, "")}/${src?.replace(/^\/+/, "")}`;
                }
                setImgSrc(new URL(url).href);
            } else {
                setImgSrc(src);
            }
        } else {
            setImgSrc(src);
        }
    }, [src, local]);

    const handleImageError = (e) => {
        e.preventDefault();
        e.currentTarget.onerror = null;
        e.currentTarget.src = "/images/placeholder-image.jpg";
    };

    return (
        <img
            onError={handleImageError}
            src={imgSrc}
            loading={priority ? "eager" : "lazy"}
            alt={alt}
            referrerPolicy="no-referrer"
            draggable={false}
            className={className}
            style={style}
        />
    );
};

ImageWithFallback.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    priority: PropTypes.bool,
    local: PropTypes.bool,
};