import {
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel, getPaginationRowModel, getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import React, {Fragment, useState, useMemo, useEffect} from "react";
import TablePaginationNavigation from "../TablePaginationNavigation/TablePaginationNavigation";
import {getOrders} from "../../services/SellerSpace/API";
import {toast} from "sonner";
import ToastError, {toastStyle} from "../Toasts/Error/ToastError";
import {formatCurrency} from "../../utils/utils";
import {getClientOrders} from "../../services/ClientServices/API";
import {ImageWithFallback} from "../ImageWithFallBack/ImageWithFallBack";
import Cookies from "js-cookie";
import {useNavigate} from "react-router";
import ToastWarn from "../Toasts/Warn/ToastWarn";
import {useTranslation} from "react-i18next";

const ROW_HEIGHT = 73;

const calculatePageSize = (maxHeight) => {
    if (!maxHeight) return 6;

    if (maxHeight?.endsWith("vh")) {
        const vhValue = parseFloat(maxHeight);
        // height of the row area is calculated as a percentage of the inner height - nav height - table search height - table header
        maxHeight = (vhValue / 100) * window.innerHeight - 170;
    }

    return Math.floor(maxHeight / ROW_HEIGHT);
};

const rowSizeOptions = [5, 10, 25, 50, 100];

export default function SellerOrderTable({maxHeight = "40vh"}) {
    const [fetchedData, updateFetchedData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchData = () => {
        setLoading(true);
        let user = null;
        try {
            user = JSON.parse(Cookies.get("user"));
        } catch (e) {
            navigate("/login", {state: {from: window.location.pathname}});
        }
        if (Number(user?.role_id) === 4) {
            getClientOrders().then((response) => {
                if (response.status === 200) {
                    if (response.data.orders?.length > 0) {
                        const result = response.data.orders.flatMap((order) => {
                            if (order.offers.length > 0) {
                                return order.offers.map((offer) => ({
                                    order_status: order.status,
                                    ...offer
                                }));
                            } else {
                                return order;
                            }
                        });
                        updateFetchedData(result);
                    } else {
                        toast.error(<ToastWarn message={t("none.command")}/>, toastStyle);
                        updateFetchedData([]);
                    }
                }
                setLoading(false);
            }).catch((error) => {
                if (error.response.status === 401 || error.response.status === 403) {
                    navigate("/login", {state: {from: window.location.pathname}});
                }
                if (error.response.status === 404) {
                    toast.error(<ToastError message={t("none.command")}/>, toastStyle);
                }
                toast.error(<ToastError message={error.message}/>, toastStyle);
                setLoading(false);
            })
        } else {
            getOrders().then((response) => {
                if (response.status === 200) {
                    const result = response.data.flatMap((order) => {
                        if (order.offers.length > 0) {
                            return order.offers.map((offer) => ({
                                order_status: order.status,
                                ...offer
                            }));
                        } else {
                            return order;
                        }
                    })
                    updateFetchedData(result);
                }
                setLoading(false);
            }).catch((error) => {
                if (error.response.status === 401 || error.response.status === 403) {
                    navigate("/login", {state: {from: window.location.pathname}});
                }
                toast.error(<ToastError message={error.message}/>, toastStyle);
            })
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const data = useMemo(
        () => [
            ...fetchedData.map((order) => ({
                id: order.offer_name ? order.offer_name + " # " + order.id : "# " + order.id,
                date: new Date(order.created_at).toLocaleDateString("fr-FR"),
                productsCount: order?.products?.length,
                totalAmount: order.total_amount || order.price,
                status: order.offer_name ? order.order_status : order.status,
                shippingAddress: "Constantine",
                deliveryMethod: "Yalidine, Inc",
                paymentMethod: "Espèces",
                trackingNumber: "ID120310249-C",
                products: [
                    ...order?.products?.map((product) => ({
                        name: product.name,
                        sku: product.serial_number,
                        quantity: product.pivot.quantity,
                        price: order.applied_promotion != null ? product.price - (product.price * order.discount_percentage / 100) : product.price,
                        total:
                            (product.price * product.pivot.quantity) -
                            (order.applied_promotion != null ? (product.price * product.pivot.quantity * order.discount_percentage / 100) : 0),
                        imageUrl: product?.first_photo?.link || product?.photos?.[0].link || "/images/brakes.png"
                    }))
                ],
            }))
        ],
        [fetchedData]
    );

    const isSmallScreen = window.innerWidth <= 640; // Or use a media query hook for React

    const columns = useMemo(() => {
        const allColumns = [
            {
                accessorKey: "id",
                header: "ID de commande",
                cell: ({row}) => {
                    const id = row.original.id;
                    return (
                        <div className="flex flex-row items-center gap-4">
                            {row.getCanExpand() ? (
                                <button
                                    onClick={row.getToggleExpandedHandler()}
                                    className="min-h-10 min-w-10 flex items-center justify-center border border-border dark:border-[#494949] rounded-lg bg-white dark:bg-[#333333]">
                                    {row.getIsExpanded() ? (
                                        <img
                                            style={{transform: "rotate(180deg)"}}
                                            src="/resources/chevron_down.svg"
                                            alt="chevron"
                                        />
                                    ) : (
                                        <img src="/resources/chevron_down.svg" alt="chevron"/>
                                    )}
                                </button>
                            ) : null}
                            <p className="dark:text-[#F2F2F2]">{id}</p>
                        </div>
                    );
                },
                enableGlobalFilter: true,
            },
            {
                accessorKey: "date",
                header: "Date",
                enableGlobalFilter: true,
            },
            {
                accessorKey: "productsCount",
                header: "Produits",
                enableGlobalFilter: true,
            },
            {
                accessorKey: "totalAmount",
                header: "Montant total",
                cell: ({row}) => <p className="dark:text-[#F2F2F2]">{formatCurrency(row.original.totalAmount)}</p>,
                enableGlobalFilter: true,
            },
            {
                accessorKey: "status",
                header: "Statut",
                enableGlobalFilter: true,
                cell: ({row}) => <DeliveryStatus status={row.original.status}/>,
            },
        ];

        const essentialColumns = ["id", "totalAmount", "status"];
        const filteredColumns = isSmallScreen
            ? allColumns.filter(col => essentialColumns.includes(col.accessorKey))
            : allColumns;

        return filteredColumns.map(column => ({
            ...column,
            // Handle expandable rows only on larger screens
            ...(column.accessorKey === "id" && !isSmallScreen
                ? {
                    // Expandable configuration
                    Cell: ({row}) => (
                        <div className="flex flex-row items-center gap-4">
                            {row.getCanExpand() && (
                                <button
                                    onClick={row.getToggleExpandedHandler()}
                                    className="h-10 w-10 flex items-center justify-center border border-border dark:border-[#494949] rounded-lg bg-white dark:bg-[#333333]">
                                    {row.getIsExpanded() ? (
                                        <img
                                            style={{transform: "rotate(180deg)"}}
                                            src="/resources/chevron_down.svg"
                                            alt="chevron"
                                        />
                                    ) : (
                                        <img src="/resources/chevron_down.svg" alt="chevron"/>
                                    )}
                                </button>
                            )}
                            <p className="dark:text-[#F2F2F2]">{row.original.id}</p>
                        </div>
                    ),
                }
                : {}),
        }));
    }, [isSmallScreen]);

    const [expanded, setExpanded] = useState({});
    const [filter, setFilter] = useState("");
    const [sort, setSort] = useState([]);
    const [pageSize, setPageSize] = useState(calculatePageSize(maxHeight));
    const [height, setHeight] = useState(0);
    const {t} = useTranslation();
    const handlePageSizeChange = (e) => {
        const newSize = Number(e.target.value);
        setPageSize(newSize);
        table.setPageSize(newSize);
    };

    useEffect(() => {
        setPageSize(calculatePageSize(maxHeight));
    }, [maxHeight]);

    // the height is applied on the table
    // Update the height when the page size changes
    // the height = number of rows * each row height + the height of the header + border (1px) for each row
    useEffect(() => {
        setHeight(calculatePageSize(maxHeight) * ROW_HEIGHT + 56 + pageSize);
    }, [pageSize,maxHeight]);

    const filterFunction = (row, columnId, filterValue) => {
        const parentMatch = row.getValue(columnId)?.toString().toLowerCase().includes(filterValue.toLowerCase());

        const subRowMatch = row.subRows?.some(subRow =>
            Object.values(subRow.original).some(value =>
                value?.toString().toLowerCase().includes(filterValue.toLowerCase())
            )
        );

        return parentMatch || subRowMatch;
    };

    const table = useReactTable({
        data,
        columns,
        state: {
            expanded,
            globalFilter: filter,
            sorting: sort,
        },
        onGlobalFilterChange: setFilter,
        onSortingChange: setSort,
        onExpandedChange: setExpanded,
        getSubRows: (row) => row.products || [],
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getRowCanExpand: (row) => row.products && row.products.length > 0,
        filterFromLeafRows: true,
        maxLeafRowFilterDepth: 1,
        enableColumnFilters: true,
        globalFilterFn: filterFunction,
        initialState: {
            pagination: {
                pageSize: pageSize,
                pageIndex: 0
            }
        }
    });

    if (loading) {
        return (
            <div
                style={{minHeight: maxHeight}}
                className="relative flex flex-col items-center justify-center w-full border border-border dark:border-[#494949] rounded-lg bg-white dark:bg-[#333333]">
                <div  className="flex items-center justify-center w-full h-full">
                    <div className="w-5">
                        <div className="loader"></div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div
            className="relative flex flex-col items-center justify-between w-full border border-border dark:border-[#494949] rounded-lg bg-white dark:bg-[#333333]">
            {
                fetchedData.length === 0 ?
                    <div style={{minHeight: maxHeight, maxHeight: maxHeight}}
                         className="flex flex-col items-center justify-center w-full h-full p-8">
                        <img src="/images/undraw_no_data.svg" alt="empty" className="w-[250px] mb-8"/>
                        <p className="text-lg text-gray-500 dark:text-[#CBCBCB]">{t("none.command")}</p>
                    </div>
                    :
                    <div className="flex flex-col items-center justify-between w-full h-full">
                        <div className="flex flex-col justify-between w-full h-full items-center">
                            <div className="flex flex-col w-full h-full">
                                <div
                                    className="flex flex-row items-center justify-end gap-4 border-b-border dark:border-b-[#494949] border-b px-4 min-h-16 w-full">
                                    <div
                                        className="w-[30vw] sm:w-[15vw] h-12 bg-[#FCFCFC] dark:bg-[#232323] border-[#D4D4D4] dark:border-[#494949] border rounded-lg px-2 sm:px-4 flex items-center justify-between">
                                        <input
                                            value={filter}
                                            onChange={e => table.setGlobalFilter(e.target.value)}
                                            placeholder="Recherche"
                                            className="w-[calc(30vw-40px)] sm:w-[calc(15vw-56px)] h-9 bg-[#FCFCFC] dark:bg-[#232323] dark:text-[#F2F2F2] border-none focus:outline-none"
                                        />
                                        <img src="/resources/search.svg" alt="search"/>
                                    </div>
                                </div>
                                <div style={{maxHeight: height, minHeight: height}} className="w-full overflow-y-auto">
                                    <table className="min-w-full bg-white dark:bg-[#333333] border border-gray-200 dark:border-[#494949] rounded-lg">
                                        <thead
                                            className="bg-gray-100 dark:bg-[#232323] sticky top-0 z-10 min-h-14 h-14 border-b border-border dark:border-[#494949]">
                                        {table.getHeaderGroups().map((headerGroup) => (
                                            <tr key={headerGroup.id} className="text-left">
                                                {headerGroup.headers.map((header) => (
                                                    <th
                                                        key={header.id}
                                                        onClick={header.column.getToggleSortingHandler()}
                                                        className="px-6 py-3 text-[#979797] dark:text-[#797979] text-sm font-medium border-b border-gray-200 dark:border-[#494949]"
                                                    >
                                                        {
                                                            flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )
                                                        }
                                                        {
                                                            {asc: " ↑", desc: " ↓"}[header.column.getIsSorted() ?? null]
                                                        }
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                        </thead>
                                        <tbody className="overflow-y-auto">
                                        {table.getRowModel().rows.map((row) => {
                                            return (
                                                <Fragment key={row.id}>
                                                    {(row.getCanExpand() || filter !== "") &&
                                                        <tr className="hover:bg-gray-50 dark:hover:bg-[#232323] transition-all">
                                                            {row.getVisibleCells().map((cell) => (
                                                                <td
                                                                    key={cell.id}
                                                                    className="px-6 py-4 text-sm font-medium border-b border-gray-200 dark:border-[#494949] dark:text-[#F2F2F2]"
                                                                >
                                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                                </td>
                                                            ))}
                                                        </tr>}

                                                    {row.getIsExpanded() && (
                                                        <>
                                                            <tr>
                                                                <td colSpan={columns.length} className="p-2 sm:p-4">
                                                                    <table
                                                                        className="min-w-full bg-white dark:bg-[#333333] border-border dark:border-[#494949] border rounded-lg">
                                                                        <thead className="bg-[#F8F8F8] dark:bg-[#232323]">
                                                                        <tr>
                                                                            <th className="px-2 sm:px-4 py-2 text-left text-[#797979] dark:text-[#797979] font-medium text-sm">Adresse
                                                                                de livraison
                                                                            </th>
                                                                            <th className="hidden sm:table-cell px-4 py-2 text-left text-[#797979] dark:text-[#797979] font-medium text-sm">Méthode
                                                                                de livraison
                                                                            </th>
                                                                            <th className="hidden sm:table-cell px-4 py-2 text-left text-[#797979] dark:text-[#797979] font-medium text-sm">Méthodes
                                                                                de paiement
                                                                            </th>
                                                                            <th className="px-2 sm:px-4 py-2 text-left text-[#797979] dark:text-[#797979] font-medium text-sm">Numéro
                                                                                de suivi
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <tr>
                                                                            <td className="px-2 sm:px-4 py-2 font-medium text-sm dark:text-[#F2F2F2]">{row.original.shippingAddress}</td>
                                                                            <td className="hidden sm:table-cell px-4 py-2 font-medium text-sm dark:text-[#F2F2F2]">{row.original.deliveryMethod}</td>
                                                                            <td className="hidden sm:table-cell px-4 py-2 font-medium text-sm dark:text-[#F2F2F2]">{row.original.paymentMethod}</td>
                                                                            <td className="px-2 sm:px-4 py-2 font-medium text-sm dark:text-[#F2F2F2]">{row.original.trackingNumber}</td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={columns.length} className="p-2 sm:p-4">
                                                                    <table
                                                                        className="min-w-full bg-white dark:bg-[#333333] border-border dark:border-[#494949] border rounded-lg">
                                                                        <thead className="bg-[#F8F8F8] dark:bg-[#232323]">
                                                                        <tr>
                                                                            <th className="px-2 sm:px-4 py-2 text-left text-[#797979] dark:text-[#797979] font-medium text-sm">Produit</th>
                                                                            <th className="hidden sm:table-cell px-4 py-2 text-left text-[#797979] dark:text-[#797979] font-medium text-sm">Quantité</th>
                                                                            <th className="table-cell sm:hidden px-2 py-2 text-left text-[#797979] dark:text-[#797979] font-medium text-sm">Qte</th>
                                                                            <th className="hidden sm:table-cell px-4 py-2 text-left text-[#797979] dark:text-[#797979] font-medium text-sm">Prix</th>
                                                                            <th className="px-2 sm:px-4 py-2 text-left text-[#797979] dark:text-[#797979] font-medium text-sm">Totale</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {row.original.products.map((product, index) => (
                                                                            <tr key={index} className="transition-all">
                                                                                <td className="px-2 sm:px-4 py-2 text-sm flex items-center">
                                                                                    <ImageWithFallback
                                                                                        src={product.imageUrl}
                                                                                        alt={product.name}
                                                                                        className={"w-10 h-10 mr-4"}/>
                                                                                    <div
                                                                                        className="flex flex-col items-start gap-1">
                                                                                        <p className="font-medium text-sm max-w-[12vw] text-ellipsis whitespace-nowrap overflow-hidden dark:text-[#F2F2F2]">{product.name}</p>
                                                                                        <small
                                                                                            className="text-gray-500 dark:text-[#797979]">SKU: {product.sku}</small>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="px-2 sm:px-4 py-2 text-sm font-medium dark:text-[#F2F2F2]">{product.quantity}</td>
                                                                                <td className="hidden sm:table-cell px-4 py-2 text-sm font-medium dark:text-[#F2F2F2]">{formatCurrency(product.price)}</td>
                                                                                <td className="px-2 sm:px-4 py-2 text-sm font-medium dark:text-[#F2F2F2]">{formatCurrency(product.total)}</td>
                                                                            </tr>
                                                                        ))}
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )}
                                                </Fragment>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="flex flex-row w-full gap-3 items-center justify-center">
                                <TablePaginationNavigation table={table}/>
                                <select
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                    className="px-4 py-2 rounded text-sm border border-gray-200 dark:border-[#494949] dark:bg-[#333333] dark:text-[#F2F2F2] h-full outline-none focus:outline-primaryLight font-medium">
                                    {pageSize < 5 && <option key={0} className="font-medium dark:bg-[#333333]">{pageSize}</option>}
                                    {rowSizeOptions.map(size => (
                                        <option key={size} value={size}
                                                className="hover:bg-primaryExtraLight dark:hover:bg-[#232323] font-medium dark:bg-[#333333]">
                                            {size}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}

const DeliveryStatus = ({status}) => {
    return (
        (status === "pending") ?
            <div className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-[#D8ECFF] dark:bg-[#232323]'>
                <p className="text-sm font-medium text-[#2688E4] dark:text-[#F2F2F2]">En Attente</p>
            </div>
            :
            status == "on delivery" ?
                <div
                    className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-yellow-100 dark:bg-[#232323] border border-yellow-500 dark:border-[#494949]'>
                    <p className="text-sm font-medium text-yellow-500 dark:text-[#F2F2F2]">Expédié</p>
                </div>
                :
                <div
                    className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-primaryExtraLight dark:bg-[#232323] border border-primaryLight dark:border-[#494949]'>
                    <p className="text-sm font-medium text-primaryDark dark:text-[#F2F2F2]">Livré</p>
                </div>
    )
}