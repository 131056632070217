import React, {useEffect, useMemo, useState, useCallback, useRef} from "react";
import Select, {components} from "react-select";
import {useClickOutside} from "../../hooks/context/context";

const CustomValueContainer = (props) => {
    const {children, getValue, hasValue, selectProps} = props;
    const maxDisplayedValues = selectProps.maxDisplayedValues || 1;

    if (hasValue && Array.isArray(getValue()) && getValue().length > maxDisplayedValues) {
        const selected = getValue();
        const displayedOptions = selected.slice(0, maxDisplayedValues);
        const extraCount = selected.length - maxDisplayedValues;

        return (
            <components.ValueContainer {...props}>
                {displayedOptions.map((option, index) => (
                    <span key={option.value} className="multi-value-container max-w-[60%] border dark:border-[#494949]">
                        <p className="truncate">{option.label}</p>
                        {index !== displayedOptions.length - 1 &&
                            <span className="mx-1 text-gray-400 dark:text-[#797979]">,</span>
                        }
                    </span>
                ))}
                <span className="shrink-0 text-gray-500 dark:text-[#797979]">
                    +{extraCount} more
                </span>
                {children[children.length - 1]}
            </components.ValueContainer>
        );
    }

    return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
};

const CustomMenuList = ({children, ...props}) => {
    const {selectProps} = props;
    const {options, value, onChange} = selectProps;

    const handleSelectAll = useCallback((e) => {
        e.preventDefault();
        const allOptions = options;
        const allSelected = allOptions.length === value.length;

        if (allSelected) {
            onChange([]);
        } else {
            onChange(allOptions);
        }
    }, [options, value, onChange]);

    const allSelected = options.length === value.length;

    return (
        <components.MenuList {...props} className="w-full">
            {
                options.length > 0 &&
                <button
                    className="px-3 py-2 border-b border-gray-200 dark:border-[#494949] cursor-pointer hover:bg-gray-50 dark:hover:bg-[#333333] z-[99] w-full dark:bg-[#232323]"
                    onClick={handleSelectAll}
                >
                    <div className="flex items-center justify-between">
                    <span className="text-sm font-medium dark:text-[#F2F2F2]">
                        {!allSelected ? "Select All" : "Deselect All"}
                    </span>
                        {allSelected && (
                            <span className="text-sm text-green-600">✓</span>
                        )}
                    </div>
                </button>
            }
            {children}
        </components.MenuList>
    );
};

export default function GenericSelect({
                                          isMulti = true,
                                          defaults = [],
                                          inputs = [],
                                          handleChanges,
                                          setWidth = '100%',
                                          reset = false,
                                          maxDisplayedValues = 1,
                                          isDisabled = false,
                                      }) {
    const [selectedValues, setSelectedValues] = useState(defaults || []);
    const [inputsState, setInputsState] = useState(inputs || []);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [darkMode, setDarkMode] = useState(false);
    const selectWrapperRef = useRef(null);

    useEffect(() => {
        // Initial dark mode check
        const isDark = localStorage.getItem('darkMode') === 'true';
        setDarkMode(isDark);

        // Listen for dark mode changes
        const handleDarkModeChange = (e) => {
            if (e.key === 'darkMode') {
                setDarkMode(e.newValue === 'true');
            }
        };

        window.addEventListener('storage', handleDarkModeChange);

        // Watch for DOM class changes to detect dark mode toggle
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {
                    setDarkMode(document.documentElement.classList.contains('dark'));
                }
            });
        });

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['class']
        });

        // Cleanup listeners
        return () => {
            window.removeEventListener('storage', handleDarkModeChange);
            observer.disconnect();
        };
    }, []);

    const customStyles = useMemo(() => ({
        control: (base, state) => ({
            ...base,
            width: "100%",
            borderRadius: "3px",
            borderColor: state.isFocused ? "#FF7354" : "var(--border)",
            boxShadow: null,
            "&:hover": {
                borderColor: "#FF7354",
            },
            cursor: "pointer",
            height: "auto",
            minHeight: "38px",
            backgroundColor: darkMode ? '#232323' : 'white',
            color: darkMode ? '#F2F2F2' : 'inherit'
        }),
        multiValue: (base) => ({
            ...base,
            whiteSpace: "nowrap",
            overflow: "hidden",
            minWidth: "100%",
            backgroundColor: darkMode ? '#333333' : base.backgroundColor,
            color: darkMode ? '#F2F2F2' : 'inherit'
        }),
        singleValue: (base) => ({
            ...base,
            whiteSpace: "nowrap",
            overflow: "hidden",
            minWidth: "100%",
            color: darkMode ? '#F2F2F2' : 'inherit'
        }),
        valueContainer: (base) => ({
            ...base,
            padding: '2px 8px',
            flexWrap: 'nowrap',
            overflow: 'hidden',
            gap: '0',
        }),
        menu: (base) => ({
            ...base,
            zIndex: 9999,
            position: 'absolute',
            backgroundColor: darkMode ? '#232323' : base.backgroundColor,
        }),
        menuList: (base) => ({
            ...base,
            padding: 0,
            maxHeight: '300px',
            zIndex: 9999,
            backgroundColor: darkMode ? '#232323' : base.backgroundColor,
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused ? "#FF7354" : (darkMode ? '#232323' : null),
            color: state.isFocused ? "white" : (darkMode ? '#F2F2F2' : null),
            cursor: 'pointer',
            width: "100%",
            minWidth: "fit-content",
            '&:hover': {
                backgroundColor: darkMode ? '#333333' : '#FF7354',
                color: 'white'
            }
        }),
    }), [darkMode]);

    useEffect(() => {
        const uniqueInputs = inputs
            ? Array.from(
                new Map(inputs.map((item) => [item.value, item])).values()
            )
            : [];
        setInputsState(uniqueInputs);
    }, [inputs]);

    useEffect(() => {
        setSelectedValues(defaults || []);
    }, [defaults]);

    useEffect(() => {
        if (reset) {
            setSelectedValues([]);
            handleChanges([]);
        }
    }, [reset, handleChanges]);

    const handleChange = useCallback((selectedOptions) => {
        setSelectedValues(selectedOptions || []);
        handleChanges(selectedOptions || []);
    }, [handleChanges]);

    const handleClickOutside = useCallback(() => {
        if (selectWrapperRef.current) {
            selectWrapperRef.current.blur();
            setIsMenuOpen(false)
        }
    }, []);

    useClickOutside(selectWrapperRef, handleClickOutside);

    const selectComponent = useMemo(() => (
        <Select
            styles={customStyles}
            isMulti={isMulti}
            options={inputsState}
            value={selectedValues}
            className="font-medium text-sm text-ellipsis whitespace-nowrap h-10 dark:text-[#F2F2F2]"
            onChange={handleChange}
            onMenuClose={() => setIsMenuOpen(false)}
            onMenuOpen={() => setIsMenuOpen(true)}
            menuIsOpen={isMenuOpen}
            components={{
                ValueContainer: CustomValueContainer,
                MenuList: isMulti ? CustomMenuList : components.MenuList
            }}
            maxDisplayedValues={maxDisplayedValues}
            placeholder="Select options..."
            noOptionsMessage={() => "No options available"}
            aria-label="Select input"
            isDisabled={isDisabled}
            isClearable
            closeMenuOnSelect={!isMulti}
            blurInputOnSelect={!isMulti}
            menuShouldBlockScroll={false}
        />
    ), [customStyles, isMulti, inputsState, selectedValues, handleChange, isMenuOpen, maxDisplayedValues]);

    return (
        <div ref={selectWrapperRef} className="select-container w-full" style={{width: setWidth}}>
            {selectComponent}
        </div>
    );
}