import {api, api2} from "../instance"

export const registerUser = async (data) => {
    try {
        return await api.post('/api/register', data);
    } catch (error) {
        return error.response.data;
    }
}

export const loginUser = (data) => {
    try {
        return api.post('/api/login', data);
    } catch (error) {
        return error.response.data;
    }
}

export const getUserInfoFromFacebookToken = async (token) => {
    try {
        return await api.get("/api/auth/facebook/user-info", {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    } catch (err) {
        return err;
    }
}
export const getUserInfoFromGoogleToken = (token) => {
    return api2.get("/api/auth/google/user-info", {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
}

export const googleAuthCallBack = async (data) => {
    try {
        return api.post('/api/auth/google/callback', data);
    } catch (error) {
        return error.response.data;
    }
}

export const facebookAuthCallBack = async (data) => {
    try {
        return api.post('/api/auth/facebook/callback', data);
    } catch (error) {
        return error.response.data;
    }
}

export const getProfile = () => {
    try {
        return api.get('/api/profile');
    } catch (error) {
        return error.response.data;
    }
}
export const updateProfile = (id,data) => {
    console.log(data.values())
    return api.post(`/api/profile/${id}`, data);
}

export const logoutUser = async () => {
    try {
        return await api.post('/api/logout');
    } catch (error) {
        return error.response.data;
    }
}

export const sendResetLink = (email)=>{
    return api.post("/api/forgot-password",{email:email})
}

export const resetPassword = (email,token,password,confirmed)=>{
    return api.post("/api/reset-password",{email:email,token:token,password:password,password_confirmation:confirmed})
}