import CheckBox from "../../../../../components/Checkbox";
import React from "react";
import {useFilterContext} from "../../../index";

export default function StockAvailability() {
    const {updateStockAvailability, stockAvailability} = useFilterContext();
    return (
        <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row h-9 items-center justify-between">
                <p className="font-medium dark:text-[#F2F2F2]">Disponibilité en stock</p>
            </div>
            <div className="flex flex-col gap-2">
                <div className="flex flex-row items-center gap-2">
                    <CheckBox
                        checkedValueState={[
                            stockAvailability, // Check if the current option is selected
                            (value) => {
                                updateStockAvailability(value)
                            }// Call handleOptionChange on click
                        ]}
                    />
                    <p className="font-medium dark:text-[#F2F2F2]">Disponible</p>
                </div>
            </div>
        </div>
    );

}