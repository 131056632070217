import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent } from '@mui/material';
import DataTable from '../dataTable/DataTable';
import { GetAllReports, UpdateReportStatus, ReportStats } from '../../../../services/AdminSapce/API';
import ToastError, { toastStyle } from "../../../../components/Toasts/Error/ToastError";
import { toast } from "sonner";
import { useNavigate } from 'react-router-dom';
import StatsCard from '../Dashboard/components/StatsCard';
import {useTranslation} from "react-i18next";
import GenericDropDown from "../../../../components/GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../../../../components/GenericDropDown/GenericDropDownItem";
import {ImageWithFallback} from '../../../../components/ImageWithFallBack/ImageWithFallBack';
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";

function getCurrentDate() {
    const options = { day: 'numeric', month: 'short' };
    return new Date().toLocaleDateString('fr-FR', options);
}

const ContentDialog = ({ open, handleClose, imageUrl, message }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle className="bg-gray-50 dark:bg-[#333333] border-b">
                <Typography variant="h6" className="text-gray-800 dark:text-[#F2F2F2]">
                    {t("adminPages.reports.reportDetails")}
                </Typography>
            </DialogTitle>
            <DialogContent className="mt-4 dark:bg-[#232323]">
                {message && (
                    <Typography className="mb-4 text-gray-800 dark:text-[#F2F2F2]">
                        {message}
                    </Typography>
                )}
                {imageUrl && (
                    <ImageWithFallback src={imageUrl} alt="Report" className="w-full h-auto" />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default function Reports() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isLaptop = useMediaQuery(theme.breakpoints.down('lg'));
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [stats, setStats] = useState({
        total: 0,
        in_progress: 0,
        solved: 0,
        rejected: 0
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [reports, setReports] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedMessage, setSelectedMessage] = useState('');

    const fetchReports = async () => {
        setLoading(true);
        setError(null);
        try {
            const [reportsResponse, statsResponse] = await Promise.all([
                GetAllReports(),
                ReportStats()
            ]);

            const reportsData = reportsResponse.data;
            setReports(reportsData.report.map(report => ({
                id: report.id,
                user: report.reportable.username,
                reported: report.reportable.username,
                message: report.message,
                status: report.status,
                issueType: report.type_issue,
                photo: report.photo,
                created_at: new Date(report.created_at).toLocaleDateString()
            })));

            const statsData = statsResponse.data;
            setStats(statsData.data);
        } catch (error) {
            toast.error(<ToastError message={t("adminPages.reports.errorFetchingData")} />, toastStyle);
            setError(t("adminPages.reports.errorLoadingReports"));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchReports();
    }, []);

    const handleContentClick = (imageUrl, message) => {
        setSelectedImage(imageUrl);
        setSelectedMessage(message);
        setDialogOpen(true);
    };

    const handleUpdateStatus = async (reportId, newStatus) => {
        try {
            await UpdateReportStatus(reportId, newStatus);
            toast.success(<ToastSuccess message={t("adminPages.reports.statusUpdateSuccess")}/>, toastStyle);
            fetchReports();
        } catch (error) {
            toast.error(<ToastError message={t("adminPages.reports.errorUpdatingStatus")} />, toastStyle);
        }
    };

    const getStatusColor = (status) => {
        switch(status.toLowerCase()) {
            case 'in progress':
                return 'text-yellow-500 dark:text-yellow-400';
            case 'resolved':
                return 'text-green-500 dark:text-green-400';
            case 'rejected':
                return 'text-red-500 dark:text-red-400';
            default:
                return 'text-gray-500 dark:text-gray-400';
        }
    };

    const getResponsiveWidth = (baseWidth) => {
        if (isMobile) return baseWidth * 0.6;
        if (isTablet) return baseWidth * 0.8;
        if (isLaptop) return baseWidth * 0.9;
        return baseWidth;
    };

    const columns = [
        {
            field: 'user',
            headerName: t("adminPages.reports.user"),
            width: getResponsiveWidth(150),
            flex: 1,
            minWidth: 100,
            renderCell: ({row}) => (
                <div className="font-semibold dark:text-[#F2F2F2] truncate">
                    {row.user}
                </div>
            )
        },
        {
            field: 'reported',
            headerName: t("adminPages.reports.reported"),
            width: getResponsiveWidth(150),
            flex: 1,
            minWidth: 100,
            renderCell: ({row}) => (
                <div className="dark:text-[#F2F2F2] truncate">
                    {row.reported}
                </div>
            )
        },
        {
            field: 'details',
            headerName: t("adminPages.reports.details"),
            width: getResponsiveWidth(120),
            flex: 0.8,
            minWidth: 90,
            renderCell: ({ row }) => (
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        handleContentClick(row.photo, row.message);
                    }}
                    className="cursor-pointer text-blue-500 hover:text-blue-700 dark:text-[#F2F2F2] dark:hover:text-[#CBCBCB] transition-colors truncate"
                >
                    {t("adminPages.reports.viewDetails")}
                </div>
            )
        },
        {
            field: 'status',
            headerName: t("adminPages.reports.status"),
            width: getResponsiveWidth(120),
            flex: 0.8,
            minWidth: 90,
            renderCell: ({ row }) => (
                <div className={`font-semibold ${getStatusColor(row.status)} truncate`}>
                    {row.status}
                </div>
            )
        },
        {
            field: 'issueType',
            headerName: t("adminPages.reports.issueType"),
            width: getResponsiveWidth(150),
            flex: 1,
            minWidth: 100,
            hide: isMobile,
            renderCell: ({row}) => (
                <div className="truncate">
                    {row.issueType}
                </div>
            )
        },
        {
            field: 'created_at',
            headerName: t("adminPages.reports.createdAt"),
            width: getResponsiveWidth(150),
            flex: 1,
            minWidth: 100,
            hide: isMobile,
            renderCell: ({row}) => (
                <div className="truncate">
                    {row.created_at}
                </div>
            )
        },
        {
            field: 'actions',
            headerName: '',
            width: getResponsiveWidth(120),
            flex: 0.5,
            minWidth: 80,
            renderCell: (params) => (
                <Box display="flex">
                    <GenericDropDown>
                        {params.row.status !== 'in progress' && (
                            <GenericDropDownItem
                                value={t("adminPages.reports.inProgress")}
                                handleClick={() => handleUpdateStatus(params.row.id, 'in progress')}
                            />
                        )}
                        {params.row.status !== 'resolved' && (
                            <GenericDropDownItem
                                value={t("adminPages.reports.resolved")}
                                handleClick={() => handleUpdateStatus(params.row.id, 'solved')}
                            />
                        )}
                        {params.row.status !== 'rejected' && (
                            <GenericDropDownItem
                                value={t("adminPages.reports.rejected")}
                                handleClick={() => handleUpdateStatus(params.row.id, 'rejected')}
                            />
                        )}
                    </GenericDropDown>
                </Box>
            ),
        }
    ];

    return (
        <Box className="min-h-screen bg-gray-50 dark:bg-[#232323] p-6">
            <Box className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
                <StatsCard
                    title={t("adminPages.reports.totalReports")}
                    value={stats.total}
                    ispercentage={false}
                    date={getCurrentDate()}
                />
                <StatsCard
                    title={t("adminPages.reports.pendingReports")}
                    value={stats.in_progress}
                    ispercentage={false}
                    date={getCurrentDate()}
                />
                <StatsCard
                    title={t("adminPages.reports.resolvedReports")}
                    value={stats.solved}
                    ispercentage={false}
                    date={getCurrentDate()}
                />
                <StatsCard
                    title={t("adminPages.reports.rejectedReports")}
                    value={stats.rejected}
                    ispercentage={false}
                    date={getCurrentDate()}
                />
            </Box>

            <Box className="bg-white dark:bg-[#333333] rounded-lg shadow-sm overflow-x-auto">
                {loading ? (
                    <div className="flex items-center justify-center h-64">
                        <div className="loader"/>
                    </div>
                ) : error ? (
                    <Box className="flex items-center justify-center h-64">
                        <Typography className="text-red-500 dark:text-[#F9CDC4]">
                            {error}
                        </Typography>
                    </Box>
                ) : (
                    <>
                        <DataTable
                            rows={reports}
                            columns={columns}
                            showActions={false}
                            onRowClick={() => {}}
                            showAddButton={false}
                            showExportImport={false}
                            showApproveRejectButtons={false}
                            showSelectAll={false}
                            autoHeight
                            disableExtendRowFullWidth
                        />
                        <ContentDialog
                            open={dialogOpen}
                            handleClose={() => setDialogOpen(false)}
                            imageUrl={selectedImage}
                            message={selectedMessage}
                        />
                    </>
                )}
            </Box>
        </Box>
    );
}