import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, Avatar, Typography, useTheme, useMediaQuery } from '@mui/material';
import CustomTabs from '../Tabs/Tabs';
import DataTable from '../dataTable/DataTable';
import {changeUserStatus, deleteUser, getUsers} from '../../../../services/AdminSapce/API';
import ToastError, { toastStyle } from "../../../../components/Toasts/Error/ToastError";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import { toast } from "sonner";
import GenericDropDown from "../../../../components/GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../../../../components/GenericDropDown/GenericDropDownItem";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";

const VerificationStatus = ({ status }) => {
    return (
        <>
            {(status === "banné" || status === "banned") ? (
                <div className='w-fit px-2 sm:px-4 py-1 sm:py-2 rounded-lg flex items-center justify-center bg-red-100 dark:bg-[#2A2A2A] border border-red-500 dark:border-[#E44826]'>
                    <p className="text-xs sm:text-sm font-medium text-red-500 dark:text-[#E44826]">{status === "banned" ? "banné" : status}</p>
                </div>
            ) : (
                <div className='w-fit px-2 sm:px-4 py-1 sm:py-2 rounded-lg flex items-center justify-center bg-green-100 dark:bg-[#2A2A2A] border border-green-500 dark:border-[#4CAF50]'>
                    <p className="text-xs sm:text-sm font-medium text-green-500 dark:text-[#4CAF50]">{status}</p>
                </div>
            )}
        </>
    );
};
export default function Users() {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    const secondaryMenuItems = useMemo(() => [
        { id: 'acheteur', label: `${t("adminPages.user.buyer")}`, icon: '/resources/buyer.svg' },
        { id: 'vendeur', label: `${t("adminPages.user.seller")}`, icon: '/resources/seller.svg' },
        { id: 'secretaire', label: `${t("adminPages.user.secretary")}`, icon: '/resources/management-icon.svg' },
    ], [t]);

    const roleMap = useMemo(() => ({
        'acheteur': 4,
        'vendeur': 3,
        'secretaire': 2
    }), []);

    const [currentTab, setCurrentTab] = useState('acheteur');
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentUsers, setCurrentUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);

    const fetchUsers = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const users = await getUsers();
            setAllUsers(users);
            const filteredUsers = users
                .filter(user => user.role_id === roleMap[currentTab])
                .map(user => ({
                    id: user.id,
                    role_id: user.role_id,
                    name: `${user.first_name} ${user.last_name}`,
                    email: user.email,
                    phone: user.contact?.phone_number ?? 'N/A',
                    status: user.status,
                    avatarUrl: user.profile_photo || 'https://example.com/default-avatar.jpg',
                }));
            setCurrentUsers(filteredUsers);
        } catch (error) {
            toast.error(<ToastError message={t("adminPages.user.error.dataReception")} />, toastStyle);
            setError('Erreur lors du chargement des utilisateurs.');
        } finally {
            setLoading(false);
        }
    }, [t, currentTab, roleMap]);

    useEffect(() => {
        fetchUsers();
    }, []); // Only fetch on mount

    useEffect(() => {
        if (allUsers.length) {
            const filteredUsers = allUsers
                .filter(user => user.role_id === roleMap[currentTab])
                .map(user => ({
                    id: user.id,
                    role_id: user.role_id,
                    name: `${user.first_name} ${user.last_name}`,
                    email: user.email,
                    phone: user.contact?.phone_number ?? 'N/A',
                    status: user.status,
                    avatarUrl: user.profile_photo || 'https://example.com/default-avatar.jpg',
                }));
            setCurrentUsers(filteredUsers);
        }
    }, [currentTab, allUsers, roleMap]);

    const getResponsiveWidth = useCallback(() => {
        if (isMobile) return 150;
        if (isTablet) return 200;
        return 250;
    }, [isMobile, isTablet]);

    const handleNavigateToProfile = useCallback((roleId, userId) => {
        const paths = {
            2: `/admin/secretary/${userId}`,
            3: `/admin/seller/${userId}`,
            4: `/admin/buyer/${userId}`
        };
        navigate(paths[roleId] || '');
    }, [navigate]);

    const commonColumns = useMemo(() => [
        {
            field: 'name',
            headerName: `${t("adminPages.user.name")}`,
            width: getResponsiveWidth(),
            renderCell: (params) => (
                <Box display="flex" alignItems="center" className="text-[#333333] dark:text-[#F2F2F2]">
                    <Avatar
                        alt={params.row.name}
                        src={params.row.avatarUrl}
                        style={{
                            marginRight: isMobile ? 5 : 10,
                            width: isMobile ? 24 : 32,
                            height: isMobile ? 24 : 32
                        }}
                    />
                    <Typography variant={isMobile ? "caption" : "body1"} className="text-[#333333] dark:text-[#F2F2F2]">
                        <a onClick={() => handleNavigateToProfile(params.row.role_id, params.row.id)}>
                            <Box display="flex" alignItems="center">
                                {params.row.name}
                            </Box>
                        </a>
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'email',
            headerName: `${t("adminPages.user.email")}`,
            width: getResponsiveWidth(),
            hide: isMobile,
        },
        {
            field: 'phone',
            headerName: `${t("adminPages.user.phone")}`,
            width: getResponsiveWidth(),
            hide: isMobile || isTablet,
        },
        {
            field: 'status',
            headerName: `${t("adminPages.user.status")}`,
            width: isMobile ? 100 : 200,
            renderCell: (params) => <VerificationStatus status={params.row.status} />,
        },
        {
            field: 'actions',
            headerName: '',
            width: isMobile ? 50 : 150,
            renderCell: (params) => (
                <Box display="flex">
                    <GenericDropDown>
                        <GenericDropDownItem
                            value={params.row.status === 'banned' ? t('activate') : t('ban')}
                            index={2}
                            handleClick={() => handleChangeUserStatus([params.row.id], params.row.status === 'banned' ? 'active' : 'banned')}
                        />
                        <GenericDropDownItem
                            value={t('delete')}
                            index={3}
                            handleClick={() => handleDelete(params.row.id)}
                        />
                    </GenericDropDown>
                </Box>
            ),
        },
    ], [t, getResponsiveWidth, isMobile, isTablet, handleNavigateToProfile]);

    const handleDelete = async (id) => {
        try {
            await deleteUser(id);
            setAllUsers(prevUsers => prevUsers.filter(user => user.id !== id));
            toast.success(<ToastSuccess message={t("adminPages.user.success.userDeleted")} />, toastStyle);
        } catch (err) {
            toast.error(<ToastError message={err.response?.data?.message} />, toastStyle);
        }
    }

    const handleChangeUserStatus = async (userId, status) => {
        try {
            await changeUserStatus(userId, status);
            setAllUsers(prevUsers =>
                prevUsers.map(user =>
                    user.id === userId[0] ? {...user, status} : user
                )
            );
            toast.success(<ToastSuccess message={`Utilisateur ${status === 'banned' ? 'banni' : 'activé'} avec succès`}/>,toastStyle );
        } catch (error) {
            toast.error(<ToastError message={error.response?.data?.message}  />);
        }
    };

    const handleTabChange = (tabId) => {
        setCurrentTab(tabId);
    };

    const link = useMemo(() => {
        const links = {
            'acheteur': '/admin/AddBuyer',
            'vendeur': '/admin/AddSeller',
            'secretaire': '/admin/AddSecretary'
        };
        return links[currentTab] || '';
    }, [currentTab]);

    return (
        <Box sx={{ height: '100%', width: '100%', overflow: 'auto' }} className="bg-white dark:bg-[#232323]">
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
                <Box flex={1} display="flex" flexDirection="row">
                    <Box flex={1} padding={1}>
                        <CustomTabs menuItems={secondaryMenuItems} onTabChange={handleTabChange} />
                        <Box flex="1" display="flex" flexDirection="column">
                            {loading ? (
                                <div className="flex items-center justify-center w-full h-full">
                                    <div className="loader" />
                                </div>
                            ) : error ? (
                                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                    <Typography variant={isMobile ? "body2" : "body1"} className="text-red-500 dark:text-[#F9CDC4]">
                                        {error}
                                    </Typography>
                                </Box>
                            ) : (
                                <DataTable
                                    key={currentTab}
                                    rows={currentUsers}
                                    link={link}
                                    columns={commonColumns}
                                    showActions={false}
                                    showAddButton={true}
                                    onAddRow={() => console.log('Add new row for', currentTab)}
                                    searchQuery={searchQuery}
                                    setSearchQuery={setSearchQuery}
                                    sortConfig={sortConfig}
                                    setSortConfig={setSortConfig}
                                    showExportImport={false}
                                    showSelectAll={false}
                                    showApproveRejectButtons={false}
                                    enableToggle={false}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}