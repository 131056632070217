import {useTranslation} from "react-i18next";
export default function ProductGeneralCard({name,serial_number,average_rating,reviews,sub_category,description,brand}){
    const {t} = useTranslation();
    return(
        <div className="w-full h-fit flex flex-col gap-[10px] px-4 sm:px-0">
            <h1 className={"text-2xl font-medium line-clamp-2 text-ellipsis overflow-hidden dark:text-[#F2F2F2]"}>{name}</h1>
            <p className="text-[#767676] dark:text-[#CBCBCB] font-medium">SKU :{serial_number}</p>
            <div className="flex flex-row items-center gap-4">
                <div className="flex flex-col gap-2 items-start">
                    <div className="flex flex-row gap-2 items-center">
                        <div className="flex flex-row gap-2 items-center">
                            <img src="/resources/star.svg" alt="star"/>
                            <p className="font-bold dark:text-[#F2F2F2]">{Number(average_rating).toFixed(1)}</p>
                        </div>
                        <p className="font-medium text-[#9B9B9B] dark:text-[#797979] text-sm">({reviews} Avis)</p>
                    </div>
                    <p className="px-4 py-1 border-border border dark:border-[#494949] rounded-lg text-sm flex items-center justify-center w-fit dark:text-[#CBCBCB]">{sub_category?.name}</p>
                </div>
                <div className="flex flex-row items-center gap-6">
                    {brand?.logo ? (
                        <img
                            src={brand.logo}
                            alt="marque"
                            className="w-12"
                            onError={(e) => {
                                e.target.src = '/images/marque_logo.png';
                            }}
                        />
                    ) : (
                        <p className="text-gray-700 dark:text-[#CBCBCB] font-medium">{brand.brand_name}</p>
                    )}
                </div>
            </div>
            <p className="text-gray-500 dark:text-[#797979] font-medium">{t("addVehicle.generalInfo.description")} : </p>
            <div className="px-4 py-2 text-ellipsis overflow-hidden leading-8 dark:text-[#F2F2F2]" dangerouslySetInnerHTML={{__html: description}}></div>
        </div>
    )
}