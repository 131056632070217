import CheckBox from "../../../../components/Checkbox";
import React, {useEffect, useRef, useState} from "react";
import GenericSelect from "../../../../components/GenericSelect/GenericSelect";
import {create} from "zustand";
import GenericDropDown from "../../../../components/GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../../../../components/GenericDropDown/GenericDropDownItem";
import {
    createProduct,
    getCategoryhasSub,
    getSubCategory,
    getProductBrands,
    updateProduct,
    getVehicleBrandsByType,
    getVehicleModelsByBrand,
    getVehicleMotorisationsByModel,
    getVehiclesIdsByMotorisationIds,
    getVehiclesByIds
} from "../../../../services/SellerSpace/API";
import {toast} from "sonner";
import ToastError, {toastStyle} from "../../../../components/Toasts/Error/ToastError";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import Cookies from "js-cookie";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import {getProductById} from "../../../../services/marketplace/API";
import {getProductTypeLabelFromValue, processImageUrl} from "../../../../utils/utils";
import {useTranslation} from "react-i18next";
import {ArrowLeft,Plus} from "lucide-react"

export default function AddProduct() {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const {
        updateProductInfo,
        vehicle_motorisation_ids,
        setVehicle_motorisation_id,
        setVehicle_brand_id,
        setVehicle_model_id,
        productInfo,
        addNameValue,
        clearProductInfo
    } = AddProductPageContext();

    const navigate = useNavigate();

    useEffect(() => {
        clearProductInfo();
    }, []);

    useEffect(() => {
        try {
            const shopData = Cookies.get("shop")
            if (shopData) {
                if (shopData) {
                    const shopInfo = JSON.parse(shopData);
                    if (shopInfo?.status !== "subscribed") {
                        navigate(-1);
                    }
                }
            }
        } catch (e) {

        }
    }, [navigate]);

    const handleSave = async () => {
        // Input validation
        if (productInfo.stock_quantity <= 0) {
            toast.error(<ToastError message={t('sellerSpace.addProduct.validation.quantityRequired')}/>, toastStyle);
            return;
        }
        if (productInfo.price <= 0) {
            toast.error(<ToastError message={t('sellerSpace.addProduct.validation.priceRequired')}/>, toastStyle);
            return;
        }
        if (!productInfo.name) {
            toast.error(<ToastError message={t('sellerSpace.addProduct.validation.nameRequired')}/>, toastStyle);
            return;
        }
        if (!productInfo.description) {
            toast.error(<ToastError message={t('sellerSpace.addProduct.validation.descriptionRequired')}/>, toastStyle);
            return;
        }
        if (!productInfo.category_id) {
            toast.error(<ToastError message={t('sellerSpace.addProduct.validation.categoryRequired')}/>, toastStyle);
            return;
        }
        if (!productInfo.sub_category_id) {
            toast.error(<ToastError message={t('sellerSpace.addProduct.validation.subCategoryRequired')}/>, toastStyle);
            return;
        }
        if (!productInfo.brand_id) {
            toast.error(<ToastError message={t('sellerSpace.addProduct.validation.brandRequired')}/>, toastStyle);
            return;
        }
        if (!productInfo.mainPhoto) {
            toast.error(<ToastError message={t('sellerSpace.addProduct.validation.mainImageRequired')}/>, toastStyle);
            return;
        }
        if (!productInfo.photos || productInfo.photos.length === 0) {
            toast.error(<ToastError message={t('sellerSpace.addProduct.validation.additionalImageRequired')}/>, toastStyle);
            return;
        }

        setLoading(true);

        let fetchedData = []
        if (productInfo.type && productInfo.type !== "boat" && productInfo.type !== "engin") {
            const response = await getVehiclesIdsByMotorisationIds(vehicle_motorisation_ids);
            fetchedData = response.data.vehicle_ids
        } else {
            toast.error(<ToastError message={t('sellerSpace.addProduct.validation.vehicleTypeRequired')}/>, toastStyle);
            return
        }

        const newProductData = {...productInfo, vehicle_ids: fetchedData};
        updateProductInfo(newProductData);

        // Prepare form data for submission
        let data = new FormData();
        data.append("name", newProductData.name);
        data.append("description", newProductData.description);
        data.append("price", newProductData.price);
        data.append("stock_quantity", newProductData.stock_quantity);
        data.append("category_id", newProductData.category_id);
        data.append("sub_category_id", newProductData.sub_category_id);
        data.append("brand_id", newProductData.brand_id);
        data.append("condition", newProductData.condition);
        data.append("payment_type", newProductData.payment_type);
        newProductData.vehicle_ids.forEach(vehicleId =>
            data.append("vehicle_ids[]", vehicleId)
        );
        data.append("type", newProductData.type)

        if (newProductData.mainPhoto?.image_file) {
            data.append("photos[0][image_file]", newProductData.mainPhoto.image_file);
        } else if (newProductData.mainPhoto?.photo_link) {
            data.append("photos[0][photo_link]", newProductData.mainPhoto.photo_link);
        }
        data.append("photos[0][type]", 'img');

        productInfo.photos.forEach((photo, index) => {
            if (photo.image_file) {
                data.append(`photos[${index + 1}][image_file]`, photo.image_file);
            }
            if (photo.photo_link) {
                data.append(`photos[${index + 1}][photo_link]`, photo.photo_link);
            }
            data.append(`photos[${index + 1}][type]`, photo.type || 'img');
        });

        // Append characteristics
        Object.keys(productInfo.characteristics).forEach((key) => {
            data.append(`characteristics[${key}]`, productInfo.characteristics[key]);
        });

        if (id) {
            try {
                // Update product
                data.append("_method", "PUT");
                const updateResponse = await updateProduct(id, data);
                if (updateResponse.status === 200) {
                    toast.success(<ToastSuccess message={t('sellerSpace.addProduct.success.productUpdated')}/>, toastStyle);
                } else {
                    toast.success(<ToastSuccess message={t('sellerSpace.addProduct.success.productUpdated')}/>, toastStyle);
                }
            } catch (error) {
                handleCreateProductError(error);
            } finally {
                setLoading(false);
            }

        } else {
            try {
                // Create product
                const createResponse = await createProduct(data);
                if (createResponse.status === 201) {
                    toast.success(<ToastSuccess message={t('sellerSpace.addProduct.success.productAdded')}/>, toastStyle);
                } else {
                    toast.success(<ToastSuccess message={t('sellerSpace.addProduct.success.productAdded')}/>, toastStyle);
                }
            } catch (error) {
                handleCreateProductError(error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleCreateProductError = (error) => {
        let errorMessage = t('sellerSpace.addProduct.validation.unexpectedError');
        if (error.response?.data?.errors) {
            const firstErrorKey = Object.keys(error.response.data.errors)[0];
            errorMessage = error.response.data.errors[firstErrorKey];
        } else if (error.response?.data?.error) {
            errorMessage = error.response.data.error;
        }
        toast.error(<ToastError message={errorMessage}/>, toastStyle);
    };

    const location = useLocation();
    const {id} = location.state || {};

    useEffect(() => {
        if (id) {
            setLoading(true)
            getProductById(id)
                .then((response) => {
                    if (response.status === 200) {
                        addNameValue("name", response.data.name)
                        addNameValue("description", response.data.description)
                        addNameValue("price", response.data.price)
                        addNameValue("payment_type", response.data.payment_type)
                        addNameValue("stock_quantity", response.data.stock_quantity)
                        addNameValue("category_id", response.data.sub_category.category_id)
                        addNameValue("sub_category_id", response.data.sub_category_id)
                        addNameValue("brand_id", response.data.brand_id)
                        addNameValue("photos", response.data?.photos?.slice(1)?.map(photo => ({
                            photo_link: processImageUrl(photo.link),
                            type: photo.type
                        })))
                        addNameValue("mainPhoto", response.data?.photos?.[0] ? {
                            photo_link: processImageUrl(response.data.photos[0].link),
                            type: response.data.photos[0].type
                        } : null);
                        addNameValue("characteristics", response.data.characteristics)
                        addNameValue("type", response.data.type)
                        addNameValue("Etat", response.data.characteristics.Etat)
                        addNameValue("Color", response.data.characteristics.Couleur)
                        addNameValue("Weight", response.data.characteristics["Poids_(Kg)"])
                        addNameValue("Origine", response.data.characteristics.Origine)
                        addNameValue("OEM", response.data.characteristics.OEM)


                        if (response.data.type && response.data.vehicles?.length > 0) {
                            console.log("GETTING VEHICLES BY IDS")
                            getVehiclesByIds(response.data.vehicles?.map(vehicle => vehicle.id)).then((vehicleResponse) => {
                                let fetchedData = vehicleResponse.data;
                                let vehicle_brand_id = []
                                let vehicle_model_id = []
                                let vehicle_ids = []

                                const motorisation_ids = [...new Set(fetchedData.map(vehicle => vehicle.motorisation.id))];
                                vehicle_ids = [...new Set(fetchedData.map(vehicle => vehicle.id))];
                                vehicle_model_id = [...new Set(fetchedData.map(vehicle => vehicle.motorisation.vehicle_model.id))];
                                vehicle_brand_id = [...new Set(fetchedData.map(vehicle => vehicle.motorisation.vehicle_model.vehicle_brand.id))];

                                addNameValue("vehicle_ids", vehicle_ids)
                                setVehicle_motorisation_id(motorisation_ids)
                                setVehicle_brand_id(vehicle_brand_id)
                                setVehicle_model_id(vehicle_model_id)
                            })
                        }

                    }
                })
                .catch(() => {
                })
                .finally(() => {
                    setLoading(false)
                });
        }
    }, [addNameValue, id]);

    return (
        <div className="w-full h-full flex flex-col bg-white dark:bg-[#232323]">
            <div className="h-16 flex flex-row items-center justify-between px-4 border-b border-border dark:border-[#494949]">
                <div className="flex flex-row items-center gap-2">
                    <button
                        onClick={() => navigate(-1)}
                        className="p-4 hover:bg-gray-100 dark:hover:bg-[#333333] cursor-pointer rounded flex items-center justify-center">
                        <ArrowLeft />
                    </button>
                    <p className='sm:text-2xl font-semibold text-black dark:text-[#F2F2F2]'>{t('sellerSpace.addProduct.title')}</p>
                </div>
                <button
                    onClick={handleSave}
                    className={`${loading ? "bg-gray-400" :"bg-primaryLight"} dark:bg-[#E44826] flex items-center justify-center gap-2 px-6 py-3 w-fit h-fit rounded cursor-pointer`}>
                    {loading ?
                        <div className="w-6">
                            <div className="loader_white"></div>
                        </div> :
                        <img src="/resources/save.svg" alt="save icon"/>
                    }
                    <p className="text-sm font-semibold text-white">{t('sellerSpace.addProduct.save')}</p>
                </button>
            </div>
            <div
                className='w-full h-full flex flex-col sm:flex-row items-start max-h-[calc(91vh-112px)] min-h-[calc(91vh-112px)] overflow-y-auto'>
                <div
                    className="w-full sm:w-[30%] h-full flex flex-col border-r border-border dark:border-[#494949] gap-6 p-4 sm:overflow-y-auto sm:max-h-[calc(91vh-112px)] sm:min-h-[calc(91vh-112px)] items-start">
                    <AddProductPictures/>
                </div>
                <div
                    className="w-full sm:w-[70%] h-full flex flex-col py-4 px-2 sm:px-4 md:px-8 lg:px-16 gap-4 sm:overflow-y-auto sm:max-h-[calc(91vh-112px)] sm:min-h-[calc(91vh-112px)] items-start">
                    <GeneralInformation/>
                    <Characteristics/>
                </div>
            </div>
        </div>
    );
}

const tiktokRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/.+$/;

const AddProductPictures = () => {
    const [mainImage, setMainImage] = useState(null);
    const [additionalMedia, setAdditionalMedia] = useState([]);
    const [tiktokMedia, setTiktokMedia] = useState([]);
    const [showUrlModal, setShowUrlModal] = useState(false);
    const [mediaUrl, setMediaUrl] = useState('');
    const [isMainImage, setIsMainImage] = useState(true);
    const [isVideo, setIsVideo] = useState(false);
    const {addPhoto, removePhoto, productInfo} = AddProductPageContext();
    const {t} = useTranslation();

    const additionalMediaRef = useRef(null);

    const handleMainImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 10 * 1024 * 1024) {
                toast.error(<ToastError message={t('sellerSpace.addProduct.validation.imageSizeLimit')}/>, toastStyle);
                return;
            }

            const imageUrl = URL.createObjectURL(file);
            setMainImage(imageUrl);
            addPhoto({image_file: file, type: "img"}, true);
        }
    };

    const handleAdditionalMediaUpload = async (e) => {
        const files = Array.from(e.target.files);

        const validFiles = [];
        const invalidFiles = [];
        files.forEach(file => {
            if (file.size > 10 * 1024 * 1024) {
                invalidFiles.push(file.name);
            } else {
                validFiles.push(file);
            }
        });

        if (invalidFiles.length > 0) {
            toast.error(
                <ToastError message={`Les fichiers suivants sont trop volumineux : ${invalidFiles.join(', ')}.`} />,
                toastStyle
            );
        }

        if (validFiles.length === 0) {
            toast.error(<ToastError message="Aucun fichier valide à ajouter." />, toastStyle);
            e.target.value = null; // Clear the input
            return;
        }

        const newMedia = validFiles.map(file => ({
            file,
            url: URL.createObjectURL(file),
            name: file.name,
            type: "img"
        }));

        setAdditionalMedia(prev => [...prev, ...newMedia]);

        for (const {file} of newMedia) {
            try {
                addPhoto({image_file: file, type: "img"});
            } catch (error) {
                console.error(`Error uploading image: ${file.name}`, error);
                toast.error(<ToastError message={t('sellerSpace.addProduct.validation.uploadError', {name: file.name})}/>, toastStyle);
            }
        }

        e.target.value = null;
    };

    const handleUrlMediaSubmit = () => {
        if (mediaUrl) {
            if (isVideo) {
                const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
                const tiktokRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/.+$/;

                if (!youtubeRegex.test(mediaUrl) && !tiktokRegex.test(mediaUrl)) {
                    toast.error(<ToastError message={t('sellerSpace.addProduct.validation.invalidVideoUrl')}/>, toastStyle);
                    return;
                }
                if (youtubeRegex.test(mediaUrl)) {
                    setAdditionalMedia(prev => [...prev, {
                        url: mediaUrl,
                        name: mediaUrl,
                        type: "video",
                        origin: "youtube"
                    }]);
                    addPhoto({
                        photo_link: mediaUrl,
                        type: "video",
                        origin: "youtube"
                    });
                } else {
                    setTiktokMedia(prev => [...prev, {
                        url: mediaUrl,
                        name: mediaUrl,
                        type: "video",
                        index_at_photos: productInfo.photos.length
                    }]);
                    addPhoto({
                        photo_link: mediaUrl,
                        type: "video",
                        origin: "tiktok"
                    });
                }
            } else if (isMainImage) {
                setMainImage(mediaUrl);
                addPhoto({photo_link: mediaUrl, type: "img"}, true);
            } else {
                setAdditionalMedia(prev => [...prev, {url: mediaUrl, name: mediaUrl, type: "img"}]);
                addPhoto({photo_link: mediaUrl, type: "img"});
            }
            setMediaUrl('');
            setShowUrlModal(false);
        }
    };

    const removeAdditionalMedia = (index) => {
        const updatedMedia = additionalMedia.filter((_, i) => i !== index);
        removePhoto(index);
        setAdditionalMedia(updatedMedia);
    };

    const renderMediaItem = (item, index) => {
        console.log(item)
        if (item.type === "video") {
            const isYouTube = item.url?.includes("youtube.com") || item.url?.includes("youtu.be");
            if (isYouTube) {
                return (
                    <div key={index} className="relative w-[31%] h-32 bg-gray-100 dark:bg-[#333333]">
                        <iframe
                            src={`https://www.youtube.com/embed/${getYouTubeVideoId(item.url)}`}
                            className="w-full h-full"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        <button
                            onClick={() => removeAdditionalMedia(index)}
                            className="absolute top-1 right-1 bg-red-200 border border-primaryLight dark:border-[#E44826] h-10 w-10 flex items-center justify-center text-white rounded-full px-2 py-1"
                        >
                            <img src="/resources/close.svg" alt="close icon"/>
                        </button>
                    </div>
                )
            }
        } else {
            return (
                <div key={index} className="relative w-[31%] h-32 bg-gray-100 dark:bg-[#333333]">
                    <img src={item.url} alt={`additional-${index}`} className="w-full h-full object-contain"/>
                    <button
                        onClick={() => removeAdditionalMedia(index)}
                        className="absolute top-1 right-1 bg-red-200 border border-primaryLight dark:border-[#E44826] h-10 w-10 flex items-center justify-center text-white rounded-full px-2 py-1"
                    >
                        <img src="/resources/close.svg" alt="close icon"/>
                    </button>
                </div>
            );
        }
    };

    const getYouTubeVideoId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    const getTikTokVideoId = (url) => {
        const match = url.match(/tiktok\.com\/(?:.*\/)?video\/(\d+)/);
        return match ? match[1] : null;
    };

    const removeTikTokMedia = (indexToRemove, index_at_photos) => {
        setTiktokMedia(prevMedia => prevMedia.filter((_, index) => index !== indexToRemove));
        removePhoto(index_at_photos)
    };

    const isYouTubeVideo = (url) => url?.includes('youtube.com') || url?.includes('youtu.be');

    const location = useLocation();
    const {id} = location.state || {}

    useEffect(() => {
        if (id) {
            if (productInfo.photos && productInfo.photos.length > 0) {
                const additionalPhotos = productInfo.photos;
                setAdditionalMedia(additionalPhotos.map((photo, index) => {
                    console.log("USE EFFECT")
                    console.log(photo)
                    if (photo.type === "img" && !isYouTubeVideo(photo.photo_link) && !tiktokRegex.test(photo.photo_link)) {
                        if (photo?.photo_link){
                            return (
                                {
                                    url: photo?.photo_link ? photo?.photo_link : photo?.url,
                                    name: photo?.name ? photo?.name : photo?.photo_link,
                                    type: "img",
                                    index_at_photos: index
                                }
                            )
                        }else{
                            return (
                                {
                                    url: URL.createObjectURL(photo.image_file),
                                    name: photo.image_file.name,
                                    type:"img",
                                    index_at_photos: index
                                }
                            )
                        }
                    } else if (isYouTubeVideo(photo.photo_link)) {
                        return {
                            url: photo?.photo_link,
                            name: photo?.photo_link,
                            type: "video",
                            origin: "youtube",
                            index_at_photos: index
                        }
                    } else {
                        return null;
                    }
                }).filter(item => item !== null));
                setTiktokMedia(additionalPhotos.map((photo, index) => {
                    if (tiktokRegex.test(photo.photo_link)) {
                        return {
                            url: photo?.photo_link,
                            name: photo?.photo_link,
                            type: "video",
                            origin: "tiktok",
                            index_at_photos: index
                        }
                    } else {
                        return null
                    }
                }).filter(item => item !== null))
            }
        }
    }, [id, productInfo.photos]);

    useEffect(() => {
        if (id) {
            if (productInfo.mainPhoto) {
                setMainImage(productInfo.mainPhoto.photo_link);
            }
        }
    }, [id, productInfo.mainPhoto]);

    return (
        <div className="flex flex-col gap-4 w-full pb-4">
            <p className="leading-none pb-4 border-b border-border dark:border-[#494949] font-medium text-xl text-black dark:text-[#F2F2F2]">{t('sellerSpace.addProduct.media.title')}</p>

            {/* Main Image Section */}
            <div className="flex flex-col gap-2 w-full h-full">
                <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-[#797979] dark:text-[#CBCBCB] font-medium truncate max-w-fit">{t('sellerSpace.addProduct.media.mainImage')}</p>
                    <GenericDropDown parent={
                        <div
                            className="cursor-pointer flex flex-row items-center gap-2 px-4 py-1 rounded border-border dark:border-[#494949] border">
                            <Plus className="w-4"/>
                            <p className="font-medium text-sm text-ellipsis whitespace-nowrap overflow-hidden text-black dark:text-[#F2F2F2]">{t('sellerSpace.addProduct.media.addImage')}</p>
                        </div>
                    }>
                        <GenericDropDownItem
                            value={t('sellerSpace.addProduct.media.imageFromUrl')}
                            index={2}
                            handleClick={() => {
                                setIsMainImage(true);
                                setIsVideo(false);
                                setShowUrlModal(true);
                            }}
                        />
                        <GenericDropDownItem
                            value={t('sellerSpace.addProduct.media.imageFromDevice')}
                            index={3}
                            handleClick={() => document.getElementById('mainImageInput').click()}
                        />
                    </GenericDropDown>
                    <input
                        id="mainImageInput"
                        type="file"
                        accept="image/*"
                        style={{display: 'none'}}
                        onChange={handleMainImageUpload}
                    />
                </div>
                <img src={mainImage || "/images/placeholder.png"} alt="product"
                     className="dark:hidden block w-full h-[200px] object-contain bg-gray-100 dark:bg-[#333333]"/>
                <img src={mainImage || "/images/placeholder-dark.jpg"} alt="product"
                     className="dark:block hidden w-full h-[200px] object-contain bg-gray-100 dark:bg-[#333333]"/>
            </div>

            {/* Additional Media Section */}
            <div className="flex flex-col gap-2 w-full h-full">
                <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-[#797979] dark:text-[#CBCBCB] font-medium truncate max-w-fit">{t('sellerSpace.addProduct.media.additionalMedia')}</p>
                    <GenericDropDown parent={
                        <div
                            className="cursor-pointer flex flex-row items-center gap-2 px-4 py-1 rounded border-border dark:border-[#494949] border">
                            <Plus className="w-4"/>
                            <p className="font-medium text-sm text-ellipsis whitespace-nowrap overflow-hidden text-black dark:text-[#F2F2F2]">{t('sellerSpace.addProduct.media.addMedia')}</p>
                        </div>
                    }>
                        <GenericDropDownItem
                            value={t('sellerSpace.addProduct.media.imageFromUrl')}
                            index={2}
                            handleClick={() => {
                                setIsMainImage(false);
                                setIsVideo(false);
                                setShowUrlModal(true);
                            }}
                        />
                        <GenericDropDownItem
                            value={t('sellerSpace.addProduct.media.imageFromDevice')}
                            index={3}
                            handleClick={() => additionalMediaRef.current.click()}
                        />
                        <GenericDropDownItem
                            value={t('sellerSpace.addProduct.media.videoFromUrl')}
                            index={4}
                            handleClick={() => {
                                setIsMainImage(false);
                                setIsVideo(true);
                                setShowUrlModal(true);
                            }}
                        />
                    </GenericDropDown>
                    <input
                        ref={additionalMediaRef}
                        type="file"
                        accept="image/*"
                        multiple={true}
                        style={{display: 'none'}}
                        onChange={handleAdditionalMediaUpload}
                    />
                </div>

                {/* Display additional media or placeholders */}
                <div className="flex flex-row flex-wrap items-center gap-4 w-full">
                    {additionalMedia.map((item, index) => renderMediaItem(item, index))}
                    {Array.from({length: Math.max(0, 3 - additionalMedia.length)}).map((_, index) => (
                        <div key={`placeholder-${index}`}
                             className="relative w-[31%] h-32 bg-gray-100 dark:bg-[#333333]">
                            <img src="/images/placeholder.png" alt="placeholder"
                                 className="dark:hidden block w-full h-full object-contain"/>
                            <img src="/images/placeholder-dark.jpg" alt="placeholder"
                                 className="dark:block hidden w-full h-full object-contain"/>
                        </div>
                    ))}
                </div>
                {tiktokMedia.map((item, index) => (
                    <div key={index} className="flex flex-col border border-border dark:border-[#494949] rounded">
                        <div
                            className="flex flex-row items-center min-h-12 border-b-border dark:border-[#494949] border-b pl-4 pr-2 justify-between">
                            <p className="font-medium text-black dark:text-[#F2F2F2]">{t('sellerSpace.addProduct.media.tiktokVideo')} {index + 1}</p>
                            <button
                                onClick={() => removeTikTokMedia(index, item.index_at_photos)}
                                className="bg-red-200 border border-primaryLight dark:border-[#E44826] h-10 w-10 flex items-center justify-center text-white rounded-full px-2 py-1"
                            >
                                <img src="/resources/close.svg" alt="close icon"/>
                            </button>
                        </div>
                        <iframe
                            key={index}
                            src={`https://www.tiktok.com/player/v1/${getTikTokVideoId(item.url)}`}
                            className="w-full"
                            width="100%"
                            height="100%"
                            allowFullScreen
                        />
                    </div>
                ))}
            </div>

            {/* URL Modal */}
            {showUrlModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white dark:bg-[#232323] p-4 border-border dark:border-[#494949] border rounded-lg shadow-lg w-[400px]">
                        <h2 className="text-lg font-semibold mb-4 text-black dark:text-[#F2F2F2]">
                            {isVideo ? t('sellerSpace.addProduct.media.addVideo') : t('sellerSpace.addProduct.media.addImageFromUrl')}
                        </h2>
                        <input
                            type="text"
                            className="w-full border p-2 rounded mb-4 h-10 placeholder:text-sm focus:outline-primaryLight dark:focus:outline-[#E44826] dark:bg-[#333333] dark:text-[#F2F2F2] dark:border-[#494949]"
                            placeholder={isVideo ? t('sellerSpace.addProduct.media.addVideoFromUrl') : t('sellerSpace.addProduct.media.AddImageURL')}
                            value={mediaUrl}
                            onChange={(e) => setMediaUrl(e.target.value)}
                        />
                        <div className="flex justify-end gap-4">
                            <button
                                className="border border-border dark:border-[#494949] text-black dark:text-[#F2F2F2] px-4 py-2 rounded font-medium hover:bg-gray-50 dark:hover:bg-[#333333]"
                                onClick={() => setShowUrlModal(false)}
                            >
                                {t('sellerSpace.addProduct.media.cancel')}
                            </button>
                            <button
                                onClick={handleUrlMediaSubmit}
                                className="bg-primaryLight dark:bg-[#E44826] flex items-center justify-center gap-2 px-6 py-3 w-fit h-fit rounded cursor-pointer"
                            >
                                <img src="/resources/save.svg" alt="save icon"/>
                                <p className="text-sm font-semibold text-white">{t('sellerSpace.addProduct.media.add')}</p>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const GeneralInformation = () => {
    const [paiement, setPaiement] = useState(false);
    const [brands, setBrands] = useState([]);

    const {
        addNameValue,
        productInfo,
        vehicle_brand_ids,
        vehicle_motorisation_ids,
        vehicle_model_ids
    } = AddProductPageContext();

    const [filteredModels, setFilteredModels] = useState([]);
    const [filteredMotorisations, setFilteredMotorisations] = useState([]);

    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedModels, setSelectedModels] = useState([]);
    const [selectedMotorisations, setSelectedMotorisations] = useState([]);

    const [productCategory, updateProductCategory] = useState([]);
    const [subCategories, setSubCategories] = useState([]);

    const [productBrand, updateProductBrand] = useState([]);

    const location = useLocation();
    const {id} = location.state || {}
    const {t} = useTranslation();

    useEffect(() => {
        const handleDataOnEdit = async () => {
            if (id) {
                setPaiement(productInfo.payment_type !== "cash")
                let brandResult = selectedBrands;
                let modelResult = selectedModels;
                if (selectedBrands.length === 0) {
                    brandResult = brands.filter(brand => vehicle_brand_ids?.includes(brand.value))
                    setSelectedBrands(brandResult)
                }
                if (filteredModels.length === 0) {
                    console.log("updating Models within handleData On Edit")
                    let response = await getVehicleModelsByBrand(brandResult.map(brand => brand.value))
                    modelResult = response.data.vehicle_models.filter(model => vehicle_model_ids?.includes(model.id)).map(model => ({
                        value: model.id,
                        label: model.name
                    }))
                    setSelectedModels(modelResult)
                    setFilteredModels(modelResult);
                }
                if (filteredMotorisations.length === 0) {
                    let response = await getVehicleMotorisationsByModel(modelResult.map(model => model.value))

                    const filteredMotorisation = response.data.motorisations
                        .map(motorisation => ({
                            value: motorisation.id,
                            label: motorisation.name
                        }))
                    const selectedMotorisation = filteredMotorisation.filter(motorisation => vehicle_motorisation_ids?.includes(motorisation.value))
                    setSelectedMotorisations(selectedMotorisation)
                    setFilteredMotorisations(filteredMotorisation)
                }
            }
        }
        handleDataOnEdit()
    }, [brands, id, productInfo.payment_type, vehicle_brand_ids, vehicle_model_ids, vehicle_motorisation_ids]);

    useEffect(() => {
        getCategoryhasSub().then((response) => {
            if (response.status === 200) {
                const filteredCategories = response?.data?.filter(category => category.type === 'product');
                updateProductCategory(filteredCategories.map(item => ({
                    value: item.id,
                    label: item.category_name,
                    compatibility_type: item.compatibility_type
                })));
            }
        }).catch((error) => {
            toast.error(<ToastError message="Erreur lors de la récupération des catégories"/>, toastStyle);
        });

        getProductBrands()
            .then((response) => {
                if (response.status === 200) {
                    updateProductBrand(response.data.map(item => ({
                        value: item.id,
                        label: item.brand_name
                    })));
                }
            }).catch(() => {
            toast.error(<ToastError message="Erreur lors de la récupération des marques"/>, toastStyle);
        });
    }, []);

    const [loadingVehicles, setLoadingVehicles] = useState(false);

    useEffect(() => {
        if (!productInfo.type) return;

        if (productInfo.type === "industrial_machine") return;
        if (productInfo.type === "boat") return;
        if (productInfo.type === "engin") return;

        const fetchVehiclesByType = async () => {
            setLoadingVehicles(true);
            setSelectedBrands([]);
            setSelectedModels([]);
            setSelectedMotorisations([]);

            try {
                let response;

                response = await getVehicleBrandsByType(productInfo.type)

                const fetchedData = response.data || [];

                const brandMap = new Map();

                fetchedData.forEach(
                    item => {
                        if (!brandMap.has(item.id)) {
                            brandMap.set(item.id, item.brand_name);
                        }
                    });

                const uniqueBrands = Array.from(brandMap.entries()).map(([id, name]) => ({
                    value: id,
                    label: name
                }));

                setBrands(uniqueBrands);

            } catch (error) {
                toast.error(<ToastError message="Erreur lors de la récupération des véhicules"/>, toastStyle);
            } finally {
                setLoadingVehicles(false);
            }
        };

        fetchVehiclesByType();
    }, [productInfo.type]);

    useEffect(() => {
        addNameValue("brand_id", productBrand[0]?.value);
    }, [addNameValue, productBrand]);

    useEffect(() => {
        if (paiement) {
            addNameValue("payment_type", "cash/online");
        } else {
            addNameValue("payment_type", "cash");
        }
    }, [addNameValue, paiement]);

    const {setVehicle_brand_id, setVehicle_model_id, setVehicle_motorisation_id} = AddProductPageContext();

    const handleBrandChange = async (selectedOptions) => {
        setSelectedBrands(selectedOptions);

        const selectedBrandIds = selectedOptions.map(option => option.value);

        let response = await getVehicleModelsByBrand(selectedBrandIds);

        const filteredModels = response.data.vehicle_models;
        console.log("updating filtered models within handle brand change")
        setFilteredModels(filteredModels.map(model => ({value: model.id, label: model.name})));

        const validSelectedModels = selectedModels.filter(model =>
            filteredModels.some(filteredModel => filteredModel.id === model.value)
        );
        setSelectedModels(validSelectedModels);

        const selectedModelIds = validSelectedModels.map(option => option.value);

        const responseMotorisation = await getVehicleMotorisationsByModel(selectedModelIds);

        const filteredMotorisations = selectedMotorisations.filter(motorisation =>
            responseMotorisation.data.motorisations.some(
                response => response.id === motorisation.value
            ));
        setFilteredMotorisations(filteredMotorisations.map(motorisation => ({
            value: motorisation.id,
            label: motorisation.name
        })));

        const validSelectedMotorisations = selectedMotorisations.filter(motorisation =>
            filteredMotorisations.some(filteredMotorisation => filteredMotorisation.value === motorisation.value)
        );
        setSelectedMotorisations(validSelectedMotorisations);

        setVehicle_brand_id(selectedBrandIds);
        setVehicle_model_id(selectedModelIds);
        setVehicle_motorisation_id(validSelectedMotorisations.map(option => option.value));
    };

    const handleModelChange = async (selectedOptions) => {
        setSelectedModels(selectedOptions);

        const selectedModelIds = selectedOptions.map(option => option.value);

        let response = await getVehicleMotorisationsByModel(selectedModelIds);

        setFilteredMotorisations(response.data.motorisations.map(motorisation => ({
            value: motorisation.id,
            label: motorisation.name
        })));

        const validSelectedMotorisations = selectedMotorisations.filter(motorisation =>
            response.data.motorisations.some(filteredMotorisation => filteredMotorisation.id === motorisation.value)
        );

        setSelectedMotorisations(validSelectedMotorisations);
        setVehicle_model_id(selectedModelIds);
        setVehicle_motorisation_id(validSelectedMotorisations.map(option => option.value));
    };

    const handleMotorisationChange = (selectedOptions) => {
        setSelectedMotorisations(selectedOptions);
        setVehicle_motorisation_id(selectedOptions.map(option => option.value));
    };

    const handleCategoryChange = (selectedOption) => {
        if (selectedOption.value === null){
            addNameValue("type",null)
        }else{
            addNameValue("type", selectedOption.compatibility_type);
        }
        addNameValue("category_id", selectedOption.value);

        if (selectedOption.value === null || selectedOption.value === undefined){
            addNameValue("sub_category_id", []);
            setSubCategories([]);
            return;
        }

        getSubCategory(selectedOption.value).then((response) => {
            if (response.status === 200) {
                addNameValue("sub_category_id", response.data[0].id);
                setSubCategories(response.data.map(item => ({value: item.id, label: item.name})));
            }
        }).catch((error) => {
            console.error("Error fetching subcategories: ", error);
            toast.error(<ToastError
                message={error.response?.data?.message}/>, toastStyle);
        });

    };

    useEffect(() => {
        if (productInfo.category_id) {
            getSubCategory(productInfo.category_id).then((response) => {
                if (response.status === 200) {
                    addNameValue("sub_category_id", response.data[0].id);
                    setSubCategories(response.data.map(item => ({value: item.id, label: item.name})));
                }
            }).catch((error) => {
                toast.error(<ToastError
                    message={error.response?.data?.message}/>, toastStyle);
            });
        }
    }, [addNameValue, productInfo.category_id]);

    const handleSubCategoryChange = (selectedOption) => {
        addNameValue("sub_category_id", selectedOption.value);
    };

    return (
        <div className="flex flex-col gap-2 items-start w-full">
            <p className="leading-none pb-4 border-border dark:border-[#494949] border-dashed border-b w-full text-xl font-medium text-black dark:text-[#F2F2F2]">
                {t('sellerSpace.addProduct.generalInfo.title')}
            </p>

            <div className="flex flex-row sm:flex-nowrap flex-wrap gap-4 sm:gap-8 w-full h-fit">
                <div className="flex flex-col items-start justify-between w-full">
                    <p className="text-black dark:text-[#F2F2F2]">{t('sellerSpace.addProduct.generalInfo.productName')}</p>
                    <input
                        value={productInfo.name}
                        onChange={(e) => addNameValue(e.target.name, e.target.value)}
                        name="name"
                        type="text"
                        disabled={id}
                        className="w-full h-10 border border-border dark:border-[#494949] rounded px-4 py-2 focus:outline-primaryLight dark:focus:outline-[#E44826] dark:bg-[#333333] dark:text-[#F2F2F2]"
                    />
                </div>
                <div className="flex flex-col items-start justify-between w-full">
                    <p className="text-black dark:text-[#F2F2F2]">{t('sellerSpace.addProduct.generalInfo.brand')}</p>
                    <GenericSelect
                        inputs={productBrand}
                        defaults={productInfo.brand_id ? [
                                {
                                    value: productInfo.brand_id,
                                    label: productBrand.find(brand => brand.value === productInfo.brand_id)?.label
                                }]
                            : []}
                        isMulti={false}
                        handleChanges={(e) => addNameValue("brand_id", e.value)}
                        setWidth="100%"
                        isDisabled={id}
                    />
                </div>
            </div>
            <div className="flex flex-row sm:flex-nowrap flex-wrap gap-4 sm:gap-8 w-full h-fit">
                <div className="flex flex-col items-start justify-between w-full">
                    <p className="text-black dark:text-[#F2F2F2]">{t('sellerSpace.addProduct.generalInfo.quantity')}</p>
                    <input value={productInfo.stock_quantity} name={"stock_quantity"} disabled={id}
                           onChange={(e) => addNameValue(e.target.name, e.target.value)} min={0} type="number"
                           className="w-full h-10 border border-border dark:border-[#494949] rounded px-4 dark:bg-[#333333] dark:text-[#F2F2F2]"/>
                </div>
                <div className="flex flex-col items-start justify-between w-full">
                    <p className="text-black dark:text-[#F2F2F2]">{t('sellerSpace.addProduct.generalInfo.unitPrice')}</p>
                    <input value={productInfo.price} name={"price"} disabled={id}
                           onChange={(e) => addNameValue(e.target.name, e.target.value)}
                           type="number" min={0} className="w-full h-10 border border-border dark:border-[#494949] rounded px-4 dark:bg-[#333333] dark:text-[#F2F2F2]"/>
                </div>
            </div>
            <div className="flex flex-col items-start justify-between w-full mb-11">
                <p className="text-black dark:text-[#F2F2F2]">{t('sellerSpace.addProduct.generalInfo.description')}</p>
                <ReactQuill
                    className={"w-full h-[10vh] focus:outline-primaryLight dark:focus:outline-[#E44826]"}
                    onChange={(value) => addNameValue("description", value)}
                    value={productInfo.description}
                    theme={"snow"}
                />
            </div>
            <div className="flex flex-row sm:flex-nowrap flex-wrap items-center gap-4 sm:gap-2 w-full mt-2">
                <div className="flex flex-row items-center gap-2 w-full max-w-full sm:max-w-[25%]">
                    <CheckBox checkedValueState={[paiement, setPaiement]} disabled={id}/>
                    <p className="dark:text-[#F2F2F2]">{t('sellerSpace.addProduct.generalInfo.onlinePayment')}</p>
                </div>
                <div className="flex flex-col gap-2 w-full h-fit">
                    <p className="dark:text-[#F2F2F2]">{t('sellerSpace.addProduct.generalInfo.productFor')}</p>
                    <GenericSelect
                        inputs={productCategory}
                        defaults={productInfo.category_id ? productCategory.find(product => product.value === productInfo.category_id) : []}
                        isMulti={false}
                        handleChanges={handleCategoryChange}
                        setWidth="100%"
                    />
                </div>

                {subCategories?.length > 0 && (
                    <div className="flex flex-col gap-2 w-full h-fit">
                        <p className="text-black dark:text-[#F2F2F2]">{t('sellerSpace.addProduct.generalInfo.category')}</p>
                        <GenericSelect
                            inputs={subCategories}
                            defaults={productInfo.sub_category_id ? subCategories.find(subcategory => subcategory.value === productInfo.sub_category_id) : []}
                            isMulti={false}
                            handleChanges={handleSubCategoryChange}
                            setWidth="100%"
                        />
                    </div>
                )}
            </div>

            {
                (productInfo.type !== "boat" && productInfo.type !== "engin" && productInfo.type !== "industrial_machine") &&
                <div className="flex flex-col gap-2 w-full h-fit">
                    <p className="text-black dark:text-[#F2F2F2]">{t('sellerSpace.addProduct.compatibleVehicles.title')}</p>
                    <div className="flex flex-row sm:flex-nowrap flex-wrap gap-4 sm:gap-2 items-center w-full">
                        {productInfo.type ?
                            brands.length > 0 ?
                                <>
                                    <div className="flex flex-col gap-1 items-start w-full sm:max-w-[33%]">
                                        <p className="dark:text-[#F2F2F2]">{t('sellerSpace.addProduct.compatibleVehicles.vehicleBrand')}</p>
                                        <GenericSelect
                                            isDisabled={id && vehicle_brand_ids}
                                            inputs={brands}
                                            defaults={selectedBrands}
                                            isMulti={true}
                                            handleChanges={handleBrandChange}
                                            setWidth="100%"
                                        />
                                    </div>
                                    <div className="flex flex-col gap-1 items-start w-full sm:max-w-[33%]">
                                        <p className="dark:text-[#F2F2F2]">{t('sellerSpace.addProduct.compatibleVehicles.vehicleModel')}</p>
                                        <GenericSelect
                                            isDisabled={id && vehicle_model_ids}
                                            inputs={filteredModels}
                                            defaults={selectedModels}
                                            isMulti={true}
                                            handleChanges={handleModelChange}
                                            setWidth="100%"
                                            disabled={selectedBrands.length === 0}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-1 items-start w-full sm:max-w-[33%]">
                                        <p className="dark:text-[#F2F2F2]">{t('sellerSpace.addProduct.compatibleVehicles.vehicleEngine')}</p>
                                        <GenericSelect
                                            isDisabled={id && vehicle_motorisation_ids}
                                            inputs={filteredMotorisations}
                                            defaults={selectedMotorisations}
                                            isMulti={true}
                                            handleChanges={handleMotorisationChange}
                                            setWidth="100%"
                                            disabled={selectedModels.length === 0}
                                        />
                                    </div>
                                </>
                                :
                                <div
                                    className="flex flex-row rounded items-center justify-center text-center h-fit sm:h-10 bg-primaryExtraLight dark:bg-[#494949] w-full px-4 py-2">
                                    {loadingVehicles ?
                                        <div className="w-5">
                                            <div className="loader_primary"></div>
                                        </div>
                                        :
                                        <p className="font-medium text-primaryLight dark:text-[#E44826]">
                                            {t('sellerSpace.addProduct.compatibleVehicles.contactAdmin')}
                                        </p>
                                    }
                                </div>
                            :
                            <div
                                className="flex flex-row rounded items-center justify-center text-center h-10 bg-primaryExtraLight dark:bg-primaryDark w-full">
                                {loadingVehicles ?
                                    <div className="w-5">
                                        <div className="loader_primary"></div>
                                    </div>
                                    :
                                    <p className="font-medium text-primaryLight dark:text-white">
                                        {t('sellerSpace.addProduct.compatibleVehicles.selectVehicleType')}
                                    </p>
                                }
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    );
};

const Characteristics = () => {
    const {productInfo} = AddProductPageContext();
    const {t} = useTranslation();
    const [characteristicsData, setCharacteristicsData] = useState([]);

    useEffect(() => {
        setCharacteristicsData(() => [
            {
                characteristic: t('sellerSpace.addProduct.characteristics.condition'),
                value: productInfo.Etat,
                inputType: 'select',
                editable: true,
                options: [t('sellerSpace.addProduct.characteristics.new'), t('sellerSpace.addProduct.characteristics.used')]
            },
            {
                characteristic: t('sellerSpace.addProduct.characteristics.color'),
                value: productInfo.Color,
                inputType: 'text',
                editable: true,
                placeholder: t('sellerSpace.addProduct.characteristics.enterColor')
            },
            {
                characteristic: t('sellerSpace.addProduct.characteristics.weight'),
                value: productInfo.Weight,
                inputType: 'text',
                editable: true,
                placeholder: t('sellerSpace.addProduct.characteristics.enterWeight')
            },
            {
                characteristic: t('sellerSpace.addProduct.characteristics.origin'),
                value: productInfo.Origine,
                inputType: 'select',
                editable: true,
                options: [t('sellerSpace.addProduct.characteristics.original'), t('sellerSpace.addProduct.characteristics.chinese')]
            },
            {
                characteristic: t('sellerSpace.addProduct.characteristics.oem'),
                value: productInfo.OEM,
                inputType: 'text',
                editable: true,
                placeholder: t('sellerSpace.addProduct.characteristics.enterOEM')
            },
        ])
    }, [productInfo.Color, productInfo.Etat, productInfo.OEM, productInfo.Origine, productInfo.Weight, t]);

    const [newCharacteristic, setNewCharacteristic] = useState('');
    const [newValue, setNewValue] = useState('');
    const [requiredValue, setRequiredValue] = useState(false);
    const [requiredCharacteristic, setRequiredCharacteristic] = useState(false);

    const {setCharacteristics} = AddProductPageContext();

    useEffect(() => {
        const updatedData = [...characteristicsData];
        setCharacteristics(
            updatedData.reduce((acc, item) => {
                const key = item.characteristic.replace(/ /g, '_').replace(/Ø/, 'Ø');
                acc[key] = item.value;
                return acc;
            }, {})
        );
    }, [characteristicsData, setCharacteristics]);

    const handleAddRow = () => {
        if (!newCharacteristic) {
            setRequiredCharacteristic(true);
            return;
        }
        if (!newValue) {
            setRequiredValue(true);
            return;
        }
        setCharacteristicsData((prev) => [
            ...prev,
            {characteristic: newCharacteristic, value: newValue, inputType: "text", editable: true}
        ]);
        setNewCharacteristic('');
        setNewValue('');
    };

    const OEMref = useRef(false);

    const handleChange = (index, newValue, name) => {
        const updatedData = [...characteristicsData];
        OEMref.current = false;
        if (name === t('sellerSpace.addProduct.characteristics.oem')) {
            const isValid = /^[A-Za-z0-9\-*/\\]{0,15}$/.test(newValue);
            if (!isValid) {
                OEMref.current = true;
                return;
            }
            const isValid2 = /^[A-Za-z0-9\-*/\\]{6,15}$/.test(newValue);
            if (!isValid2) {
                OEMref.current = true;
            }
        }
        if (name === t('sellerSpace.addProduct.characteristics.weight')) {
            const isValid = /^[0-9]{0,10}(\.[0-9]{0,2})?$/.test(newValue);
            if (!isValid) {
                return;
            }
        }

        updatedData[index].value = newValue;
        setCharacteristicsData(updatedData);
    };

    return (
        <div className="flex flex-col gap-2 items-start w-full relative">
            <p className="leading-none pb-4 border-border dark:border-[#494949] border-dashed border-b w-full text-xl font-medium text-black dark:text-[#F2F2F2]">
                {t('sellerSpace.addProduct.characteristics.title')}
            </p>
            <table className="min-w-full bg-white dark:bg-[#232323] border border-gray-300 dark:border-[#494949] rounded-lg">
                <thead className="bg-gray-100 dark:bg-[#333333] top-0 z-10 min-h-14 h-14 border-b border-border dark:border-[#494949] max-h-14">
                <tr>
                    <th className="px-6 py-3 text-gray-500 dark:text-[#CBCBCB] text-left text-sm font-medium border-b border-gray-300 dark:border-[#494949] border-r">
                        {t('sellerSpace.addProduct.characteristics.characteristics')}
                    </th>
                    <th className="px-6 py-3 text-gray-500 dark:text-[#CBCBCB] text-left text-sm font-medium border-b border-gray-300 dark:border-[#494949]">
                        {t('sellerSpace.addProduct.characteristics.value')}
                    </th>
                </tr>
                </thead>
                <tbody>
                {characteristicsData.map((item, index) => (
                    <tr key={index} className="relative">
                        <td className="px-6 py-4 border-b border-gray-300 dark:border-[#494949] border-r text-black dark:text-[#F2F2F2]">
                            {item.characteristic}
                        </td>
                        <td className={`min-h-12 border ${item.characteristic === t('sellerSpace.addProduct.characteristics.oem') && OEMref.current ? `border-red-500 z-50` : `border-gray-300 dark:border-[#494949]`}`}>
                            {item.inputType === 'select' ? (
                                <select
                                    value={item.value}
                                    name={item.characteristic}
                                    onChange={(e) => handleChange(index, e.target.value, e.target.name)}
                                    className="w-full min-h-full border-none focus:outline-none px-4 bg-white dark:bg-[#333333] text-black dark:text-[#F2F2F2]"
                                >
                                    {item.options.map((option, i) => (
                                        <option key={i} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <input
                                    type={item.inputType}
                                    value={item.value}
                                    name={item.characteristic}
                                    onChange={(e) => handleChange(index, e.target.value, e.target.name)}
                                    min={0}
                                    placeholder={item.placeholder}
                                    className={`w-full min-h-full h-12 border-none ${item.editable ? '' : 'bg-gray-100 dark:bg-[#333333]'} focus:outline-none px-4 bg-white dark:bg-[#333333] text-black dark:text-[#F2F2F2] placeholder:text-gray-500 dark:placeholder:text-[#797979]`}
                                    readOnly={!item.editable}
                                    {...(item.characteristic === t('sellerSpace.addProduct.characteristics.oem') && {
                                        minLength: 6,
                                        maxLength: 15,
                                        placeholder: 'Enter 6-15 characters only',
                                    })}
                                />
                            )}
                        </td>
                    </tr>
                ))}
                <tr>
                    <td
                        style={{borderColor: requiredCharacteristic ? 'red' : 'inherit'}}
                        className="min-h-12 border-b border-gray-300 dark:border-[#494949] border-r">
                        <input
                            style={{borderColor: requiredCharacteristic ? 'red' : 'inherit'}}
                            type="text"
                            placeholder={t('sellerSpace.addProduct.characteristics.newCharacteristic')}
                            value={newCharacteristic}
                            onChange={(e) => {
                                setRequiredCharacteristic(false);
                                setNewCharacteristic(e.target.value);
                            }}
                            className="w-full h-12 min-h-full border-none focus:outline-none px-4 bg-white dark:bg-[#333333] text-black dark:text-[#F2F2F2] placeholder:text-gray-500 dark:placeholder:text-[#797979]"
                        />
                    </td>
                    <td
                        style={{borderColor: requiredValue ? 'red' : 'inherit'}}
                        className="min-h-12 border-b border-gray-300 dark:border-[#494949]">
                        <input
                            style={{borderColor: requiredValue ? 'red' : 'inherit'}}
                            type="text"
                            value={newValue}
                            placeholder={t('sellerSpace.addProduct.characteristics.newValue')}
                            className="w-full h-12 min-h-full border-none focus:outline-none px-4 bg-white dark:bg-[#333333] text-black dark:text-[#F2F2F2] placeholder:text-gray-500 dark:placeholder:text-[#797979]"
                            onChange={(e) => {
                                setRequiredValue(false);
                                setNewValue(e.target.value);
                            }}
                        />
                    </td>
                </tr>
                </tbody>
            </table>
            <button
                onClick={handleAddRow}
                className="flex items-center justify-center h-12 w-12 rounded-full border-border dark:border-[#494949] border absolute -bottom-6 left-1/2 transform -translate-x-1/2 bg-white dark:bg-[#333333]">
                <p className="text-lg font-medium leading-none text-black dark:text-[#F2F2F2]">+</p>
            </button>
        </div>
    );
};

export const AddProductPageContext = create((set) => ({
    promoPopUpVisible: false,
    vehicle_brand_ids: [],
    vehicle_model_ids: [],
    vehicle_motorisation_ids: [],
    productInfo: {
        name: "",
        description: "",
        payment_type: "cash",
        condition: "",
        price: 0,
        stock_quantity: 0,
        tiktok_link: "",
        category_id: null,
        sub_category_id: null,
        brand_id: null,
        vehicle_ids: [],
        photos: [],
        mainPhoto: null,
        characteristics: {},
        type: null,
        Etat: "Neuf",
        Color: null,
        Weight: null,
        Origine: "Original (marque)",
        OEM: null,
    },
    clearProductInfo: () => set(() => ({
        productInfo: {
            name: "",
            description: "",
            payment_type: "cash",
            condition: "",
            price: 0,
            stock_quantity: 0,
            tiktok_link: "",
            category_id: null,
            sub_category_id: null,
            brand_id: null,
            vehicle_ids: [],
            photos: [],
            mainPhoto: null,
            characteristics: {},
            type: null,
            Etat: "Neuf",
            Color: null,
            Weight: null,
            Origine: "Original (marque)",
            OEM: null,
        }
    })),
    updateProductInfo: (info) => set(() => ({productInfo: info})),
    setVehicle_brand_id: (ids) => set(() => ({vehicle_brand_ids: ids})),
    setVehicle_model_id: (ids) => set(() => ({vehicle_model_ids: ids})),
    setVehicle_motorisation_id: (ids) => set(() => ({vehicle_motorisation_ids: ids})),
    addNameValue: (name, value) => set((state) => ({productInfo: {...state.productInfo, [name]: value}})),
    addPhoto: (photo, isMain = false) => set((state) => ({
        productInfo: {
            ...state.productInfo,
            photos: isMain ? state.productInfo.photos : [...state.productInfo.photos, photo],
            mainPhoto: isMain ? photo : state.productInfo.mainPhoto,
        },
    })),
    setCharacteristics: (caracteristics) => set((state) => ({
        productInfo: {
            ...state.productInfo,
            characteristics: caracteristics,
        },
    })),
    removePhoto: (index) => set((state) => ({
        productInfo: {
            ...state.productInfo,
            photos: state.productInfo.photos.filter((_, i) => i !== index),
        },
    })),
    addCompatibleVehicles: (ids) => set((state) => ({productInfo: {...state.productInfo, vehicle_ids: ids}})),

    updatePromoPopUpVisible: (visible) => set(() => ({promoPopUpVisible: visible})),

}));
