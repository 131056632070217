import { create } from 'zustand';
import React from 'react';
import { Search } from 'lucide-react';
import {useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {handleLocalStorage} from "../../../../../utils/utils";

const SearchBar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {t} = useTranslation();
    const { searchQuery, search, setSearchQuery, saveSearch } = useSearchContext();

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            saveSearch(searchQuery);
        }
    };

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);
        if (value === '') {
            saveSearch('');
        }
    };

    return (
        <div className={`relative w-full ${isMobile ? 'max-w-full' : 'max-w-xs'}`}>
            <input
                type="text"
                placeholder={t('adminPages.dataTable.search')}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-[#494949] rounded-lg focus:outline-none focus:ring focus:border-[#E44826] dark:bg-[#333333] dark:text-[#F2F2F2]"
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyPress={handleKeyPress}
            />
            <Search className="absolute left-3 top-2 text-gray-400 dark:text-[#CBCBCB] w-5 h-5"/>
        </div>
    );
};
export default SearchBar;

export const useSearchContext = create((set) => ({
    searchQuery: '',
    search: handleLocalStorage('search', ''),
    setSearchQuery: (newSearchQuery) => {
        set({ searchQuery: newSearchQuery });
    },
    saveSearch: (searchQuery) => {
        set({ search: searchQuery });
        localStorage.setItem('search', JSON.stringify(searchQuery));
    }
}));