import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import React, {useState, useMemo, useEffect} from "react";
import TablePaginationNavigation from "../../components/TablePaginationNavigation/TablePaginationNavigation";
import {useNonMarketPlaceRoutesContext} from "../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import {api} from "../../services/instance";
import {formatDistanceToNow} from "date-fns";
import {ImageWithFallback} from "../../components/ImageWithFallBack/ImageWithFallBack";
import {useDeleteConfirmationContext} from "../../components/DeleteConfirmation/DeleteConfirmation";
import {toast} from "sonner";
import ToastSuccess from "../../components/Toasts/Success/ToastSuccess";
import ToastError, {toastStyle} from "../../components/Toasts/Error/ToastError";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";

export default function MyVehicules() {
    const {t} = useTranslation();
    const [notifications, setNotifications] = useState([]);

    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);

    const {updateLoading} = useNonMarketPlaceRoutesContext();

    const fetchNotifications = async () => {
        try {
            setLoading(true);
            updateLoading(false);
            const {data} = await api.get("/api/my-vehicles");
            setNotifications(
                Object.entries(data || {}).reduce((acc, [key, items]) => {
                    return [
                        ...acc,
                        ...items.map((item) => ({ ...item, originalType: key }))
                    ];
                }, [])
            );
        } catch (error) {
            console.error("Failed to fetch notifications:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);


    const {updateDeletePopUpVisible, updateDeleteFunc, updateItemInfo} = useDeleteConfirmationContext();
    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();

    const handleDelete = (id, name, type) => {
        const func = (id) => api.delete(`/api/posts/${type}/${id}`).then((response) => {
            if (response.status === 200 || response.status === 204) {
                toast.success(<ToastSuccess message={t('announcementsTable.deleteSuccess')}/>, toastStyle);
                fetchNotifications();
            } else {
                toast.error(<ToastError message={t('announcementsTable.deleteError')}/>, toastStyle);
            }
        }).catch((error) => {
            toast.error(<ToastError message={t('announcementsTable.deleteError')}/>, toastStyle);
        }).finally(() => {
            updateDeletePopUpVisible(false);
            updatePopUpVisible(false);
            updateItemInfo("", "", "");
        })

        updateDeleteFunc(() => func(id));
        updateDeletePopUpVisible(true);
        updatePopUpVisible(true);
        updateItemInfo(name, "Annonces", t('announcementsTable.deleteConfirmation'));
    }

    const data = useMemo(
        () => notifications.map((notification) => ({
            photo: notification.first_photo,
            id: notification.id,
            type: notification.type,
            originalType:notification.originalType,
            name: notification.name,
            price: notification.price,
            date: notification.created_at,
            status: notification.status,
        })),
        [notifications]
    );

    const columns = useMemo(
        () => [{
            accessorKey: "name",
            header: t('announcementsTable.name'),
            cell: ({row}) => {
                return (
                    <div className="flex items-center">
                        <ImageWithFallback src={row.original.photo?.link} alt={row.original.name}
                                           className="h-10 w-10 sm:w-12 sm:h-12 rounded mr-4 object-contain"/>
                        <div className="flex-1 min-w-0 max-w-[140px] sm:max-w-[200px]">
                            <div className="font-medium truncate dark:text-[#F2F2F2]">{row.original?.name}</div>
                        </div>
                    </div>
                );
            }
        },
            {
                accessorKey: "price",
                header: t('announcementsTable.price'),
                cell: ({row}) =>
                    <p className="dark:text-[#F2F2F2]">{row.original.price} {t('announcementsTable.million')}</p>
            },
            {
                accessorKey: "status",
                header: t('announcementsTable.status'),
                cell: ({row}) => <VerificationStatus verification_status={row.original.status}/>
            },
            {
                accessorKey: "date",
                header: t('announcementsTable.date'),
                cell: ({row}) => {
                    return (
                        <p className="dark:text-[#F2F2F2]">{formatDistanceToNow(new Date(row.original.date))} {t('announcementsTable.ago')}</p>
                    )
                }
            },
            {
                header: t('announcementsTable.action'),
                cell: ({row}) => {
                    return (
                        <div className="cta flex flex-row gap-2">
                            <div
                                onClick={() => navigate("/ajouter-vehicule", {
                                    state: {
                                        id: row.original.id,
                                        type: row.original.type
                                    }
                                })}
                                className="cta min-h-10 min-w-10 max-w-10 bg-white dark:bg-[#333333] cursor-pointer border border-border rounded-lg flex items-center justify-center">
                                <img src="/resources/pen.svg" alt="pen" className="cta dark_gray_filter"/>
                            </div>
                            <div
                                onClick={() => handleDelete(row.original.id, row.original.name, row.original.originalType)}
                                className="cta min-h-10 min-w-10 max-w-10 bg-white dark:bg-[#333333] cursor-pointer border border-border rounded-lg flex items-center justify-center">
                                <img src="/resources/delete.svg" alt="delete" className="cta"/>
                            </div>
                        </div>
                    )
                }
            }
        ],
        [t]
    );

    const table = useReactTable({
        data,
        columns,
        state: {
            globalFilter,
            sorting,
        },
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        initialState: {
            pagination: {
                pageSize: 10
            }
        }
    });

    const navigate = useNavigate();

    if (loading) {
        return (
            <div
                className="flex items-center justify-center w-full h-full min-h-[90vh] bg-white dark:bg-[#333333] border border-border rounded-lg">
                <div className="w-5">
                    <div className="loader"/>
                </div>
            </div>
        );
    }

    if (notifications.length === 0) {
        return (
            <div
                className="flex flex-col items-center justify-center w-full h-full min-h-[90vh] bg-white dark:bg-[#333333] border border-border rounded-lg">
                <img src="/images/undraw_no_data.svg" alt="No data" className="w-[250px] mb-8"/>
                <p className="text-lg text-gray-500 dark:text-[#CBCBCB]">{t('announcementsTable.noDataFound')}</p>
            </div>
        );
    }

    const boatTypes = [
        t('announcementsTable.boatTypes.jetSki'),
        t('announcementsTable.boatTypes.yacht'),
        t('announcementsTable.boatTypes.sailboat'),
        t('announcementsTable.boatTypes.catamaran'),
        t('announcementsTable.boatTypes.largeFishingBoat'),
        t('announcementsTable.boatTypes.trawler'),
        t('announcementsTable.boatTypes.doryFlouka')
    ]

    const machineryTypes = [
        "Bulldozer",
        "Pelleteuse (Excavatrice)",
        "Grue",
        "Chargeuse",
        "Niveleuse",
        "Compacteur (Rouleau)",
        "Tractopelle",
        "Bétonnière",
        "Camion-benne",
        "Foreuse",
        "Chariot élévateur",
        "Trancheuse",
        "Malaxeur",
        "Ripper",
        "Décapeuse (Scraper)"
    ];


    return (
        <div
            className="flex flex-col min-w-full min-h-[90vh] bg-white dark:bg-[#333333] dark:border-0 p-4 rounded-lg border border-border overflow-hidden items-center">
            {/* Search Header */}

            <div className="flex flex-row gap-3 items-center w-full">
                <div
                    onClick={() => navigate(-1)}
                    className="h-10 w-10 flex items-center justify-center cursor-pointer">
                    <img src="/resources/arrow_right.svg" alt="right" className="transform rotate-180 dark:invert"/>
                </div>
                <p className='font-medium dark:text-[#F2F2F2]'>{t('announcementsTable.notificationsList')}</p>
            </div>
            <div className="w-full flex items-center justify-end px-4 border-b border-border min-h-16">
                <div
                    className="w-[30vw] sm:w-[15vw] h-10 bg-[#FCFCFC] dark:bg-[#232323] border-[#D4D4D4] border rounded-lg px-4 flex items-center relative">
                    <input
                        value={globalFilter}
                        onChange={e => setGlobalFilter(e.target.value)}
                        placeholder={t('announcementsTable.searchPlaceholder')}
                        className="w-[25vw] sm:w-[10vw] h-9 bg-[#FCFCFC] dark:bg-[#232323] dark:text-[#F2F2F2] border-none focus:outline-none"
                    />
                    <img src="/resources/search.svg" alt="search"
                         className="absolute top-1/2 right-2 transform -translate-y-1/2 dark:invert"/>
                </div>
            </div>

            {/* Table */}
            <div className="w-full flex-grow overflow-auto">
                <table className="w-full border-collapse">
                    <thead className="bg-gray-100 dark:bg-[#232323] sticky top-0 z-10">
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th
                                    key={header.id}
                                    onClick={header.column.getToggleSortingHandler()}
                                    className="px-6 py-3 text-left text-sm font-medium text-[#979797] dark:text-[#797979] border-b border-gray-200 cursor-pointer"
                                >
                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                    {header.column.getIsSorted() && (
                                        <span>{header.column.getIsSorted() === "asc" ? " ↑" : " ↓"}</span>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map(row => (
                        <tr
                            onClick={(event) => {
                                if (event.target.classList.contains('cta')) return;
                                if (boatTypes.includes(row.original.type)) {
                                    navigate(`/boat/${row.original.id}`);
                                } else if (machineryTypes.includes(row.original.type)) {
                                    navigate(`/engin/${row.original.id}`)
                                } else {
                                    navigate(`/vehicle/${row.original.id}`);
                                }
                            }}
                            key={row.id} className="hover:bg-gray-50 dark:hover:bg-[#232323]">
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id} className="px-6 py-4 text-sm font-medium border-b border-gray-200">
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <TablePaginationNavigation table={table}/>
        </div>
    );
}

const VerificationStatus = ({verification_status}) => {
    const {t} = useTranslation();

    const statusStyles = {
        pending: {
            container: 'bg-orange-100 dark:bg-[#494949] border-red-500',
            text: 'text-orange-500 dark:text-[#F9CDC4]'
        },
        rejected: {
            container: 'bg-red-100 dark:bg-[#494949] border-red-500',
            text: 'text-red-500 dark:text-[#E44826]'
        },
        verified: {
            container: 'bg-yellow-100 dark:bg-[#494949] border-yellow-500',
            text: 'text-yellow-500 dark:text-[#F9CDC4]'
        },
    };

    const getStatusConfig = () => {
        if (verification_status === "pending") {
            return {
                styles: statusStyles.pending,
                text: t('sellerSpace.productTable.status.pending')
            };
        }
        if (verification_status === "rejected") {
            return {
                styles: statusStyles.rejected,
                text: t('sellerSpace.productTable.status.rejected')
            };
        }
        if (verification_status === "verified") {
            return {
                styles: statusStyles.verified,
                text: t('sellerSpace.productTable.status.verified')
            };
        }
        return {
            styles: statusStyles.incomplete,
            text: t('sellerSpace.productTable.status.incomplete')
        };
    };

    const {styles, text} = getStatusConfig();

    return (
        <div className={`w-fit px-4 py-2 rounded-lg flex items-center justify-center border ${styles.container}`}>
            <p
                className={`text-sm font-medium truncate ${styles.text}`}>{text}</p>
        </div>
    )
}