import { useEffect, useState } from "react";
import Select from "react-select";
import {useNavigate} from "react-router";
import {useMarketplaceContext} from "../../../pages/Marketplace";
import {useNonMarketPlaceRoutesContext} from "../../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import {useTranslation} from "react-i18next";

export const categories = [
    { value: "products", label: "products" },
    { value: "cars", label: "vehicles" },
    { value: "boats", label: "boats" },
    { value: "engins", label: "engines" },
];

export const customStyles = {
    control: (base, state) => ({
        ...base,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: "var(--background, #FFFFFF)", // Ensure background is white (or your desired color)
        borderRadius: "8px 0px 0px 8px",
        borderColor: state.isFocused ? "#E44826" : "var(--border)",
        cursor: "pointer",
        height: "5.5vh",
        padding: "0 8px",
        "&:hover": {
            borderColor: "#E44826",
        },
    }),
    placeholder: (base) => ({
        ...base,
        color: "var(--text-secondary, #595959)",
        fontWeight: 500,
        maxWidth: '100%',
    }),
    input: (base) => ({
        ...base,
        color: "var(--text-primary, #232323)",
        fontWeight: 500,
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    }),
    singleValue: (base) => ({
        ...base,
        color: "var(--text-primary, #232323)",
        fontWeight: 500,
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    }),
    menu: (base) => ({
        ...base,
        borderRadius: "4px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        marginTop: "4px",
        padding: "0px",
        borderColor: "var(--border)",
        width: "100%",
        backgroundColor: "var(--background) !important", // Force non-transparent background
        color: "var(--text-primary, #232323)", // Ensure text is legible
        border: "1px solid var(--border)", // Optional: add a border to match the control
        zIndex: 9999,
    }),
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isFocused ? "var(--background, #F9CDC4)" : "var(--background, #FFFFFF)",
        color: "var(--text-primary, #232323)",
        padding: "10px 15px",
        cursor: "pointer",
        fontWeight: 500,
        "&:hover": {
            backgroundColor: "var(--bg-hover, #F9CDC4)",
        },
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        color: state.isFocused ? "#E44826" : "var(--text-secondary, #595959)",
        padding: "0px 0px 0px 4px",
        "&:hover": {
            color: "#E44826",
        },
    }),
};

export default function GlobalSearch() {
    const { t } = useTranslation();

    const translatedCategories = categories.map(cat => ({
        ...cat,
        label: t(cat.label)
    }));

    const {updateSubCategoryId,updateCategoryId} = useMarketplaceContext();

    const [selectedCategory, setSelectedCategory] = useState(translatedCategories[0].value);
    const [term, setTerm] = useState("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const onResize = () =>{
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, []);

    const navigate = useNavigate();
    const handleSearch = () => {
        if (selectedCategory && term.trim()) {
            updateCategoryId(null)
            updateSubCategoryId(null)
            navigate("/search", { state: { category: selectedCategory, term:term } });
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };

    const {updatePopupVisible,updateShowSearchBar} = useMarketplaceContext();
    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();

    return windowWidth > 919 ? (
        <div className="w-fit h-[5.5vh] bg-[#ECECEC] dark:bg-[#333333] border-[#E5E7EB] dark:border-[#494949] border flex flex-row items-center rounded-lg justify-between">
            <div className="flex flex-row items-center gap-2">
                <Select
                    options={translatedCategories}
                    defaultValue={translatedCategories[0]}
                    styles={customStyles}
                    placeholder={t('product')}
                    onChange={(selectedOption) => setSelectedCategory(selectedOption.value)}
                />
                <input
                    className="h-9 bg-[#ECECEC] dark:bg-[#333333] dark:text-[#F2F2F2] border-none focus:outline-none"
                    placeholder={t('search')}
                    value={term}
                    onChange={(e) => setTerm(e.target.value)}
                    onKeyDown={handleKeyPress}
                />
            </div>
            <button
                onClick={handleSearch}
                className="cursor-pointer py-4 px-4 "
            >
                <img
                    src="/resources/search.svg"
                    alt="search icon"
                    className="dark:invert"
                />
            </button>
        </div>
    ) : (
        <button
            className="w-[48px] h-[48px] sm:w-[54px] sm:h-[54px] flex items-center justify-center cursor-pointer hover:bg-[#f1f1f1] dark:hover:bg-[#494949] rounded-full border-border border dark:border-[#595959]"
            onClick={()=>{
                updatePopupVisible(true)
                updateShowSearchBar(true)
                updatePopUpVisible(true)
            }}
        >
            <img src="/resources/search.svg" alt="search icon" className="dark:invert" />
        </button>
    );
}