import React from 'react';
import {FiChevronDown, FiChevronUp, FiMoreVertical} from 'react-icons/fi';
import {IconButton, Tooltip, MenuItem, Button, Menu,  useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';

const  TableBody = ({rows, columns, openRow, toggleRowDetails, handleOpenMenu, menuAnchor, actions, actionsDisabled, handleCloseMenu, onRowClick, showActions, columnVisibility,
                        selectedRows, handleRowSelect, showApproveRejectButtons, showDeleteButton}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {t} = useTranslation();
    return (
        <tbody>
        {rows.length > 0 ? (
            rows.map((row) => (
                <React.Fragment key={row.id}>
                    <tr
                        className={`border-b dark:border-[#494949] hover:bg-gray-100 dark:hover:bg-[#232323] transition duration-150 ease-in-out ${isMobile ? 'text-sm' : ''}`}
                        onClick={(e) => {
                            if (!e.target.closest('input[type="checkbox"]') && !e.target.closest('button') && onRowClick) {
                                onRowClick(row);
                            }
                        }}
                    >
                        {isMobile ? (
                            // Show first and last columns on mobile
                            <>
                                {columns.slice(0, 1).map((col) =>
                                    columnVisibility[col.field] ? (
                                        <td key={col.field} className="py-4 px-6 text-left text-sm dark:text-[#F2F2F2]">
                                            {col.renderCell ? col.renderCell({row}) : row[col.field]}
                                        </td>
                                    ) : null
                                )}
                                {columns.slice(-1).map((col) =>
                                    columnVisibility[col.field] ? (
                                        <td key={col.field} className="py-4 px-6 text-left text-sm dark:text-[#F2F2F2]">
                                            {col.renderCell ? col.renderCell({row}) : row[col.field]}
                                        </td>
                                    ) : null
                                )}
                                <td className="py-4 px-6 text-right">
                                    <Tooltip
                                        title={openRow === row.id ? t('adminPages.dataTable.collapse') : t('adminPages.dataTable.expand')}>
                                        <IconButton
                                            aria-expanded={openRow === row.id}
                                            aria-label={openRow === row.id ? t('adminPages.dataTable.collapse') : t('adminPages.dataTable.expand')}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggleRowDetails(row.id);
                                            }}
                                            size="small"
                                            className="dark:text-[#F2F2F2]"
                                        >
                                            {openRow === row.id ? <FiChevronUp/> : <FiChevronDown/>}
                                        </IconButton>
                                    </Tooltip>
                                </td>
                            </>
                        ) : (
                            <>
                                {(showApproveRejectButtons || showDeleteButton) && (
                                    <td className="py-4 px-6" onClick={e => e.stopPropagation()}>
                                        <input
                                            type="checkbox"
                                            className="h-5 w-5 border- rounded-lg shadow-sm focus:ring-[#E44826] border-gray-300 dark:border-[#494949] accent-[#E44826] text-white dark:bg-[#333333]"
                                            checked={selectedRows.includes(row.id)}
                                            onChange={() => handleRowSelect(row.id)}
                                        />
                                    </td>
                                )}
                                {columns.map((col) =>
                                    columnVisibility[col.field] ? (
                                        <td key={col.field} className="py-4 px-6 text-left dark:text-[#F2F2F2]">
                                            {col.renderCell ? col.renderCell({row}) : row[col.field]}
                                        </td>
                                    ) : null
                                )}
                            </>
                        )}
                        {showActions && !isMobile && (
                            <td className="py-4 px-6 text-right">
                                {!actionsDisabled && actions.length > 0 && (
                                    <Tooltip title={t('adminPages.dataTable.moreActions')}>
                                        <IconButton
                                            aria-label={t('adminPages.dataTable.moreActions')}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleOpenMenu(e, row.id);
                                            }}
                                            size="small"
                                            className="dark:text-[#F2F2F2]"
                                        >
                                            <FiMoreVertical/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <Menu
                                    anchorEl={menuAnchor?.element}
                                    open={menuAnchor?.id === row.id}
                                    onClose={handleCloseMenu}
                                    className="dark:bg-[#333333]"
                                >
                                    {actions.map((action) => (
                                        <MenuItem
                                            key={action.label}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                action.onClick(row);
                                                handleCloseMenu();
                                            }}
                                            className="dark:text-[#F2F2F2] dark:hover:bg-[#232323]"
                                        >
                                            {isMobile ? (
                                                <IconButton aria-label={action.label} size="small" className="dark:text-[#F2F2F2]">
                                                    {action.icon}
                                                </IconButton>
                                            ) : (
                                                <>
                                                    <IconButton aria-label={action.label} size="medium" className="dark:text-[#F2F2F2]">
                                                        {action.icon}
                                                    </IconButton>
                                                    {action.label}
                                                </>
                                            )}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </td>
                        )}
                    </tr>
                    {isMobile && openRow === row.id && (
                        <tr className="bg-gray-50 dark:bg-[#333333]">
                            <td colSpan={4} className="py-4 px-6">
                                <div className="space-y-2">
                                    {columns.slice(1, -1).map((col) =>
                                        columnVisibility[col.field] ? (
                                            <div key={col.field} className="text-sm dark:text-[#F2F2F2]">
                                                <strong>{col.headerName}:</strong> {row[col.field]}
                                            </div>
                                        ) : null
                                    )}
                                    {showActions && !actionsDisabled && actions.length > 0 && (
                                        <div className="flex gap-2 mt-4">
                                            {actions.map((action) => (
                                                <Button
                                                    key={action.label}
                                                    startIcon={action.icon}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        action.onClick(row);
                                                    }}
                                                    size="small"
                                                    variant="outlined"
                                                    className="dark:text-[#F2F2F2] dark:border-[#494949]"
                                                >
                                                    {action.label}
                                                </Button>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    )}
                </React.Fragment>
            ))
        ) : (
            <tr>
                <td colSpan={columns.length + 2} className="py-4 px-6 text-center text-gray-500 dark:text-[#CBCBCB]">
                    {t('adminPages.dataTable.noData')}
                </td>
            </tr>
        )}
        </tbody>
    );
};
export default TableBody;