import React, { useState } from 'react';
import { Button } from '@mui/material';

const LegalDocumentsSection = ({ documentState }) => {
    const [documents, updateDocuments] = documentState;
    const [identityCardPreview, setIdentityCardPreview] = useState('');

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const objectUrl = URL.createObjectURL(file);
            setIdentityCardPreview(objectUrl);
            updateDocuments({ ...documents, identity_card: file });
        }
    };

    return (
        <div className="bg-white dark:bg-[#333333] p-6 border border-gray-200 dark:border-[#494949] flex flex-col">
            <h3 className="text-2xl font-bold mb-6 dark:text-[#F2F2F2]">Documents légaux</h3>
            <div className="space-y-4">
                <InputField
                    label="NIF"
                    value={documents?.niff || ''}
                    onChange={(value) => updateDocuments({...documents, niff: value })}
                />
                <InputField
                    label="Registre de commerce"
                    value={documents?.register_number || ''}
                    onChange={(value) => updateDocuments({...documents, register_number: value })}
                />
                <div className="flex justify-between items-center">
                    <label className="block text-gray-600 dark:text-[#CBCBCB] font-medium mb-1">Photo de carte d'identité</label>
                    <Button
                        component="label"
                        sx={{
                            backgroundColor: 'white',
                            '&.MuiButton-root': {
                                backgroundColor: '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: '#F2F2F2'
                                },
                                '&.dark': {
                                    backgroundColor: '#333333',
                                    '&:hover': {
                                        backgroundColor: '#494949'
                                    }
                                }
                            },
                            border: '1px solid gray',
                            color: 'gray'
                        }}
                        className="dark:bg-[#333333] dark:border-[#494949] dark:text-[#CBCBCB]"
                    >
                        <img src="/resources/import_icon.svg" alt="Import Icon" className="w-4 h-4" />
                        <span className="truncate dark:text-[#CBCBCB]">Importer depuis le local</span>
                        <input
                            type="file"
                            hidden
                            onChange={handleFileChange}
                        />
                    </Button>
                </div>

                {identityCardPreview && (
                    <div className="mt-6 w-full h-80 border border-gray-200 dark:border-[#494949] rounded-lg overflow-hidden">
                        <img
                            src={identityCardPreview}
                            alt="Carte d'identité Preview"
                            className="w-full h-full object-cover"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const InputField = ({ label, value, onChange }) => (
    <div className="flex justify-between items-center">
        <label className="block text-gray-600 dark:text-[#CBCBCB] font-medium mb-1">{label}</label>
        <input
            type="text"
            placeholder={label}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className="border p-2 rounded-lg shadow-sm focus:outline-none focus:border-orange-500 dark:border-[#494949] dark:bg-[#333333] dark:text-[#F2F2F2] dark:focus:border-[#E44826] transition duration-200"
        />
    </div>
);

export default LegalDocumentsSection;