import {formatCurrency, getPhoto} from "../../../../utils/utils";
import {useEffect, useState} from "react";
import AddToCart from "../../../../components/AddToCart/AddToCart";
import {useMarketplaceContext} from "../../index";
import {ImageWithFallback} from "../../../../components/ImageWithFallBack/ImageWithFallBack";
import {usePackDetailsFlyOutMenuContext} from "../../../../components/PackDetailsFlyOutMenu/PackDetailsFlyOutMenu";
import {useTranslation} from "react-i18next";
export default function PackCard({id, offer_name, products = null, discount_percentage, offer_quantity}) {
    const [totalPrice, setTotalPrice] = useState(0);
    const {t} = useTranslation();
    const {updatePopupVisible} = useMarketplaceContext();
    const {updatePackDetailsFlyOutMenuVisible,updateSelectedOffer} = usePackDetailsFlyOutMenuContext();

    useEffect(() => {
        let total = 0;
        products?.forEach((product) => {
            total += product.price * product.pivot.quantity;
        });
        setTotalPrice(total);
    }, [products]);


    return (
        <div className="w-fit h-full border border-border dark:border-[#494949] rounded-xl flex flex-col overflow-hidden">
            <div
                className="flex items-center justify-center border-b border-dashed border-b-border dark:border-b-[#494949] py-2 min-h-12 relative">
                <p className="font-medium text-[#797979]">{offer_name}</p>
                <div
                    className='flex items-center justify-center bg-primaryExtraLight px-4 h-full rounded-lg rotate-45 w-[150px] absolute -right-12 top-3'>
                    <p className="font-bold text-xl text-primaryLight">{discount_percentage} %</p>
                </div>
            </div>
            <div
                className='flex flex-col px-2 sm:px-4 py-2 gap-2 items-start w-full h-full border-dashed border-b border-b-border dark:border-b-[#494949]'>
                {(products != null && products?.length > 0) &&
                    products.slice(0, 2).map((item, index) => (
                        <PackItem key={index} {...item}/>
                    ))
                }
                <div className="flex items-center justify-center w-full">
                    {products?.length > 2 ?
                        <div
                            onClick={() => {
                                updateSelectedOffer({
                                    id: id,
                                    offer_name: offer_name,
                                    discount_percentage: discount_percentage,
                                    products: products
                                })
                                updatePopupVisible(true)
                                updatePackDetailsFlyOutMenuVisible(true)
                            }}
                            className="cursor-pointer w-fit h-fit px-4 border-primaryLight rounded border flex items-center justify-center">
                            <p className="font-medium text-primaryLight">{t('marketplace.sections.newItems.seeMore')}</p>
                        </div>
                        :
                        <div className="w-fit h-[24px]"></div>
                    }
                </div>
            </div>
            <div className="flex flex-row items-center justify-end h-16 gap-2 px-4 py-2">
                <div className="flex flex-row items-center gap-2 px-4 py-2">
                    <span className="font-medium text-gray-500 line-through">{formatCurrency(totalPrice)}</span>
                    <span
                        className="text-2xl font-bold">{formatCurrency(totalPrice - (discount_percentage / 100) * totalPrice)}</span>
                </div>
                <AddToCart ids={products?.map(product => product.id)} offer={{
                    id: id,
                    offer_name: offer_name,
                    discount_percentage: discount_percentage,
                    products: products,
                    offer_quantity: offer_quantity
                }}/>
            </div>
        </div>
    )
}

const PackItem = ({name, first_photo, pivot, price}) => {
    return (
        <div className="flex flex-row items-center gap-2 w-full">
            <div className="flex flex-row items-end justify-center gap-1 min-w-[40px] max-w-[40px]">
                <p className="text-2xl font-medium text-primaryLight leading-none">{pivot.quantity}</p>
                <p className="font-semibold leading-none">X</p>
            </div>
            <div
                className="h-24 flex flex-row items-center justify-between p-2 gap-2 sm:gap-6 border border-border dark:border-[#494949] rounded-lg w-full">
                <div className="flex flex-row gap-4 items-center">
                    {
                        <ImageWithFallback src={getPhoto(first_photo)} alt="product"
                                           className="w-16 h-16 object-contain rounded"/>
                    }
                    <p className="font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-[150px]">{name}</p>
                </div>
                <p className="text-[#FFD3CA] text-2xl font-semibold text-ellipsis whitespace-nowrap">{formatCurrency(price)}</p>
            </div>
        </div>
    )
}