import React, {useState, useEffect} from 'react';
import {usePersonalInfoContext} from "../../Pages/SignUp";
import {registerUser} from "../../../../services/AuthAndSignUp/API";
import Cookies from 'js-cookie';
import {toast} from "sonner";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import {toastStyle} from "../../../../components/Toasts/Warn/ToastWarn";
import ToastError from "../../../../components/Toasts/Error/ToastError";
import { useTranslation } from 'react-i18next';
import { Eye, EyeOff, ArrowRight, Check } from 'lucide-react';

const PasswordForm = ({ handlePrevious, handleNext, setIsSucessfull }) => {
    const { t } = useTranslation();
    const { personalInfo, addFieldValue, addInfo } = usePersonalInfoContext();
    const [formData, setFormData] = useState({
        password: personalInfo.password || "",
        confirmPassword: personalInfo.confirmPassword || "",
    });
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const validateForm = () => {
        const newErrors = {};

        // Password strength validation
        if (!formData.password) {
            newErrors.password = t('passwordForm.errorMessages.passwordRequired');
        } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\/\\])[A-Za-z\d@$!%*?&\/\\]{8,}$/.test(formData.password)) {
            newErrors.password = t('passwordForm.errorMessages.passwordComplexity');
        }

        // Confirm password match validation
        if (!formData.confirmPassword) {
            newErrors.confirmPassword = t('passwordForm.errorMessages.confirmPasswordRequired');
        } else if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = t('passwordForm.errorMessages.passwordMismatch');
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        addFieldValue(name, value);

        // Clear errors for the field being edited
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        setLoading(true);

        try {
            const data = new FormData();
            Object.entries(personalInfo).forEach(([key, value]) => {
                if (key === "profile_picture" && (value instanceof File || value instanceof Blob)) {
                    data.append("profile_photo_file", value);
                } else {
                    data.append(key, value);
                }
            });

            const response = await registerUser(data);
            if (response.status === 201) {
                Cookies.remove("user_temp");
                setLoading(false);
                toast.success(<ToastSuccess message={t('passwordForm.toast.success')} />, toastStyle);
                setIsSucessfull(true);
            } else {
                handleApiErrors(response);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleApiErrors = (response) => {
        if (response?.messages) {
            Object.values(response.messages).forEach((errors) =>
                errors.forEach((message) => toast.error(<ToastError message={message} />, toastStyle))
            );
        } else if (response?.error) {
            toast.error(<ToastError message={response.error} />, toastStyle);
        }
    };

    return (
        <div className="w-full h-full flex flex-col items-center justify-center p-6 space-y-4 xl:space-y-6 relative">
            <h2 className="lg:text-xl xl:text-2xl 2xl:text-3xl font-bold text-gray-800 dark:text-[#F2F2F2] text-center">
                {t('passwordForm.title')}
            </h2>
            <p className="text-xs xl:text-sm text-gray-600 dark:text-[#CBCBCB] text-center max-w-sm xl:max-w-lg">
                {t('passwordForm.description')}
            </p>
            <div className="grid grid-cols-1 gap-6 w-[60vw] sm:w-[30vw] lg:w-[20vw] self-center">
                {["password", "confirmPassword"].map((field, idx) => (
                    <div key={idx} className="relative">
                        <label
                            htmlFor={field}
                            className="block text-xs xl:text-sm font-medium mb-2 text-gray-500 dark:text-[#CBCBCB]"
                        >
                            {field === "password" ? t('passwordForm.labels.password') : t('passwordForm.labels.confirmPassword')}
                        </label>
                        <input
                            type={showPassword ? "text" : "password"}
                            id={field}
                            name={field}
                            placeholder={field === "password" ? t('passwordForm.placeholders.password') : t('passwordForm.placeholders.confirmPassword')}
                            className={`w-full h-10 placeholder:xl:text-sm placeholder:text-xs xl:h-12 px-4 pr-12 border rounded-lg shadow-sm focus:outline-none focus:ring-2 
                                ${errors[field] ? "border-red-500 focus:ring-red-500" : "focus:ring-[#E44826] focus:border-[#E44826]"}
                                dark:bg-[#494949] dark:text-[#F2F2F2] dark:border-[#595959] dark:placeholder-[#797979]
                                border-gray-300`}
                            value={formData[field]}
                            onChange={handleChange}
                            required
                        />
                        {errors[field] && <p className="text-red-500 text-sm mt-1">{errors[field]}</p>}
                        <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute right-3 top-9 xl:top-[42px] text-gray-600 dark:text-[#CBCBCB] hover:text-gray-900 dark:hover:text-[#F2F2F2]"
                        >
                            {showPassword ? (
                                <EyeOff className="w-5 h-5" />
                            ) : (
                                <Eye className="w-5 h-5" />
                            )}
                        </button>
                    </div>
                ))}
            </div>

            <div className="flex justify-between absolute left-6 bottom-6 w-[calc(100%-48px)]">
                <button
                    onClick={handlePrevious}
                    className="px-4 xl:px-6 py-2 xl:py-2.5 rounded-lg border border-slate-300 dark:border-[#595959] text-slate-600 dark:text-[#CBCBCB] hover:bg-slate-100 dark:hover:bg-[#333333] transition-colors"
                >
                    <p className="text-sm xl:text-normal">{t('passwordForm.buttons.previous')}</p>
                </button>

                {personalInfo.role_id === 4 ? (
                    <button
                        disabled={loading}
                        onClick={handleSubmit}
                        className={`w-fit px-4 h-10 xl:h-12 disabled:bg-gray-300 dark:disabled:bg-[#333333] flex justify-center items-center flex-row bg-[#E44826] text-white py-2 xl:py-3 rounded-lg font-medium hover:bg-[#F9CDC4] dark:hover:bg-[#F9CDC4] transition duration-300 gap-4 ${
                            !formData.password || !formData.confirmPassword
                                ? "opacity-50 cursor-not-allowed"
                                : ""
                        } transition-colors`}
                    >
                        {loading ? (
                            <div className="w-5">
                                <div className="loader_white"></div>
                            </div>
                        ) : (
                            <Check className="w-6 h-6 text-white" />
                        )}
                        <p className="text-sm xl:text-normal">{t('passwordForm.buttons.submit')}</p>
                    </button>
                ) : (
                    <button
                        onClick={() => {
                            if (validateForm()) {
                                addInfo(formData);
                                handleNext();
                            }
                        }}
                        className={`px-4 xl:px-6 h-10 xl:h-12 flex justify-center items-center bg-[#E44826] text-white py-2 xl:py-3 rounded-lg font-medium hover:bg-primaryLight dark:hover:bg-primaryLight transition duration-300 gap-4 ${
                            !formData.password || !formData.confirmPassword
                                ? "opacity-50 cursor-not-allowed"
                                : ""
                        } transition-colors`}
                    >
                        <p className="text-sm xl:text-normal">{t('passwordForm.buttons.next')}</p>
                        <ArrowRight className="w-4 h-4 text-white" />
                    </button>
                )}
            </div>
        </div>
    );
};

export default PasswordForm;
