import React, { useState } from 'react';
import CheckBox from '../../../../../components/Checkbox';

const MultiOptionFilter = ({ title, options, selectedOptions, onChange }) => {
    const [showAll, setShowAll] = useState(false);

    const handleOptionChange = (option, isSelected) => {
        if (isSelected) {
            onChange([...selectedOptions, option]);
        } else {
            onChange(selectedOptions.filter((item) => item !== option));
        }
    };

    return (
        <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row h-9 items-center justify-between">
                <p className="font-medium dark:text-[#F2F2F2]">{title}</p>
            </div>
            <div className="flex flex-col gap-2">
                {options.slice(0, showAll ? options.length : 5).map((option, index) => (
                    <div key={index} className="flex flex-row items-center gap-2">
                        <CheckBox
                            checkedValueState={[
                                selectedOptions.includes(option),
                                (value) => handleOptionChange(option, value),
                            ]}
                        />
                        <p className="font-medium dark:text-[#F2F2F2]">{option}</p>
                    </div>
                ))}
                {options.length > 5 && (
                    <button onClick={() => setShowAll(!showAll)}>
                        <p className="text-blue-500 dark:text-[#F9CDC4] font-medium text-sm">
                            {showAll ? 'Voir Moins' : 'Voir Plus'}
                        </p>
                    </button>
                )}
            </div>
        </div>
    );
};

export default MultiOptionFilter;