import React from 'react';

export default function ChartCard({ title, value, children }) {
    return (
        <div className="bg-white dark:bg-[#333333]  shadow-lg rounded-lg p-2">
            <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-[#CBCBCB]">{title}</h2>
            <div className="text-2xl font-semibold mb-2 text-gray-900 dark:text-[#F2F2F2]">{value}</div>
            {children}
        </div>
    );
}