import React, { useEffect, useState } from "react";
import CustomTabs from "../Tabs/Tabs";
import {
    Avatar,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    DialogContentText, Modal,
} from "@mui/material";
import DataTable from "../dataTable/DataTable";
import ToastError, { toastStyle } from "../../../../components/Toasts/Error/ToastError";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import {toast} from "sonner";
import {
    changeBoatStatus,
    getCars,
    updateCarStatus,
    UpdateEnginStatus
} from "../../../../services/AdminSapce/API";
import {useNavigate} from "react-router";
import GenericDropDown from "../../../../components/GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../../../../components/GenericDropDown/GenericDropDownItem";
import { useTranslation } from "react-i18next";

export default function VehicleSubmissions() {
    const { t } = useTranslation();

    const secondaryMenuItems = [
        { id: 'cars', label: t('adminPages.validationVehicles.menu.cars'), icon: '/resources/car.svg' },
        { id: 'motorcycles', label: t('adminPages.validationVehicles.menu.motorcycles'), icon: '/resources/motorcycle.svg' },
        { id: 'boats', label: t('adminPages.validationVehicles.menu.boats'), icon: '/resources/boat.svg' },
        { id: 'engines', label: t('adminPages.validationVehicles.menu.engines'), icon: '/resources/engine.svg' }
    ];

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentTab, setCurrentTab] = useState('cars');
    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [justification, setJustification] = useState('');
    const [allData, setAllData] = useState({
        cars: [],
        motorcycles: [],
        boats: [],
        engines: []
    });
    const [selectedAll, setSelectedAll] = useState([]);
    const navigate = useNavigate();

    const carColumns = [
        {
            field: 'Vehicle',
            headerName: t('adminPages.validationVehicles.columns.vehicle'),
            width: 300,
            renderCell: (params) => (
                <a onClick={()=> navigate(`/vehicle/${params.row.id}`)} className="dark:text-[#F2F2F2] hover:dark:text-[#F9CDC4]">
                    <Box display="flex" alignItems="center">
                        <Avatar alt={params.row.Vehicle} src={params.row.avatarUrl} style={{ marginRight: 8 }} />
                        {params.row.Vehicle}
                    </Box>
                </a>
            ),
        },
        { field: 'Year', headerName: t('adminPages.validationVehicles.columns.year'), width: 100 },
        { field: 'Mileage', headerName: t('adminPages.validationVehicles.columns.mileage'), width: 150 },
        { field: 'Energy', headerName: t('adminPages.validationVehicles.columns.energy'), width: 150 },
        { field: 'submissionDate', headerName: t('adminPages.validationVehicles.columns.submissionDate'), width: 200 },
        {
            field: 'actions',
            headerName: '',
            width: 150,
            renderCell: (params) => (
                <Box display="flex">
                    <GenericDropDown>
                        <GenericDropDownItem value={t('adminPages.validationVehicles.actions.reject')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleReject(params.row)}}/>
                        <GenericDropDownItem value={t('adminPages.validationVehicles.actions.approve')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleApprove(params.row)}}
                        />
                    </GenericDropDown>
                </Box>
            ),
        },
    ];

    const motorcycleColumns = [
        {
            field: 'Vehicle',
            headerName: t('adminPages.validationVehicles.columns.vehicle'),
            width: 300,
            renderCell: (params) => (
                <a onClick={()=> navigate(`/motorcycle/${params.row.id}`)} className="dark:text-[#F2F2F2] hover:dark:text-[#F9CDC4]">
                    <Box display="flex" alignItems="center">
                        <Avatar alt={params.row.Vehicle} src={params.row.avatarUrl} style={{ marginRight: 8 }} />
                        {params.row.Vehicle}
                    </Box>
                </a>
            ),
        },
        { field: 'Year', headerName: t('adminPages.validationVehicles.columns.year'), width: 100 },
        { field: 'Mileage', headerName: t('adminPages.validationVehicles.columns.mileage'), width: 150 },
        { field: 'Energy', headerName: t('adminPages.validationVehicles.columns.energy'), width: 150 },
        { field: 'submissionDate', headerName: t('adminPages.validationVehicles.columns.submissionDate'), width: 200 },
        {
            field: 'actions',
            headerName: '',
            width: 150,
            renderCell: (params) => (
                <Box display="flex">
                    <GenericDropDown>
                        <GenericDropDownItem value={t('adminPages.validationVehicles.actions.reject')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleReject(params.row)}}/>
                        <GenericDropDownItem value={t('adminPages.validationVehicles.actions.approve')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleApprove(params.row)}}
                        />
                    </GenericDropDown>
                </Box>
            ),
        },
    ];

    const boatColumns = [
        {
            field: 'Boat',
            headerName: t('adminPages.validationVehicles.menu.boats'),
            width: 300,
            renderCell: (params) => (
                <a onClick={()=> navigate(`/boat/${params.row.id}`)} className="dark:text-[#F2F2F2] hover:dark:text-[#F9CDC4]">
                    <Box display="flex" alignItems="center">
                        <Avatar alt={params.row.Boat} src={params.row.avatarUrl} style={{ marginRight: 8 }} />
                        {params.row.Boat}
                    </Box>
                </a>
            )
        },
        { field: 'Type', headerName: t('adminPages.validationVehicles.columns.type'), width: 150 },
        { field: 'Width', headerName: t('adminPages.validationVehicles.columns.width'), width: 100 },
        { field: 'Length', headerName: t('adminPages.validationVehicles.columns.length'), width: 100 },
        { field: 'Material', headerName: t('adminPages.validationVehicles.columns.material'), width: 150 },
        { field: 'submissionDate', headerName: t('adminPages.validationVehicles.columns.submissionDate'), width: 200 },
        {
            field: 'actions',
            headerName: '',
            width: 150,
            renderCell: (params) => (
                <Box display="flex">
                    <GenericDropDown>
                        <GenericDropDownItem value={t('adminPages.validationVehicles.actions.reject')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleReject(params.row)}}/>
                        <GenericDropDownItem value={t('adminPages.validationVehicles.actions.approve')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleApprove(params.row)}}
                        />
                    </GenericDropDown>
                </Box>
            ),
        }
    ];

    const engineColumns = [
        {
            field: 'Engine',
            headerName: t('adminPages.validationVehicles.menu.engines'),
            width: 300,
            renderCell: (params) => (
                <a onClick={()=> navigate(`/engine/${params.row.id}`)} className="dark:text-[#F2F2F2] hover:dark:text-[#F9CDC4]">
                    <Box display="flex" alignItems="center">
                        <Avatar alt={params.row.Engine} src={params.row.avatarUrl} style={{ marginRight: 8 }} />
                        {params.row.Engine}
                    </Box>
                </a>
            )
        },
        { field: 'Type', headerName: t('adminPages.validationVehicles.columns.type'), width: 150 },
        { field: 'description', headerName: t('adminPages.validationVehicles.columns.description'), width: 150 },
        { field: 'submissionDate', headerName: t('adminPages.validationVehicles.columns.submissionDate'), width: 200 },
        {
            field: 'actions',
            headerName: '',
            width: 150,
            renderCell: (params) => (
                <Box display="flex">
                    <GenericDropDown>
                        <GenericDropDownItem value={t('adminPages.validationVehicles.actions.reject')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleReject(params.row)}}/>
                        <GenericDropDownItem value={t('adminPages.validationVehicles.actions.approve')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation(); handleApprove(params.row)}}
                        />
                    </GenericDropDown>
                </Box>
            ),
        }
    ];

    const fetchAllData = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await getCars();

            // Process cars
            const pendingCars = response.cars?.filter(car => car.status === "pending") || [];
            const formattedCars = pendingCars.map((car) => ({
                id: car.id,
                Vehicle: car.name,
                Year: car.year || 'N/A',
                Mileage: car.mileage ? `${car.mileage} km` : 'N/A',
                Energy: car.energie || 'N/A',
                submissionDate: car.created_at ? new Date(car.created_at).toLocaleDateString() : 'N/A',
                avatarUrl: car.first_photo?.link || '',
            }));

            // Process motorcycles
            const pendingMotorcycles = response.motorcycles?.filter(motorcycle => motorcycle.status === "pending") || [];
            const formattedMotorcycles = pendingMotorcycles.map((motorcycle) => ({
                id: motorcycle.id,
                Vehicle: motorcycle.name,
                Year: motorcycle.year || 'N/A',
                Mileage: motorcycle.mileage ? `${motorcycle.mileage} km` : 'N/A',
                Energy: motorcycle.energie || 'N/A',
                submissionDate: motorcycle.created_at ? new Date(motorcycle.created_at).toLocaleDateString() : 'N/A',
                avatarUrl: motorcycle.first_photo?.link || '',
            }));

            // Process boats
            const pendingBoats = response.boats?.filter(boat => boat.status === "pending") || [];
            const formattedBoats = pendingBoats.map((boat) => ({
                id: boat.id,
                Boat: boat.name,
                Type: boat.type || 'N/A',
                Width: boat.width ? `${boat.width}m` : 'N/A',
                Length: boat.length ? `${boat.length}m` : 'N/A',
                Material: boat.characteristics?.material || 'N/A',
                submissionDate: boat.created_at ? new Date(boat.created_at).toLocaleDateString() : 'N/A',
                avatarUrl: boat.first_photo?.link || '',
            }));

            // Process engines
            const pendingEngines = response.engins?.filter(engine => engine.status === "pending") || [];
            const formattedEngines = pendingEngines.map((engine) => ({
                id: engine.id,
                Engine: engine.name,
                Type: engine.type || 'N/A',
                description: engine.description || 'N/A',
                submissionDate: engine.created_at ? new Date(engine.created_at).toLocaleDateString() : 'N/A',
                avatarUrl: engine.first_photo || '',
            }));

            setAllData({
                cars: formattedCars,
                motorcycles: formattedMotorcycles,
                boats: formattedBoats,
                engines: formattedEngines
            });

        } catch (error) {
            setError("Failed to fetch data");
            toast.error(<ToastError message={t('adminPages.validationVehicles.messages.fetchError')}/>, toastStyle);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAllData();
    }, []);

    const handleApprove = async (row) => {
        try {
            if (currentTab === 'cars') {
                await updateCarStatus([row.id], 'verified', "Votre véhicule est accepté");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.validationSuccessVehicle')}/>, toastStyle);
            } else if (currentTab === 'motorcycles') {
                await updateCarStatus([row.id], 'verified', "Votre moto est acceptée");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.validationSuccessMotorcycle')}/>, toastStyle);
            } else if (currentTab === 'boats') {
                await changeBoatStatus([row.id], 'verified', "Votre bateau est accepté");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.validationSuccessBoat')}/>, toastStyle);
            } else if (currentTab === 'engines') {
                await UpdateEnginStatus([row.id], 'verified', "Votre engin est accepté");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.validationSuccessEngine')}/>, toastStyle);
            }
            await fetchAllData();
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.validationVehicles.messages.validationError')}/>, toastStyle);
            setError('Failed to update status');
        }
    };

    const handleApproveAll = async (ids) => {
        try {
            if (currentTab === 'cars') {
                await updateCarStatus(ids, 'verified', "Votre véhicule est accepté");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.validationSuccessVehicle')}/>, toastStyle);
            } else if (currentTab === 'motorcycles') {
                await updateCarStatus(ids, 'verified', "Votre moto est acceptée");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.validationSuccessMotorcycle')}/>, toastStyle);
            } else if (currentTab === 'boats') {
                await changeBoatStatus(ids, 'verified', "Votre bateau est accepté");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.validationSuccessBoat')}/>, toastStyle);
            } else if (currentTab === 'engines') {
                await UpdateEnginStatus(ids, 'verified', "Votre engin est accepté");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.validationSuccessEngine')}/>, toastStyle);
            }
            await fetchAllData();
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.validationVehicles.messages.validationError')}/>, toastStyle);
            setError('Failed to update status');
        }
    };

    const handleReject = (row) => {
        setSelectedRow(row);
        setOpenRejectDialog(true);
    };

    const handleRejectAll = async (ids) => {
        try {
            if (currentTab === 'cars') {
                await updateCarStatus(ids, 'rejected', "Votre véhicule est non conforme");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.rejectionSuccessVehicle')}/>, toastStyle);
            } else if (currentTab === 'motorcycles') {
                await updateCarStatus(ids, 'rejected', "Votre moto est non conforme");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.rejectionSuccessMotorcycle')}/>, toastStyle);
            } else if (currentTab === 'boats') {
                await changeBoatStatus(ids, 'rejected', "Votre bateau est  non conforme");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.rejectionSuccessBoat')}/>, toastStyle);
            } else if (currentTab === 'engines') {
                await UpdateEnginStatus(ids, 'rejected', "Votre engin est non conforme");
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.rejectionSuccessEngine')}/>, toastStyle);
            }
            setJustification('');
            await fetchAllData();
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.validationVehicles.messages.rejectionError')}/>, toastStyle);
            setError('Failed to update status');
        }
    };

    const handleRejectSubmit = async () => {
        if (!selectedRow) return;
        try {
            if (currentTab === 'cars') {
                await updateCarStatus([selectedRow.id], 'rejected', justification);
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.rejectionSuccessVehicle')}/>, toastStyle);
            } else if (currentTab === 'motorcycles') {
                await updateCarStatus([selectedRow.id], 'rejected', justification);
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.rejectionSuccessMotorcycle')}/>, toastStyle);
            } else if (currentTab === 'boats') {
                await changeBoatStatus([selectedRow.id], 'rejected', justification);
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.rejectionSuccessBoat')}/>, toastStyle);
            } else if (currentTab === 'engines') {
                await UpdateEnginStatus([selectedRow.id], 'rejected', justification);
                toast.success(<ToastSuccess message={t('adminPages.validationVehicles.messages.rejectionSuccessEngine')}/>, toastStyle);
            }
            setOpenRejectDialog(false);
            setJustification('');
            await fetchAllData();
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.validationVehicles.messages.rejectionError')}/>, toastStyle);
            setError('Failed to update status');
        }
    };

    const handleCancel = () => {
        setOpenRejectDialog(false);
        setJustification('');
    };

    const handleTabChange = (tabId) => {
        setCurrentTab(tabId);
    };

    let columns, rows;
    switch (currentTab) {
        case 'cars':
            columns = carColumns;
            rows = allData.cars;
            break;
        case 'motorcycles':
            columns = motorcycleColumns;
            rows = allData.motorcycles;
            break;
        case 'boats':
            columns = boatColumns;
            rows = allData.boats;
            break;
        case 'engines':
            columns = engineColumns;
            rows = allData.engines;
            break;
        default:
            columns = [];
            rows = [];
            break;
    }

    return (
        <Box display="flex" flexDirection="column" height="100%" width="100%" className="dark:bg-[#232323]">
            <Box flex={1} overflow="hidden" display="flex" flexDirection="row">
                <Box flex={1} padding={1}>
                    <CustomTabs menuItems={secondaryMenuItems} onTabChange={handleTabChange} />
                    <Box display="flex" flexDirection="column" height="100%">
                        {loading ? (
                            <div className="flex items-center justify-center w-full h-full dark:bg-[#232323]">
                                <div className="loader dark:border-[#F2F2F2]" />
                            </div>
                        ) : error ? (
                            <Typography color="error" className="dark:text-[#F9CDC4]">{error}</Typography>
                        ) : (
                            <DataTable
                                key={currentTab}
                                rows={rows}
                                columns={columns}
                                showActions={false}
                                showExportImport={false}
                                showAddButton={false}
                                onSelectionChange={(selected) => setSelectedAll(selected)}
                                handleApproveAll={() => handleApproveAll(selectedAll)}
                                handleRejectAll={() => handleRejectAll(selectedAll)}
                                tabLabel={secondaryMenuItems.find((item) => item.id === currentTab)?.label}
                                className="dark:bg-[#333333] dark:text-[#F2F2F2]"
                            />
                        )}
                    </Box>
                </Box>
            </Box>

            <Modal
                open={openRejectDialog}
                onClose={handleCancel}
                className="flex items-center justify-center"
            >
                <div className="bg-white dark:bg-[#333333] p-6 rounded-lg shadow-xl w-[500px] max-w-[90%]">
                    <h2 className="text-xl font-semibold mb-4 dark:text-[#F2F2F2]">
                        {t('adminPages.validationVehicles.dialogs.rejectTitle')}
                    </h2>
                    <div className="mb-6">
                        <p className="mb-4 dark:text-[#CBCBCB]">
                            {t('adminPages.validationVehicles.dialogs.rejectMessage')}
                        </p>
                        <textarea
                            value={justification}
                            onChange={(e) => setJustification(e.target.value)}
                            placeholder="Entrez la justification ici..."
                            rows={4}
                            className="w-full h-[115px] border border-border rounded px-4 py-2 dark:bg-[#494949] dark:text-[#F2F2F2] dark:border-[#595959]"
                        />
                    </div>
                    <div className="flex justify-end gap-4">
                        <Button onClick={handleCancel} variant="outlined" className="dark:text-[#F9CDC4] dark:border-[#F9CDC4]">
                            {t('adminPages.validationVehicles.dialogs.cancel')}
                        </Button>
                        <Button onClick={handleRejectSubmit} variant="contained" className="dark:bg-[#E44826] dark:text-[#F2F2F2]">
                            {t('adminPages.validationVehicles.dialogs.confirmReject')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </Box>
    );
}