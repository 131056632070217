import {useNavigate} from "react-router";
import {ImageWithFallback} from "../../../../components/ImageWithFallBack/ImageWithFallBack";
import {useTranslation} from "react-i18next";

export default function ShopCard({id, name, description, rating, logo}) {
    const navigate = useNavigate();
    const {t} = useTranslation()

    return (
        <div
            className="min-w-[250px] max-w-[250px] 2xl:min-w-[300px] 2xl:max-w-[300px] w-full h-fit flex flex-col gap-2 bg-white dark:bg-[#232323] rounded-lg border border-border dark:border-[#333333]">
            <div className="flex flex-row items-center justify-center gap-3 w-fit h-fit p-2">
                <div
                    className="w-10 h-10 rounded-full border-border dark:border-[#333333] border object-cover overflow-hidden flex items-center justify-center">
                    <ImageWithFallback src={logo} alt="logo"/>
                </div>
                <div className="flex flex-col gap-1 items-start">
                    <p className="font-semibold text-sm text-ellipsis whitespace-nowrap overflow-hidden max-w-[80px] 2xl:w-full dark:text-[#F2F2F2]">{name}</p>
                    <div className="flex flex-row gap-2 items-center">
                        <div className="flex flex-row gap-2 items-center">
                            <p className="font-bold text-warning dark:text-[#fff]">{Number(rating || 0).toFixed(1)}</p>
                            <img src="/resources/star.svg" alt="star"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative group">
                <div
                    className="hidden sm:flex flex-row px-2 items-center gap-2 text-sm font-medium text-[#797979] dark:text-[#CBCBCB] text-ellipsis whitespace-nowrap"
                >
                    <p className='truncate min-w-[234px] max-w-[234px] 2xl:min-w-[284px] 2xl:max-w-[284px]'>
                        {description}
                    </p>
                </div>

                <span
                    className="absolute left-0 z-50 w-max px-2 py-1 text-xs text-white bg-black dark:bg-[#494949] rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200">
        {description}
    </span>
            </div>
            <div className="flex flex-row items-center px-2 justify-end border-t border-t-border dark:border-t-[#333333] h-10 min-h-10">
                <div
                    onClick={() => navigate("/shop/" + id)}
                    className="cta flex items-center justify-center px-4 py-1 border-border dark:border-[#333333] border rounded bg-white dark:bg-[#232323] cursor-pointer relative group overflow-hidden"
                >
                    <div
                        className="absolute inset-0 bg-black dark:bg-[#E44826] rounded-full w-[110%] pb-[110%] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-transform duration-600 ease-[cubic-bezier(0.1,0,0.3,1)] scale-0 group-hover:scale-100"
                    ></div>
                    <div
                        className="absolute inset-0 bg-black dark:bg-[#E44826] opacity-0 transition-opacity duration-600 group-hover:opacity-100 group-hover:duration-[0.02s] group-hover:delay-[0.6s]"
                    ></div>
                    <p className="cta font-medium text-xs sm:text-sm text-ellipsis whitespace-nowrap overflow-hidden relative z-10 group-hover:text-white text-black dark:text-[#F2F2F2]">
                        {t("productDetails.detailsTab.CheckShop")}
                    </p>
                </div>
            </div>
        </div>
    )
}