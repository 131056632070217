import WidgetElement from "../../components/WidgetElement/WidgetElement";
import SellerServiceTable from "../../../../components/SellerServiceTables/SellerServiceTable";
import {useNavigate} from "react-router";
import React, {useEffect, useState} from "react";
import {getServiceStats} from "../../../../services/SellerSpace/API";
import {toast} from "sonner";
import ToastError, {toastStyle} from "../../../../components/Toasts/Error/ToastError";
import Cookies from "js-cookie";
import { useTranslation } from 'react-i18next';

export default function Services() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [stats, updateStats] = useState([]);
    useEffect(() => {
        getServiceStats().then((response) => {
            console.log(response.data);
            if (response.status === 200) {
                updateStats(response.data);
            }
        }).catch(() => {
            toast.error(<ToastError
                message={t('sellerSpace.stats.error')}/>, toastStyle);
        })
    }, []);

    const [shop,setShop] = useState({})
    useEffect(() => {
        try {
            const shopData = Cookies.get("shop")
            if (shopData) {
                setShop(JSON.parse(shopData))
            }
        }catch (e){
            //TODO : handle error
        }
    }, []);

    return (
        <div  className="w-full h-full flex flex-col items-start gap-4 px-1 sm:px-4 py-2 overflow-y-auto noScrollBar max-h-[calc(91vh-40px)]">

            <div className="w-full h-fit flex flex-row items-center justify-between">
                <p className="hidden sm:block font-semibold text-2xl w-full">{t('sellerSpace.services.title')}</p>

                <div className="flex flex-row items-center gap-4 w-full justify-end">
                    <div
                        onClick={() => navigate("./reservations")}
                        className="w-[140px] bg-primaryExtraLight flex items-center border-primaryLight border justify-center gap-2 px-6 py-3 sm:w-fit h-fit rounded cursor-pointer">
                        <img src="/resources/soumission.svg" className="primary_filter h-5 w-5" alt="list icon"/>
                        <p className="text-sm font-semibold text-primaryLight truncate">{t('sellerSpace.services.reservationList')}</p>
                    </div>
                    <div
                        onClick={() => {
                            if (shop?.status === "subscribed") {
                                navigate("./addService")
                            }
                        }}
                        title={shop?.status === "subscribed" ? "" : t('sellerSpace.services.subscribeToAdd')}
                        className={`w-[140px] ${
                            shop?.status === "subscribed" ? "bg-primaryLight cursor-pointer" : "bg-gray-500 cursor-not-allowed"
                        } flex items-center justify-center gap-2 px-6 py-3 sm:w-fit h-fit rounded`}
                    >
                        <img src="/resources/add.svg" className="white_filter" alt="add icon"/>
                        <p className="text-sm font-semibold text-white truncate">{t('sellerSpace.services.addService')}</p>
                    </div>
                </div>
            </div>

            <div className="w-full h-fit flex flex-row items-center flex-wrap sm:flex-nowrap gap-4">
                <WidgetElement title={t('sellerSpace.services.stats.totalServices')} amount={stats?.services}/>
                <WidgetElement title={t('sellerSpace.services.stats.totalReservations')} amount={stats?.reservations}/>
                <WidgetElement title={t('sellerSpace.services.stats.totalRevenue')} amount={stats?.totalPayment + stats?.totalRest}/>
            </div>
            <div className="border dark:bg-[#333] dark:border-0 border-border rounded-lg bg-white w-full max-h-[calc(91vh-40px)]">
                <SellerServiceTable maxHeight={"62vh"}/>
            </div>
        </div>
    )
}