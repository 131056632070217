export default function AboutUs() {
    return (
        <div className="bg-gray-50 dark:bg-[#333333] min-h-screen">
            <div className="relative bg-gradient-to-r from-primaryDark to-primaryLight text-white py-16 sm:py-24 md:py-36 flex items-center justify-center px-4">
                <div className="flex flex-col md:flex-row items-center gap-6 md:gap-8 max-w-6xl w-full">
                    <div className="flex-shrink-0 w-40 sm:w-52">
                        <img src="/resources/white_Logo.svg" alt="white logo" className="w-full"/>
                    </div>

                    <div className="text-center md:text-left">
                        <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-3 md:mb-4">
                            Bienvenue chez MecaNext
                        </h1>
                        <p className="font-medium text-base sm:text-lg md:text-xl">
                            Une marketplace innovante dédiée à la vente et à l'achat de pièces et services mécaniques.
                        </p>
                    </div>
                </div>
            </div>

            {/* Content Section */}
            <div className="max-w-7xl mx-auto py-8 sm:py-12 md:py-16 px-4 sm:px-6 md:px-12">
                {/* About Us Section */}
                <section className="mb-8 sm:mb-12 md:mb-16">
                    <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 dark:text-[#F2F2F2] mb-3 md:mb-4 text-center">À Propos de Nous</h2>
                    <p className="text-center text-gray-600 dark:text-[#CBCBCB] text-sm sm:text-base mb-6 sm:mb-8 font-medium">
                        Découvrez qui nous sommes et ce que nous faisons.
                    </p>
                    <div className="space-y-3 sm:space-y-4">
                        <p className="font-medium text-gray-700 dark:text-[#CBCBCB] leading-relaxed text-sm sm:text-base">
                            MecaNext est une marketplace en ligne innovante dédiée à la vente et à l'achat de pièces et services mécaniques. Notre plateforme couvre une large gamme de véhicules et machines, incluant voitures, motos, équipements lourds et machines industrielles. Nous mettons en relation les clients avec un réseau de vendeurs de confiance, garantissant l'accès à des pièces et services de haute qualité. Que vous soyez un professionnel de la mécanique ou un passionné de véhicules, MecaNext propose un catalogue complet adapté à vos besoins.
                        </p>
                        <p className="font-medium text-gray-700 dark:text-[#CBCBCB] leading-relaxed text-sm sm:text-base">
                            Nous mettons l'accent sur la satisfaction des clients, en priorisant la transparence, des transactions sécurisées et un service fiable. Notre objectif est de bâtir un écosystème de confiance où les utilisateurs peuvent naviguer facilement, comparer les produits et choisir les meilleures options pour leurs besoins mécaniques.
                        </p>
                    </div>
                </section>

                {/* Vision Section */}
                <section className="mb-8 sm:mb-12 md:mb-16">
                    <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 dark:text-[#F2F2F2] mb-3 md:mb-4 text-center">Notre Vision</h2>
                    <p className="text-center font-medium text-gray-600 dark:text-[#CBCBCB] text-sm sm:text-base mb-6 sm:mb-8">
                        Notre engagement pour l'avenir de l'industrie mécanique.
                    </p>
                    <div className="space-y-3 sm:space-y-4">
                        <p className="font-medium text-gray-700 dark:text-[#CBCBCB] leading-relaxed text-sm sm:text-base">
                            Chez MecaNext, notre vision est de révolutionner l'industrie mécanique en créant une marketplace numérique fluide et inclusive. Nous visons à devenir la plateforme leader en Algérie, en offrant aux entreprises et aux particuliers des outils innovants pour acheter, vendre et découvrir des pièces mécaniques de qualité. Nous nous engageons à favoriser la croissance, à soutenir des pratiques durables et à améliorer l'expérience client à travers l'innovation et l'intégration technologique.
                        </p>
                        <p className="font-medium text-gray-700 dark:text-[#CBCBCB] leading-relaxed text-sm sm:text-base">
                            Nous croyons en la création d'un écosystème communautaire axé sur la confiance, la qualité et l'efficacité. MecaNext aspire à combler le fossé entre fournisseurs et clients, rendant le processus de recherche et d'achat de pièces mécaniques plus accessible et plus efficace que jamais.
                        </p>
                    </div>
                </section>

                <section>
                    <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 dark:text-[#F2F2F2] mb-3 md:mb-4 text-center">Contactez-Nous</h2>
                    <p className="text-center font-medium text-gray-600 dark:text-[#CBCBCB] text-sm sm:text-base mb-6 sm:mb-8">
                        Nous sommes là pour répondre à toutes vos questions.
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16">
                        <div className="space-y-4">
                            <div>
                                <p className="font-medium text-gray-700 dark:text-[#CBCBCB]">
                                    <strong className="text-base sm:text-lg font-bold text-black dark:text-[#F2F2F2] block mb-2">Adresse du Siège :</strong>
                                    Cité Houari Boumédiene, Béjaïa, Algérie
                                </p>
                            </div>
                            <div>
                                <p className="text-base sm:text-lg text-gray-700 dark:text-[#CBCBCB]">
                                    <strong>Email :</strong> <a href="mailto:contact@mecanex-dz.com"
                                                                className="text-blue-600 dark:text-[#F9CDC4] underline break-all">contact@mecanext-dz.com</a>
                                </p>
                            </div>
                        </div>
                        <div>
                            <p className="font-medium text-gray-700 dark:text-[#CBCBCB] mb-2">
                                <strong className="font-bold text-black dark:text-[#F2F2F2] text-base sm:text-lg block">Numéros de Téléphone :</strong>
                            </p>
                            <ul className="list-disc font-medium pl-6 text-gray-700 dark:text-[#CBCBCB] space-y-2">
                                <li>
                                    <a href={"tel: +213 557 27 05 39"}>
                                        +213 557 27 05 39
                                    </a>
                                </li>
                                <li>
                                    <a href={"tel: +213 662 05 36 04"}>
                                        +213 662 05 36 04
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}