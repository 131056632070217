import React, { useEffect, useState } from "react";
import { usePersonalInfoContext } from "../../Pages/SignUp";
import { useTranslation } from "react-i18next";

const PersonalInfoForm = ({ handleNext, handlePrevious }) => {
    const { t } = useTranslation();
    const { addInfo, personalInfo } = usePersonalInfoContext();
    const [formData, setFormData] = useState({
        first_name: personalInfo.first_name || "",
        last_name: personalInfo.last_name || "",
        date_of_birth: personalInfo.date_of_birth || "",
        email: personalInfo.email || "",
        username: personalInfo.username || "",
    });

    const [errors, setErrors] = useState({});
    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        const darkModePreference = localStorage.getItem("darkMode") === "true";
        setDarkMode(darkModePreference);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const validateForm = () => {
        const newErrors = {};
        const today = new Date();
        const birthDate = new Date(formData.date_of_birth);
        const age = today.getFullYear() - birthDate.getFullYear();
        const isOver18 =
            age > 18 || (age === 18 && today >= new Date(birthDate.setFullYear(birthDate.getFullYear() + 18)));

        if (!formData.first_name) newErrors.first_name = t("personalInfoForm.fields.first_name.errorRequired");
        if (!formData.last_name) newErrors.last_name = t("personalInfoForm.fields.last_name.errorRequired");
        if (!formData.date_of_birth) {
            newErrors.date_of_birth = t("personalInfoForm.fields.date_of_birth.errorRequired");
        } else if (!isOver18) {
            newErrors.date_of_birth = t("personalInfoForm.fields.date_of_birth.errorAge");
        }
        if (!formData.email) {
            newErrors.email = t("personalInfoForm.fields.email.errorRequired");
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = t("personalInfoForm.fields.email.errorInvalid");
        }
        if (!formData.username) newErrors.username = t("personalInfoForm.fields.username.errorRequired");

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            addInfo(formData);
            handleNext();
        }
    };

    return (
        <div className="w-full h-full flex flex-col items-center justify-center p-6 space-y-4 xl:space-y-6 relative">
            <h2 className="lg:text-xl xl:text-2xl 2xl:text-3xl font-bold text-gray-800 dark:text-[#F2F2F2] text-center">
                {t("personalInfoForm.title")}
            </h2>
            <p className="text-xs xl:text-sm text-gray-600 dark:text-[#CBCBCB] text-center max-w-lg">
                {t("personalInfoForm.description")}
            </p>

            <div className="grid grid-cols-2 gap-2 xl:gap-4 2xl:gap-6">
                {["first_name", "last_name", "date_of_birth", "username", "email"].map((field) => (
                    <div key={field} className={`relative ${field === "email" ? "col-span-2" : ""}`}>
                        <label
                            htmlFor={field}
                            className="block font-medium text-xs lg:text-sm xl:text-normal xl:mb-2 text-gray-600 dark:text-[#CBCBCB]"
                        >
                            {t(`personalInfoForm.fields.${field}.label`)}
                        </label>
                        <input
                            id={field}
                            type={field === "date_of_birth" ? "date" : field === "email" ? "email" : "text"}
                            name={field}
                            placeholder={t(`personalInfoForm.fields.${field}.placeholder`)}
                            className={`dark:focus:bg-[#494949] border h-10 xl:h-12 px-3 rounded w-full focus:outline-none focus:ring-2 
                                ${errors[field] ? "border-red-500 focus:ring-red-500" : "focus:ring-[#E44826]"}
                                bg-white dark:bg-[#494949] text-gray-700 dark:text-[#F2F2F2] 
                                border-gray-300 dark:border-[#595959]`}
                            value={formData[field]}
                            onChange={handleChange}
                        />
                        {errors[field] && <p className="text-red-500 dark:text-[#F9CDC4] text-sm">{errors[field]}</p>}
                    </div>
                ))}
            </div>

            <div className="flex justify-between absolute left-6 bottom-6 w-[calc(100%-48px)]">
                <button
                    onClick={handlePrevious}
                    className="px-4 xl:px-6 py-2 xl:py-2.5 rounded-lg border border-slate-300 dark:border-[#595959] text-slate-600 dark:text-[#CBCBCB] hover:bg-slate-100 dark:hover:bg-[#494949] transition-colors"
                >
                    {t("personalInfoForm.buttons.previous")}
                </button>
                <button
                    onClick={handleSubmit}
                    className={`px-4 xl:px-6 h-10 xl:h-12 flex justify-center items-center bg-[#E44826] text-white py-2 xl:py-3 rounded-lg font-medium hover:bg-primaryDark transition duration-300 gap-4 ${
                        (!formData.date_of_birth || !formData.last_name || !formData.first_name || !formData.username || !formData.email)
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                    }`}
                >
                    {t("personalInfoForm.buttons.next")}
                    <img src="/resources/arrow_right.svg" alt="Next" className="white_filter w-3 xl:w-4"/>
                </button>
            </div>
        </div>
    );
};

export default PersonalInfoForm;
