import React from "react";
import {useFilterContext} from "../../../index";

export const MileageRange = () => {
    const {updateMileage:onChange,mileage:value} = useFilterContext();
    const handleMinChange = (e) => {
        const newMin = e.target.value ? parseInt(e.target.value) : '';
        onChange(newMin,value.max);
    };

    const handleMaxChange = (e) => {
        const newMax = e.target.value ? parseInt(e.target.value) : '';
        onChange(value.min,newMax);
    };

    return (
        <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row h-9 items-center justify-between">
                <p className="font-medium dark:text-[#F2F2F2]">Kilométrage</p>
            </div>
            <div className="flex flex-row items-center gap-4 w-full">
                <div className="flex flex-row items-center gap-2 border border-borderLight dark:border-[#494949] rounded px-2 h-10">
                    <input
                        type="number"
                        placeholder="Min"
                        className="w-full h-full focus:outline-none dark:bg-[#232323] dark:text-[#F2F2F2]"
                        value={value.min}
                        onChange={handleMinChange}
                    />
                </div>

                <div className="flex flex-row items-center gap-2 border border-borderLight dark:border-[#494949] rounded px-2 h-10">
                    <input
                        type="number"
                        placeholder="Max"
                        className="w-full h-full focus:outline-none dark:bg-[#232323] dark:text-[#F2F2F2]"
                        value={value.max}
                        onChange={handleMaxChange}
                    />
                </div>
            </div>
        </div>
    );
};