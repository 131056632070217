import SellerServiceReservation from "../../../../components/SellerServiceReservation/SellerServiceReservation";

export default function ReservationService() {
    return (
        <div className="w-full h-full flex flex-col items-start gap-4 px-4 py-2">
            <div className="w-full h-fit flex flex-row items-center justify-between">
                <p className="font-semibold text-2xl">Mes Reservation</p>
            </div>

            <div className="border dark:border-0 dark:bg-[#333] border-border rounded-lg bg-white w-full h-full">
                <SellerServiceReservation maxHeight={"80vh"}/>
            </div>
        </div>
    )
}