import React, {useState} from "react";
import PropTypes from "prop-types";
import {House, X} from "lucide-react"
import axios from "axios";

const instance = axios.create({
    baseURL: "https://nominatim.openstreetmap.org",
    params: {
        format: "jsonv2",
    },
});

export default function LocationFilter({onChange}) {

    const [address, setAddress] = useState("");

    const [loading, setLoading] = useState(false)

    const handleGetCurrentPos = () => {
        navigator.geolocation.getCurrentPosition(
            (pos) => {
                const {latitude, longitude} = pos.coords;
                instance.get(`reverse?format=json&lat=${latitude}&lon=${longitude}`)
                    .then((response) => {
                        setAddress(response.data.display_name)
                    }).catch((error) => {
                    console.error('Error fetching reverse geocoding data:', error);
                })
                onChange([latitude, longitude]);
            },
            (error) => console.error("Error fetching location:", error),
            {enableHighAccuracy: true, timeout: 10000, maximumAge: 0}
        );
    }

    const handleSearch = async (e) => {
        console.log("Get Search ")
        e.preventDefault();
        setLoading(true)
        try {
            const response = await instance.get(`search.php?q=${address}`)
            if (response.data.length > 0) {
                const {lat, lon} = response.data[0];
                onChange([lat, lon]);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching geocoding data:', error);
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col gap-2 w-full mt-2">
            <div className="flex flex-row h-9 items-center justify-between">
                <p className="font-medium dark:text-[#F2F2F2]">Locations</p>
                <button onClick={
                    () => {
                        onChange([])
                        setAddress("")
                    }
                } className="border border-borderLight dark:border-[#494949] rounded p-1 dark:hover:bg-[#333333]">
                    <X className="w-5 dark:text-[#CBCBCB]"/>
                </button>
            </div>
            <div className="flex flex-row items-center gap-4 w-full">
                <form onSubmit={handleSearch} className="flex flex-row items-center gap-2 w-full">
                    <input
                        disabled={loading}
                        type="text"
                        placeholder="Location"
                        className="w-full focus:outline-none border border-borderLight dark:border-[#494949] rounded px-2 h-10 dark:bg-[#232323] dark:text-[#F2F2F2] dark:placeholder-[#797979]"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                    <button type={"button"} onClick={handleGetCurrentPos}
                            className="border border-borderLight dark:border-[#494949] rounded p-2 dark:hover:bg-[#333333]">
                        <House className="w-5 dark:text-[#CBCBCB]"/>
                    </button>
                </form>
            </div>
        </div>
    )
}

LocationFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
}