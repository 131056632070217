import React, {useEffect, useRef, useState} from "react";
import {ImageWithFallback} from "../../../components/ImageWithFallBack/ImageWithFallBack"
import { useTranslation } from 'react-i18next';
import {getAllShops, StoreConversation, Conversations, editConversation, StoreMessages,ConversationMessages} from "../../../services/marketplace/ChatServices";
import Cookies from 'js-cookie';
import Echo from 'laravel-echo';
import { Search, MessageCircle } from 'lucide-react';

const Chat = () => {
    const { t } = useTranslation();
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [shops, setShops] = useState([]);
    const [filteredShops, setFilteredShops] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [conversationSearchTerm, setConversationSearchTerm] = useState('');
    const [selectedShop, setSelectedShop] = useState(null);
    const [messages, setMessages] = useState({data: {data: []}});
    const [newMessage, setNewMessage] = useState('');
    const [conversationId, setConversationId] = useState(null);
    const [userConversations, setUserConversations] = useState([]);
    const [filteredConversations, setFilteredConversations] = useState([]);
    const [showConversations, setShowConversations] = useState(true);
    const [isEditingName, setIsEditingName] = useState(false);
    const [editedName, setEditedName] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const messagesEndRef = useRef(null);
    const inputRef = useRef(null);
    const nameInputRef = useRef(null);
    const echoRef = useRef(null);
    const channelRef = useRef(null);
    const messagesContainerRef = useRef(null);
    const chatRef = useRef(null);
    const [userId] = useState(() => {
        const user = Cookies.get('user');
        return user ? JSON.parse(user).id : null;
    });

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (chatRef.current && !chatRef.current.contains(event.target)) {
                setIsChatOpen(false);
                setSelectedShop(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const initEcho = () => {
            echoRef.current = new Echo({
                broadcaster: 'pusher',
                key: process.env.REACT_APP_PUSHER_APP_KEY,
                cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
                wsHost: process.env.REACT_APP_PUSHER_HOST,
                wsPort: process.env.REACT_APP_PUSHER_PORT,
                forceTLS: false,
                encrypted: true,
                enabledTransports: ["ws"]
            });
        };

        if (!echoRef.current) {
            initEcho();
        }

        return () => {
            if (echoRef.current) {
                echoRef.current.disconnect();
                echoRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        const subscribeToChannel = () => {
            if (!conversationId || !echoRef.current) return;

            if (channelRef.current) {
                channelRef.current.stopListening('message.sent');
                echoRef.current.leave(`conversation.${conversationId}`);
            }

            channelRef.current = echoRef.current.channel(`conversation.${conversationId}`);

            channelRef.current.listen('.message.sent', async (e) => {
                try {
                    const messageData = JSON.parse(e);
                    setMessages(prevMessages => {
                        const newData = {...prevMessages};
                        if (!newData.data?.data?.data) {
                            newData.data = {data: {data: []}};
                        }
                        newData.data.data.data.unshift(messageData);
                        return newData;
                    });
                } catch (error) {
                    const messagesData = await ConversationMessages(conversationId);
                    setMessages(messagesData);
                }
            });
        };

        subscribeToChannel();

        // Cleanup function
        return () => {
            if (channelRef.current) {
                channelRef.current.stopListening('message.sent');
                if (echoRef.current) {
                    echoRef.current.leave(`conversation.${conversationId}`);
                }
                channelRef.current = null;
            }
        };
    }, [conversationId]);

    const handleScroll = () => {
        if (!messagesContainerRef.current) return;
    };

    const loadConversationsAndShops = async () => {
        try {
            const conversationsResponse = await Conversations(userId);
            const conversations = conversationsResponse.data?.data || [];
            setUserConversations(conversations);
            setFilteredConversations(conversations);

            const shopsData = await getAllShops();
            const shopsArray = Array.isArray(shopsData) ? shopsData : shopsData.data || [];

            const existingShopIds = conversations.map(conv => conv.seller.id);
            const filteredShopsArray = shopsArray.filter(shop => !existingShopIds.includes(shop.seller.user_id));

            setShops(filteredShopsArray);
            setFilteredShops(filteredShopsArray);
        } catch (error) {
            console.error('Error loading data:', error);
            setShops([]);
            setFilteredShops([]);
            setUserConversations([]);
            setFilteredConversations([]);
        }
    };

    useEffect(() => {
        loadConversationsAndShops();
    }, [userId]);

    useEffect(() => {
        if (!Array.isArray(shops)) {
            setFilteredShops([]);
            return;
        }
        const filtered = shops.filter(shop =>
            shop.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredShops(filtered);
    }, [searchTerm, shops]);

    useEffect(() => {
        if (!Array.isArray(userConversations)) {
            setFilteredConversations([]);
            return;
        }
        const filtered = userConversations.filter(conversation =>
            conversation.name.toLowerCase().includes(conversationSearchTerm.toLowerCase()) ||
            conversation.seller.username.toLowerCase().includes(conversationSearchTerm.toLowerCase())
        );
        setFilteredConversations(filtered);
    }, [conversationSearchTerm, userConversations]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
        if (isChatOpen && inputRef.current) {
            inputRef.current.focus();
        }
    }, [messages, isChatOpen]);

    useEffect(() => {
        if (isEditingName && nameInputRef.current) {
            nameInputRef.current.focus();
        }
    }, [isEditingName]);

    const selectShop = async (shop) => {
        try {
            await StoreConversation(userId, shop.seller.user_id);
            setShops(prevShops => prevShops.filter(s => s.id !== shop.id));
            setFilteredShops(prevShops => prevShops.filter(s => s.id !== shop.id));

            await loadConversationsAndShops();
            setShowConversations(true);
        } catch (error) {
            console.error('Error creating conversation:', error);
        }
    };

    const selectConversation = async (conversation) => {
        const shop = {
            id: conversation.seller.id,
            name: `${conversation.name}`,
            logo: conversation.seller.profile_photo,
            description: conversation.seller.username,
            seller_id: conversation.seller.id
        };
        setSelectedShop(shop);
        setCurrentPage(1);
        setConversationId(conversation.id);
        const messagesData = await ConversationMessages(conversation.id);
        setMessages(messagesData);
        setEditedName(shop.name);
    };

    const handleNameEdit = async () => {
        if (editedName.trim() === selectedShop.name) {
            setIsEditingName(false);
            return;
        }

        try {
            await editConversation(conversationId, { name: editedName });
            setSelectedShop(prev => ({...prev, name: editedName}));
            setIsEditingName(false);
            await loadConversationsAndShops();
        } catch (error) {
            console.error('Error updating conversation name:', error);
            setEditedName(selectedShop.name);
        }
    };

    const handleNameKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleNameEdit();
        } else if (e.key === 'Escape') {
            setEditedName(selectedShop.name);
            setIsEditingName(false);
        }
    };

    const sendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim() || isSubmitting) return;

        try {
            setIsSubmitting(true);
            const messageData = {
                sender_id: userId,
                conversation_id: conversationId,
                content: newMessage.trim()
            };

            await StoreMessages(messageData);

            const updatedMessages = await ConversationMessages(conversationId);
            setMessages(updatedMessages);

            setNewMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleTyping = (e) => {
        setNewMessage(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage(e);
        }
    };

    return (
        <>
            <button
                onClick={() => setIsChatOpen(!isChatOpen)}
                className="fixed bottom-4 right-4 bg-primaryLight text-white p-4 rounded-full shadow-lg hover:bg-primaryDark transition z-40"
            >
                <MessageCircle className="w-6 h-6" />
            </button>

            {isChatOpen && !selectedShop && (
                <div ref={chatRef} className="fixed bottom-0 right-4 w-96 h-[500px] bg-white dark:bg-[#333333] rounded-t-lg shadow-xl flex flex-col z-50 transition-all duration-300 ease-in-out">
                    <div className="flex items-center justify-between p-4 border-b bg-gray-50 dark:bg-[#232323] dark:border-[#494949] rounded-t-lg">
                        <div className="flex items-center gap-4">
                            <h2 className="text-lg font-semibold text-primaryDark dark:text-[#F2F2F2] transition-colors">
                                {showConversations ? t('chat.conversations') : t('chat.availableShops')}
                            </h2>
                            <div className="flex items-center gap-2">
                                <button
                                    onClick={() => setShowSearch(!showSearch)}
                                    className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-[#494949] text-primaryLight dark:text-[#E44826] hover:text-primaryDark dark:hover:text-[#F9CDC4] transition-all duration-200"
                                >
                                    <Search className="w-5 h-5" />
                                </button>
                                <button
                                    onClick={() => {
                                        setShowConversations(!showConversations);
                                        setShowSearch(false);
                                    }}
                                    className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-[#494949] text-primaryLight dark:text-[#E44826] hover:text-primaryDark dark:hover:text-[#F9CDC4] transition-all duration-200"
                                >
                                    {showConversations ? t('chat.availableShops') : t('chat.conversations')}
                                </button>
                            </div>
                        </div>
                    </div>

                    {showSearch && (
                        <div className="p-4 border-b dark:border-[#494949] transition-all duration-200">
                            <input
                                type="text"
                                placeholder={showConversations ? t('chat.placeholders.searchConversations') : t('chat.placeholders.searchShops')}
                                value={showConversations ? conversationSearchTerm : searchTerm}
                                onChange={(e) => showConversations ? setConversationSearchTerm(e.target.value) : setSearchTerm(e.target.value)}
                                className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primaryLight dark:focus:ring-[#E44826] dark:bg-[#232323] dark:text-[#F2F2F2] dark:border-[#494949] transition-all duration-200"
                            />
                        </div>
                    )}

                    {showConversations ? (
                        <div className="flex-1 overflow-y-auto dark:bg-[#333333] transition-colors duration-200">
                            {!Array.isArray(filteredConversations) || filteredConversations.length === 0 ? (
                                <div className="h-full flex items-center justify-center text-gray-400 dark:text-[#797979]">
                                    <p>{t('chat.placeholders.noConversations')}</p>
                                </div>
                            ) : (
                                <div className="divide-y dark:divide-[#494949]">
                                    {filteredConversations.map(conversation => (
                                        <button
                                            key={conversation.id}
                                            onClick={() => selectConversation(conversation)}
                                            className="w-full p-4 hover:bg-gray-50 dark:hover:bg-[#494949] transition-all duration-200 flex items-center gap-4 group"
                                        >
                                            <div className="w-12 h-12 rounded-full border-2 border-primaryLight dark:border-[#E44826] overflow-hidden transform transition-transform duration-200 group-hover:scale-105">
                                                <ImageWithFallback
                                                    src={conversation.seller.profile_photo}
                                                    alt={conversation.seller.username}
                                                    className="h-full w-full object-cover transition-transform duration-200 group-hover:scale-110"
                                                />
                                            </div>
                                            <div className="flex flex-col items-start">
                                                <span className="font-medium text-primaryDark dark:text-[#F2F2F2] group-hover:text-primaryLight dark:group-hover:text-[#F9CDC4] transition-colors duration-200">
                                                    {`${conversation.name}`}
                                                </span>
                                                <span className="text-sm text-gray-500 dark:text-[#797979] transition-colors duration-200">{conversation.seller.username}</span>
                                                <span className="text-xs text-gray-400 dark:text-[#797979] transition-colors duration-200">
                                                    {new Date(conversation.created_at).toLocaleDateString()}
                                                </span>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="flex-1 overflow-y-auto dark:bg-[#333333] transition-colors duration-200">
                            {!Array.isArray(filteredShops) || filteredShops.length === 0 ? (
                                <div className="h-full flex items-center justify-center text-gray-400 dark:text-[#797979]">
                                    <p>{t('chat.placeholders.noShops')}</p>
                                </div>
                            ) : (
                                <div className="divide-y dark:divide-[#494949]">
                                    {filteredShops.map(shop => (
                                        <button
                                            key={shop.id}
                                            onClick={() => selectShop(shop)}
                                            className="w-full p-4 hover:bg-gray-50 dark:hover:bg-[#494949] transition-all duration-200 flex items-center gap-4 group"
                                        >
                                            <div className="w-12 h-12 rounded-full border-2 border-primaryLight dark:border-[#E44826] overflow-hidden transform transition-transform duration-200 group-hover:scale-105">
                                                <ImageWithFallback
                                                    src={shop.logo}
                                                    alt={shop.name}
                                                    className="h-full w-full object-cover transition-transform duration-200 group-hover:scale-110"
                                                />
                                            </div>
                                            <div className="flex flex-col items-start">
                                                <span className="font-medium text-primaryDark dark:text-[#F2F2F2] group-hover:text-primaryLight dark:group-hover:text-[#F9CDC4] transition-colors duration-200">{shop.name}</span>
                                                <span className="text-sm text-gray-500 dark:text-[#797979] transition-colors duration-200">{shop.description}</span>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}

            {isChatOpen && selectedShop && (
                <div ref={chatRef} className="fixed bottom-0 right-4 w-96 h-[500px] bg-white dark:bg-[#333333] rounded-t-lg shadow-xl flex flex-col z-50 transition-all duration-300 ease-in-out">
                    <div className="flex items-center justify-between p-4 border-b bg-gray-50 dark:bg-[#232323] dark:border-[#494949] rounded-t-lg">
                        <div className="flex flex-row items-center gap-4">
                            <div className="w-12 h-12 rounded-full border-2 border-primaryLight dark:border-[#E44826] shadow-md flex items-center justify-center overflow-hidden transform transition-transform duration-200 hover:scale-105">
                                <ImageWithFallback
                                    src={selectedShop.logo}
                                    alt={selectedShop.name}
                                    className="h-full w-full object-cover rounded-full transition-transform duration-200 hover:scale-110"
                                />
                            </div>
                            <div className="flex flex-col items-start max-w-[30vw] sm:max-w-[20vw]">
                                {isEditingName ? (
                                    <input
                                        ref={nameInputRef}
                                        type="text"
                                        value={editedName}
                                        onChange={(e) => setEditedName(e.target.value)}
                                        onBlur={handleNameEdit}
                                        onKeyDown={handleNameKeyPress}
                                        className="font-bold w-full bg-transparent border-b-2 border-primaryLight dark:border-[#E44826] focus:outline-none text-primaryDark dark:text-[#F2F2F2] transition-all duration-200"
                                    />
                                ) : (
                                    <h1
                                        className="font-bold truncate max-w-fit text-primaryDark dark:text-[#F2F2F2] cursor-pointer hover:text-primaryLight dark:hover:text-[#F9CDC4] transition-colors duration-200"
                                        onClick={() => setIsEditingName(true)}
                                    >
                                        {selectedShop.name}
                                    </h1>
                                )}
                                <p className="text-gray-500 dark:text-[#797979] text-sm truncate max-w-full transition-colors duration-200">{selectedShop.description}</p>
                            </div>
                        </div>
                    </div>

                    <div
                        ref={messagesContainerRef}
                        onScroll={handleScroll}
                        className="flex-1 p-4 overflow-y-auto bg-gray-50 dark:bg-[#232323] transition-colors duration-200"
                    >
                        {!messages.data?.data?.data || messages.data.data.data.length === 0 ? (
                            <div className="h-full flex items-center justify-center text-gray-400 dark:text-[#797979]">
                                <p>{t('chat.placeholders.empty')}</p>
                            </div>
                        ) : (
                            Array.isArray(messages.data?.data?.data) && [...messages.data.data.data].reverse().map(message => {
                                const isCurrentUser = message.sender_id === userId;
                                return (
                                    <div
                                        key={message.id}
                                        className={`mb-4 flex ${isCurrentUser ? 'justify-end' : 'justify-start'}`}
                                    >
                                        <div
                                            className={`max-w-[70%] rounded-2xl p-3 shadow-sm transform transition-all duration-200 hover:scale-[1.02] ${
                                                isCurrentUser
                                                    ? 'bg-primaryLight dark:bg-[#E44826] text-white rounded-br-none'
                                                    : 'bg-white dark:bg-[#494949] dark:text-[#F2F2F2] rounded-bl-none'
                                            } hover:shadow-md`}
                                        >
                                            <p className="break-words">{message.content}</p>
                                            <span className="text-xs opacity-75 mt-1 block">
                                                {message.created_at ? new Date(message.created_at).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) : ''}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })
                        )}
                        <div ref={messagesEndRef}/>
                    </div>

                    <form onSubmit={sendMessage} className="border-t p-4 bg-white dark:bg-[#333333] dark:border-[#494949] transition-colors duration-200">
                        <div className="flex gap-2 items-end">
                            <div className="flex-1 relative">
                                <textarea
                                    ref={inputRef}
                                    value={newMessage}
                                    onChange={handleTyping}
                                    onKeyPress={handleKeyPress}
                                    placeholder={t('chat.placeholders.message')}
                                    rows={1}
                                    className="w-full p-3 border rounded-2xl focus:outline-none focus:ring-2 focus:ring-primaryLight dark:focus:ring-[#E44826] focus:border-transparent resize-none bg-gray-50 dark:bg-[#232323] dark:text-[#F2F2F2] dark:border-[#494949] dark:placeholder-[#797979] transition-all duration-200"
                                    style={{maxHeight: '120px', minHeight: '44px'}}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
};

export default Chat;