import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {getUserInfoFromGoogleToken, googleAuthCallBack, loginUser} from "../../../services/AuthAndSignUp/API";
import Cookies from 'js-cookie';
import {GoogleLogin, GoogleOAuthProvider} from '@react-oauth/google';
import ToastError, {toastStyle} from "../../../components/Toasts/Error/ToastError";
import {toast, Toaster} from "sonner";
import {Moon, Sun , Languages,Eye,EyeOff} from 'lucide-react';
import Checkbox from "../../../components/Checkbox";
import AuthSideBarContent from "../Components/Aside/AuthSideBarContent";
import RegistrationSteps from "./RegistrationSteps";
import {usePersonalInfoContext} from "./SignUp";
import PropTypes from "prop-types";
import ForgotPassword from "../Components/ResetPassword/ForgotPassword";
import { motion } from 'framer-motion';
import {useTranslation} from "react-i18next";

const Login = () => {
    const [darkMode, setDarkMode] = useState(localStorage.getItem("darkMode") === "true");

    useEffect(() => {
        // Initial dark mode check
        const isDark = localStorage.getItem('darkMode') === 'true';
        setDarkMode(isDark);

        // Listen for dark mode changes
        const handleDarkModeChange = (e) => {
            if (e.key === 'darkMode') {
                setDarkMode(e.newValue === 'true');
            }
        };

        window.addEventListener('storage', handleDarkModeChange);

        // Watch for DOM class changes to detect dark mode toggle
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {
                    setDarkMode(document.documentElement.classList.contains('dark'));
                }
            });
        });

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['class']
        });

        // Cleanup listeners
        return () => {
            window.removeEventListener('storage', handleDarkModeChange);
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        localStorage.setItem('darkMode', darkMode);
        if (darkMode) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    }, [darkMode]);

    useEffect(() => {
        Cookies.remove("user_temp");
    }, []);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const [searchParams] = useSearchParams();
    const [forgotPassword, setForgotPassword] = useState(false);

    const [startRegistration, setStartRegistration] = useState(false);
    const {resetPersonalInfo} = usePersonalInfoContext();

    useEffect(() => {
        const signupParam = searchParams.get("signup");
        if (signupParam === "true") {
            setStartRegistration(true);
            resetPersonalInfo();
        }
    }, [resetPersonalInfo, searchParams]);

    const handleRegistration = () => {
        setStartRegistration(true);
        resetPersonalInfo();
    }

    const handleForgotPassword = () => {
        setForgotPassword(true);
    }

    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const handleLanguageChange = (selectedLanguage) => {
        i18n.changeLanguage(selectedLanguage);
        localStorage.setItem('language', selectedLanguage);
        setIsOpen(false);
    };

    if (forgotPassword){
        return (
            <main className="min-h-screen max-h-screen flex items-center justify-center h-full w-screen relative">
                <ForgotPassword/>
                <div className="flex flex-row items-center gap-4 absolute top-8 right-8">
                    <p
                        style={{display: (startRegistration || forgotPassword) ? "block" : "none"}}
                        className="text-center text-sm text-[#595959] dark:text-[#CBCBCB]"
                    >
                        {t('already_have_account')}{' '}
                        <button
                            onClick={() => {
                                setForgotPassword(false);
                                setStartRegistration(false)
                            }}
                            className="text-[#E44826] hover:underline font-medium"
                        >
                            {t('sign_in')}
                        </button>
                    </p>
                    <div className="relative">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="flex flex-row items-center gap-2 cursor-pointer"
                        >
                            <Languages/>
                            <p className="font-medium leading-none">{localStorage.getItem('language')}</p>
                        </button>

                        <motion.div
                            className="absolute top-8 overflow-hidden -left-1/2 -translate-x-1/2 bg-[#232323] dark:bg-[#333333] text-[#F2F2F2] w-fit rounded-lg shadow-lg"
                            initial={{opacity: 0, y: -20}}
                            animate={{opacity: isOpen ? 1 : 0, y: isOpen ? 0 : -20}}
                            transition={{duration: 0.3}}
                            style={{display: isOpen ? 'block' : 'none'}}
                        >
                            <ul>
                                <li
                                    className="hover:bg-[#494949] p-2 cursor-pointer"
                                    onClick={() => handleLanguageChange('fr')}
                                >
                                    Francais
                                </li>
                                <li
                                    className="hover:bg-[#494949] p-2 cursor-pointer"
                                    onClick={() => handleLanguageChange('en')}
                                >
                                    English
                                </li>
                            </ul>
                        </motion.div>
                    </div>
                    <button onClick={toggleDarkMode}>
                        {darkMode ? <Sun className="w-5 mx-2"/> : <Moon className="w-5 mx-2"/>}
                    </button>
                </div>
            </main>
        )
    }

    return (
        <>
            <GoogleOAuthProvider clientId="77114714966-e7atiagssre0ejuknlcq4hesrri0j085.apps.googleusercontent.com">
                <div className="min-h-screen max-h-screen flex">
                    <AuthSideBarContent/>
                    <main
                        className="w-screen md:w-[60vw] lg:w-[65vw] 2xl:w-[70vw] h-screen flex items-center justify-center rounded-lg space-y-6 relative">
                        {
                            startRegistration ?
                                <RegistrationSteps/>
                                :
                                <LoginForm handleForgotPassword={handleForgotPassword}
                                           handleRegistration={handleRegistration}/>
                        }
                        <div className="flex flex-row items-center gap-4 absolute top-4 right-8">
                            <p
                                style={{display: (startRegistration || forgotPassword) ? "block" : "none"}}
                                className="text-center text-sm text-[#595959] dark:text-[#CBCBCB]"
                            >
                                {t('already_have_account')}{' '}
                                <button
                                    onClick={() => setStartRegistration(false)}
                                    className="text-[#E44826] hover:underline font-medium"
                                >
                                    {t('sign_in')}
                                </button>
                            </p>
                            <div className="relative">
                                <button
                                    onClick={() => setIsOpen(!isOpen)}
                                    className="flex flex-row items-center gap-2 cursor-pointer"
                                >
                                    <Languages/>
                                    <p className="font-medium leading-none">{localStorage.getItem('language')}</p>
                                </button>

                                <motion.div
                                    className="absolute top-8 overflow-hidden -left-1/2 -translate-x-1/2 bg-[#232323] dark:bg-[#333333] text-[#F2F2F2] w-fit rounded-lg shadow-lg"
                                    initial={{ opacity: 0, y: -20 }}
                                    animate={{ opacity: isOpen ? 1 : 0, y: isOpen ? 0 : -20 }}
                                    transition={{ duration: 0.3 }}
                                    style={{ display: isOpen ? 'block' : 'none' }}
                                >
                                    <ul>
                                        <li
                                            className="hover:bg-[#494949] p-2 cursor-pointer"
                                            onClick={() => handleLanguageChange('fr')}
                                        >
                                            Francais
                                        </li>
                                        <li
                                            className="hover:bg-[#494949] p-2 cursor-pointer"
                                            onClick={() => handleLanguageChange('en')}
                                        >
                                            English
                                        </li>
                                    </ul>
                                </motion.div>
                            </div>
                            <button onClick={toggleDarkMode}>
                                {darkMode ? <Sun className="w-5 mx-2"/> : <Moon className="w-5 mx-2"/>}
                            </button>
                        </div>
                    </main>
                </div>
            </GoogleOAuthProvider>

            <Toaster toastOptions={{
                style: {
                    background: darkMode ? '#333333' : '#F2F2F2',
                    border: darkMode ? '1px solid #595959' : '1px solid #CBCBCB',
                    borderRadius: "8px",
                    color: darkMode ? '#CBCBCB' : '#232323'
                },
                duration: Infinity,
            }}/>

        </>
    );
};

export function LoginForm({handleRegistration, handleForgotPassword}) {

    const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'true');
    const {updateCurrentStep} = usePersonalInfoContext();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [pendingVerification, setPendingVerification] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const handleSuccessfulLogin = (response) => {
        Cookies.set('token', response.data.token, {expires: 30});
        Cookies.set("user", JSON.stringify(response.data.user), {expires: 30, secure: true, sameSite: 'strict'});
        const role_id = Number(response.data.user.role_id);
        const roleRoutes = {
            1: '/admin',
            2: '/admin',
            3: `/seller/${response.data.user.id}`,
            4: '/',
        };
        const from = location.state?.from || roleRoutes[role_id];
        navigate(from, {replace: true});
    };

    useEffect(() => {
        updateCurrentStep(1);
    }, [])

    const handleGoogleLoginSuccess = (credentialResponse) => {
        setLoading(true);
        const googleToken = credentialResponse.credential;
        getUserInfoFromGoogleToken(googleToken).then((response) => {
            if (response.status === 200) {
                googleAuthCallBack({user: response.data.user, provider: response.data.user.provider}).then((res) => {
                    if (res.status === 200) {
                        handleSuccessfulLogin(res);
                    }
                    setLoading(false);
                });
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                Cookies.set('user_temp', JSON.stringify(error.response.data.user), {expires: 30});
                handleRegistration();
                setLoading(false);
            }
        });
    };

    const handleLogin = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {email, password};
        loginUser(data)
            .then((response) => {
                if (response.status === 200) {
                    handleSuccessfulLogin(response);
                } else if (response.status === 403) {
                    setPendingVerification(true);
                }
                setLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    toast.error(<ToastError message="Votre compte est en cours de validation"/>, toastStyle);
                    setPendingVerification(true);
                    setLoading(false);
                } else {
                    toast.error(<ToastError message="Email ou mot de passe incorrect"/>, toastStyle);
                    setLoading(false);
                }
            });
    };

    const [remember, setRemember] = useState(false);

    const { t } = useTranslation();

    return (
        <div
            className="flex flex-col w-[90vw] xs:w-[80vw] sm:w-[65vw] md:w-[45vw] lg:w-[35vw] xl:w-[30vw] 2xl:w-[25vw] h-[calc(100vh-24px)] sm:px-10 sm:py-12 justify-center relative"
        >
            <div className="flex flex-col items-start gap-2">
                <h2 className="font-semibold xl:text-xl 2xl:text-2xl">{t('loginForm.welcomeBack')}</h2>
                <p className="text-xs xl:text-sm font-medium text-[#595959] dark:text-[#CBCBCB]">{t('loginForm.enterCredentials')}</p>
            </div>

            <div className="flex justify-center my-2 xl:my-3 2xl:my-6 flex-row items-center gap-4">
                <GoogleLogin
                    onSuccess={handleGoogleLoginSuccess}
                    onError={() => console.log('Google login failed')}
                    theme={darkMode ? "filled_black" : "outline"}
                    size="large"
                />
            </div>

            <div className="relative mb-4 2xl:mb-6 mt-4">
                <div className="absolute inset-0 flex items-center">
                    <div className="flex-grow border-t border-[#CBCBCB] dark:border-[#595959]"></div>
                    <div className="px-2">
                        <span className="px-2 text-sm text-[#595959] dark:text-[#F2F2F2]">
                          {t('loginForm.orVia')}
                        </span>
                    </div>
                    <div className="flex-grow border-t border-[#CBCBCB] dark:border-[#595959]"></div>
                </div>
            </div>

            <form onSubmit={handleLogin} className="space-y-4 xl:space-y-6">
                <div>
                    <label
                        htmlFor="email"
                        className="block text-xs xl:text-sm font-medium text-[#595959] dark:text-[#CBCBCB] mb-2"
                    >
                        {t('loginForm.emailLabel')}
                    </label>
                    <input
                        type="email"
                        id="email"
                        placeholder={t('loginForm.emailPlaceholder')}
                        className="w-full lg:h-10 xl:h-12 px-4 py-3 border bg-white dark:bg-[#494949] border-[#CBCBCB] dark:border-[#595959] focus:outline-none focus:border-primaryLight text-[#232323] dark:text-[#CBCBCB] rounded-lg shadow-sm"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>

                <div className="relative">
                    <label
                        htmlFor="password"
                        className="block text-xs xl:text-sm font-medium text-[#595959] dark:text-[#CBCBCB] mb-2"
                    >
                        {t('loginForm.passwordLabel')}
                    </label>
                    <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        placeholder={t('loginForm.passwordPlaceholder')}
                        className="w-full lg:h-10 xl:h-12 px-4 py-3 pr-12 border bg-white dark:bg-[#494949] border-[#CBCBCB] dark:border-[#595959] focus:outline-none focus:border-primaryLight text-[#232323] dark:text-[#CBCBCB] rounded-lg shadow-sm"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button
                        type="button"
                        className="absolute inset-y-0 right-4 top-1/2 -translate-y-[7px] flex items-center text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? <EyeOff size={20}/> : <Eye size={20}/>}
                    </button>
                </div>

                <div className="flex flex-row w-full items-center justify-between">
                    <div className="flex flex-row gap-2">
                        <Checkbox checkedValueState={[remember, setRemember]}/>
                        <p className="text-sm text-[#232323] dark:text-[#F2F2F2]">{t('loginForm.rememberMe')}</p>
                    </div>
                    <button
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            handleForgotPassword();
                        }}
                        className="font-medium text-[#E44826] text-sm"
                    >
                        <p>{t('loginForm.forgotPassword')}</p>
                    </button>
                </div>
                <button
                    disabled={loading}
                    type="submit"
                    className="w-full h-10 xl:h-12 flex justify-center items-center bg-[#E44826] text-white py-3 rounded xl:rounded-lg"
                >
                    {!loading ? (
                        <div className="flex flex-row gap-2 items-center">
                            <p className="font-medium text-sm xl:text-normal">{t('loginForm.loginButton')}</p>
                        </div>
                    ) : (
                        <div className="w-8">
                            <div className="dot_loader_white"></div>
                        </div>
                    )}
                </button>
            </form>

            <p className="text-center text-sm text-[#595959] dark:text-[#CBCBCB] mt-4">
                {t('loginForm.noAccount')}{' '}
                <button
                    onClick={handleRegistration}
                    className="text-[#E44826] hover:underline font-medium"
                >
                    {t('loginForm.createAccount')}
                </button>
            </p>
            <p className="text-[#797979] self-center absolute bottom-0">{t('loginForm.allRightsReserved')}</p>
        </div>
    )
}

LoginForm.propTypes = {
    handleRegistration: PropTypes.func.isRequired,
}

export default Login;