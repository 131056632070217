export default function ProductGeneralCard(service ) {
    const serviceData = service?.serivce; // Correct the typo here
    return (
        <div className="w-full h-fit flex flex-col gap-[10px] px-4 sm:px-0">
            {/* Display the service name */}
            <h1 className="text-2xl font-medium line-clamp-2 text-ellipsis overflow-hidden text-[#232323] dark:text-[#F2F2F2]">
                {serviceData?.name || 'Service Name Not Available'}
            </h1>

            {/* Rating and reviews section */}
            <div className="flex flex-row gap-2 items-center">
                <div className="flex flex-row gap-2 items-center">
                    <img src="/resources/star.svg" alt="star" />
                    <p className="font-bold text-[#232323] dark:text-[#F2F2F2]">{service?.rating || '0'}</p>
                </div>
                <p className="font-medium text-[#797979] dark:text-[#CBCBCB] text-sm">
                    ({serviceData?.reviews || 0} Avis)
                </p>
            </div>

            {/* Sub-category display */}
            <p className="px-4 py-1 border-border border rounded-lg text-sm flex items-center justify-center w-fit text-[#333333] dark:text-[#CBCBCB] dark:border-[#595959]">
                {serviceData?.sub_category?.name || 'No Sub-Category'}
            </p>

            {/* Description */}
            <p className="font-medium text-[#232323] dark:text-[#F2F2F2]">Description:</p>
            <p className="text-[#797979] dark:text-[#CBCBCB] leading-8">
                {serviceData?.description || 'No description available.'}
            </p>
        </div>
    );
}