import React, {useEffect, useState} from 'react';
import { Tab, Tabs as MuiTabs, Box } from '@mui/material';

export default function CustomTabs({ menuItems, onTabChange }) {
    const [selected, setSelected] = useState(
        menuItems.find(item => item.state === 'selected')?.id || menuItems[0]?.id || ''
    );

    const handleTabChange = (event, newValue) => {
        setSelected(newValue);
        onTabChange(newValue);
    };
    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        // Initial dark mode check
        const isDark = localStorage.getItem('darkMode') === 'true';
        setDarkMode(isDark);

        // Listen for dark mode changes
        const handleDarkModeChange = (e) => {
            if (e.key === 'darkMode') {
                setDarkMode(e.newValue === 'true');
            }
        };

        window.addEventListener('storage', handleDarkModeChange);

        // Watch for DOM class changes to detect dark mode toggle
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {
                    setDarkMode(document.documentElement.classList.contains('dark'));
                }
            });
        });

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['class']
        });

        // Cleanup listeners
        return () => {
            window.removeEventListener('storage', handleDarkModeChange);
            observer.disconnect();
        };
    }, []);

    return (
        <Box display="flex" justifyContent="center" width="100%" sx={{ py: 2 }}>
            <MuiTabs
                value={selected}
                onChange={handleTabChange}
                centered
                variant="fullWidth"
                scrollButtons="auto"
                aria-label="Custom Tabs"
                sx={{
                    borderColor: '#E44826',
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#E44826',
                    },
                    '.dark &': {
                        borderColor: '#494949',
                        '& .MuiTabs-indicator': {
                            backgroundColor: '#F9CDC4',
                        },
                    }
                }}
            >
                {menuItems.map((item) => (
                    <Tab
                        key={item.id}
                        label={
                            <Box display="flex" alignItems="center">
                                <img
                                    src={item.icon}
                                    alt={item.label}
                                    className={`w-[1.2rem] h-[1.2rem] mr-2 ${darkMode ? 'white_filter' : ''} ${
                                        selected === item.id
                                            ? 'primary_filter'
                                            : ''
                                    }`}
                                />
                                <Box
                                    component="span"
                                    sx={{
                                        display: {
                                            xs: 'none',
                                            sm: 'flex',
                                            width: 'auto',
                                        },
                                        fontSize: "12px"
                                    }}
                                >
                                    <p className="text-ellipsis whitespace-nowrap mr-2 dark:text-[#F2F2F2] ">{item.label}</p>
                                </Box>
                            </Box>
                        }
                        value={item.id}
                        aria-selected={selected === item.id ? 'true' : 'false'}
                        sx={{
                            textTransform: 'none',
                            flex: 1,
                            color: selected === item.id ? '#E44826' : '#000',
                            '&:hover': {
                                backgroundColor: '#FFF5F3',
                                color: '#E44826',
                            },
                            '&.Mui-selected': {
                                color: '#E44826',
                            },
                            '.dark &': {
                                color: selected === item.id ? '#F9CDC4' : '#F2F2F2',
                                '&:hover': {
                                    backgroundColor: '#494949',
                                    color: '#F9CDC4',
                                },
                                '&.Mui-selected': {
                                    color: '#F9CDC4',
                                }
                            }
                        }}
                    />
                ))}
            </MuiTabs>
        </Box>
    );
}