import { motion } from 'framer-motion';

export default function GenericDropDownItem({ index, value, handleClick }) {
    return (
        <motion.li
            key={index}
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.05 }}
            onClick={handleClick}
            className="cta dropdown relative z-[99] inset-0 px-4 py-2 cursor-pointer hover:bg-[#F2F2F2] dark:hover:bg-[#494949] border-b border-[#CBCBCB] dark:border-[#494949] dark:bg-[#333] w-full min-h-[40px] flex items-center"
        >
            <p className="cta dropdown text-ellipsis whitespace-nowrap text-sm font-medium text-[#232323] dark:text-[#fff]">{value}</p>
        </motion.li>
    );
}