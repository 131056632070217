import SellerOrderTable from "../../../components/SellerOrderTable/SellerOrderTable";
import {useEffect, useState} from "react";
import {useNonMarketPlaceRoutesContext} from "../../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import Cookies from "js-cookie";
import {getProfile} from "../../../services/AuthAndSignUp/API";

const OrderTable = () => {

    const [userData, setUserData] = useState(null); // State to store user data
    const {updateLoading} = useNonMarketPlaceRoutesContext();

    // Fetch profile data when the component mounts
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                // Check if the user data is already stored in the cookie
                const userCookie = Cookies.get("user");

                if (userCookie) {
                    // If user data exists in cookies, use it to set the state
                    setUserData(JSON.parse(userCookie));
                    updateLoading(false);
                } else {
                    // If user data does not exist in cookies, fetch it from the API
                    const response = await getProfile();

                    if (response.status === 200) {
                        setUserData(response.data); // Set the user data
                        // Store the fetched data in cookies to avoid future API calls
                        Cookies.set("user", JSON.stringify(response.data.user), { expires: 30 , secure: true, sameSite: 'strict'});
                    } else {
                        console.log('Error fetching profile:', response.error);
                    }
                    updateLoading(false);
                }
            } catch (error) {
                console.log('Error fetching profile:', error);
                updateLoading(false);
            }
        };

        fetchUserData();
    }, []); // Empty dependency array to run once when the component mounts

    return (
        <div className="w-full h-full border border-border dark:border-0 overflow-y-auto rounded-lg bg-white dark:bg-[#333333]">
            <SellerOrderTable maxHeight="86vh"/>
        </div>);
};

export default OrderTable;
