import {useEffect, useState} from "react";
import ShopCard from "../../../Marketplace/components/Cards/ShopCard";
import QuantityInput from "../../../../components/QuantityInput/QuantityInput";
import {formatCurrency} from "../../../../utils/utils";
import {useNavigate} from "react-router";
import {useCompareContext} from "../../../../hooks/context/context";
import {useCartPageContext} from "../../index";
import {useMarketplaceContext} from "../../../Marketplace";
import {ImageWithFallback} from "../../../../components/ImageWithFallBack/ImageWithFallBack";
import {useTranslation} from "react-i18next";

export default function CartProductItem({expanded = false, id, product}) {
    const {updateProductQuantity, removeProductFromIndex} = useCartPageContext();
    const {removeProductFromCart} = useMarketplaceContext();
    const [expand, updateExpand] = useState(expanded);
    const [quantity, setQuantity] = useState(product?.offer_product_quantity || 1);
    const [price, updatePrice] = useState(product.price);
    const [width, setWidth] = useState(0);
    const [loadingDelete, updateLoadingDelete] = useState(false);
    const [editable, setEditable] = useState(false);

    useEffect(() => {
        if (product.offer !== null && product.offer !== undefined) {
            setEditable(false)
        } else {
            setEditable(true)
        }
    }, [product.offer])

    useEffect(() => {
        setWidth(window.innerWidth)
        window.addEventListener('resize', () => {
            setWidth(window.innerWidth)
        })
        return () => {
            window.removeEventListener('resize', () => {
                setWidth(window.innerWidth)
            })
        }
    }, []);

    useEffect(() => {
        updatePrice(product.price * quantity);
        updateProductQuantity(product, quantity)
    }, [product.price, quantity, updateProductQuantity]);

    const navigate = useNavigate();
    const handleClick = (e) => {
        if (e.target.classList.contains("cta")) return;
        if (e.target.parentElement.classList.contains("cta")) return;
        navigate("/product/" + id)
    }

    const {compareProducts, addProductToCompare, removeProductFromCompare} = useCompareContext();
    const [isCompared, setIsCompared] = useState(false);
    const handleCompare = (product) => {
        if (compareProducts.find(p => Number(p.id) === Number(product.id))) {
            removeProductFromCompare(product)
        } else {
            addProductToCompare(product)
        }
    }

    useEffect(() => {
        if (compareProducts.find(p => Number(p.id) === Number(id))) {
            setIsCompared(true)
        } else {
            setIsCompared(false)
        }
    }, [compareProducts, id]);

    const [isFavorite, setIsFavorite] = useState(false);

    const {t} = useTranslation();

    return (
        <div
            onClick={handleClick}
            className="w-full h-fit p-2 flex gap-4 2xs:gap-0 flex-col 2xs:flex-row justify-between items-center border border-[#CBCBCB] dark:border-[#494949] rounded-lg relative overflow-hidden">
            {
                (product.stock_quantity === 0 || product.offer?.offer_quantity === 0) &&
                <div className="w-full h-full background-blur opacity-50 absolute top-0 left-0 z-10"></div>}
            {
                product.stock_quantity === 0 &&
                <div className="w-full h-full absolute top-0 left-0 z-[44] flex items-center justify-center">
                    <div
                        className="px-4 py-2 flex items-center justify-center bg-[#F9CDC4] dark:bg-[#333333] w-fit rounded">
                        <p className="font-medium text-[#E44826]">Produit en rupture de stock</p>
                    </div>
                </div>
            }

            {
                product?.offer?.offer_quantity === 0 &&
                <div className="w-full h-full absolute top-0 left-0 z-[44] flex items-center justify-center">
                    <div
                        className="px-4 py-2 flex items-center justify-center bg-[#F9CDC4] dark:bg-[#333333] w-fit rounded">
                        <p className="font-medium text-[#E44826]">Offre en rupture de stock</p>
                    </div>
                </div>
            }

            {(product?.offer !== null && product?.offer !== undefined) && <div
                className="min-h-12 w-[200px] bg-[#F9CDC4] dark:bg-[#333333] flex items-center justify-center absolute -left-14 top-3 transform -rotate-45">
                <p className="font-medium text-[#E44826]">{product.offer?.offer_name}</p>
            </div>}
            <div
                className="h-fit flex flex-row gap-4 items-center w-full sm:max-w-[50%]">
                <ImageWithFallback src={product.photos.length > 0 ? product.photos[0].link : "/images/brakes.png"}
                                   className="object-contain h-full rounded-lg transition-all duration-300 ease-in-out"
                                   style={{width: '100px', height: "100px"}}
                                   alt={"product"}/>
                <div className="flex flex-col gap-2 w-auto">
                    <p className="font-medium text-sm text-[#797979] dark:text-[#595959] whitespace-nowrap">SKU
                        : {product.serial_number}</p>
                    <p className="font-medium line-clamp-2 lg:line-clamp-1 text-ellipsis overflow-hidden dark:text-[#F2F2F2]">{product.name}</p>
                    <div className="hidden sm:flex flex-row items-center gap-6">
                        <p className="hidden sm:block font-medium text-[#797979] dark:text-[#595959] whitespace-nowrap">Catégorie
                            :</p>
                        <p className="px-4 py-1 border border-[#CBCBCB] dark:border-[#494949] rounded-lg text-sm flex items-center justify-center text-ellipsis whitespace-nowrap dark:text-[#F2F2F2]">
                            {product?.sub_category?.name}
                        </p>
                    </div>
                    <div className="hidden sm:flex ">
                        {expand && <ShopCard {...product.shop} />}
                    </div>
                </div>
            </div>
            <div className="w-full h-full flex flex-col justify-between items-end gap-3">
                <div className="flex flex-col gap-2 items-end w-fit">
                    <div className="flex flex-row 2xs:flex-col items-end gap-2">
                        {product?.discount_percentage ?
                            <div
                                className="flex items-center justify-center px-4 py-2 bg-[#F9CDC4] dark:bg-[#333333] rounded-lg border border-[#E44826]">
                                <p className="text-[#E44826] font-medium">-{product.discount_percentage}%</p>
                            </div>
                            :
                            null
                        }
                        <div className="flex flex-row items-center gap-2">
                            <p style={{display: product?.discount_percentage ? "block" : "none"}}
                               className="line-through font-medium text-[#797979] dark:text-[#595959]">{formatCurrency(price)}</p>
                            <p className="text-2xl font-semibold dark:text-[#F2F2F2]">{formatCurrency(product?.discount_percentage ? (price - price * (product?.discount_percentage / 100)) : price)}</p>
                        </div>
                    </div>

                    {expand &&
                        <div className="flex flex-col gap-1 items-start">
                            <p style={{display: !editable ? "none" : "block"}}
                               className="text-[#797979] dark:text-[#595959] text-sm self-start">Quantité max.
                                : {product.stock_quantity}</p>
                            {editable &&
                                <QuantityInput quantityState={[quantity, setQuantity]}
                                               maxQuantity={product.stock_quantity}
                                               editable={editable}
                                               inputWidth={"130px"}
                                />}
                            {!editable &&
                                <div className="flex flex-row items-center gap-2">
                                    <p
                                        className="text-[#797979] dark:text-[#595959] text-sm">{t("productDetails.PurchaseProduct.Quantity")} :
                                    </p>
                                    <p className="text-sm font-medium py-2 px-3 rounded bg-[#F9CDC4] dark:bg-[#333333] dark:text-[#F2F2F2]">{quantity}</p>
                                </div>
                            }
                        </div>
                    }
                </div>
                <div className="cta flex flex-row flex-wrap items-center justify-end gap-3">
                    <RectangleButton handleClick={() => updateExpand(!expand)}>
                        {
                            !expand ?
                                <img src="/resources/expand.svg" alt="expand"/>
                                :
                                <img src="/resources/collapse.svg" alt="collapse"/>
                        }
                    </RectangleButton>
                    <RectangleButton
                        handleClick={() => setIsFavorite(!isFavorite)}>
                        {
                            isFavorite ?
                                <img src="/resources/heart-active.svg" alt="heart"/>
                                :
                                <img src="/resources/heart.svg" alt="heart" className="dark_gray_filter"/>
                        }
                    </RectangleButton>
                    <RectangleButton
                        isActive={isCompared}
                        handleClick={() => handleCompare(product)}>
                        {
                            isCompared ?
                                <img src="/resources/git-compare-arrows.svg" alt="compare"
                                     className="w-5 primary_filter"/>
                                :
                                <img src="/resources/git-compare-arrows.svg" alt="compare"
                                     className="w-5 dark_gray_filter"/>
                        }
                    </RectangleButton>
                    <RectangleButton
                        style={{zIndex: 44}}
                        handleClick={() => {
                            updateLoadingDelete(true)
                            removeProductFromCart(product, product.offer)
                            removeProductFromIndex(product)
                            updateLoadingDelete(false)
                        }}
                    >
                        {loadingDelete ?
                            <div className="w-5">
                                <div className="loader_white gray_filter"></div>
                            </div>
                            :
                            <img src="/resources/delete.svg" alt="delete" className="dark_gray_filter"/>}
                    </RectangleButton>
                </div>
            </div>
        </div>
    )
}

function RectangleButton({
                             isActive = false,
                             style,
                             handleClick = () => {
                             },
                             children
                         }) {
    return (
        <div
            style={{
                borderColor: isActive ? "#E44826" : "#CBCBCB",
                backgroundColor: isActive ? "#F9CDC4" : "white",
                ...style
            }}
            onClick={handleClick}
            className="cta w-11 h-11 rounded-lg bg-white dark:bg-[#232323] flex items-center justify-center border border-[#CBCBCB] dark:border-[#494949] cursor-pointer hover:bg-[#F2F2F2] dark:hover:bg-[#333333]">
            {children}
        </div>
    )
}