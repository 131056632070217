import React, {useState, useRef} from "react";
import {ChevronLeft, ChevronRight} from "lucide-react";
import {ImageWithFallback} from "../../../../components/ImageWithFallBack/ImageWithFallBack";

export default function ProductPictures({photos = [], tiktok = null}) {
    const [selectedMedia, setSelectedMedia] = useState(photos.length > 0 ? photos[0]?.link : "");
    const videoRef = useRef(null);
    const scrollContainerRef = useRef(null);

    const isYouTubeVideo = (url) => url.includes('youtube.com') || url.includes('youtu.be');

    const getYouTubeEmbedUrl = (url) => {
        const videoId = url.includes('youtu.be')
            ? url.split('/').pop()
            : new URL(url).searchParams.get('v');
        return `https://www.youtube.com/embed/${videoId}`;
    };

    const getYouTubeThumbnail = (url) => {
        const videoId = url.includes('youtu.be')
            ? url.split('/').pop()
            : new URL(url).searchParams.get('v');
        return `https://img.youtube.com/vi/${videoId}/0.jpg`;
    };

    const handleThumbnailClick = (media) => {
        setSelectedMedia(media);
        if (videoRef.current) {
            videoRef.current.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
        }
    };

    const scrollThumbnails = (direction) => {
        if (scrollContainerRef.current) {
            const scrollAmount = scrollContainerRef.current.offsetWidth;
            scrollContainerRef.current.scrollBy({
                left: direction === 'left' ? -scrollAmount : scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    const [mediaItems, updateMediaItems] = useState(photos)

    const tiktokRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/.+$/;

    const getTikTokVideoId = (url) => {
        const match = url.match(/tiktok\.com\/(?:.*\/)?video\/(\d+)/);
        return match ? match[1] : null;
    };

    return (
        <div className='w-full h-full max-w-3xl mx-auto flex flex-col items-center gap-4'>
            {isYouTubeVideo(selectedMedia) ? (
                <div
                    className="w-full h-full aspect-video flex items-center justify-center rounded-lg overflow-hidden bg-gray-100 dark:bg-[#333333]">
                    <iframe
                        ref={videoRef}
                        src={`${getYouTubeEmbedUrl(selectedMedia)}?enablejsapi=1`}
                        className="w-full h-full"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </div>
            ) : (
                <div
                    className="w-full sm:w-[33vw] lg:w-[25vw] 2xl:w-[calc(19.5vw-4px)] h-full max-h-[50vh] flex items-center justify-center rounded-lg overflow-hidden dark:bg-[#333333]">
                    <ImageWithFallback src={selectedMedia} alt={"Selected Product"} priority={true}
                                       className={"w-full h-full object-contain"}/>
                </div>
            )}

            <div className="w-full relative">
                <button
                    style={{transform: 'translate(-50%,-50%)'}}
                    onClick={() => scrollThumbnails('left')}
                    className="absolute -left-0 top-1/2 transform -translate-y-1/2 bg-white dark:bg-[#333333] hover:bg-opacity-75 rounded-full p-1 z-10 border border-border dark:border-[#494949]"
                >
                    <ChevronLeft size={24} className="dark:text-[#F2F2F2]"/>
                </button>
                <div
                    ref={scrollContainerRef}
                    className="flex overflow-x-auto overflow-y-auto noScrollBar gap-2 py-2"
                    style={{scrollSnapType: 'x mandatory'}}
                >
                    {mediaItems.map((item, index) => (
                        item.type === "img" && !isYouTubeVideo(item.link) && !tiktokRegex.test(item.link) ? (

                            <div
                                key={index}
                                className={`flex-shrink-0 w-24 h-24 flex items-center justify-center rounded-lg overflow-hidden cursor-pointer dark:bg-[#333333] ${selectedMedia === item.src ? "ring-2 ring-blue-500" : ""}`}
                                onClick={() => handleThumbnailClick(item.link)}
                                style={{scrollSnapAlign: 'start'}}
                            >
                                <ImageWithFallback src={item.link} alt={item.id} priority={false}
                                                   className="w-full h-full object-contain"/>
                            </div>
                        ) : (
                            isYouTubeVideo(item.link)
                            &&

                            <div
                                key={index}
                                className={`flex-shrink-0 w-24 h-24 flex items-center justify-center rounded-lg overflow-hidden cursor-pointer dark:bg-[#333333] ${selectedMedia === item.src ? "ring-2 ring-blue-500" : ""}`}
                                onClick={() => handleThumbnailClick(item.link)}
                                style={{scrollSnapAlign: 'start'}}
                            >
                                <div className="relative w-full h-full">
                                    <img
                                        src={getYouTubeThumbnail(item.link)}
                                        alt={item.alt}
                                        className="w-full h-full object-contain"
                                        loading="lazy"
                                    />
                                    <div
                                        className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30">
                                        <svg className="w-8 h-8 text-white" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M6.5 3.5v13l10-6.5z"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        )
                    )).filter((item) => item !== null)}
                </div>
                <button
                    style={{transform: 'translate(-50%,-50%)'}}
                    onClick={() => scrollThumbnails('right')}
                    className="absolute -right-10 top-1/2 transform -translate-y-1/2 bg-white dark:bg-[#333333] hover:bg-opacity-75 rounded-full p-1 z-10 border border-border dark:border-[#494949]"
                >
                    <ChevronRight size={24} className="dark:text-[#F2F2F2]"/>
                </button>
            </div>
            {mediaItems.map((item, index) => (
                    tiktokRegex.test(item.link) ?
                        <iframe
                            key={index}
                            src={`https://www.tiktok.com/player/v1/${getTikTokVideoId(item.link)}`}
                            className="w-full"
                            width="100%"
                            height="100%"
                            allowFullScreen
                        />
                        :
                        null
                )
            )}
        </div>
    );
}