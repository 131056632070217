import CheckBox from "../../../../../components/Checkbox";
import {useFilterContext} from "../../../index";
import {useEffect, useState} from "react";

export default function AvisClient() {
    const [checkedState, setCheckedState] = useState(0);
    const {updateMinRating} = useFilterContext();

    useEffect(() => {
        updateMinRating(checkedState);
    }, [checkedState, updateMinRating]);

    return (
        <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row h-9 items-center justify-between">
                <p className="font-medium dark:text-[#F2F2F2]">Avis Client</p>
            </div>
            <div className="flex flex-col gap-2">
                <div className="flex flex-row items-center gap-2">
                    <CheckBox checkedValueState={[Number(checkedState) === 5, setCheckedState]} handleCheck={
                        () => {
                            console.log(checkedState)
                            if (Number(checkedState) === 5) {
                                setCheckedState(0)
                            } else {
                                setCheckedState(5)
                            }
                        }
                    }/>
                    <div className="flex flex-row items-center gap-1">
                        <img src="/resources/star.svg" alt="star" className="dark:white_filter"/>
                        <img src="/resources/star.svg" alt="star" className="dark:white_filter"/>
                        <img src="/resources/star.svg" alt="star" className="dark:white_filter"/>
                        <img src="/resources/star.svg" alt="star" className="dark:white_filter"/>
                        <img src="/resources/star.svg" alt="star" className="dark:white_filter"/>
                    </div>
                </div>
                <div className="flex flex-row items-center gap-2">
                    <CheckBox checkedValueState={[Number(checkedState) === 4, setCheckedState]}
                              handleCheck={
                                  () => {
                                      if (Number(checkedState) === 4) {
                                          setCheckedState(0)
                                      } else {
                                          setCheckedState(4)
                                      }
                                  }
                              }/>
                    <div className="flex flex-row items-center gap-1">
                        <img src="/resources/star.svg" alt="star" className="dark:white_filter"/>
                        <img src="/resources/star.svg" alt="star" className="dark:white_filter"/>
                        <img src="/resources/star.svg" alt="star" className="dark:white_filter"/>
                        <img src="/resources/star.svg" alt="star" className="dark:white_filter"/>
                        <img src="/resources/empty_star.svg" alt="star" className="dark:white_filter"/>
                    </div>
                </div>
                <div className="flex flex-row items-center gap-2">
                    <CheckBox checkedValueState={[Number(checkedState) === 3, setCheckedState]}
                              handleCheck={
                                  () => {
                                      if (Number(checkedState) === 3) {
                                          setCheckedState(0)
                                      } else {
                                          setCheckedState(3)
                                      }
                                  }
                              }/>
                    <div className="flex flex-row items-center gap-1">
                        <img src="/resources/star.svg" alt="star" className="dark:white_filter"/>
                        <img src="/resources/star.svg" alt="star" className="dark:white_filter"/>
                        <img src="/resources/star.svg" alt="star" className="dark:white_filter"/>
                        <img src="/resources/empty_star.svg" alt="star" className="dark:white_filter"/>
                        <img src="/resources/empty_star.svg" alt="star" className="dark:white_filter"/>
                    </div>
                </div>
                <div className="flex flex-row items-center gap-2">
                    <CheckBox checkedValueState={[Number(checkedState) === 2, setCheckedState]}
                              handleCheck={
                                  () => {
                                      if (Number(checkedState) === 2) {
                                          setCheckedState(0)
                                      } else {
                                          setCheckedState(2)
                                      }
                                  }
                              }/>
                    <div className="flex flex-row items-center gap-1">
                        <img src="/resources/star.svg" alt="star" className="dark:white_filter"/>
                        <img src="/resources/star.svg" alt="star" className="dark:white_filter"/>
                        <img src="/resources/empty_star.svg" alt="star" className="dark:white_filter"/>
                        <img src="/resources/empty_star.svg" alt="star" className="dark:white_filter"/>
                        <img src="/resources/empty_star.svg" alt="star" className="dark:white_filter"/>
                    </div>
                </div>
                <div className="flex flex-row items-center gap-2">
                    <CheckBox checkedValueState={[Number(checkedState) === 1, setCheckedState]}
                              handleCheck={
                                  () => {
                                      if (Number(checkedState) === 1) {
                                          setCheckedState(0)
                                      } else {
                                          setCheckedState(1)
                                      }
                                  }
                              }/>
                    <div className="flex flex-row items-center gap-1">
                        <img src="/resources/star.svg" alt="star" className="dark:white_filter"/>
                        <img src="/resources/empty_star.svg" alt="star" className="dark:white_filter"/>
                        <img src="/resources/empty_star.svg" alt="star" className="dark:white_filter"/>
                        <img src="/resources/empty_star.svg" alt="star" className="dark:white_filter"/>
                        <img src="/resources/empty_star.svg" alt="star" className="dark:white_filter"/>
                    </div>
                </div>
            </div>
        </div>
    )
}