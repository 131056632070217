import React, {useState, useRef} from 'react';
import {usePersonalInfoContext} from "../../Pages/SignUp";
import {registerUser} from "../../../../services/AuthAndSignUp/API";
import ToastError, {toastStyle} from "../../../../components/Toasts/Error/ToastError";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import {toast} from "sonner";
import Cookies from 'js-cookie';
import {Upload} from 'lucide-react';

import {useTranslation} from 'react-i18next';

const regex = /^(?=.*[a-zA-Z])(?:[a-zA-Z\d]*\d[a-zA-Z\d]*){13}$/;

const LegalDocumentsForm = ({handlePrevious, setIsSucessfull}) => {
    const {t} = useTranslation();
    const {addFieldValue, personalInfo} = usePersonalInfoContext();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        register_number: personalInfo.register_number,
        niff: personalInfo.niff,
        identity_card: personalInfo.identity_card,
    });

    const [errors, setErrors] = useState({
        register_number: '',
        niff: '',
    });

    const validateField = (name, value) => {
        let error = '';

        if (name === 'niff') {
            if (!/^\d{15}$/.test(value)) {
                error = 'Le NIFF doit contenir exactement 15 chiffres';
            }
        } else if (name === 'register_number') {
            if (!regex.test(value)) {
                error = 'Le numéro de registre doit contenir exactement une lettre et 13 chiffres';
            }
        }

        setErrors((prevErrors) => ({...prevErrors, [name]: error}));
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        setFormData({...formData, identity_card: file});
        addFieldValue("identity_card", file);
        e.target.files = null;
    };

    const handleFileRemove = () => {
        setFormData({...formData, identity_card: null});
        addFieldValue("identity_card", null);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        addFieldValue(name, value);
        setFormData({...formData, [name]: value});
        validateField(name, value);
    };

    const handleBlur = (e) => {
        const {name, value} = e.target;
        validateField(name, value);
    };

    const handleSubmit = async () => {

        if (!formData.register_number || !formData.niff || !formData.identity_card) {
            return;
        }

        const nifRegex = /^\d{15}$/;
        if (!nifRegex.test(formData.niff)) {
            toast.error(<ToastError message={t('legalDocumentsForm.validation.invalidNiff')}/>, toastStyle);
            return;
        }

        if (!regex.test(formData.register_number)) {
            toast.error(<ToastError message={t('legalDocumentsForm.validation.invalidRegisterNumber')}/>, toastStyle);
            return;
        }

        setLoading(true);
        let data = new FormData();
        data.append("register_number", formData.register_number);
        data.append("niff", formData.niff);
        data.append("identity_card", formData.identity_card);
        data.append("first_name", personalInfo.first_name);
        data.append("last_name", personalInfo.last_name);
        data.append("email", personalInfo.email);
        data.append("phone_number", personalInfo.phone_number);
        data.append("date_of_birth", personalInfo.date_of_birth);
        data.append("username", personalInfo.username);
        if (personalInfo.profile_picture instanceof File || personalInfo.profile_picture instanceof Blob) {
            data.append("profile_photo_file", personalInfo.profile_picture);
        } else {
            data.append("profile_photo", personalInfo.profile_picture);
        }
        data.append("country", personalInfo.country);
        data.append("role_id", personalInfo.role_id);
        data.append("city", personalInfo.city);
        data.append("state", personalInfo.state);
        data.append("street", personalInfo.street);
        data.append("postal_code", personalInfo.postal_code);
        data.append("password", personalInfo.password);
        data.append("confirmPassword", personalInfo.confirmPassword);

        try {
            const response = await registerUser(data);
            if (response.status === 201) {
                Cookies.remove("user_temp");
                setLoading(false);
                toast.success(<ToastSuccess message={t('legalDocumentsForm.toast.success')}/>, toastStyle);
                setIsSucessfull(true);
            } else {
                setLoading(false);
                if (response?.messages) {
                    Object.values(response.messages).forEach((errorArray) =>
                        errorArray.forEach((errorMessage) =>
                            toast.error(<ToastError message={errorMessage}/>, toastStyle)
                        )
                    );
                } else if (response.error) {
                    toast.error(<ToastError message={response.error}/>, toastStyle);
                }
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error(<ToastError message={t('legalDocumentsForm.toast.error')}/>, toastStyle);
        }
    };

    const nifRef = useRef(null);
    const registerRef = useRef(null);

    return (
        <div className="w-full h-full flex flex-col items-center justify-center p-6 space-y-4 xl:space-y-6 relative">
            <h2 className="lg:text-xl xl:text-2xl 2xl:text-3xl font-bold text-gray-800 dark:text-[#F2F2F2] text-center">
                {t('legalDocumentsForm.title')}
            </h2>
            <p className="text-xs xl:text-sm text-gray-600 dark:text-[#CBCBCB] text-center max-w-sm xl:max-w-lg">
                {t('legalDocumentsForm.description')}
            </p>

            <div className="space-y-3 xl:space-y-6 w-full max-w-sm">
                {/* Register Number */}
                <div className="space-y-2 w-full">
                    <label className="text-xs xl:text-sm font-medium text-slate-700 dark:text-[#CBCBCB]">
                        {t('legalDocumentsForm.labels.registerNumber')}
                    </label>
                    <div className="relative w-full">
                        <input
                            ref={registerRef}
                            type="text"
                            name="register_number"
                            value={formData.register_number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="w-full px-4 h-10 xl:h-12 placeholder:xl:text-normal placeholder:text-sm focus:outline-none rounded-lg border bg-white dark:bg-[#333333] border-slate-200 dark:border-[#494949] text-slate-800 dark:text-[#F2F2F2] focus:border-[#E44826] dark:focus:border-[#E44826] focus:ring-2 focus:ring-[#F9CDC4] dark:focus:ring-[#F9CDC4] transition-all duration-200"
                            placeholder={t('legalDocumentsForm.placeholders.registerNumber')}
                        />
                        <div className="px-4 py-2 absolute right-1 top-1/2 -translate-y-1/2 bg-green-50 dark:bg-[#494949] border-green-400 dark:border-[#595959] border text-green-600 dark:text-[#F2F2F2] font-medium rounded">
                            <p>{registerRef?.current?.value.split('').length || 0}</p>
                        </div>
                    </div>
                    {errors.register_number && (
                        <p className="text-xs text-red-500 dark:text-[#F9CDC4]">{errors.register_number}</p>
                    )}
                    {!errors.register_number && (
                        <p className="text-xs text-gray-500 dark:text-[#797979]">Format: 1 lettre et 13 chiffres</p>
                    )}
                </div>

                {/* NIFF */}
                <div className="space-y-2 w-full">
                    <label className="text-xs xl:text-sm font-medium text-slate-700 dark:text-[#CBCBCB]">
                        {t('legalDocumentsForm.labels.niff')}
                    </label>
                    <div className="relative w-full">
                        <input
                            ref={nifRef}
                            type="text"
                            name="niff"
                            value={formData.niff}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="w-full px-4 h-10 xl:h-12 placeholder:xl:text-normal placeholder:text-sm rounded-lg border bg-white dark:bg-[#333333] border-slate-200 dark:border-[#494949] text-slate-800 dark:text-[#F2F2F2] focus:border-[#E44826] dark:focus:border-[#E44826] focus:outline-none focus:ring-2 focus:ring-[#F9CDC4] dark:focus:ring-[#F9CDC4] transition-all duration-200"
                            placeholder={t('legalDocumentsForm.placeholders.niff')}
                        />
                        <div className="px-4 py-2 absolute right-1 top-1/2 -translate-y-1/2 bg-green-50 dark:bg-[#494949] border-green-400 dark:border-[#595959] border text-green-600 dark:text-[#F2F2F2] font-medium rounded">
                            <p>{nifRef?.current?.value.split('').length || 0}</p>
                        </div>
                    </div>
                    {errors.niff && (
                        <p className="text-xs text-red-500 dark:text-[#F9CDC4]">{errors.niff}</p>
                    )}
                    {!errors.niff && (
                        <p className="text-xs text-gray-500 dark:text-[#797979]">Format: Exactement 15 chiffres (ex: 123456789012345)</p>
                    )}
                </div>

                {/* Identity Card Upload */}
                <div className="space-y-2">
                    <label className="text-xs xl:text-sm font-medium text-slate-700 dark:text-[#CBCBCB]">
                        {t('legalDocumentsForm.labels.identityCard')}
                    </label>
                    <div className={`border-2 border-dashed rounded-lg p-6 border-slate-200 dark:border-[#494949] bg-slate-50 dark:bg-[#333333] ${formData.identity_card ? 'bg-[#F9CDC4] dark:bg-[#494949]' : ''} transition-all duration-200`}>
                        <input
                            type="file"
                            name="identity_card"
                            accept="image/*"
                            onChange={handleFileUpload}
                            className="hidden"
                            id="identity_card"
                        />
                        {!formData.identity_card ? (
                            <label htmlFor="identity_card" className="cursor-pointer">
                                <div className="flex flex-col items-center gap-2 xl:gap-3">
                                    <Upload className="w-8 xl:w-12 h-8 xl:h-12 text-slate-400 dark:text-[#797979]"/>
                                    <p className="text-sm font-medium text-slate-600 dark:text-[#CBCBCB]">
                                        {t('legalDocumentsForm.uploadInstructions')}
                                    </p>
                                    <p className="text-xs text-slate-500 dark:text-[#797979]">
                                        {t('legalDocumentsForm.uploadFileFormat')}
                                    </p>
                                </div>
                            </label>
                        ) : (
                            <div className="flex flex-col items-center gap-2">
                                <div className="flex items-center gap-2 text-slate-700 dark:text-[#CBCBCB]">
                                    <Upload className="w-5 h-5"/>
                                    <span className="font-medium text-sm">{formData.identity_card.name}</span>
                                </div>
                                <button
                                    onClick={handleFileRemove}
                                    className="text-sm text-red-500 dark:text-[#F9CDC4] hover:text-red-600 dark:hover:text-[#E44826] transition-colors"
                                >
                                    {t('legalDocumentsForm.removeFile')}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="flex justify-between absolute left-6 bottom-6 w-[calc(100%-48px)]">
                <button
                    onClick={handlePrevious}
                    className="px-4 xl:px-6 py-2 xl:py-2.5 rounded-lg border border-slate-300 dark:border-[#494949] text-slate-600 dark:text-[#CBCBCB] hover:bg-slate-100 dark:hover:bg-[#494949] transition-colors"
                >
                    <p className="text-sm xl:text-normal">{t('legalDocumentsForm.buttons.previous')}</p>
                </button>

                <button
                    disabled={loading || !formData.niff || !formData.identity_card || !formData.register_number}
                    onClick={handleSubmit}
                    className={`flex items-center gap-2 px-4 xl:px-6 py-2 xl:py-2.5 rounded-lg bg-[#E44826] text-white font-medium ${loading || !formData.niff || !formData.identity_card || !formData.register_number ? 'opacity-70 cursor-not-allowed' : 'hover:bg-[#E44826]/80'} transition-all duration-200`}
                >
                    {loading ? (
                        <div className="w-4 h-4 xl:w-5 xl:h-5 border-2 border-white border-t-transparent rounded-full animate-spin"/>
                    ) : (
                        <svg className="w-4 h-4 xl:w-5 xl:h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7"/>
                        </svg>
                    )}
                    <p className="text-sm xl:text-normal">{t('legalDocumentsForm.buttons.submit')}</p>
                </button>
            </div>
        </div>
    );
};

export default LegalDocumentsForm;
