import React, { useState} from 'react';
import { Menu, MenuItem, Button, Modal, Box } from '@mui/material';

const ProfileSection = ({
                            profileImage,
                            setProfileImage,
                            showAddress = true,
                            formDataState
                        }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [imageLink, setImageLink] = useState('');
    const [formData, setFormData] = formDataState;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfileImageChange = (e) => {
        if (e.target.files[0]) {
            const imageURL = URL.createObjectURL(e.target.files[0]);
            setProfileImage(imageURL);
            setFormData({ ...formData, profile_photo_file: e.target.files[0] });
        }
        handleClose();
    };

    const handleUploadFromLink = () => {
        setProfileImage(imageLink);
        setFormData({ ...formData, profile_photo: imageLink });
        setOpenModal(false);
        setImageLink('');
    };

    const handleInputChange = (e) => {
        const updatedData = {
            ...formData,
            [e.target.name]: e.target.value,
        };

        setFormData(updatedData);
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px',
        outline: 'none',
    };

    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className="bg-white dark:bg-[#333333] p-6 border border-gray-200 dark:border-[#494949] flex flex-col">
            <h2 className="text-2xl font-bold mb-6 dark:text-[#F2F2F2]">Informations personnelles</h2>

            <div className="flex justify-between items-center mb-6">
                <h3 className="font-semibold text-gray-500 dark:text-[#CBCBCB]">Image de profile</h3>
                <div>
                    <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        sx={{
                            backgroundColor: 'white',
                            border: '1px solid',
                            borderColor: 'gray',
                            color: 'gray',
                            fontSize: '12px',
                            paddingX: '0.5rem',
                            paddingY: '0.5rem',
                            borderRadius: '0.8rem',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: 'gray.100',
                                borderColor: 'gray.800',
                            },
                            transition: 'background-color 0.2s ease, border-color 0.2s ease',
                            '@media (prefers-color-scheme: dark)': {
                                backgroundColor: '#333333',
                                borderColor: '#494949',
                                color: '#F2F2F2'
                            }
                        }}
                    >
                        <img src="/resources/import_icon.svg" alt="Import Icon" className="w-4 h-4"/>
                        <span className="dark:text-[#F2F2F2]">Importer Une Image</span>
                    </Button>

                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem>
                            <label htmlFor="profileImageInput" className="cursor-pointer dark:text-[#F2F2F2]">
                                Importer depuis le local
                            </label>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            setOpenModal(true);
                            handleClose();
                        }}>
                            <span className="dark:text-[#F2F2F2]">Importer depuis le lien</span>
                        </MenuItem>
                    </Menu>
                </div>
            </div>

            <div className="flex items-center mb-6">
                {profileImage ? (
                    <img
                        src={profileImage}
                        alt="Profile"
                        className="w-28 h-28 rounded border dark:border-[#494949]"
                    />
                ) : (
                    <div className="w-28 h-28 bg-gray-300 dark:bg-[#494949] rounded flex items-center justify-center">
                        <img src="/resources/avatar.svg" alt="Default Avatar" className="w-28 h-28"/>
                    </div>
                )}
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleProfileImageChange}
                    className="hidden"
                    id="profileImageInput"
                />
            </div>

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{...modalStyle, '@media (prefers-color-scheme: dark)': {bgcolor: '#333333'}}}>
                    <h2 id="modal-title" className="text-xl font-bold mb-4 dark:text-[#F2F2F2]">Importer depuis un lien</h2>
                    <input
                        type="text"
                        placeholder="Entrez l'URL de l'image"
                        value={imageLink}
                        onChange={(e) => setImageLink(e.target.value)}
                        className="border p-3 w-full rounded-lg shadow-sm focus:outline-none focus:border-orange-500 transition duration-200 dark:bg-[#494949] dark:border-[#595959] dark:text-[#F2F2F2] dark:placeholder-[#797979]"
                    />
                    <div className="flex justify-end mt-4">
                        <Button
                            onClick={() => setOpenModal(false)}
                            sx={{
                                color: '#ff5722',
                                marginRight: '1rem',
                                '@media (prefers-color-scheme: dark)': {
                                    color: '#F9CDC4'
                                }
                            }}
                        >
                            Annuler
                        </Button>
                        <Button
                            onClick={handleUploadFromLink}
                            sx={{
                                backgroundColor: '#ff5722',
                                color: 'white',
                                paddingX: '1rem',
                                paddingY: '0.5rem',
                                borderRadius: '0.5rem',
                                '@media (prefers-color-scheme: dark)': {
                                    backgroundColor: '#E44826'
                                }
                            }}
                        >
                            Importer
                        </Button>
                    </div>
                </Box>
            </Modal>

            <div className="flex flex-col space-y-4 mt-6">
                <h3 className="font-semibold text-gray-500 dark:text-[#CBCBCB]">Informations personnelles</h3>
                <div className="grid grid-cols-2 gap-4">
                    <input
                        type="text"
                        name="last_name"
                        placeholder="Nom"
                        value={formData?.last_name}
                        onChange={handleInputChange}
                        className="border p-4 rounded-lg shadow-sm focus:outline-none focus:border-orange-500 transition duration-200 dark:bg-[#494949] dark:border-[#595959] dark:text-[#F2F2F2] dark:placeholder-[#797979]"
                    />
                    <input
                        type="text"
                        name="first_name"
                        placeholder="Prénom"
                        value={formData?.first_name}
                        onChange={handleInputChange}
                        className="border p-4 rounded-lg shadow-sm focus:outline-none focus:border-orange-500 transition duration-200 dark:bg-[#494949] dark:border-[#595959] dark:text-[#F2F2F2] dark:placeholder-[#797979]"
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData?.email}
                        onChange={handleInputChange}
                        className="border p-4 rounded-lg shadow-sm focus:outline-none focus:border-orange-500 transition duration-200 dark:bg-[#494949] dark:border-[#595959] dark:text-[#F2F2F2] dark:placeholder-[#797979]"
                    />
                    <input
                        type="text"
                        name="username"
                        placeholder="Nom d'utilisateur"
                        value={formData?.username}
                        onChange={handleInputChange}
                        className="border p-4 rounded-lg shadow-sm focus:outline-none focus:border-orange-500 transition duration-200 dark:bg-[#494949] dark:border-[#595959] dark:text-[#F2F2F2] dark:placeholder-[#797979]"
                    />
                    <div className="relative w-full">
                        <input type={showPassword ? "text" : "password"} id="password"
                               placeholder="Mot de passe"
                               name={"password"}
                               className="w-full border p-4 rounded-lg shadow-sm focus:outline-none focus:border-orange-500 transition duration-200 dark:bg-[#494949] dark:border-[#595959] dark:text-[#F2F2F2] dark:placeholder-[#797979]"
                               value={formData?.password}
                               onChange={handleInputChange}
                               required={true}/>
                        <button type="button" onClick={() => setShowPassword(!showPassword)}
                                className="absolute transform -translate-y-1/2 right-3 top-1/2 text-gray-600 hover:text-gray-900 dark:text-[#CBCBCB] dark:hover:text-[#F2F2F2]">
                            {showPassword ? (
                                <img src="/resources/eye-slash.svg" alt="Hide Password"
                                     className="w-5 h-5"/>
                            ) : (
                                <img src="/resources/eye.svg" alt="Show Password" className="w-5 h-5"/>
                            )}
                        </button>
                    </div>

                    <input
                        type="text"
                        name="phone_number"
                        placeholder="Numéro de téléphone"
                        value={formData?.phone_number}
                        onChange={handleInputChange}
                        className="border p-4 rounded-lg shadow-sm focus:outline-none focus:border-orange-500 transition duration-200 dark:bg-[#494949] dark:border-[#595959] dark:text-[#F2F2F2] dark:placeholder-[#797979]"
                    />
                </div>
            </div>
            {showAddress && (
                <div className="flex flex-col space-y-4 mt-6">
                    <h3 className="font-semibold text-gray-500 dark:text-[#CBCBCB]">Adresse</h3>
                    <div className="grid grid-cols-2 gap-4">
                        <input
                            type="text"
                            name="street"
                            placeholder="Rue"
                            value={formData?.street}
                            onChange={handleInputChange}
                            className="border p-4 rounded-lg shadow-sm focus:outline-none focus:border-orange-500 transition duration-200 dark:bg-[#494949] dark:border-[#595959] dark:text-[#F2F2F2] dark:placeholder-[#797979]"
                        />
                        <input
                            type="text"
                            name="city"
                            placeholder="Ville"
                            value={formData?.city}
                            onChange={handleInputChange}
                            className="border p-4 rounded-lg shadow-sm focus:outline-none focus:border-orange-500 transition duration-200 dark:bg-[#494949] dark:border-[#595959] dark:text-[#F2F2F2] dark:placeholder-[#797979]"
                        />
                        <input
                            type="text"
                            name="state"
                            placeholder="Wilaya"
                            value={formData?.state}
                            onChange={handleInputChange}
                            className="border p-4 rounded-lg shadow-sm focus:outline-none focus:border-orange-500 transition duration-200 dark:bg-[#494949] dark:border-[#595959] dark:text-[#F2F2F2] dark:placeholder-[#797979]"
                        />
                        <input
                            type="text"
                            name="postal_code"
                            placeholder="Code postal"
                            value={formData?.postal_code}
                            onChange={handleInputChange}
                            className="border p-4 rounded-lg shadow-sm focus:outline-none focus:border-orange-500 transition duration-200 dark:bg-[#494949] dark:border-[#595959] dark:text-[#F2F2F2] dark:placeholder-[#797979]"
                        />
                        <input
                            type="text"
                            name="country"
                            placeholder="Pays"
                            value={formData?.country}
                            onChange={handleInputChange}
                            className="border p-4 rounded-lg shadow-sm focus:outline-none focus:border-orange-500 transition duration-200 dark:bg-[#494949] dark:border-[#595959] dark:text-[#F2F2F2] dark:placeholder-[#797979]"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProfileSection;