import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { usePersonalInfoContext } from "../../Pages/SignUp";
import {useTranslation} from "react-i18next";

const CoordinatesForm = ({ handleNext, handlePrevious }) => {
    const { t } = useTranslation();
    const { addInfo, personalInfo } = usePersonalInfoContext();
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [formData, setFormData] = useState({
        phone_number: personalInfo.phone_number || '',
        postal_code: personalInfo.postal_code || '',
        city: personalInfo.city || '',
        country: personalInfo.country || '',
        state: personalInfo.state || '',
        street: personalInfo.street || '',
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        const darkMode = localStorage.getItem('darkMode') === 'true';
        setIsDarkMode(darkMode);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (value.trim() !== '') {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
        }
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        Object.keys(formData).forEach((field) => {
            if (!formData[field].trim()) {
                newErrors[field] = t(`coordinatesForm.fields.${field}.errorRequired`);
                valid = false;
            }
        });

        const phoneRegex = /^[0-9]{10}$/;
        if (formData.phone_number && !phoneRegex.test(formData.phone_number)) {
            newErrors.phone_number = t('coordinatesForm.fields.phone_number.errorInvalid');
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleNextClick = () => {
        if (validateForm()) {
            addInfo(formData);
            handleNext();
        }
    };

    return (
        <div className="w-full h-full flex flex-col items-center justify-center p-6 space-y-4 xl:space-y-6 relative">
            <h2 className="lg:text-xl xl:text-2xl 2xl:text-3xl font-bold text-gray-800 dark:text-[#F2F2F2] text-center">
                {t('coordinatesForm.title')}
            </h2>
            <p className="text-xs xl:text-sm text-gray-600 dark:text-[#CBCBCB] text-center max-w-lg">
                {t('coordinatesForm.description')}
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 xl:gap-4 2xl:gap-6">
                {['phone_number', 'postal_code', 'city', 'country', 'state', 'street'].map((field) => (
                    <div key={field} className="relative">
                        <label
                            htmlFor={field}
                            className="block text-sm xl:text-normal font-semibold xl:mb-2 text-gray-600 dark:text-[#CBCBCB]"
                        >
                            {t(`coordinatesForm.fields.${field}.label`)}
                        </label>
                        <input
                            id={field}
                            type={field === 'phone_number' ? 'tel' : 'text'}
                            name={field}
                            placeholder={t(`coordinatesForm.fields.${field}.placeholder`)}
                            className={`w-full px-3 h-10 xl:h-12 border rounded focus:ring-2 focus:outline-none 
                                ${errors[field] ? 'border-red-500 focus:ring-red-500' : 'focus:ring-[#E44826]'}
                                bg-white dark:bg-[#494949] text-gray-700 dark:text-[#F2F2F2] 
                                border-gray-300 dark:border-[#595959]`}
                            value={formData[field]}
                            onChange={handleChange}
                            aria-describedby={`${field}-error`}
                        />
                        {errors[field] && (
                            <p id={`${field}-error`} className="text-red-500 dark:text-[#F9CDC4] text-sm mt-1">
                                {errors[field]}
                            </p>
                        )}
                    </div>
                ))}
            </div>

            <div className="flex justify-between absolute left-6 bottom-6 w-[calc(100%-48px)]">
                <button
                    onClick={handlePrevious}
                    className="px-4 xl:px-6 py-2 xl:py-2.5 rounded-lg border border-slate-300 dark:border-[#595959] text-slate-600 dark:text-[#CBCBCB] hover:bg-slate-100 dark:hover:bg-[#494949] transition-colors"
                >
                    {t('coordinatesForm.buttons.previous')}
                </button>

                <button
                    onClick={handleNextClick}
                    className={`px-4 xl:px-6 h-10 xl:h-12 flex justify-center items-center bg-[#E44826] text-white py-2 xl:py-3 rounded-lg font-medium hover:bg-primaryDark transition duration-300 gap-4 ${
                        !Object.values(formData).every((value) => value.trim()) ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                >
                    {t('coordinatesForm.buttons.next')}
                    <img src="/resources/arrow_right.svg" alt="Next" className="white_filter w-3 xl:w-4"/>
                </button>
            </div>
        </div>
    );
};

export default CoordinatesForm;