import React, {useEffect, useState, useCallback} from 'react';
import {Alert, Avatar, Box, Modal} from '@mui/material';
import CustomTabs from '../Tabs/Tabs';
import DataTable from '../dataTable/DataTable';
import {
    createSubscription,
    getSubscriptions,
    getShopSubscriptions,
    changeShopSubscriptionStatus,
    getSubscriptionCancellations,
    SubscriptionCancellationsStatus,
    updateSubscription,
    deleteSubscription
} from '../../../../services/AdminSapce/API';
import ToastError, {toastStyle} from "../../../../components/Toasts/Error/ToastError";
import {toast} from "sonner";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import GenericDropDown from "../../../../components/GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../../../../components/GenericDropDown/GenericDropDownItem";
import {useTranslation} from 'react-i18next';
import {useDeleteConfirmationContext} from '../../../../components/DeleteConfirmation/DeleteConfirmation';
import {useNonMarketPlaceRoutesContext} from '../../../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes';

const VerificationStatus = ({status, url}) => {
    const {t} = useTranslation();

    return (
        <>
            {(status === t('adminPages.subscription.status.noReceipt') || status === null) ? (
                <div
                    className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-red-100 dark:bg-red-900/20 border border-red-500 dark:border-red-500/50'>
                    <p className="text-sm font-medium text-red-500 dark:text-red-400">{status === null ? t('adminPages.subscription.status.noReceipt') : status}</p>
                </div>
            ) : (
                <div
                    className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-green-100 dark:bg-green-900/20 border border-green-500 dark:border-green-500/50'>
                    <p className="text-sm font-medium text-green-500 dark:text-green-400">
                        {url ? (
                            <a onClick={() => window.open(`${url}`, '_blank')}>
                                {status}
                            </a>
                        ) : (
                            status
                        )}
                    </p>
                </div>
            )}
        </>
    );
};

const StatusBadge = ({status}) => {
    if (status === 'pending') {
        return (
            <div
                className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-yellow-100 dark:bg-yellow-900/20 border border-yellow-500 dark:border-yellow-500/50'>
                <p className="text-sm font-medium text-yellow-500 dark:text-yellow-400">{status}</p>
            </div>
        );
    } else if (status === 'canceled') {
        return (
            <div
                className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-red-100 dark:bg-red-900/20 border border-red-500 dark:border-red-500/50'>
                <p className="text-sm font-medium text-red-500 dark:text-red-400">{status}</p>
            </div>
        );
    } else {
        return (
            <div
                className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-green-100 dark:bg-green-900/20 border border-green-500 dark:border-green-500/50'>
                <p className="text-sm font-medium text-green-500 dark:text-green-400">{status}</p>
            </div>
        );
    }
};

export default function Subscription() {
    const {t} = useTranslation();
    const {updateDeletePopUpVisible, updateItemInfo, updateDeleteFunc} = useDeleteConfirmationContext();
    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();
    const [currentTab, setCurrentTab] = useState('plans');
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [formData, setFormData] = useState({name: '', price: '', description: '', duration_days: ''});
    const [selectedRow, setSelectedRow] = useState(null);
    const [justification, setJustification] = useState('');
    const [selectedAll, setSelectedAll] = useState([]);
    const [allSubscriptions, setAllSubscriptions] = useState([]);

    const handleDelete = useCallback((id, name) => {
        const func = async (id) => {
            try {
                await deleteSubscription(id);
                toast.success(<ToastSuccess message={t('adminPages.subscription.success.planDeleted')}/>, toastStyle);
                fetchData(currentTab);
            } catch (error) {
                toast.error(<ToastError message={t('adminPages.subscription.errors.deletePlan')}/>, toastStyle);
            } finally {
                updateDeletePopUpVisible(false);
                updatePopUpVisible(false);
                updateItemInfo("", "", "");
            }
        };

        updateDeleteFunc(() => func(id));
        updateDeletePopUpVisible(true);
        updatePopUpVisible(true);
        updateItemInfo(name, t('subscription'), t('adminPages.subscription.labels.deleteSubscription'));
    }, [currentTab, t, updateDeleteFunc, updateDeletePopUpVisible, updateItemInfo, updatePopUpVisible]);

    const menuItems = [
        {id: 'plans', label: t('adminPages.subscription.tabs.plans'), icon: '/resources/plans.svg'},
        {
            id: 'shopSubscriptions',
            label: t('adminPages.subscription.tabs.shopSubscriptions'),
            icon: '/resources/receipt-icon.svg'
        },
        {
            id: 'allSubscribers',
            label: t('adminPages.subscription.tabs.allSubscribers'),
            icon: '/resources/subscribers.svg'
        },
        {
            id: 'subscriptionCancellation',
            label: t('adminPages.subscription.tabs.subscriptionCancellation'),
            icon: '/resources/cancel_sub.svg'
        },
    ];

    const getColumns = useCallback((tab) => {
        if (tab === 'plans') {
            return [
                {field: 'name', headerName: t('adminPages.subscription.labels.planName'), flex: 1, minWidth: 250},
                {field: 'price', headerName: t('adminPages.subscription.labels.price'), flex: 0.6, minWidth: 150},
                {field: 'description', headerName: t('adminPages.subscription.labels.description'), flex: 1.2, minWidth: 300},
                {field: 'duration_days', headerName: t('adminPages.subscription.labels.durationDays'), flex: 0.6, minWidth: 150},
                {
                    field: 'actions',
                    headerName: '',
                    flex: 0.6,
                    minWidth: 150,
                    renderCell: (params) => (
                        <Box display="flex">
                            <GenericDropDown>
                                <GenericDropDownItem value={t('adminPages.productConfig.buttons.modify')}
                                                     index={0}
                                                     handleClick={() => {
                                                         setFormData({
                                                             name: params.row.name,
                                                             price: params.row.price,
                                                             description: params.row.description,
                                                             duration_days: params.row.duration_days
                                                         });
                                                         setSelectedRow(params.row);
                                                         setOpenAddDialog(true);
                                                     }}/>
                                <GenericDropDownItem value={t('adminPages.productConfig.buttons.delete')}
                                                     index={1}
                                                     handleClick={() => {
                                                         handleDelete(params.row.id, params.row.name);
                                                     }}/>
                            </GenericDropDown>
                        </Box>
                    ),
                },
            ];
        } else if (tab === 'shopSubscriptions' || tab === 'allSubscribers') {
            return [
                {
                    field: 'shop_name',
                    headerName: t('adminPages.subscription.labels.shopName'),
                    flex: 1.2,
                    minWidth: 300,
                    renderCell: (params) => (
                        <Box display="flex" alignItems="center">
                            <Avatar alt={params.row.shop_name} src={params.row.avatarUrl} style={{marginRight: 8}}/>
                            {params.row.shop_name}
                        </Box>
                    ),
                },
                {field: 'subscription_name', headerName: t('adminPages.subscription.labels.subscriptionName'), flex: 1, minWidth: 200},
                {field: 'sellerName', headerName: t('adminPages.subscription.labels.FullName'), flex: 0.8, minWidth: 150},
                {field: 'sellerPhone', headerName: t('adminPages.subscription.labels.NumTel'), flex: 0.8, minWidth: 150},
                {
                    field: 'receipt',
                    headerName: t('adminPages.subscription.labels.receipt'),
                    flex: 1,
                    minWidth: 200,
                    renderCell: (params) => <VerificationStatus status={params.row.receipt}
                                                                url={params.row.receiptUrl}/>
                },
                {
                    field: 'status',
                    headerName: t('adminPages.subscription.labels.status'),
                    flex: 0.8,
                    minWidth: 150,
                    renderCell: (params) => <StatusBadge status={params.row.status}/>
                },
                ...(tab === 'shopSubscriptions' ? [{
                    field: 'actions',
                    headerName: '',
                    flex: 0.6,
                    minWidth: 150,
                    renderCell: (params) => (
                        <Box display="flex">
                            <GenericDropDown>
                                <GenericDropDownItem
                                    value={t('adminPages.subscription.labels.approve')}
                                    handleClick={() => handleApprove(params.row)}
                                />
                                <GenericDropDownItem
                                    value={t('adminPages.subscription.labels.reject')}
                                    index={0}
                                    handleClick={() => handleRejectClick(params.row)}/>
                            </GenericDropDown>
                        </Box>
                    ),
                }] : []),
            ];
        } else if (tab === 'subscriptionCancellation') {
            return [
                {
                    field: 'shop_name',
                    headerName: t('adminPages.subscription.labels.shopName'),
                    flex: 1.2,
                    minWidth: 300,
                    renderCell: (params) => (
                        <Box display="flex" alignItems="center">
                            <Avatar alt={params.row.shop_name} src={params.row.avatarUrl} style={{marginRight: 8}}/>
                            {params.row.shop_name}
                        </Box>
                    ),
                },
                {
                    field: 'subscription_name',
                    headerName: t('adminPages.subscription.labels.subscriptionName'),
                    flex: 1,
                    minWidth: 200
                },
                {field: 'seller_reason', headerName: t('adminPages.subscription.labels.sellerReason'), flex: 1.2, minWidth: 300},
                {
                    field: 'actions',
                    headerName: '',
                    flex: 0.6,
                    minWidth: 150,
                    renderCell: (params) => (
                        <Box display="flex">
                            <GenericDropDown>
                                <GenericDropDownItem
                                    value={t('adminPages.subscription.labels.reject')}
                                    index={0}
                                    handleClick={() => handleRejectClick(params.row)}/>
                                <GenericDropDownItem
                                    value={t('adminPages.subscription.labels.approve')}
                                    handleClick={() => handleApprove(params.row)}
                                />
                            </GenericDropDown>
                        </Box>
                    ),
                },
            ];
        }
        return [];
    }, [handleDelete, t]);

    const fetchData = useCallback(async (tab) => {
        setLoading(true);
        setError(null);
        setSuccess(null);
        setColumns(getColumns(tab));
        try {
            let response;
            switch(tab) {
                case 'plans':
                    response = await getSubscriptions();
                    setRows(response?.map(plan => ({
                        id: plan.id,
                        name: plan.name,
                        price: plan.price,
                        description: plan.description,
                        duration_days: plan.duration_days,
                    })) || []);
                    break;

                case 'shopSubscriptions':
                    response = await getShopSubscriptions();
                    setAllSubscriptions(response || []);
                    setRows(response
                        ?.filter(sub => sub.status === 'pending')
                        .map(sub => ({
                            id: sub.id,
                            shop_name: sub?.shop?.name,
                            avatarUrl: sub?.shop?.logo,
                            subscription_name: sub?.subscription?.name,
                            sellerName: `${sub?.shop?.seller?.user?.first_name}  ${sub?.shop?.seller?.user?.last_name}`,
                            sellerPhone: sub?.shop?.seller?.user?.contact?.phone_number,
                            receipt: sub.receipt !== null ? t('adminPages.subscription.status.viewReceipt') : t('adminPages.subscription.status.noReceipt'),
                            receiptUrl: sub.receipt ? (process.env.REACT_APP_API_URL === "/" ? window.location.origin + sub.receipt : `${process.env.REACT_APP_API_URL.replace(/\/+$/, "")}/${sub.receipt.replace(/^\/+/, "")}`) : null,
                        })) || []);
                    break;

                case 'allSubscribers':
                    response = await getShopSubscriptions();
                    setAllSubscriptions(response || []);
                    setRows(response
                        ?.filter(sub => sub.status !== 'rejected')
                        .map(sub => ({
                            id: sub.id,
                            shop_name: sub?.shop?.name,
                            avatarUrl: sub?.shop?.logo,
                            subscription_name: sub?.subscription?.name,
                            sellerName: `${sub?.shop?.seller?.user?.first_name}  ${sub?.shop?.seller?.user?.last_name}`,
                            sellerPhone: sub?.shop?.seller?.user?.contact?.phone_number,
                            receipt: sub.receipt !== null ? t('adminPages.subscription.status.viewReceipt') : t('adminPages.subscription.status.noReceipt'),
                            receiptUrl: sub.receipt ? (process.env.REACT_APP_API_URL === "/" ? window.location.origin + sub.receipt : `${process.env.REACT_APP_API_URL.replace(/\/+$/, "")}/${sub.receipt.replace(/^\/+/, "")}`) : null,
                            status: sub.status
                        })) || []);
                    break;

                case 'subscriptionCancellation':
                    response = await getSubscriptionCancellations();
                    setRows(response?.filter(sub => sub.status === 'pending')
                        .map(sub => ({
                            id: sub.id,
                            shop_name: sub?.shop_subscription?.shop?.name,
                            avatarUrl: sub?.shop_subscription?.shop?.logo,
                            subscription_name: sub?.shop_subscription?.subscription?.name,
                            seller_reason: sub?.seller_reason,
                        })) || []);
                    break;
            }
        } catch (err) {
            toast.error(<ToastError message={t('adminPages.subscription.errors.fetchData')}/>, toastStyle);
            setError('Failed to fetch data');
            setRows([]);
        } finally {
            setLoading(false);
        }
    }, [getColumns, t]);

    const handleApprove = useCallback(async (row) => {
        try {
            if (currentTab === 'subscriptionCancellation') {
                await SubscriptionCancellationsStatus(row.id, 'accepted', "Votre désabonnement est accepté");
                toast.success(<ToastSuccess
                    message={t('adminPages.subscription.status.cancellationRejected')}/>, toastStyle);
            } else {
                await changeShopSubscriptionStatus([row.id], 'approved', "Votre désabonnement est accepté");
                toast.success(<ToastSuccess
                    message={t('adminPages.subscription.status.subscriptionRejected')}/>, toastStyle);
            }
            await fetchData(currentTab);
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.subscription.errors.updateStatus')}/>, toastStyle);
            setError('Failed to update status');
        }
    }, [currentTab, fetchData, t]);

    const handleApproveAll = useCallback(async (ids) => {
        try {
            await changeShopSubscriptionStatus(ids, 'approved', "Votre abonnement est accepté");
            toast.success(<ToastSuccess
                message={t('adminPages.subscription.status.subscriptionApproved')}/>, toastStyle);
            await fetchData(currentTab);
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.subscription.errors.updateStatus')}/>, toastStyle);
            setError('Failed to update status');
        }
    }, [currentTab, fetchData, t]);

    const handleUpdate = useCallback(async () => {
        try {
            await updateSubscription(selectedRow.id, formData);
            await fetchData(currentTab);
            toast.success(<ToastSuccess message={t('adminPages.subscription.success.planUpdated')}/>, toastStyle);
            setOpenAddDialog(false);
            setFormData({name: '', price: '', description: '', duration_days: ''});
            setSelectedRow(null);
        } catch (error) {
            if (error?.response?.status === 400) {
                console.log(error);
                toast.error(<ToastError message={t('adminPages.subscription.errors.updatePlan')}/>, toastStyle);
            }
        }
    }, [currentTab, fetchData, formData, selectedRow, t]);

    const handleRejectClick = useCallback((row) => {
        setSelectedRow(row);
        setOpenRejectDialog(true);
    }, []);

    const handleRejectAll = useCallback(async (ids) => {
        try {
            await changeShopSubscriptionStatus(ids, 'rejected', "Votre abonnement est rejeté");
            await fetchData(currentTab);
            toast.success(<ToastSuccess
                message={t('adminPages.subscription.status.subscriptionRejected')}/>, toastStyle);
            setOpenRejectDialog(false);
            setJustification('');
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.subscription.errors.updateStatus')}/>, toastStyle);
            setError('Failed to reject subscription.');
        }
    }, [currentTab, fetchData, t]);

    const handleRejectSubmit = useCallback(async () => {
        if (!justification) {
            toast.error(<ToastError message={t('adminPages.subscription.errors.justificationRequired')}/>, toastStyle);
            return;
        }
        try {
            if (currentTab === 'subscriptionCancellation') {
                await SubscriptionCancellationsStatus(selectedRow.id, 'rejected', justification);
                toast.success(<ToastSuccess
                    message={t('adminPages.subscription.status.cancellationRejected')}/>, toastStyle);
            } else {
                await changeShopSubscriptionStatus(selectedRow.id, 'rejected', justification);
                toast.success(<ToastSuccess
                    message={t('adminPages.subscription.status.subscriptionRejected')}/>, toastStyle);
            }
            await fetchData(currentTab);
            setOpenRejectDialog(false);
            setJustification('');
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.subscription.errors.updateStatus')}/>, toastStyle);
            setError('Failed to reject subscription.');
        }
    }, [currentTab, fetchData, justification, selectedRow, t]);

    const handleTabChange = useCallback((tabId) => {
        if (tabId !== currentTab) { // Only update if tab actually changed
            setCurrentTab(tabId);
            localStorage.setItem('currentTab', tabId);
            fetchData(tabId);
        }
    }, [currentTab, fetchData]);

    const handleAddPlan = useCallback(async () => {
        try {
            await createSubscription(formData);
            toast.success(<ToastSuccess message={t('adminPages.subscription.success.planAdded')}/>, toastStyle);
            setOpenAddDialog(false);
            setFormData({name: '', price: '', description: '', duration_days: ''});
            await fetchData('plans');
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.subscription.errors.addPlan')}/>, toastStyle);
            setError('Failed to add plan');
        }
    }, [fetchData, formData, t]);

    useEffect(() => {
        fetchData(currentTab);
    }, []); // Only run on mount

    return (
        <Box className="w-full h-full flex flex-col">
            <Box p={1}>
                <CustomTabs menuItems={menuItems} onTabChange={handleTabChange}/>
            </Box>
            <Box p={1} flex="1" display="flex" flexDirection="column">
                {loading ? (
                    <div className="flex items-center justify-center w-full h-full">
                        <div className="loader"></div>
                    </div>
                ) : (
                    <>
                        {error && <Alert severity="error" onClose={() => setError(null)}>{error}</Alert>}
                        {success && <Alert severity="success" onClose={() => setSuccess(null)}>{success}</Alert>}
                        <DataTable
                            key={currentTab}
                            rows={rows}
                            columns={columns}
                            showExportImport={false}
                            showActions={false}
                            showAddButton={currentTab === 'plans'}
                            onAddRow={() => {
                                setFormData({name: '', price: '', description: '', duration_days: ''});
                                setSelectedRow(null);
                                setOpenAddDialog(true);
                            }}
                            onSelectionChange={(selected) => console.log('Selected:', setSelectedAll(selected))}
                            handleApproveAll={() => handleApproveAll(selectedAll)}
                            handleRejectAll={() => handleRejectAll(selectedAll)}
                            showSelectAll={currentTab === 'shopSubscriptions'}
                            showApproveRejectButtons={currentTab === 'shopSubscriptions'}
                            tabLabel={menuItems.find((item) => item.id === currentTab)?.label}
                        />
                    </>
                )}
            </Box>

            {/* Add/Edit Plan Dialog */}
            <Modal open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
                <div
                    className="bg-white dark:bg-[#333333] rounded-lg p-6 w-full max-w-md mx-auto shadow-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <h3 className="text-lg font-semibold text-center mb-4 dark:text-[#F2F2F2]">
                        {selectedRow ? t('adminPages.productConfig.buttons.modify') : t('adminPages.subscription.labels.addPlan')}
                    </h3>

                    <div className="mb-4">
                        <label
                            className="block text-gray-600 dark:text-[#CBCBCB] mb-1 text-sm font-light">{t('adminPages.subscription.labels.planName')}</label>
                        <input
                            className="w-full border border-gray-300 dark:border-[#595959] dark:bg-[#232323] dark:text-[#F2F2F2] rounded px-3 py-2 focus:outline-none focus:border-orange-500"
                            value={formData.name}
                            onChange={(e) => setFormData({...formData, name: e.target.value})}
                        />
                    </div>

                    <div className="mb-4">
                        <label
                            className="block text-gray-600 dark:text-[#CBCBCB] mb-1 text-sm font-light">{t('adminPages.subscription.labels.price')}</label>
                        <input
                            type="number"
                            className="w-full border border-gray-300 dark:border-[#595959] dark:bg-[#232323] dark:text-[#F2F2F2] rounded px-3 py-2 focus:outline-none focus:border-orange-500"
                            value={formData.price}
                            onChange={(e) => setFormData({...formData, price: e.target.value})}
                        />
                    </div>

                    <div className="mb-4">
                        <label
                            className="block text-gray-600 dark:text-[#CBCBCB] mb-1 text-sm font-light">{t('adminPages.subscription.labels.description')}</label>
                        <textarea
                            className="w-full resize-none border border-gray-300 dark:border-[#595959] dark:bg-[#232323] dark:text-[#F2F2F2] rounded px-3 py-2 focus:outline-none focus:border-orange-500"
                            value={formData.description}
                            onChange={(e) => setFormData({...formData, description: e.target.value})}
                        />
                    </div>

                    <div className="mb-4">
                        <label
                            className="block text-gray-600 dark:text-[#CBCBCB] mb-1 text-sm font-light">{t('adminPages.subscription.labels.durationDays')}</label>
                        <input
                            type="number"
                            className="w-full border border-gray-300 dark:border-[#595959] dark:bg-[#232323] dark:text-[#F2F2F2] rounded px-3 py-2 focus:outline-none focus:border-orange-500"
                            value={formData.duration_days}
                            onChange={(e) => setFormData({...formData, duration_days: e.target.value})}
                        />
                    </div>

                    <div className="flex justify-end mt-6">
                        <button
                            onClick={() => setOpenAddDialog(false)}
                            className="mr-4 bg-gray-200 dark:bg-[#494949] text-gray-800 dark:text-[#F2F2F2] font-medium px-4 py-2 rounded"
                        >
                            {t('adminPages.subscription.labels.cancel')}
                        </button>
                        <button
                            onClick={selectedRow ? handleUpdate : handleAddPlan}
                            className="bg-orange-500 dark:bg-[#E44826] text-white dark:text-[#F2F2F2] font-medium px-4 py-2 rounded"
                        >
                            {selectedRow ? t('adminPages.productConfig.buttons.modify') : t('adminPages.subscription.labels.submit')}
                        </button>
                    </div>
                </div>
            </Modal>

            {/* Reject Subscription Dialog */}
            <Modal open={openRejectDialog} onClose={() => setOpenRejectDialog(false)}>
                <div
                    className="bg-white dark:bg-[#333333] rounded-lg p-6 w-full max-w-md mx-auto shadow-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <h3 className="text-lg font-semibold text-center mb-4 dark:text-[#F2F2F2]">{t('adminPages.subscription.labels.rejectSubscription')}</h3>
                    <textarea
                        className="w-full border border-gray-300 dark:border-[#595959] dark:bg-[#232323] dark:text-[#F2F2F2] rounded px-3 py-2 focus:outline-none focus:border-orange-500"
                        value={justification}
                        onChange={(e) => setJustification(e.target.value)}
                        placeholder="Justification"
                        rows={4}
                    />
                    <div className="flex justify-end mt-6">
                        <button
                            onClick={() => setOpenRejectDialog(false)}
                            className="mr-4 bg-gray-200 dark:bg-[#494949] text-gray-800 dark:text-[#F2F2F2] font-medium px-4 py-2 rounded"
                        >
                            {t('adminPages.subscription.labels.cancel')}
                        </button>
                        <button
                            onClick={handleRejectSubmit}
                            className="bg-orange-500 dark:bg-[#E44826] text-white dark:text-[#F2F2F2] font-medium px-4 py-2 rounded"
                        >
                            {t('adminPages.subscription.labels.reject')}
                        </button>
                    </div>
                </div>
            </Modal>
        </Box>
    );
}