import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import React, {useState, useMemo, useEffect} from "react";
import TablePaginationNavigation from "../../components/TablePaginationNavigation/TablePaginationNavigation";
import {useNonMarketPlaceRoutesContext} from "../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import {api} from "../../services/instance";
import {formatDistanceToNow} from "date-fns";
import {useDeleteConfirmationContext} from "../../components/DeleteConfirmation/DeleteConfirmation";
import {toast} from "sonner";
import ToastSuccess from "../../components/Toasts/Success/ToastSuccess";
import ToastError, {toastStyle} from "../../components/Toasts/Error/ToastError";
import CheckBox from "../../components/Checkbox";
import { useTranslation } from "react-i18next";

const ROW_HEIGHT = 73;

const calculatePageSize = (maxHeight) => {
    if (!maxHeight) return 6;

    if (maxHeight?.endsWith("vh")) {
        const vhValue = parseFloat(maxHeight);
        maxHeight = (vhValue / 100) * window.innerHeight - 197;
    }

    return Math.floor(maxHeight/ ROW_HEIGHT);
};

export default function NotificationsPage({maxHeight = "91vh"}) {
    const { t } = useTranslation();
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [pageSize,setPageSize] = useState(calculatePageSize(maxHeight));
    const [height , setHeight] = useState(maxHeight);
    const [darkMode, setDarkMode] = useState(false);
    useEffect(() => {
        // Initial dark mode check
        const isDark = localStorage.getItem('darkMode') === 'true';
        setDarkMode(isDark);

        // Listen for dark mode changes
        const handleDarkModeChange = (e) => {
            if (e.key === 'darkMode') {
                setDarkMode(e.newValue === 'true');
            }
        };

        window.addEventListener('storage', handleDarkModeChange);

        // Watch for DOM class changes to detect dark mode toggle
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {
                    setDarkMode(document.documentElement.classList.contains('dark'));
                }
            });
        });

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['class']
        });

        // Cleanup listeners
        return () => {
            window.removeEventListener('storage', handleDarkModeChange);
            observer.disconnect();
        };
    }, []);


    useEffect(() => {
        setPageSize(calculatePageSize(maxHeight));
    }, [maxHeight]);

    useEffect(() => {
        setHeight(pageSize * ROW_HEIGHT + 45 + pageSize);
    }, [pageSize]);

    const {updateLoading} = useNonMarketPlaceRoutesContext();

    const fetchNotifications = async () => {
        try {
            setLoading(true);
            updateLoading(false);
            const {data} = await api.get("/api/notifications");
            setNotifications(data);
        } catch (error) {
            console.error("Failed to fetch notifications:", error);
        } finally {
            setLoading(false);
        }
    };

    const {updateDeletePopUpVisible, updateDeleteFunc, updateItemInfo} = useDeleteConfirmationContext();
    const {updatePopUpVisible,popUpVisible} = useNonMarketPlaceRoutesContext();

    useEffect(() => {
        if (popUpVisible === false){
            setShowNotificationMessagePopUp(false)
        }
    }, [popUpVisible]);

    const handleDelete = (id, name) => {
        const func = (id) => api.delete("/api/notifications/delete", {params: {notification_ids: [id]}}).then((response) => {
            if (response.status === 200 || response.status === 204) {
                toast.success(<ToastSuccess message={t('notification.deleteSuccess')}/>, toastStyle);
                fetchNotifications();
            } else {
                toast.error(<ToastError
                    message={t('notification.deleteError')}/>, toastStyle);
            }
        }).catch((error) => {
            toast.error(<ToastError
                message={t('notification.deleteError')}/>, toastStyle);
        }).finally(() => {
            updateDeletePopUpVisible(false);
            updatePopUpVisible(false);
            updateItemInfo("", "", "");
        })

        updateDeleteFunc(() => func(id));
        updateDeletePopUpVisible(true);
        updatePopUpVisible(true);
        updateItemInfo(`${t('notification.notificationFrom')} ${name}`, "Notification", t('notification.deleteConfirmationTitle'));
    }

    useEffect(() => {
        fetchNotifications();
    }, []);

    const [language,]  = useState(localStorage.getItem("language") ? localStorage.getItem("language") : "fr");

    const data = useMemo(
        () => notifications.map((notification) => ({
            id: notification.id,
            message: language === "en" ? notification.data.message.en : notification.data.message.fr,
            type: notification.data.type,
            date: notification.created_at,
        })),
        [language, notifications]
    );

    const isSmallScreen = window.innerWidth <= 640;

    const [showNotificationMessagePopUp,setShowNotificationMessagePopUp] = useState(false);
    const [notificationMessage,setNotificationMessage] = useState("");
    const [textWidth,setTextWidth] = useState(80);
    useEffect(() => {
        if (isSmallScreen){
            setTextWidth(40);
        }
    }, [isSmallScreen]);
    const columns = useMemo(
        () => {
            const allColumns = [
                {
                    id: 'select-col',
                    header: ({table}) => (
                        <div className="w-[50px] flex justify-center">
                            <CheckBox
                                checkedValueState={[table.getIsAllRowsSelected(),]}
                                handleCheck={table.getToggleAllRowsSelectedHandler()}
                                indeterminate={table.getIsSomeRowsSelected()}
                            />
                        </div>
                    ),
                    cell: ({row}) => (
                        <div className="w-[50px] flex justify-center">
                            <CheckBox
                                checkedValueState={[row.getIsSelected(),]}
                                handleCheck={row.getToggleSelectedHandler()}
                            />
                        </div>
                    ),
                    size: 50,
                    minSize: 50,
                    maxSize: 50,
                    className: 'w-[50px]'
                },
                {
                    id:"message",
                    accessorKey: "message",
                    header: "Message",
                    cell: ({row}) => {
                        return (
                            <div className="relative">
                                <p className={`line-clamp-1 dark:text-[#F2F2F2]`}>
                                    {row.original.message}
                                </p>
                                {row.original.message?.length > textWidth && (
                                    <button
                                        className="text-blue-500 hover:underline dark:text-[#E44826]"
                                        onClick={()=>{
                                            setShowNotificationMessagePopUp(true)
                                            setNotificationMessage(row.original.message)
                                            updatePopUpVisible(true)
                                        }}
                                    >
                                        Show More
                                    </button>
                                )}
                            </div>                        )
                    }
                },
                {
                    accessorKey: "type",
                    header: "Type",
                    cell: ({row}) => {
                        return (
                            <p className="dark:text-[#F2F2F2]">{row.original.type}</p>
                        )
                    }
                },
                {
                    accessorKey: "date",
                    header: "Date",
                    cell: ({row}) => {
                        return (
                            <p className="dark:text-[#F2F2F2]">{formatDistanceToNow(new Date(row.original.date))} ago</p>
                        )
                    }
                },
                {
                    id: "actions",
                    header: "Action",
                    cell: ({row}) => {
                        return (
                            <div
                                onClick={() => handleDelete(row.original.id, row.original.type)}
                                className="min-h-10 min-w-10 max-w-10 bg-white dark:bg-[#333333] cursor-pointer border border-border dark:border-[#494949] rounded-lg flex items-center justify-center">
                                <img src="/resources/delete.svg" alt="delete"/>
                            </div>
                        )
                    }
                }
            ];
            const essentialColumns = ["select-col", "message", "actions"];
            return isSmallScreen
                ? allColumns.filter(col => essentialColumns.includes(col.id))
                : allColumns;

        }, [handleDelete, isSmallScreen, textWidth, updatePopUpVisible]
    );

    const table = useReactTable({
        data,
        columns,
        state: {
            globalFilter,
            sorting,
            rowSelection
        },
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        enableRowSelection: true,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        initialState: {
            pagination: {
                pageSize: pageSize,
                pageIndex:0
            }
        }
    });

    const [deleteLoading, updateDeleteLoading] = useState(false);
    const handleDeleteSelected = async () => {
        const ids = table.getSelectedRowModel().rows.map(row => row.original.id);
        const func = () => {
            updateDeleteLoading(true);
            api.delete("/api/notifications/delete", {params: {notification_ids: ids}}).then((response) => {
                if (response.status === 200 || response.status === 204) {
                    toast.success(<ToastSuccess message={t('notification.deleteMultipleSuccess')}/>, toastStyle);
                    fetchNotifications();
                } else {
                    toast.error(<ToastError
                        message={t('notification.deleteMultipleError')}/>, toastStyle);
                }
            }).catch((error) => {
                toast.error(<ToastError
                    message={t('notification.deleteMultipleError')}/>, toastStyle);
            }).finally(() => {
                updateDeleteLoading(false);
                updateDeletePopUpVisible(false);
                updatePopUpVisible(false);
                updateItemInfo("", "", "");
            })
        }

        updateDeleteFunc(() => func());
        updateDeletePopUpVisible(true);
        updatePopUpVisible(true);
        updateItemInfo("plusieurs notifications", "Notifications", t('notification.deleteMultipleConfirmationTitle'));
    }

    if (loading) {
        return (
            <div
                className="flex items-center justify-center w-full h-full min-h-[90vh] bg-white dark:bg-[#333333] border border-border dark:border-[#494949] rounded-lg">
                <div className="w-5">
                    <div className="loader"/>
                </div>
            </div>
        );
    }

    if (notifications.length === 0) {
        return (
            <div
                className="flex flex-col items-center justify-center w-full h-full min-h-[90vh] bg-white dark:bg-[#333333] border border-border dark:border-[#494949] rounded-lg">
                <img src="/images/undraw_no_data.svg" alt="No data" className="w-[250px] mb-8"/>
                <p className="text-lg text-gray-500 dark:text-[#CBCBCB]">{t('notification.noNotifications')}</p>
            </div>
        );
    }


    return (
        <>
            <div
                className="flex flex-col min-w-full min-h-[calc(91vh-40px)] bg-white dark:bg-[#333333] rounded-lg border border-border dark:border-[#494949] overflow-hidden items-center">
                {/* Search Header */}
                <div className="w-full flex items-center justify-between px-4 border-b border-border dark:border-[#494949] min-h-16">
                    <div className="flex flex-row gap-3 items-center">
                        <img src="/resources/bell.svg" className={`${darkMode ? 'white_filter' : ''}`} alt="bell"/>
                        <p className='font-medium dark:text-[#F2F2F2]'>{t('notification.notificationsListTitle')}</p>
                    </div>
                    <div className="flex flex-row items-center justify-center gap-4">
                        {(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && (
                            <button
                                onClick={handleDeleteSelected}
                                className="bg-primaryLight dark:bg-[#E44826] min-h-12 px-4 flex flex-row gap-2 items-center justify-center rounded hover:bg-primaryDark cursor-pointer"
                            >
                                {deleteLoading ? (
                                    <div className="w-4">
                                        <div className="loader_white"></div>
                                    </div>
                                ) : (
                                    <img src="/resources/delete-black.svg" className="white_filter" alt="delete icon"/>
                                )}
                                <p className="hidden sm:block font-medium text-white leading-none truncate">{t('notification.deleteButton')}</p>
                            </button>
                        )}
                        <div
                            className="w-[30vw] sm:w-[15vw] h-12 bg-[#FCFCFC] dark:bg-[#232323] border-[#D4D4D4] dark:border-[#494949] border rounded-lg px-2 sm:px-4 flex items-center justify-between">
                            <input
                                value={globalFilter}
                                onChange={e => setGlobalFilter(e.target.value)}
                                placeholder={t('notification.searchPlaceholder')}
                                className="w-[calc(30vw-40px)] sm:w-[calc(15vw-56px)] h-9 bg-[#FCFCFC] dark:bg-[#232323] dark:text-[#F2F2F2] border-none focus:outline-none"
                            />
                            <img src="/resources/search.svg"  className='dark:invert' alt="search"/>
                        </div>
                    </div>
                </div>

                {/* Table */}
                <div style={{maxHeight: height, minHeight: height}} className="w-full flex-grow overflow-auto">
                    <table className="w-full border-collapse table-fixed">
                        <thead className="bg-gray-100 dark:bg-[#232323] sticky top-0 z-10">
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <th
                                        key={header.id}
                                        onClick={header.column.getToggleSortingHandler()}
                                        className={`sm:px-6 py-3 text-left text-sm font-medium text-[#979797] dark:text-[#CBCBCB] border-b border-gray-200 dark:border-[#494949] cursor-pointer ${header.column.id === "actions" ? 'w-[80px] p-0' : ''}${header.column.id === "select-col" ? 'w-[50px] p-0' : ''}`}
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        {header.column.getIsSorted() && (
                                            <span>{header.column.getIsSorted() === "asc" ? " ↑" : " ↓"}</span>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody>
                        {table.getRowModel().rows.map(row => (
                            <tr key={row.id} className="hover:bg-gray-50 dark:hover:bg-[#232323]">
                                {row.getVisibleCells().map(cell => (
                                    <td
                                        key={cell.id}
                                        className={`sm:px-6 py-4 text-sm font-medium border-b border-gray-200 dark:border-[#494949] ${cell.column.id === "actions" ? 'w-[80px] p-0' : ''}${cell.column.id === "select-col" ? 'w-[50px] p-0' : ''}`}
                                    >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <TablePaginationNavigation table={table}/>
            </div>
            <div style={{display:showNotificationMessagePopUp ? "flex" : "none"}} className="z-50 w-full flex flex-col max-w-[90vw] sm:max-w-[50vw] h-fit max-h-[80vh] sm:max-h-[60vh] bg-white dark:bg-[#333333] border border-border dark:border-[#494949] rounded-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <div className="flex flex-row w-full h-12 justify-between items-center px-4 border-b border-b-border dark:border-[#494949]">
                    <p className="font-medium dark:text-[#F2F2F2]">Détails de notification</p>
                    <div onClick={()=>{
                        updatePopUpVisible(false)
                        setShowNotificationMessagePopUp(false)
                        setNotificationMessage(null)
                    }} className="flex items-center justify-center cursor-pointer">
                        <img src="/resources/close.svg" alt="close"/>
                    </div>
                </div>
                <div className='flex p-4 max-h-[calc(60vh-48px)] overflow-y-auto dark:text-[#F2F2F2]'>
                    {notificationMessage}
                </div>
            </div>
        </>
    );
}

const NotificationType = (type) =>{

}