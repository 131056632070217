import AddToFavorite from "../../../Marketplace/components/AddToFavorite";
import QuantityInput from "../../../../components/QuantityInput/QuantityInput";
import AddToCart from "../../../../components/AddToCart/AddToCart";
import BuyNowButton from "../../../../components/BuyNowButton/BuyNowButton";
import {useEffect, useState} from "react";
import {useCompareContext} from "../../../../hooks/context/context";
import {formatCurrency} from "../../../../utils/utils";
import {getProductComments} from "../../../../services/marketplace/API";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {GitCompareArrows} from "lucide-react";

export default function PurchaseProduct({id,product}) {
    const [quantity, setQuantity] = useState(1);
    const {addProductToCompare, compareProducts, removeProductFromCompare} = useCompareContext();
    const [isCompared, setIsCompared] = useState(false);
    const {t} = useTranslation()
    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        // Initial dark mode check
        const isDark = localStorage.getItem('darkMode') === 'true';
        setDarkMode(isDark);

        // Listen for dark mode changes
        const handleDarkModeChange = (e) => {
            if (e.key === 'darkMode') {
                setDarkMode(e.newValue === 'true');
            }
        };

        window.addEventListener('storage', handleDarkModeChange);

        // Watch for DOM class changes to detect dark mode toggle
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {
                    setDarkMode(document.documentElement.classList.contains('dark'));
                }
            });
        });

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['class']
        });

        // Cleanup listeners
        return () => {
            window.removeEventListener('storage', handleDarkModeChange);
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        if (compareProducts.find(p => Number(p.id) === Number(id))) {
            setIsCompared(true)
        } else {
            setIsCompared(false)
        }
    }, [compareProducts, id]);
    return (
        <div className='flex flex-col gap-2 pb-4'>
            <div
                className="w-[90vw] sm:w-[33vw] lg:w-[22vw] 2xl:w-[calc(19.5vw-4px)] px-4 pt-4 pb-6 border-border rounded-lg border flex flex-col gap-4 dark:bg-[#333333] dark:border-[#494949]">
                <div className="flex flex-row w-full justify-between items-center">
                    <div
                        className={`cursor-pointer px-3 py-1 h-[40px] flex items-center justify-center border gap-2 rounded-lg
${isCompared ? 'bg-[#F9CDC4] border-[#E44826]' : 'bg-white border-[#d4d4d4]'}
dark:bg-[#232323] dark:border-[#494949]`}
                        onClick={() => {
                            if (isCompared) {
                                removeProductFromCompare({id: id})
                            } else if (compareProducts.length < 4) {
                                addProductToCompare(product)
                            }
                        }}>
                        {isCompared ?
                            <GitCompareArrows className="primary_filter w-5"/>
                            :
                            <GitCompareArrows className="w-5"/>
                        }
                        <p className={`font-medium text-sm ${isCompared ? 'text-[#E44826]' : 'text-black'} dark:text-[#F2F2F2]`}>
                            {t("compare")}
                        </p>
                    </div>
                    <AddToFavorite id={Number(id)} ElementType={"product"}/>
                </div>
                <div
                    className="flex flex-row items-center justify-between pb-2 border-dashed border-b border-b-border dark:border-b-[#494949]">
                    <p className="text-sm text-[#797979] dark:text-[#CBCBCB]">{t("productDetails.PurchaseProduct.price")}</p>
                    <p className="font-semibold text-xl dark:text-[#F2F2F2]">{formatCurrency(product.price)}</p>
                </div>
                <AddToCart ids={[id]} quantity={product.stock_quantity}/>
                {product.stock_quantity > 0 && <BuyNowButton/>}
            </div>
            <GeneralOverview average_rating={product.average_rating}/>

        </div>
    )
}

function GeneralOverview({average_rating}) {
    const {id} = useParams();
    const [comments , updateComments ] =useState([])
    const {t} = useTranslation()
    useEffect(() => {
        getProductComments(id).then((res) => {
            if (res.status === 200){
                updateComments(res.data)
                console.log("Comments : ",comments)
            }
        }).catch((err)=>{

        })
    },[])

    return (
        <div className="flex flex-col gap-2 w-full px-4">
            <div className="flex flex-row gap-2">
                <img src="/resources/star.svg" alt="star" className="w-10"/>
                <div className="flex flex-col">
                    <div className="flex flex-row gap-1 items-center">
                        <p className="font-bold text-2xl dark:text-[#F2F2F2]">{Number(average_rating).toFixed(1)}</p>
                        <p className="text-xl font-medium text-gray-500 dark:text-[#CBCBCB]">/5</p>
                    </div>
                    <p className="text-sm dark:text-[#CBCBCB]">{t("productDetails.PurchaseProduct.GlobalRating")} {comments.length} avis</p>
                </div>
            </div>
            <div className="flex flex-col gap-4">
                <NumberOfReviewsIndicator rating={5} totalReviews={comments.length} numberOfReviews={comments.filter(comment=>comment.rating === 5).length}/>
                <NumberOfReviewsIndicator rating={4} totalReviews={comments.length} numberOfReviews={comments.filter(comment=>comment.rating === 4).length}/>
                <NumberOfReviewsIndicator rating={3} totalReviews={comments.length} numberOfReviews={comments.filter(comment=>comment.rating === 3).length}/>
                <NumberOfReviewsIndicator rating={2} totalReviews={comments.length} numberOfReviews={comments.filter(comment=>comment.rating === 2).length}/>
                <NumberOfReviewsIndicator rating={1} totalReviews={comments.length} numberOfReviews={comments.filter(comment=>comment.rating === 1).length}/>
            </div>
        </div>
    )
}

function NumberOfReviewsIndicator({rating, totalReviews,numberOfReviews}){
    return (
        <div className="flex flex-row gap-2 items-center">
            <p className="font-medium dark:text-[#F2F2F2]">{rating}</p>
            <img src="/resources/star.svg" alt="star" className="w-5"/>
            <div className="w-full relative">
                <div style={{width:(numberOfReviews / totalReviews) * 100 +"%"}}
                     className="h-2 bg-yellow-300 rounded-full absolute top-0 left-0 z-30"></div>
                <div className="w-full h-2 bg-gray-200 dark:bg-[#494949] rounded-full absolute top-0 left-0"></div>
            </div>
            <p className="font-medium text-sm text-gray-500 dark:text-[#CBCBCB]">{numberOfReviews}</p>
        </div>
    )
}