import { api } from '../instance';

const baseURL = '/api';

// Helper functions for handling success and error responses
const handleSuccess = (response) => {
    console.log('Success:', response.data);
    return response.data;
};

// Admin Stats
export async function AdminStats() {
    return api.get(`${baseURL}/admin/stats`).then(handleSuccess)
}

export async function getStatsData() {
    return api.get(`${baseURL}/stats`).then(handleSuccess)
}

// Category APIs
export const getCategories = async () => {
    return api.get(`${baseURL}/categories`).then(handleSuccess)
};

export const addCategory = async (data) => {
    return api.post(`${baseURL}/categories`, data)
};

export const updateCategory = async (id, data) => {
    return api.patch(`${baseURL}/categories/${id}`, data).then(handleSuccess)
}

export const deleteCategory = async (id) => {
    return api.delete(`${baseURL}/categories/${id}`).then(handleSuccess)
}

export const toggleMonthlyCategories = async (category_ids, set) => {
    return api.patch(`${baseURL}/toggle-monthly`, { category_ids, set }).then(handleSuccess)
};

// Subcategory APIs
export const getSubCategories = async () => {
    return api.get(`${baseURL}/sub_categories`).then(handleSuccess)
};

export const addSubCategory = async (data) => {
    return api.post(`${baseURL}/sub_categories`, data).then(handleSuccess)
};

export const showSubCategory = async (id) => {
    return api.get(`${baseURL}/sub_categories/${id}`).then(handleSuccess)
}

export const updateSubCategory = async (id, data) => {
    return api.patch(`${baseURL}/sub_categories/${id}`, data).then(handleSuccess)
}

export const deleteSubCategory = async (id) => {
    return api.delete(`${baseURL}/sub_categories/${id}`).then(handleSuccess)
}

// Brand APIs
export const getBrands = async (current_page,perPage,type,search) => {
    return api.get(`${baseURL}/vehicle-brands?page=${current_page}`,{params:{perPage:perPage,type:type,search:search}}).then(handleSuccess)
};

export const createVehiculeBrand = async (data) => {
    return api.post(`${baseURL}/vehicle-brands`, data).then(handleSuccess)
};

export const deleteBrand = async (id) => {
    return api.delete(`${baseURL}/vehicle-brands/${id}`).then(handleSuccess)
}
export const updateBrand = async (id, data) => {
    return api.patch(`${baseURL}/vehicle-brands/${id}`, data).then(handleSuccess)
}

export const getProductBrands = async () => {
    return api.get(`${baseURL}/brands/products`).then(handleSuccess)
};

export const addProductBrand = async (data, type) => {
    return api.post(`${baseURL}/brands`, data, type).then(handleSuccess)
};
export const updateProductBrand = async (id, data) => {
    return api.patch(`${baseURL}/brands/${id}`, data).then(handleSuccess)
}

export const deleteMultipleVehicleEntities = async (ids, type) => {
    return api.delete(`${baseURL}/vehicle-entities-destroy`,{data:{ids:ids,type:type}}).then(handleSuccess)
}

export const deleteProductBrand = async (id) => {
    return api.delete(`${baseURL}/brands/${id}`).then(handleSuccess)
}

// Vehicle Model APIs
export const getModels = async (current_page,perPage,type,search) => {
    return api.get(`${baseURL}/vehicle-models?page=${current_page}`, {params:{perPage:perPage,type:type,search:search}}).then(handleSuccess)
};

export const createModel = async (data) => {
    return api.post(`${baseURL}/vehicle-models`, data).then(handleSuccess)
};

export const deleteModel = async (id) => {
    return api.delete(`${baseURL}/vehicle-models/${id}`).then(handleSuccess)
}
export const updateModel = async (id, data) => {
    return api.patch(`${baseURL}/vehicle-models/${id}`, data).then(handleSuccess)
}

// Motorisation APIs
export const getMotorisations = async (current_page,perPage,type,search) => {
    return api.get(`${baseURL}/motorisation?page=${current_page}`, {params:{perPage:perPage,type:type,search:search}}).then(handleSuccess)
};

export const createMotorisation = async (data) => {
    return api.post(`${baseURL}/motorisation`, data).then(handleSuccess)
};

export const deleteMotorisation = async (id) => {
    return api.delete(`${baseURL}/motorisation/${id}`).then(handleSuccess)
}
export const updateMotorisation = async (id, data) => {
    return api.patch(`${baseURL}/motorisation/${id}`, data).then(handleSuccess)
}
// User APIs
export const getUsers = async () => {
    return api.get(`${baseURL}/users`).then(handleSuccess)
};

export const createUser = async (data) => {
    return api.post(`${baseURL}/users`, data).then(handleSuccess)
};

export const showUser = async (id) => {
    return api.get(`${baseURL}/users/${id}`).then(handleSuccess)
};

export const deleteUser = async (id) => {
    return api.delete(`${baseURL}/users/${id}`).then(handleSuccess)
};

export const changeUserStatus = async (user_ids, status, justification) => {
    return api.patch(`${baseURL}/users/status`, { user_ids, status, justification }).then(handleSuccess)
};

// Product APIs
export const getProducts = async () => {
    return api.get(`${baseURL}/all-products`).then(handleSuccess)
};

export const updateProductStatus = async (ids, status, justification) => {
    return api.patch(`${baseURL}/products/status/products`, { ids, status, justification }).then(handleSuccess)
};

export const deleteProduct = async (id) => {
    return api.delete(`${baseURL}/products/${id}`).then(handleSuccess)
}

// Service APIs
export const getServices = async () => {
    return api.get(`${baseURL}/services`).then(handleSuccess)
};

export const updateServiceStatus = async (ids, status, justification) => {
    return api.patch(`${baseURL}/services/status/services`, { ids, status, justification }).then(handleSuccess)
};

export const deleteService = async (id) => {
    return api.delete(`${baseURL}/services/${id}`).then(handleSuccess)
}

// Vehicle APIs
export const getVehicles = async (current_page,perPage,search) => {
    return api.get(`${baseURL}/vehicles?page=${current_page}`, {params:{perPage:perPage,search:search}}).then(handleSuccess)
};

export const createVehicle = async (data) => {
    return api.post(`${baseURL}/vehicles`, data).then(handleSuccess)
};

export const showVehicle = async (id) => {
    return api.get(`${baseURL}/vehicles/${id}`).then(handleSuccess)
};
export const updateVehicle = async (id, data) => {
    return api.patch(`${baseURL}/vehicles/${id}`, data).then(handleSuccess)
}

export const deleteVehicle = async (id) => {
    return api.delete(`${baseURL}/vehicles/${id}`).then(handleSuccess)
}

export const ImportVehicle = async (data) => {
    return api.post(`${baseURL}/vehicles/csv`, data).then(handleSuccess)
}

// Subscription APIs
export const getSubscriptions = async () => {
    return api.get(`${baseURL}/subscriptions`).then(handleSuccess)
};

export const getShopSubscriptions = async () => {
    return api.get(`${baseURL}/shop-subscriptions`).then(handleSuccess)
};

export const createSubscription = async (data) => {
    return api.post(`${baseURL}/subscriptions`, data).then(handleSuccess)
};

export const deleteSubscription = async (id) => {
    return api.delete(`${baseURL}/subscriptions/${id}`).then(handleSuccess)
}
export const updateSubscription = async (id,data) => {
    return api.patch(`${baseURL}/subscriptions/${id}`, data);
}
export const changeShopSubscriptionStatus = async (id, action, justification) => {
    return api.patch(`${baseURL}/subscriptions/manage/${id}`, {id, action, justification }).then(handleSuccess)
};

export const getSubscriptionCancellations = async () => {
    return api.get(`${baseURL}/subscription_cancellation/admin`).then(handleSuccess)
};

export const SubscriptionCancellationsStatus = async (id, status, justification) => {
    return api.patch(`${baseURL}/subscription_cancellation/${id}`, { status, justification }).then(handleSuccess)
};

// Condition APIs
export const getConditions = async () => {
    return api.get(`${baseURL}/conditions`).then(handleSuccess)
};

export const addCondition = async (data) => {
    return api.post(`${baseURL}/conditions`, data).then(handleSuccess)
};

export const showCondition = async (id) => {
    return api.get(`${baseURL}/conditions/${id}`).then(handleSuccess)
}

export const updateCondition = async (data) => {
    return api.patch(`${baseURL}/conditions/${data.id}`, data).then(handleSuccess)
}

export const deleteCondition = async (id) => {
    return api.delete(`${baseURL}/conditions/${id}`).then(handleSuccess)
}

// Car APIs
export const getCars = async () => {
    return api.get(`${baseURL}/all-cars`).then(handleSuccess)
};

export const updateCarStatus = async (ids, status, justification) => {
    return api.patch(`${baseURL}/cars/status/cars`, { ids, status, justification }).then(handleSuccess)
};

// Offer APIs
export const getOffers = async () => {
    return api.get(`${baseURL}/all-offers`).then(handleSuccess)
};

export const updateOfferStatus = async (ids, status, justification) => {
    return api.patch(`${baseURL}/offers/status/offers`, {ids, status, justification}).then(handleSuccess)
}

// Promotion APIs
export const getPromotions = async () => {
    return api.get(`${baseURL}/promotions`).then(handleSuccess)
}

// Engine APIs
export const getEnginBrands = async () => {
    return api.get(`${baseURL}/engins-brands`).then(handleSuccess)
}
export const updateEnginBrands = async (id,data) => {
    return api.patch(`${baseURL}/engins-brands/${id}`, data).then(handleSuccess)
}

export const getEnginCategories = async () => {
    return api.get(`${baseURL}/engins-categories`).then(handleSuccess)
}
export const updateEnginCategories = async (id,data) => {
    return api.patch(`${baseURL}/engins-categories/${id}`, data).then(handleSuccess)
}

export const getEngins = async () => {
    return api.get(`${baseURL}/engins`).then(handleSuccess)
}

export const UpdateEnginStatus = async (ids, status, justification) => {
    return api.patch(`${baseURL}/engins/status/engins`, {ids, status, justification}).then(handleSuccess)
}

// Boat APIs
export const changeBoatStatus = async (ids, status, justification) => {
    return api.patch(`${baseURL}/boats/status/boats`, {ids, status, justification}).then(handleSuccess)
}


export const redDotNotification = async () => {
    try {
        // Fetch all data in parallel
        const [validation, carsValidation] = await Promise.all([
            Promise.all([
                getProducts(),
                getServices(),
                getUsers(),
                getOffers()
            ]),
            getCars()
        ]);


        const [products, services, users, offers] = validation;


        // Check pending status for first group using the already fetched data
        const hasPendingProducts = products.filter(product => product.status === 'pending').length > 0;
        const hasPendingServices = services.services.filter(service => service.status === 'pending').length > 0;
        const hasPendingUsers = users.filter(user => user.status === 'pending').length > 0;
        const hasPendingOffers = offers.filter(offer => offer.status === 'pending').length > 0;


        // Check pending status for vehicles using optional chaining and the already fetched data
        const hasPendingCars = carsValidation.cars?.some(car => car.status === 'pending') || false;
        const hasPendingMotorcycles = carsValidation.motorcycles?.some(motorcycle => motorcycle.status === 'pending') || false;
        const hasPendingBoats = carsValidation.boats?.some(boat => boat.status === 'pending') || false;
        const hasPendingEngines = carsValidation.engins?.some(engine => engine.status === 'pending') || false;


        return {
            validation: hasPendingProducts || hasPendingServices || hasPendingUsers || hasPendingOffers,
            carsValidation: hasPendingCars || hasPendingMotorcycles || hasPendingBoats || hasPendingEngines
        };
    } catch (error) {
        console.error('Error in redDotNotification:', error);
        return {
            validation: false,
            carsValidation: false
        };
    }
}

export const GetAllReports = () => {
    return api.get(`${baseURL}/reports`)
}
export const UpdateReportStatus = async (id,status) =>{
 return api.patch(`${baseURL}/reports/${id}/status `,{status})
}
export const ReportStats = async () => {
    return api.get(`${baseURL}/reportStats`)
}















