export default function PackCardSkeleton() {
    return (
        <div className="w-fit min-w-[450px] h-full border border-border rounded-xl flex flex-col overflow-hidden animate-pulse">
            <div className="flex items-center justify-center border-b border-dashed border-b-border py-2 min-h-12 relative">
                <div className="h-4 w-1/3 bg-gray-300 rounded"></div>
                <div className="absolute -right-12 top-3 flex items-center justify-center bg-gray-300 h-6 w-[150px] rounded rotate-45"></div>
            </div>

            <div className="flex flex-col px-2 sm:px-4 py-2 gap-2 items-start w-full h-full border-dashed border-b border-b-border">
                {[...Array(2)].map((_, index) => (
                    <div key={index} className="flex flex-row items-center gap-2 w-full">
                        <div className="flex flex-row items-end justify-center gap-1 min-w-[40px] max-w-[40px]">
                            <div className="h-6 w-6 bg-gray-300 rounded"></div>
                            <div className="h-4 w-4 bg-gray-300 rounded"></div>
                        </div>
                        <div className="h-24 flex flex-row items-center justify-between p-2 gap-2 sm:gap-6 border border-gray-300 rounded-lg w-full">
                            <div className="flex flex-row gap-4 items-center">
                                <div className="w-16 h-16 bg-gray-300 rounded"></div>
                                <div className="h-4 bg-gray-300 rounded w-24"></div>
                            </div>
                            <div className="h-4 bg-gray-300 rounded w-16"></div>
                        </div>
                    </div>
                ))}
                <div className="flex items-center justify-center w-full">
                    <div className="cursor-pointer w-fit h-fit px-4 py-2 bg-gray-300 rounded"></div>
                </div>
            </div>

            <div className="flex flex-row items-center justify-end h-16 gap-2 px-4 py-2">
                <div className="flex flex-row items-center gap-2 px-4 py-2">
                    <div className="h-4 w-20 bg-gray-300 rounded"></div>
                    <div className="h-6 w-16 bg-gray-300 rounded"></div>
                </div>
                <div className="h-10 w-10 bg-gray-300 rounded"></div>
            </div>
        </div>
    );
}
