import React, { useEffect, useState } from 'react';
import {CheckCircleIcon, UserIcon, LocationMarkerIcon, LockClosedIcon, DocumentIcon} from '@heroicons/react/solid';
import ToggleButton from "../../../components/ToggleButton/ToggleButton";
import {useNavigate} from "react-router";

const Stepper = ({currentStep,selectedState}) => {
    const steps = [
        {step: 1, title: 'Informations personnelles', icon: <UserIcon className="h-5 w-5"/>},
        {step: 2, title: 'Coordonnées', icon: <LocationMarkerIcon className="h-5 w-5"/>},
        {step: 3, title: 'Mot de passe', icon: <LockClosedIcon className="h-5 w-5"/>},
        {step: 4, title: 'Document juridique', icon: <DocumentIcon className="h-5 w-5"/>},
    ];

    const [selected,]= selectedState;
    const navigate = useNavigate();
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        const darkMode = localStorage.getItem('darkMode') === 'true';
        setIsDarkMode(darkMode);
    }, []);

    return (
        <div
            className={`relative ${isDarkMode ? 'bg-[#333333]' : 'bg-white'} rounded-lg shadow-lg p-6 lg:p-8 w-full lg:w-[401px] h-full lg:h-full flex flex-row lg:flex-col overflow-hidden`}>
            <div
                className="absolute top-0 left-0 w-full h-full bg-cover bg-center opacity-10"
                style={{backgroundImage: 'url("/resources/stepperBg.svg")'}}
            ></div>
            <div
                className="relative z-10 w-full h-full flex flex-col gap-4 items-center lg:items-start justify-between">

                {isDarkMode ? <img src="/resources/white_Logo.svg"  alt="Logo"
                                   onClick={() => navigate("/")} className="w-[230px] lg:w-fit"/>
                    :
                    <img src="/resources/logo.svg" alt="Logo"
                         onClick={() => navigate("/")}
                         className="w-[300px] lg:w-fit"/>
                }
                <div className="w-full flex items-center justify-center">
                    <ToggleButton selectedState={selectedState}/>
                </div>
                <div
                    className="w-full flex flex-col sm:flex-row lg:flex-col gap-2 sm:gap-4 justify-center lg:justify-start items-start">
                    {steps.map((step, index) => {
                        if (selected === 'Particulier' && step.step === 4) {
                            return null;
                        }
                        return (
                            <div key={index} className="relative flex items-center mb-3 sm:mb-6 last:mb-0">
                                {index > 0 && (
                                    <div
                                        className={`absolute top-0 left-4 transform -translate-x-1/2 h-fit w-1 bg-gradient-to-b from-[#E44826] via-[#E44826] to-transparent
                                    ${currentStep > step.step ? 'opacity-100' : 'opacity-30'}
                                    transition-all duration-500 ease-in-out
                                    `}
                                    ></div>
                                )}
                                <div className="flex flex-row gap-2 items-center relative z-10">
                                    <div
                                        className={`flex items-center justify-center h-12 w-12 rounded-full border-2 transition duration-500 ease-in-out
                                    ${currentStep >= step.step ? 'bg-[#E44826] text-white border-[#E44826]' : isDarkMode ? 'bg-[#494949] text-[#CBCBCB] border-[#595959]' : 'bg-white text-gray-500 border-gray-300'}
                                    `}
                                    >
                                        {currentStep > step.step ? (
                                            <CheckCircleIcon className="h-6 w-6 text-white"/>
                                        ) : (
                                            step.icon
                                        )}
                                    </div>
                                    <div>
                                        <h3
                                            className={`lg:text-lg font-medium transition duration-500 ease-in-out
                                        ${currentStep >= step.step ? 'text-[#E44826]' : isDarkMode ? 'text-[#CBCBCB]' : 'text-gray-600'}
                                    `}
                                        >
                                            {step.title}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="w-full text-center">
                    <p className={`${isDarkMode ? 'text-[#CBCBCB]' : 'text-gray-600'}`}>
                        Vous avez déjà un compte?{' '}
                        <a href="/login"
                           className="text-[#E44826] font-medium hover:underline transition duration-300">
                            Se Connecter
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Stepper;