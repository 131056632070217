import {forwardRef, useEffect, useState} from "react";

const Carousel = forwardRef(({items = [], renderItem, itemsWidth = 300}, ref) => {
    const [visibleElementWidth, setVisibleElementWidth] = useState(0);
    const [itemsLength, updateItemsLength] = useState(0);
    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        // Initial dark mode check
        const isDark = localStorage.getItem('darkMode') === 'true';
        setDarkMode(isDark);

        // Listen for dark mode changes
        const handleDarkModeChange = (e) => {
            if (e.key === 'darkMode') {
                setDarkMode(e.newValue === 'true');
            }
        };

        window.addEventListener('storage', handleDarkModeChange);

        // Watch for DOM class changes to detect dark mode toggle
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {
                    setDarkMode(document.documentElement.classList.contains('dark'));
                }
            });
        });

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['class']
        });

        // Cleanup listeners
        return () => {
            window.removeEventListener('storage', handleDarkModeChange);
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        if (items?.length) {
            updateItemsLength(items.length);
        }
    }, [items]);

    useEffect(() => {
        const updateVisibleWidth = () => {
            if (ref?.current) {
                setVisibleElementWidth(Math.floor(ref.current.offsetWidth / itemsWidth));
            }
        };

        updateVisibleWidth();

        window.addEventListener("resize", updateVisibleWidth);
        return () => window.removeEventListener("resize", updateVisibleWidth);
    }, [itemsWidth, ref]);

    const [currentIndex, setCurrentIndex] = useState(0);

    const ScrollRight = () => {
        if (itemsLength > visibleElementWidth) {
            if (currentIndex < itemsLength - visibleElementWidth) {
                return setCurrentIndex((prevIndex) => prevIndex + 1);
            }
            setCurrentIndex(0);
        }
    };

    const ScrollLeft = () => {
        if (itemsLength > visibleElementWidth) {
            if (currentIndex > 0) {
                return setCurrentIndex((prevIndex) => prevIndex - 1);
            }
            setCurrentIndex(itemsLength - visibleElementWidth);
        }
    };

    return (
        <div className="flex flex-row items-center w-full relative justify-center">
            <button
                onClick={ScrollLeft}
                style={{transform: "translate(0%,-50%)", display: itemsLength > visibleElementWidth ? "flex" : "none"}}
                className="absolute top-1/2 z-40 -left-[30px] h-[50px] w-[50px] rounded-full bg-white dark:bg-[#333333] border border-border dark:border-[#494949] flex items-center justify-center cursor-pointer shadow-md"
            >
                <img src="/resources/left-line.svg" alt="left" className={`w-6 ${darkMode ? 'white_filter' : ''}`}/>
            </button>

            {/* The scrolling container with invisible scrollbar */}
            <div
                ref={ref}
                className="relative w-full overflow-x-auto overflow-y-hidden noScrollBar"
                style={{WebkitOverflowScrolling: "touch"}} // Enables smooth scrolling on mobile
            >
                <div
                    className="flex flex-row gap-2 transition-transform duration-500 ease-linear"
                    style={{transform: `translateX(-${currentIndex * itemsWidth}px)`}}
                >
                    {items?.map((item, index) => (
                        <div key={index} className="flex-none w-fit">
                            {renderItem(item, index)}
                        </div>
                    ))}
                </div>
            </div>

            <button
                onClick={ScrollRight}
                style={{transform: "translate(0%,-50%)", display: itemsLength > visibleElementWidth ? "flex" : "none"}}
                className="absolute top-1/2 z-40 -right-[30px] h-[50px] w-[50px] rounded-full bg-white dark:bg-[#333333] border border-border dark:border-[#494949] flex items-center justify-center cursor-pointer shadow-md"
            >
                <img src="/resources/right-line.svg" alt="left" className={`w-6 ${darkMode ? 'white_filter' : ''}`}/>
            </button>
        </div>
    );
});

export default Carousel;
