import React, {useState, useRef, useEffect} from "react";
import {X, Upload} from "lucide-react";
import {create} from "zustand";
import {useNonMarketPlaceRoutesContext} from "../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import {useMarketplaceContext} from "../../pages/Marketplace";
import {useParams} from "react-router";
import {useLocation} from "react-router-dom";
import {
    createReport,
    getBoatById,
    getProductById,
    getServiceById,
    getShopDataById,
    getVehicleById
} from "../../services/marketplace/API";
import {ImageWithFallback} from "../ImageWithFallBack/ImageWithFallBack";
import ToastError, {toastStyle} from "../Toasts/Error/ToastError";
import {toast} from "sonner";
import { useTranslation } from "react-i18next";

const REPORT_REASONS = [
    { id: "inappropriate_content", label: "inappropriate_content" },
    { id: "bug", label: "bug" },
    { id: "content_proposition", label: "content_proposition" },
    { id: "other", label: "other" },
];

export default function ReportBugModal() {
    const { t } = useTranslation();
    const [selected, setSelected] = useState("");
    const [description, setDescription] = useState("");
    const [imageFile, setImageFile] = useState(null);
    const [preview, setPreview] = useState("");
    const fileInputRef = useRef(null);
    const [type, setType] = useState(null);
    const {id} = useParams();
    const [reportable, setReportable] = useState(null);

    const {updateReportBugModalVisible} = useReportBugModalContext();
    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();
    const {updatePopupVisible: updateMarketplacePopup} = useMarketplaceContext();

    const handleFileChange = (e) => {
        const file = e.target.files?.[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => setPreview(reader.result);
            reader.readAsDataURL(file);
        }
    };

    const handleClose = () => {
        updateReportBugModalVisible(false);
        updateMarketplacePopup(false);
        updatePopUpVisible(false);
    };

    const handleSubmit = () => {
        const form = new FormData();
        if (imageFile){
            form.append("photo", imageFile);
        }
        form.append("message", description);
        form.append("type_issue", selected);
        form.append("reportable_type", type);
        if (id){
            form.append("reportable_id",Number(id))
        }

        createReport(form).then(response => {
            handleClose();
        }).catch(error => {
            console.log(error)
            toast.error(<ToastError message={error?.response?.data?.message}/>,toastStyle)
        })
    };

    const location = useLocation();

    useEffect(() => {
        const tag = location.pathname.split("/")[1];
        if (tag === "") {
            setType("Home Page");
        } else if (tag === "ajouter-vehicule") {
            setType("Add Post");
        } else if (tag === "seller") {
            setType("Seller");
        } else if (tag === "wishlist") {
            setType("Wishlist");
        } else if (tag === "cart") {
            setType("Cart Page");
        } else if (tag === "search") {
            setType("Search Page");
        } else if (tag === "about-us") {
            setType("About Us");
        } else if (tag === "admin") {
            setType("Admin Dashboard");
        } else if (tag === "profile") {
            setType("Profile Page");
        } else if (tag === "my-reservations") {
            setType("Client Reservations");
        } else if (tag === "my-orders") {
            setType("Order Table");
        } else if (tag === "create-shop") {
            setType("Create Shop");
        } else if (tag === "notifications") {
            setType("Notifications");
        } else if (tag === "mes_annonce") {
            setType("My Vehicles");
        } else if (tag === "login") {
            setType("Login Page");
        } else if (tag === "signup") {
            setType("Sign Up");
        } else if (tag === "privacy-policy") {
            setType("Privacy Policy");
        } else if (tag === "services") {
            setType("Service Schedule");
        } else {
            setType(tag);
        }

    }, [location.pathname]);

    const [local, setLocal] = useState(false)

    useEffect(() => {
        if (type !== null){
            if (type === "product") {
                getProductById(id).then((response) => {
                    if (response.status === 200) {
                        setReportable({
                            photos: response.data.photos,
                            name: response.data.name,
                            description: response.data.serial_number
                        });
                    }
                }).catch((err) => {
                })
            } else if (type === "shop") {
                getShopDataById(id)
                    .then((response) => {
                        if (response.status === 200){
                            setReportable({
                                photos: [{link:response.data.logo}],
                                name: response.data.name,
                                description: response.data.description,
                            })
                        }
                    }).catch((err) => {
                })
            } else if(type === "service"){
                getServiceById(id)
                    .then((response) => {
                        if (response.status === 200){
                            console.log(response.data.data)
                            setReportable({
                                photos : response.data.data.photos,
                                name: response.data.data.name,
                                description: "service report",
                            })
                        }
                    }).catch((err)=>{})
            } else if (type === "vehicle"){
                getVehicleById(id)
                    .then((response) => {
                        if (response.status === 200){
                            console.log(response.data)
                            setReportable({
                                photos : response.data.photos,
                                name: response.data.name,
                                description: 'car report'
                            })
                        }
                    })
                    .catch((err)=>{})
            } else if (type === "boat"){
                getBoatById(id)
                    .then((response) => {
                        console.log(response)
                        if (response.status === "success") {
                            setReportable({
                                photos : response.data.photos,
                                name: response.data.name,
                                description: response.data.type,
                            })
                        }
                    })
                    .catch((err)=>{})
            } else {
                setLocal(true)
                setReportable({
                    photos: [{link: "/resources/logo.svg"}],
                    name: "Mecanext - "+type,
                    description: "Achetez et Vendez des Pièces Auto"
                })
            }
        }
    }, [id, type]);

    return (
        <div
            className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-[#333333] rounded-lg shadow-xl p-6 w-full max-w-md z-[1000] aria-modal='true' role='dialog'"
        >
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold dark:text-[#F2F2F2]">
                    {t('helpCenter.title')}
                </h2>
                <button
                    onClick={handleClose}
                    className="p-1 hover:bg-gray-100 dark:hover:bg-[#232323] rounded"
                    aria-label="Close Help Center Modal"
                >
                    <X className="h-5 w-5 dark:text-[#F2F2F2]"/>
                </button>
            </div>

            <div className="space-y-6">
                <div>
                    <p className="text-sm font-medium mb-2 dark:text-[#F2F2F2]">
                        {t('helpCenter.reportedObject.label')}
                    </p>
                    {reportable && type ? (
                        <div
                            className="flex items-center gap-4 border border-border dark:border-[#494949] rounded-lg p-2">
                            <ImageWithFallback
                                src={reportable.photos?.[0]?.link}
                                alt="Reported object"
                                className="max-w-20 min-h-16 max-h-16 overflow-hidden rounded-lg"
                                local={local}
                            />
                            <div className="flex flex-col overflow-hidden">
                                <p className="font-medium truncate dark:text-[#F2F2F2]">
                                    {reportable?.name}
                                </p>
                                <p className="text-sm text-gray-400 truncate dark:text-[#CBCBCB]">
                                    {reportable?.description}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <p className="text-sm text-gray-500 dark:text-[#CBCBCB]">
                            {t('helpCenter.reportedObject.noSelection')}
                        </p>
                    )}
                </div>

                <div>
                    <p className="text-sm font-medium mb-2 dark:text-[#F2F2F2]">
                        {t('helpCenter.issueType.label')}
                    </p>
                    <div className="flex flex-wrap gap-2">
                        {REPORT_REASONS.map((reason) => (
                            <button
                                key={reason.id}
                                onClick={() => setSelected(reason.id)}
                                className={`px-3 py-1.5 rounded text-sm transition ${
                                    selected === reason.id
                                        ? "bg-primaryLight dark:bg-[#E44826] text-white"
                                        : "bg-gray-100 dark:bg-[#232323] dark:text-[#F2F2F2] hover:bg-gray-200 dark:hover:bg-[#494949]"
                                }`}
                            >
                                {t(`helpCenter.issueType.reasons.${reason.label}`)}
                            </button>
                        ))}
                    </div>
                </div>

                <div>
                    <p className="text-sm font-medium mb-2 dark:text-[#F2F2F2]">
                        {t('helpCenter.description.label')}
                    </p>
                    <p className="text-xs text-gray-600 dark:text-[#CBCBCB] mb-2">
                        {t('helpCenter.description.helper')}
                    </p>
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="w-full h-24 px-3 py-2 border dark:border-[#494949] rounded resize-none focus:outline-none focus:ring-2 focus:ring-primaryLight dark:focus:ring-[#E44826] dark:bg-[#232323] dark:text-[#F2F2F2] placeholder:text-sm"
                        placeholder={t('helpCenter.description.placeholder')}
                    />
                </div>

                <div>
                    <p className="text-sm font-medium mb-2 dark:text-[#F2F2F2]">
                        {t('helpCenter.screenshot.label')}
                    </p>
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="hidden"
                        aria-label="Upload a screenshot"
                    />
                    {preview ? (
                        <div className="relative border dark:border-[#494949] rounded-lg p-2">
                            <img
                                src={preview}
                                alt="Screenshot preview"
                                className="max-h-40 mx-auto rounded"
                            />
                            <button
                                onClick={() => {
                                    setImageFile(null);
                                    setPreview("");
                                }}
                                className="absolute top-2 right-2 p-1 bg-white dark:bg-[#333333] rounded-full shadow hover:bg-gray-100 dark:hover:bg-[#232323]"
                                aria-label="Remove uploaded screenshot"
                            >
                                <X className="h-4 w-4 dark:text-[#F2F2F2]"/>
                            </button>
                        </div>
                    ) : (
                        <button
                            onClick={() => fileInputRef.current?.click()}
                            className="w-full flex items-center justify-center gap-2 p-4 border-2 border-dashed dark:border-[#494949] rounded-lg hover:bg-gray-50 dark:hover:bg-[#232323] dark:text-[#F2F2F2]"
                        >
                            <Upload className="h-4 w-4"/>
                            <span>{t('helpCenter.screenshot.upload')}</span>
                        </button>
                    )}
                </div>
            </div>

            <div className="flex justify-end gap-2 mt-6">
                <button
                    onClick={handleClose}
                    className="px-4 py-2 text-sm rounded hover:bg-gray-100 dark:hover:bg-[#232323] dark:text-[#F2F2F2]"
                >
                    {t('helpCenter.buttons.cancel')}
                </button>
                <button
                    onClick={handleSubmit}
                    disabled={!selected || !description}
                    className="px-4 py-2 text-sm bg-primaryLight dark:bg-[#E44826] text-white rounded hover:bg-primaryDark dark:hover:bg-[#F9CDC4] disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    {t('helpCenter.buttons.submit')}
                </button>
            </div>
        </div>
    );
}

export const useReportBugModalContext = create((set) => ({
    reportBugModalVisible: false,
    updateReportBugModalVisible: (visible) => set({reportBugModalVisible: visible}),
}));