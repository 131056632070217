import React, {useState} from "react";
import {useSearchParams} from "react-router-dom";
import {resetPassword} from "../../../../services/AuthAndSignUp/API"
import {toast, Toaster} from "sonner";
import {Languages, Moon, Sun} from "lucide-react";
import ToastError, {toastStyle} from "../../../../components/Toasts/Error/ToastError";
import {useNavigate} from "react-router";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import { useTranslation } from "react-i18next";
import {motion} from "framer-motion";

const ResetPasswordPage = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const email = searchParams.get("email");

    const [formData, setFormData] = useState({
        password: "",
        confirmPassword: "",
    });
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const validateForm = () => {
        const newErrors = {};

        if (!formData.password) {
            newErrors.password = t("password_required");
        } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\/\\])[A-Za-z\d@$!%*?&\/\\]{8,}$/.test(formData.password)) {
            newErrors.password = t("password_strength");
        }

        if (!formData.confirmPassword) {
            newErrors.confirmPassword = t("confirm_password_required");
        } else if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = t("passwords_do_not_match");
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setLoading(true);

        resetPassword(email, token, formData.password, formData.confirmPassword)
            .then((response) => {
                if (response.status === 200) {
                    toast.success(
                        <ToastSuccess message={t("password_reset_success")} />,
                        toastStyle
                    );
                    setTimeout(() => {
                        navigate("/login");
                    }, 3000);
                }
            })
            .catch((e) => {
                toast.error(
                    <ToastError message={e?.response?.data?.error || t("password_reset_error")} />,
                    toastStyle
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const [darkMode, setDarkMode] = useState(localStorage.getItem("darkMode") === "true");

    const toggleDarkMode = () => {
        const newDarkMode = !darkMode;
        setDarkMode(newDarkMode);
        localStorage.setItem("darkMode", newDarkMode);
        if (newDarkMode) {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    };

    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const handleLanguageChange = (selectedLanguage) => {
        i18n.changeLanguage(selectedLanguage);
        localStorage.setItem('language', selectedLanguage);
        setIsOpen(false);
    };

    return (
        <>
            <div className="w-screen h-screen flex flex-col items-center justify-center p-6 space-y-4 xl:space-y-6 relative bg-white dark:bg-[#333333]">
                <h1 className="lg:text-xl xl:text-2xl 2xl:text-3xl font-bold text-gray-800 dark:text-[#F2F2F2] text-center">
                    {t("reset_password_title")}
                </h1>
                <p className="text-xs xl:text-sm text-gray-600 dark:text-[#CBCBCB] text-center max-w-sm xl:max-w-lg">
                    {t("reset_password_instruction")}
                </p>
                <form
                    onSubmit={handleSubmit}
                    className="grid grid-cols-1 gap-6 w-[60vw] sm:w-[30vw] lg:w-[20vw] self-center"
                >
                    {["password", "confirmPassword"].map((field, idx) => (
                        <div key={idx} className="relative">
                            <label
                                htmlFor={field}
                                className="block text-xs xl:text-sm font-medium mb-2 text-gray-500 dark:text-[#797979]"
                            >
                                {t(field === "password" ? "new_password" : "confirm_password")}
                            </label>
                            <input
                                type={showPassword ? "text" : "password"}
                                id={field}
                                name={field}
                                placeholder={t(field === "password" ? "enter_new_password" : "confirm_your_password")}
                                className={`w-full h-10 placeholder:xl:text-sm placeholder:text-xs xl:h-12 px-4 pr-12 border rounded-lg shadow-sm focus:outline-none focus:ring-2 dark:bg-[#232323] dark:text-[#F2F2F2] dark:placeholder-[#595959] ${
                                    errors[field]
                                        ? "border-red-500 focus:ring-red-500 dark:border-red-500"
                                        : "focus:ring-[#E44826] focus:border-[#E44826] dark:focus:ring-[#F9CDC4] dark:focus:border-[#F9CDC4]"
                                } border-gray-300 dark:border-[#494949]`}
                                value={formData[field]}
                                onChange={handleChange}
                                required
                            />
                            {errors[field] && <p className="text-red-500 text-sm mt-1">{errors[field]}</p>}
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className="absolute right-3 top-9 xl:top-[42px] text-gray-600 hover:text-gray-900 dark:text-[#797979] dark:hover:text-[#CBCBCB]"
                            >
                                <img
                                    src={`/resources/${showPassword ? "eye-slash" : "eye"}.svg`}
                                    alt={showPassword ? t("hide_password") : t("show_password")}
                                    className="w-5 h-5"
                                />
                            </button>
                        </div>
                    ))}
                    <button
                        type="submit"
                        disabled={loading}
                        className={`w-full py-3 text-white font-medium rounded ${
                            loading
                                ? "bg-gray-400 dark:bg-[#494949] cursor-not-allowed"
                                : "bg-orange-500 hover:bg-orange-600 dark:bg-[#E44826] dark:hover:bg-[#F9CDC4]"
                        }`}
                    >
                        {loading ? t("resetting_password") : t("reset_password")}
                    </button>
                </form>
                <div className="flex flex-row items-center gap-4 absolute top-4 right-4">
                    <div className="relative">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="flex flex-row items-center gap-2 cursor-pointer text-[#232323] dark:text-[#F2F2F2]"
                        >
                            <Languages/>
                            <p className="font-medium leading-none">{localStorage.getItem('language')}</p>
                        </button>

                        <motion.div
                            className="absolute top-8 overflow-hidden -left-1/2 -translate-x-1/2 bg-black dark:bg-[#232323] text-white w-fit rounded-lg shadow-lg"
                            initial={{opacity: 0, y: -20}}
                            animate={{opacity: isOpen ? 1 : 0, y: isOpen ? 0 : -20}}
                            transition={{duration: 0.3}}
                            style={{display: isOpen ? 'block' : 'none'}}
                        >
                            <ul>
                                <li
                                    className="hover:bg-gray-800 dark:hover:bg-[#333333] p-2 cursor-pointer"
                                    onClick={() => handleLanguageChange('fr')}
                                >
                                    Francais
                                </li>
                                <li
                                    className="hover:bg-gray-800 dark:hover:bg-[#333333] p-2 cursor-pointer"
                                    onClick={() => handleLanguageChange('en')}
                                >
                                    English
                                </li>
                            </ul>
                        </motion.div>
                    </div>
                    <button onClick={toggleDarkMode} className="text-[#232323] dark:text-[#F2F2F2]">
                        {darkMode ? <Sun className="w-5 mx-2"/> : <Moon className="w-5 mx-2"/>}
                    </button>
                </div>
            </div>

            <Toaster
                toastOptions={{
                    style: {
                        background: darkMode ? "#333333" : "white",
                        border: darkMode ? "1px solid #595959" : "1px solid #CBCBCB",
                        borderRadius: "8px",
                        color: darkMode ? "#CBCBCB" : "#232323",
                    },
                    duration: Infinity,
                }}
            />
        </>
    );
};

export default ResetPasswordPage;
