import React from 'react';
import {useMediaQuery, useTheme} from "@mui/material";


const   TableHeader = ({columns, handleSort, sortConfig, columnVisibility, selectAll, onSelectAll, showApproveRejectButtons, showDeleteButton,}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <thead>
        <tr className="bg-gray-100 dark:bg-[#333333] text-gray-600 dark:text-[#F2F2F2] uppercase text-sm tracking-wider">
            {isMobile ? (
                // Show only first column and last column on mobile
                <>
                    {columns.slice(0, 1).map((col) =>
                        columnVisibility[col.field] ? (
                            <th
                                key={col.field}
                                className="py-4 px-6 text-left cursor-pointer text-sm"
                                onClick={() => handleSort(col.field)}
                            >
                                {col.headerName}
                                {sortConfig.key === col.field && (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
                            </th>
                        ) : null
                    )}
                    {columns.slice(-1).map((col) =>
                        columnVisibility[col.field] ? (
                            <th
                                key={col.field}
                                className="py-4 px-6 text-left cursor-pointer text-sm"
                                onClick={() => handleSort(col.field)}
                            >
                                {col.headerName}
                                {sortConfig.key === col.field && (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
                            </th>
                        ) : null
                    )}
                </>
            ) : (
                <>
                    {(showApproveRejectButtons || showDeleteButton) && (
                        <td className="py-4 px-6 text-left w-24" onClick={(e) => e.stopPropagation()}>
                            <input
                                type="checkbox"
                                className="h-5 w-5 border- rounded-lg shadow-sm ring-[#E44826] focus:ring-[#E44826] border-gray-300 dark:border-[#494949] accent-[#E44826] text-white dark:bg-[#333333]"
                                checked={selectAll}
                                onChange={onSelectAll}
                            />
                        </td>
                    )}
                    {columns.map((col) =>
                        columnVisibility[col.field] ? (
                            <th
                                key={col.field}
                                className="py-4 px-6 text-left cursor-pointer"
                                onClick={() => handleSort(col.field)}
                            >
                                {col.headerName}
                                {sortConfig.key === col.field && (sortConfig.direction === 'asc' ? ' ↑' : ' ↓')}
                            </th>
                        ) : null
                    )}
                </>
            )}
            {isMobile && <th className="py-4 px-6 text-right">Actions</th>}
        </tr>
        </thead>
    );
};

export default TableHeader;
