import React, {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {Languages} from "lucide-react";
import {motion} from "framer-motion";

export default function TopBanner() {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = React.useState(false);

    const dropdownRef = useRef(null);

    const handleLanguageChange = (selectedLanguage) => {
        i18n.changeLanguage(selectedLanguage);
        localStorage.setItem("language", selectedLanguage);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="min-h-10 w-full bg-black flex flex-row items-center px-2 sm:px-9 justify-between">
            <div className="flex flex-row items-center gap-4 sm:gap-7">
                <button
                    onClick={() => navigate("/about-us")}
                    className="flex flex-row items-center gap-2 bg-transparent border-0 cursor-pointer"
                    aria-label={t("phone")}
                >
                    <img
                        className="white_filter w-5"
                        src="/resources/round_phone.svg"
                        alt={t("phone_icon")}
                    />
                    <p className="lg:block hidden text-white font-medium text-ellipsis whitespace-nowrap overflow-hidden">
                        {t("phone")}
                    </p>
                </button>

                <div className="flex flex-row items-center gap-2">
                    <img
                        className="white_filter w-5"
                        src="/resources/reader-following.svg"
                        alt="follow"
                    />
                    <p className="lg:block hidden text-white font-medium text-ellipsis whitespace-nowrap overflow-hidden">
                        {t("header.followOrder")}
                    </p>
                </div>
            </div>

            <div className="flex flex-row items-center gap-2 justify-end w-fit lg:w-[20vw]">
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="flex flex-row items-center gap-2 cursor-pointer relative"
                >
                    <img src="/resources/languages.svg" alt={"language icon"} className="w-5 white_filter"/>
                    <p className="font-medium leading-none text-white">
                        {i18n.language === "en" ? "English" : i18n.language === "ar" ? "العربية" : "Francais"}
                    </p>
                    {isOpen && (
                        <motion.div
                            ref={dropdownRef}
                            className="absolute z-[130] top-9 left-0 bg-[#232323] dark:bg-[#333333] text-[#F2F2F2] rounded-lg shadow-lg"
                            initial={{opacity: 0, y: -10}}
                            animate={{opacity: 1, y: 0}}
                            exit={{opacity: 0, y: -10}}
                            transition={{duration: 0.3}}
                        >
                            <ul className="py-2">
                                <li
                                    className="hover:bg-[#494949] px-4 py-2 cursor-pointer"
                                    onClick={() => handleLanguageChange("fr")}
                                >
                                    Français
                                </li>
                                <li
                                    className="hover:bg-[#494949] px-4 py-2 cursor-pointer"
                                    onClick={() => handleLanguageChange("en")}
                                >
                                    English
                                </li>
                                {
                                    /** <li
                                     className="hover:bg-[#494949] px-4 py-2 cursor-pointer"
                                     onClick={() => handleLanguageChange("ar")}
                                     >
                                     العربية
                                     </li> **/
                                }
                            </ul>
                        </motion.div>
                    )}
                </button>
            </div>
        </div>
    );
}