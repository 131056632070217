import CartProductItem from "./components/CartProductItem/CartProductItem";
import {useMarketplaceContext} from "../Marketplace";
import {useEffect, useState} from "react";
import {getProductById, getPromoCodeInfo, orderPack, orderProduct} from "../../services/marketplace/API";
import {formatCurrency} from "../../utils/utils";
import {create} from "zustand";
import {useNavigate} from "react-router";
import Cookies from "js-cookie";
import {toast} from "sonner";
import ToastError, {toastStyle} from "../../components/Toasts/Error/ToastError";
import ToastWarn from "../../components/Toasts/Warn/ToastWarn";
import { useTranslation } from 'react-i18next';

export default function CartPage() {
    const { t } = useTranslation();
    const {cart, emptyCart, addPromoCodeToCartProduct, removeProductFromCart} = useMarketplaceContext();
    const [products, updateProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [actionLoading, setActionLoading] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [remise, setRemise] = useState(0);
    const [deliveryFee, setDeliveryFee] = useState(0);
    const {
        addProductToCart,
        product_index,
        emptyProductIndex,
        addPromoCodeToProduct,
        removeProductFromIndex
    } = useCartPageContext();
    const navigate = useNavigate();

    useEffect(() => {
        setDeliveryFee(
            product_index.some(product => {
                if (product.offer && product.offer.offer_quantity === 0) {
                    return false;
                }
                return product.stock_quantity !== 0;
            }) ? 3000 : 0
        );
        setTotalPrice(product_index.reduce((acc, product) => {
            if ((product.offer && product.offer.offer_quantity === 0) || product.stock_quantity === 0) {
                return acc + 0;
            }
            return acc + (product.price * product.quantity);
        }, 0));
        setRemise(product_index.reduce((acc, product) => {
            let discount = 0;

            if ((product.offer && product.offer.offer_quantity === 0) || product.stock_quantity === 0) {
                return acc + 0;
            }

            if (product.offer) {
                discount = product.offer.discount_percentage ? (product.price * (product.offer.discount_percentage / 100)) : 0;
            } else if (product.code) {
                discount = product.discount_percentage ? (product.price * (product.discount_percentage / 100)) : 0;
            }
            return acc + (discount * product.quantity);
        }, 0))
    }, [product_index]);

    const [listCodePromo, updateListCodePromo] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const productPromises = cart.map((product) =>
                    getProductById(product.id)
                        .then(async (response) => {
                            if (response.status === 200) {
                                let result = response.data;

                                console.log("[Remise] : Product :", product);

                                // Process offer
                                if (product.offer) {
                                    result = {
                                        ...result,
                                        offer: product.offer,
                                        offer_product_quantity: product.offer.products.find(p => p.id === product.id)?.pivot.quantity || 1
                                    };

                                    // Add discount if valid
                                    if (product.offer.discount_percentage) {
                                        result.discount_percentage = product.offer.discount_percentage;
                                        addPromoCodeToProduct(result, {
                                            discount_percentage: product.offer.discount_percentage
                                        });
                                    }
                                }

                                console.log("[Remise] : Product After Offer Processing :", result);

                                // Process promo code
                                if (product.code) {
                                    console.log("Adding promo code to product", product);
                                    addPromoCodeToProduct(result, {
                                        code: product.code,
                                        description: product.description,
                                        discount_percentage: product.discount_percentage
                                    });
                                    result = {
                                        ...result,
                                        code: product.code,
                                        description: product.description,
                                    };

                                    // Only add discount_percentage if promo code has it
                                    if (product.discount_percentage) {
                                        result.discount_percentage = product.discount_percentage;
                                    }
                                }

                                console.log("[Remise] : Product After Code Processing : ", result);

                                const input_product = {
                                    offer: product.offer,
                                    id: response.data.id,
                                    name: response.data.name,
                                    price: response.data.price,
                                    quantity: product?.offer?.products[-1]?.pivot?.quantity || 1,
                                    ...result
                                };

                                console.log("[Remise] : Product After Processing : ", input_product);

                                addProductToCart(input_product);
                                console.log("returning product", result);
                                return result;
                            }
                            return null;
                        })
                        .catch((e) => {
                            removeProductFromCart(product,product.offer);
                            return null;
                        })
                );

                const fetchedProducts = await Promise.all(productPromises);

                updateProducts(fetchedProducts.filter(product => product !== null));

                updateListCodePromo([
                    ...cart
                        .filter(product => product.code)
                        .map(product => ({
                            code: product.code,
                            description: product.description,
                            discount_percentage: product.discount_percentage || undefined
                        }))
                ]);

                setLoading(false);
            } catch (error) {
                console.error('Error fetching products', error);
                setLoading(false);
            }
        };

        if (cart.length > 0) {
            fetchProducts();
        } else {
            setLoading(false);
            updateProducts([])
        }
    }, [cart, addProductToCart, updateProducts, updateListCodePromo, addPromoCodeToProduct]);

    useEffect(() => {
        console.log("code promo list", listCodePromo)
    }, [listCodePromo]);

    async function handleOrder() {
        console.log("Ordering products");
        setActionLoading(true);

        // Filter out products with no offer
        const products_without_offers = product_index.filter(product => product.offer === undefined || product.offer === null);
        const products_order = {
            status: "pending",
            products: products_without_offers.map((product) => ({
                product_id: product.id,
                quantity: product.quantity,
                promotion_code: product.code,
            })),
        }
        try {
            if (products_without_offers.length !== 0) {
                await orderProduct(products_order);
            }
        } catch (e) {
            if (e.response && (e.response.status === 401 || e.response.status === 403)) {
                setActionLoading(false);
                navigate('/login', {state: {from: window.location.pathname}});
            }
            setActionLoading(false);
            toast.error(<ToastError message="erreur lors du traitement de la commande"/>, toastStyle);
            return;
        }

        // fetch Authenticated user data

        let userData = null

        try {
            userData = JSON.parse(Cookies.get("user"))
        } catch (e) {
            setActionLoading(false);
            navigate('/login', {state: {from: window.location.pathname}});
        }

        // Filter out products with offers

        const offerIds = new Set();

        const products_with_offers = product_index
            .filter(product => product.offer !== undefined && product.offer !== null)
            .filter(product => {
                if (offerIds.has(product.offer.id)) {
                    return false;
                }
                offerIds.add(product.offer.id);
                return true;
            })
            .map((product) => ({
                id: product.offer.id,
                quantity: 1,
            }));

        const packs_order = {
            client_id: userData?.client?.id,
            offers: products_with_offers,
        }

        if (products_with_offers.length === 0) {
            emptyCart();
            emptyProductIndex();
            updateListCodePromo([]);
            setActionLoading(false);
            return;
        }

        try {
            await orderPack(packs_order);
            emptyCart();
            emptyProductIndex();
            updateListCodePromo([]);
            setActionLoading(false);
        } catch (e) {
            products_without_offers.forEach(product => {
                removeProductFromCart(product);
                removeProductFromIndex(product);
            });
            if (e.response && (e.response.status === 401 || e.response.status === 403)) {
                setActionLoading(false);
                navigate('/login', {state: {from: window.location.pathname}});
            }
            if (e.response && e.response.status === 400) {
                setActionLoading(false);
                toast.warning(<ToastWarn message="L'offre est en rupture de stock"/>, toastStyle);
            }else{
                setActionLoading(false);
                toast.error(<ToastError message="erreur lors du traitement de la commande"/>, toastStyle);
            }
        }

    }

    const [promoCode, setPromoCode] = useState("");
    const [promoCodeLoading, updatePromoCodeLoading] = useState(false);
    const [promoError, updatePromoError] = useState(null);

    const handleApplyCodePromo = () => {
        updatePromoCodeLoading(true)
        getPromoCodeInfo(promoCode).then((response) => {
            if (response.status === 200) {
                if (new Date(response.data.end_date) < new Date()) {
                    updatePromoError(t('myCart.promoCode.expired'))
                    updatePromoCodeLoading(false)
                    return;
                }
                const exist = response.data.products.some(product => products.some(p => p.id === product.id))
                if (!exist) {
                    updatePromoError(t('myCart.promoCode.notValid'))
                    updatePromoCodeLoading(false)
                    return;
                }
                if (listCodePromo.some(code => code.code === promoCode)) {
                    updatePromoError(t('myCart.promoCode.alreadyApplied'))
                    updatePromoCodeLoading(false)
                    return;
                }
                const promo_products = product_index.filter(product => response.data.products.some(p => p.id === product.id))

                promo_products.forEach(product => {
                    addPromoCodeToProduct(product, response.data)
                    addPromoCodeToCartProduct(product, response.data)
                })
                updateProducts((prevState) =>
                    prevState.map(product => {
                        if (promo_products.some(p => p.id === product.id)) {
                            return {...product, discount_percentage: response.data.discount_percentage};
                        }
                        return product;
                    })
                );
                updateListCodePromo([...listCodePromo, response.data])
            }
            updatePromoCodeLoading(false)
        }).catch((e) => {
            updatePromoCodeLoading(false)
            console.log(e)
        })
    }
    return (
        <div className="flex-col lg:flex-row flex w-full h-full px-2 sm:px-6 md:px-[4vw] lg:px-[4vw] xl:px-[8vw] 2xl:px-[11vw] py-2 sm:py-8 gap-6">
            <div className='w-full lg:w-[71vw] xl:w-[55vw] flex flex-col gap-8 p-2'>
                <p className="font-medium text-2xl dark:text-[#F2F2F2]">{t('myCart.title')}</p>
                {loading ?
                    <div className="w-full h-full flex items-center justify-center">
                        <div className="loader"></div>
                    </div>
                    :
                    products.length > 0 ? <div className="w-full flex flex-col gap-3 pb-20">
                            {
                                products.map((product, index) => {
                                    return (
                                        <CartProductItem key={index} expanded={index === 0} id={product.id}
                                                         product={product}/>
                                    )
                                })
                            }
                        </div> :
                        <div className="w-full h-full flex items-center justify-center">
                            <p className="font-medium text-xl text-[#797979] dark:text-[#CBCBCB]">{t('myCart.empty')}</p>
                        </div>
                }
            </div>
            <div className='w-full lg:w-[25vw] xl:w-[20vw] flex flex-col gap-6 p-2'>
                <p className="font-medium text-2xl dark:text-[#F2F2F2]">{t('myCart.details')}</p>
                <div className="w-full flex flex-col gap-3">
                    <p className="font-medium dark:text-[#F2F2F2]">{t('myCart.promoCode.label')}</p>
                    <div className="flex flex-row gap-2">
                        <input type="text"
                               value={promoCode}
                               onChange={(e) => setPromoCode(e.target.value)}
                               className="w-full h-12 rounded-lg py-3 pl-2 pr-6 focus:outline-none border border-gray-300 dark:border-[#494949] dark:bg-[#333333] dark:text-[#F2F2F2] placeholder:text-[#747474] dark:placeholder:text-[#595959] placeholder:text-sm"
                               placeholder={t('myCart.promoCode.placeholder')}/>
                        <div
                            onClick={handleApplyCodePromo}
                            className="h-12 px-4 rounded-lg flex items-center justify-center border border-border dark:border-[#494949] cursor-pointer hover:bg-[#f1f1f1] dark:hover:bg-[#494949]">
                            {promoCodeLoading ? <div className='w-5'>
                                <div className="loader_white white_filter"></div>
                            </div> : <p className="text-sm dark:text-[#F2F2F2]">{t('myCart.promoCode.apply')}</p>}
                        </div>
                    </div>
                    {promoError != null && <p className="text-[#E44826] text-sm">{promoError}</p>}
                </div>
                <div className="py-4 border-y-border dark:border-[#494949] border-dashed border-y flex flex-col gap-4 px-2">
                    <div className="flex flex-row justify-between">
                        <p className="text-[#797979] dark:text-[#CBCBCB]">{t('myCart.summary.subtotal')}</p>
                        <p className="text-xl font-semibold dark:text-[#F2F2F2]">{formatCurrency(totalPrice)}</p>
                    </div>
                    <div className="flex flex-row justify-between">
                        <p className="text-[#797979] dark:text-[#CBCBCB]">{t('myCart.summary.taxes')}</p>
                        <p className="text-xl font-semibold dark:text-[#F2F2F2]">{formatCurrency(0.19 * totalPrice)}</p>
                    </div>
                    <div className="flex flex-row justify-between">
                        <p className="text-[#797979] dark:text-[#CBCBCB]">{t('myCart.summary.delivery')}</p>
                        <p className="text-xl font-semibold dark:text-[#F2F2F2]">{formatCurrency(deliveryFee)}</p>
                    </div>
                    <div className="flex flex-row justify-between">
                        <p className="text-[#797979] dark:text-[#CBCBCB]">{t('myCart.summary.discounts')}</p>
                        <p className="text-xl font-semibold dark:text-[#F2F2F2]">{formatCurrency(remise)}</p>
                    </div>
                </div>

                <div className="flex flex-row justify-between">
                    <p className="text-[#797979] dark:text-[#CBCBCB]">{t('myCart.summary.total')}</p>
                    <p className="text-xl font-semibold dark:text-[#F2F2F2]">{formatCurrency(totalPrice + 0.19 * totalPrice + deliveryFee - remise)}</p>
                </div>

                <button
                    disabled={actionLoading}
                    onClick={handleOrder}
                    className="min-h-12 disabled:bg-gray-300 dark:disabled:bg-[#494949] bg-primaryLight dark:bg-[#E44826] w-full rounded-lg flex items-center justify-center gap-4">
                    {actionLoading ?
                        <div className="w-6">
                            <div className="loader_white"></div>
                        </div>
                        :
                        <>
                            <img src="/resources/card.svg" alt="card" className="white_filter"/>
                            <p className="font-medium text-white">{t('myCart.payment.button')}</p>
                        </>
                    }
                </button>
                {listCodePromo.map((code, index) => (
                    <PromoElement key={index} code={code.code} description={code.description}
                                  setRemise={setRemise}
                                  discount_percentage={code.discount_percentage}
                                  ListCodePromoState={[listCodePromo, updateListCodePromo]}/>
                ))}
            </div>
        </div>
    )
}

const PromoElement = ({code, description, discount_percentage, ListCodePromoState, setRemise}) => {
    const [listCodePromo, updateListCodePromo] = ListCodePromoState;
    const {removePromoCodeFromCartProduct} = useMarketplaceContext();
    const {product_index, updateProductIndex} = useCartPageContext();

    const handleRemoveCodePromo = () => {
        const updatedProducts = product_index.map(product => {
            if (product.code === code) {
                console.log("Removing promo code from product", product);
                return {...product, code: null, discount_percentage: null}; // Remove the promo details
            }
            return product;
        });

        updateProductIndex(updatedProducts);

        updateListCodePromo(listCodePromo.filter(promo => promo.code !== code));


        setRemise(updatedProducts.reduce((acc, product) => {
            const discount = product.discount_percentage ? (product.price * (product.discount_percentage / 100)) : 0;
            return acc + (discount * product.quantity);
        }, 0));

        removePromoCodeFromCartProduct(code);
    };
    return (
        <div
            className="flex flex-row bg-gray-100 dark:bg-[#333333] rounded items-center justify-between divide-border dark:divide-[#494949] divide-x gap-1 h-fit border border-border dark:border-[#494949]">
            <div className="p-4 flex flex-col gap-4 items-start">
                <div className="flex flex-row gap-2 items-center ">
                    <p className="font-medium text-sm dark:text-[#F2F2F2]">Code : </p>
                    <p className="text-sm dark:text-[#CBCBCB]">{code}</p>
                </div>
                <div className="flex flex-row gap-2 items-center ">
                    <p className="font-medium text-sm dark:text-[#F2F2F2]">Description : </p>
                    <p className="text-sm dark:text-[#CBCBCB]">{description}</p>
                </div>
                <div className="flex flex-row gap-2 items-center ">
                    <p className="font-medium text-sm dark:text-[#F2F2F2]">pourcentage de remise : </p>
                    <p className="line-through font-medium dark:text-[#CBCBCB]">{discount_percentage} %</p>
                </div>
            </div>
            <div
                onClick={handleRemoveCodePromo}
                className="w-8 h-10 flex items-center justify-center cursor-pointer hover:bg-gray-200 dark:hover:bg-[#494949]">
                <img src="/resources/close.svg" alt="close" className="w-3"/>
            </div>
        </div>
    )
}

export const useCartPageContext = create((set) => ({
        product_index: [], // Renamed from `products` to `product_index`
        updateProductIndex: (products) => set({product_index: products}),
        emptyProductIndex: () => set({product_index: []}),
        addProductToCart: (product) => set((state) => {
            console.log("[Remise] : Adding product to cart", product);
            console.log("State", state.product_index);
            if (state.product_index.some(p => {
                if (p.offer !== null && p.offer !== undefined && product.offer !== null && product.offer !== undefined) {
                    return p.offer.id === product.offer.id && p.id === product.id;
                } else if ((p.offer === null || p.offer === undefined) && (product.offer === null || product.offer === undefined)) {
                    return p.id === product.id;
                } else {
                    return false;
                }
            })) {
                return {product_index: state.product_index};
            }
            return {product_index: [...state.product_index, product]};
        }),
        addPromoCodeToProduct: (product, promoCode) => set((state) => {
            console.log("Adding promo code to product INDEX", product);
            const index = state.product_index.findIndex(p => (p?.offer === null || p?.offer === undefined) && Number(p.id) === Number(product.id));
            if (index === -1) {
                return {product_index: state.product_index};
            }
            const newProductIndex = [...state.product_index];
            newProductIndex[index].code = promoCode?.code;
            newProductIndex[index].discount_percentage = promoCode.discount_percentage;
            return {product_index: newProductIndex};
        }),
        removeProductFromIndex:
            (product) => set((state) => {
                console.log("Removing product from index", product);
                return {product_index: [...state.product_index.filter(p =>{
                        if (!p.offer && !product.offer) {
                            return p.id !== product.id;
                        }else if (p.offer && product.offer) {
                            return p.offer.id !== product.offer.id;
                        }
                        return true;
                    })]}
            }),
        updateProductQuantity:
            (product, quantity) => set((state) => {
                console.log("Quantity :", quantity);
                console.log("ID : ", product.id);
                console.log(state.product_index)
                const index = state.product_index.findIndex(p => {
                    if (p.offer !== null && p.offer !== undefined && product.offer !== null && product.offer !== undefined) {
                        return p.offer.id === product.offer.id && p.id === product.id;
                    } else if ((p.offer === null || p.offer === undefined) && (product.offer === null || product.offer === undefined)) {
                        return p.id === product.id;
                    } else {
                        return false;
                    }
                });
                if (index === -1) {
                    console.log("not found")
                    return {product_index: state.product_index};
                }
                // Update the quantity of the product
                const newProductIndex = [...state.product_index];
                newProductIndex[index].quantity = quantity;
                console.log(newProductIndex)
                return {product_index: newProductIndex};
            }),
    }))
;