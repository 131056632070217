import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";

export default function RadiusRangeFilter({ value = 10, onChange }) {
    const [range, setRange] = useState(value);

    const debouncedOnChange = useCallback(
        debounce((newValue) => {
            onChange(newValue);
        }, 500),
        [onChange]
    );

    useEffect(() => {
        debouncedOnChange(range);
        return () => debouncedOnChange.cancel();
    }, [range, debouncedOnChange]);

    // Sync `value` prop with `range` state
    useEffect(() => {
        setRange(value);
    }, [value]);

    const handleChange = (e) => {
        const inputValue = e.target.value;
        const newValue = inputValue === "" ? "" : Number(inputValue);
        if (newValue > 0 || inputValue === "") {
            setRange(newValue);
        }
    };

    return (
        <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row h-9 items-center justify-between">
                <p className="font-medium text-[#333333] dark:text-[#F2F2F2]">Range Radius (km)</p>
            </div>
            <div className="flex flex-row items-center gap-4 w-full">
                <div className="flex flex-row items-center gap-2 w-full">
                    <input
                        type="number"
                        placeholder="0"
                        className="w-full focus:outline-none border border-[#CBCBCB] dark:border-[#595959] dark:bg-[#232323] text-[#333333] dark:text-[#F2F2F2] rounded px-2 h-10 focus:border-[#E44826] dark:focus:border-[#F9CDC4]"
                        value={range === "" ? "" : range}
                        onChange={handleChange}
                    />
                </div>
            </div>
        </div>
    );
}

RadiusRangeFilter.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};
