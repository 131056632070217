import React, {useEffect, useState} from "react";
import {usePersonalInfoContext} from "./SignUp";
import PersonalInfoForm from "../Components/steps/PersonalInfoForm";
import CoordinatesForm from "../Components/steps/CoordinatesForm";
import PasswordForm from "../Components/steps/PasswordForm";
import LegalDocumentsForm from "../Components/steps/LegalDocumentsForm";
import ProfilePhotoUpload from "../Components/steps/ProfilePhotoUpload";
import SuccessAccountCreation from "../Components/steps/SuccessAccountCreation";
import { useTranslation } from 'react-i18next';

export default function RegistrationSteps() {
    const {updateCurrentStep,personalInfo} = usePersonalInfoContext();
    const [selectedSeller, setSelectedSeller] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [successfullSignUp,setSuccessfullSignUp] = useState(false);

    const totalNumber = personalInfo.role_id === 3 ? 6 : 5 ;

    useEffect(() => {
        const darkMode = localStorage.getItem('darkMode') === 'true';
        setIsDarkMode(darkMode);
    }, []);

    const handleNext = () => {
        setCurrentStep(prevStep => prevStep + 1);
    };

    const handlePrevious = () => {
        setCurrentStep(prevStep => prevStep - 1);
    };

    const renderFormStep = () => {
        if (!successfullSignUp){
            switch (currentStep) {
                case 1:
                    return <ChooseAccountType selectedState={[selectedSeller, setSelectedSeller]} handleNext={()=>{
                        updateCurrentStep(2)
                        handleNext()
                    }}/>;
                case 2:
                    return <PersonalInfoForm handleNext={handleNext} handlePrevious={handlePrevious}/>;
                case 3:
                    return <CoordinatesForm handleNext={handleNext} handlePrevious={handlePrevious}/>;
                case 4:
                    return <ProfilePhotoUpload handleNext={handleNext} handlePrevious={handlePrevious}/>
                case 5:
                    return <PasswordForm handlePrevious={handlePrevious} handleNext={handleNext} setIsSucessfull={setSuccessfullSignUp}/>;
                case 6:
                    return <LegalDocumentsForm handleNext={handleNext} handlePrevious={handlePrevious} setIsSucessfull={setSuccessfullSignUp}/>;
                default:
                    return <PersonalInfoForm handleNext={handleNext}/>;
            }
        }else{
            return <SuccessAccountCreation handleRedirectToDashboard={""}/>
        }
    };


    return (
        <div className="w-full h-full flex flex-col">
            <div className="w-full bg-gray-200 dark:bg-[#333333] h-2">
                <div
                    className="bg-primaryLight dark:bg-[#E44826] h-2 transition-all"
                    style={{ width: `${(currentStep / totalNumber) * 100}%` }}
                ></div>
            </div>
            {
                renderFormStep()
            }
        </div>
    );
}

const ChooseAccountType = ({ selectedState, handleNext }) => {
    const { t } = useTranslation();
    const [selectedSeller, setSelectedSeller] = selectedState;
    const { updateRole } = usePersonalInfoContext();

    const accountOptions = [
        {
            type: t('chooseAccountType.buyerType'),
            title: t('chooseAccountType.buyerTitle'),
            description: t('chooseAccountType.buyerDescription'),
            icon: "/resources/user.svg",
            selected: !selectedSeller,
            onClick: () => {
                updateRole(4);
                setSelectedSeller(false);
            },
        },
        {
            type: t('chooseAccountType.sellerType'),
            title: t('chooseAccountType.sellerTitle'),
            description: t('chooseAccountType.sellerDescription'),
            icon: "/resources/users.svg",
            selected: selectedSeller,
            onClick: () => {
                updateRole(3);
                setSelectedSeller(true);
            },
        },
    ];

    return (
        <div className="w-full h-full flex flex-col items-center justify-center p-6 space-y-4 xl:space-y-6 relative">
            <h2 className="lg:text-xl xl:text-2xl 2xl:text-3xl font-bold text-gray-800 dark:text-[#F2F2F2] text-center">
                {t('chooseAccountType.createAccountTitle')}
            </h2>
            <p className="text-xs xl:text-sm text-gray-600 dark:text-[#CBCBCB] text-center max-w-lg" dangerouslySetInnerHTML={{ __html: t('chooseAccountType.chooseAccountDescription') }} />

            <div className="flex flex-col xs:flex-row md:flex-col lg:flex-row gap-6">
                {accountOptions.map(({ type, title, description, icon, selected, onClick }) => (
                    <button
                        key={type}
                        onClick={onClick}
                        className={`flex flex-col items-center p-2 xl:p-4 pb-4 w-[180px] h-[180px] xl:w-[220px] xl:h-[220px] rounded-xl border gap-2 xl:gap-4 transition-all ${
                            selected
                                ? "border-primaryLight dark:border-[#E44826] text-primaryLight dark:text-[#F9CDC4] bg-primaryXLight dark:bg-[#333333] shadow-lg"
                                : "border-borderLight dark:border-[#494949] text-gray-800 dark:text-[#CBCBCB] hover:border-primaryLight dark:hover:border-[#E44826] hover:bg-gray-50 dark:hover:bg-[#232323]"
                        }`}
                    >
                        <img src={icon} alt={`${type} icon`} className={`w-10 xl:w-12 ${selected ? "primary_filter dark:brightness-0 dark:invert" : "gray_filter dark:brightness-0 dark:invert"}`} />
                        <h3 className="text-sm xl:text-normal font-semibold">{title}</h3>
                        <p className={`text-xs xl:text-sm ${selected ? "text-primaryDark dark:text-[#F9CDC4]" : "text-gray-800 dark:text-[#CBCBCB]"} text-center`}>
                            {description}
                        </p>
                    </button>
                ))}
            </div>

            <button
                onClick={handleNext}
                className="absolute bottom-6 right-6 px-4 xl:px-6 h-10 xl:h-12 flex justify-center items-center bg-primaryLight dark:bg-[#E44826] text-white py-2 xl:py-3 rounded-lg font-medium hover:bg-primaryDark transition duration-300 gap-4"
            >
                <p className="text-sm xl:text-normal">{t('chooseAccountType.continueButton')}</p>
                <img src="/resources/arrow_right.svg" alt="Next" className="white_filter w-3 xl:w-4" />
            </button>
        </div>
    );
};
