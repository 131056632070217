import {toast} from "sonner";
import React, {useEffect, useState} from "react";

export default function ToastSuccess({message}) {
    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        // Initial dark mode check
        const isDark = localStorage.getItem('darkMode') === 'true';
        setDarkMode(isDark);

        // Listen for dark mode changes
        const handleDarkModeChange = (e) => {
            if (e.key === 'darkMode') {
                setDarkMode(e.newValue === 'true');
            }
        };

        window.addEventListener('storage', handleDarkModeChange);

        // Watch for DOM class changes to detect dark mode toggle
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {
                    setDarkMode(document.documentElement.classList.contains('dark'));
                }
            });
        });

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['class']
        });

        // Cleanup listeners
        return () => {
            window.removeEventListener('storage', handleDarkModeChange);
            observer.disconnect();
        };
    }, []);
    return(
        <div className="flex items-center justify-between bg-white p-4 dark:bg-[#333333] w-full rounded-lg">
            <div className="flex flex-row items-center gap-2 w-full">

                <div className="w-14 h-14 flex items-center justify-center bg-green-400 rounded-lg">
                    <img src="/resources/check.svg" alt="check" className="w-6 white_filter"/>
                </div>

                <div className="flex flex-col gap-1 items-start" style={{maxWidth: '70%'}}>
                    <p style={{fontSize: "16px"}} className="font-bold text-black dark:text-[#F2F2F2]">Succès</p>

                    <p
                        style={{fontSize: "14px"}}
                        className="text-gray-500 dark:text-[#CBCBCB] font-medium overflow-hidden whitespace-normal line-clamp-3"
                    >
                        {message}
                    </p>
                </div>
            </div>

            <button
                className="min-w-10 min-h-10 rounded-lg hover:bg-gray-50 dark:hover:bg-[#494949] flex items-center justify-center"
                onClick={() => toast.dismiss()}
            >
                <img src="/resources/close.svg" alt="close"
                     className={`w-4 ${darkMode ? 'white_filter' : 'dark_gray_filter'}`}/>
            </button>
        </div>
    )
}

export const toastStyle = {
    duration: 5000,
    style: {
        background: 'transparent',
        border: 'none',
        borderRadius: '12px',
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
}