import {useNavigate} from "react-router";
import React, {useState, useRef, useEffect} from "react";
import {useCompareContext} from "../../hooks/context/context";
import {getProfile, logoutUser} from "../../services/AuthAndSignUp/API";
import GlobalSearch from "./GlobalSearch/GlobalSearch";
import Logo from "../Logo/Logo";
import {useLocation} from "react-router-dom";
import Cookies from "js-cookie";
import {ImageWithFallback} from "../ImageWithFallBack/ImageWithFallBack";
import {useNonMarketPlaceRoutesContext} from "../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import {
    useSellerNavMenuFlyOutMenuContext
} from "../../pages/SellerSpace/components/SellerNavManuFlyOut/SellerNavMenuFlyOutMenu";
import {useTranslation} from 'react-i18next';
import {useMarketplaceContext} from "../../pages/Marketplace";
import {useFavoriteContext} from "../../hooks/context/useFavoriteContext";
import {useSellerSpaceRoutesContext} from "../../routes/SellerSpaceRoutes/SellerSpaceRoutes";
import {GitCompareArrows, Info, Moon, Sun} from 'lucide-react';
import {useReportBugModalContext} from "../ReportBugModal/ReportBugModal";
import PropTypes from "prop-types";

export default function Header() {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [isFixed, setIsFixed] = useState(false);
    const [darkMode, setDarkMode] = useState(false);
    const ref = useRef(null);
    const {showCompareFlyoutMenu, hideCompareFlyoutMenu, compareFlyoutMenuVisible} = useCompareContext();
    const location = useLocation();
    const {t} = useTranslation();

    // Handle dark mode toggle
    useEffect(() => {
        // Initial dark mode check
        const isDark = localStorage.getItem('darkMode') === 'true';
        setDarkMode(isDark);
        if (isDark) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
        const handleDarkModeChange = (e) => {
            if (e.key === 'darkMode') {
                setDarkMode(e.newValue === 'true');
            }
        };

        window.addEventListener('storage', handleDarkModeChange);

        // Watch for DOM class changes to detect dark mode toggle
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {
                    setDarkMode(document.documentElement.classList.contains('dark'));
                }
            });
        });

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['class']
        });

        // Cleanup listeners
        return () => {
            window.removeEventListener('storage', handleDarkModeChange);
            observer.disconnect();
        };
    }, []);

    const toggleDarkMode = () => {
        const newDarkMode = !darkMode;
        setDarkMode(newDarkMode);
        localStorage.setItem('darkMode', newDarkMode);
        if (newDarkMode) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    };

    // Handle click outside to close dropdown
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setDropdownVisible(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const userCookie = Cookies.get("user");

                if (userCookie) {
                    setUser(JSON.parse(userCookie));
                    return;
                }

                const response = await getProfile();
                if (response.status === 200) {
                    setUser(response.data.user);
                    Cookies.set("user", JSON.stringify(response.data.user), {
                        expires: 30,
                        secure: true,
                        sameSite: 'strict'
                    });
                } else {
                    console.error("Failed to fetch profile from API:", response.statusText || response.error);
                    // Handle additional error logic here
                }
            } catch (error) {
                console.error("Error during profile fetching:", error);
            }
        };

        fetchUserProfile();
    }, []);

    // Handle scroll behavior
    useEffect(() => {
            const handleScroll = () => {
                if (window.scrollY > 88) {
                    setIsFixed(true);
                } else {
                    setIsFixed(false);
                }
            };

            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }, [location.pathname]
    );

    // Render user profile picture
    const renderUserProfilePicture = () => {
        const profilePhoto = user?.profile_photo || "/images/blank-avatar.png";
        if (user?.profile_photo !== null) {
            return (
                <ImageWithFallback src={profilePhoto} alt="user profile" className="h-full w-full object-cover"/>
            )
        } else {
            return (
                <img
                    src={profilePhoto}
                    referrerPolicy="no-referrer"
                    alt="user profile"
                    className="h-full w-full object-cover"
                    onError={(e) => {
                        e.target.onerror = null; // Prevent infinite loop
                        e.target.src = "/images/blank-avatar.png";
                    }}
                />
            );
        }
    };

    const [logingOut, setLogingOut] = useState(false);

    function handleLogout() {
        setLogingOut(true);
        logoutUser().then(response => {
            if (response.status === 200) {
                setUser(null);
                Cookies.remove("token");
                Cookies.remove("user");
                Cookies.remove("shop");
                setLogingOut(false);
                navigate("/");
                setDropdownVisible(false);
            } else {
                console.error(response.error);
                setUser(null);
                Cookies.remove("token");
                Cookies.remove("user");
                Cookies.remove("shop");
                setLogingOut(false);
                navigate("/");
                setDropdownVisible(false);
                setDropdownVisible(false)
            }
        }).catch(() => {
            setUser(null);
            Cookies.remove("token");
            Cookies.remove("user");
            Cookies.remove("shop");
            setLogingOut(false);
            navigate("/");
            setDropdownVisible(false);
            setDropdownVisible(false)
        });
    }

    const [minimizedControlsDropDownVisible, setMinimizedControlsDropDownVisible] = useState(false);
    const minimizeControlsDropDownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (minimizeControlsDropDownRef.current && !minimizeControlsDropDownRef.current.contains(e.target)) {
                setMinimizedControlsDropDownVisible(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();
    const {updatePopupVisible: updateMarketPlacePopUpVisible} = useMarketplaceContext();
    const {favoriteList} = useFavoriteContext();
    const {updateMenuVisible} = useSellerNavMenuFlyOutMenuContext();

    const {cart} = useMarketplaceContext();
    const [unreadCount, setUnreadCount] = useState(0);
    const [unreadFavorite, setUnreadFavorite] = useState(0);
    useEffect(() => {
        if (cart.length > 0) {
            setUnreadCount(cart.length);
        } else {
            setUnreadCount(0);
        }
    }, [cart.length]);

    useEffect(() => {
        if (favoriteList.length > 0) {
            setUnreadFavorite(favoriteList.length);
        } else {
            setUnreadFavorite(0);
        }
    }, [favoriteList.length]);

    const {updateRenewPlan, updateExpiredPopUpVisible} = useSellerSpaceRoutesContext();

    const [shop, setShop] = useState({});
    useEffect(() => {
        try {
            const shopCookie = Cookies.get("shop")
            if (shopCookie) {
                setShop(JSON.parse(shopCookie))
            }
        } catch (e) {

        }
    }, []);

    const {updateReportBugModalVisible} = useReportBugModalContext();

    const handleShowReportModal = () => {
        updatePopUpVisible(true);
        updateMarketPlacePopUpVisible(true);
        updateReportBugModalVisible(true);
    }

    return (
        <div
            className={`w-screen h-[88px] ${isFixed ? 'fixed' : 'relative'} top-0 left-0 bg-white dark:bg-[#232323] z-[120] border-b border-b-borderLight dark:border-b-[#494949] px-9 py-4 flex items-center justify-between transition-all duration-300 ease-in-out`}
        >
            <div className="w-[30vw] sm:w-[20vw] lg:w-[18vw] flex items-center">
                <Logo/>
            </div>
            <div className="md:w-fit 2xl:w-[58.5vw] xl:w-[62vw] lg:w-[65vw] flex items-center justify-between gap-4">
                <GlobalSearch/>
                <div className="flex items-center gap-4 md:gap-10">
                    <div className="hidden sm:flex items-center gap-4">
                        <Buttons handleClick={handleShowReportModal}>
                            <Info className="w-5"/>
                        </Buttons>
                        <Buttons handleClick={toggleDarkMode}>
                            {darkMode ? <Sun className="w-5 mx-2"/> : <Moon className="w-5 mx-2"/>}
                        </Buttons>
                        <Buttons
                            handleClick={() => compareFlyoutMenuVisible ? hideCompareFlyoutMenu() : showCompareFlyoutMenu()}>
                            <GitCompareArrows className="w-5"/>
                        </Buttons>
                        <div className="relative w-fit h-fit ">
                            <Buttons handleClick={() => navigate("wishlist")}>
                                <img className={darkMode ? 'white_filter' : ''} src={"/resources/heart.svg"}
                                     alt="wishlist"/>
                            </Buttons>
                            {unreadFavorite > 0 && (
                                <div
                                    className="absolute left-8 top-8 bg-[#E44826] text-white rounded-full px-2 py-1 text-xs font-medium">
                                    {unreadFavorite > 9 ? '9+' : unreadFavorite}
                                </div>
                            )}

                        </div>
                        <div

                            style={{display: user ? Number(user?.role_id) === 4 ? "block" : "none" : "block"}}
                            className="relative w-fit h-fit ">
                            <Buttons handleClick={() => navigate("cart")}>
                                <img className={darkMode ? 'white_filter' : ''} src="/resources/cart.svg" alt="cart"/>
                            </Buttons>
                            {unreadCount > 0 && (
                                <div
                                    className="absolute left-8 top-8 bg-[#E44826] text-white rounded-full px-2 py-1 text-xs font-medium">
                                    {unreadCount > 9 ? '9+' : unreadCount}
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        ref={minimizeControlsDropDownRef}
                        className="relative w-fit h-fit block sm:hidden">
                        <button
                            onClick={() => setMinimizedControlsDropDownVisible(!minimizedControlsDropDownVisible)}
                            className="cursor-pointer h-[48px] w-[48px] flex items-center justify-center rounded-full overflow-hidden">
                            <img className={`${darkMode ? 'white_filter' : ''}`} src="/resources/menu.svg" alt="menu"/>
                        </button>
                        {minimizedControlsDropDownVisible && (
                            <div
                                className="z-50 w-[250px] h-fit bg-white dark:bg-[#333333] shadow-lg rounded-lg absolute top-1 right-0 border-border border dark:border-[#494949]"
                                style={{transform: 'translateY(56px)'}}>
                                <div
                                    className="border-y-border dark:border-[#494949] border-y flex flex-col w-full py-2">
                                    <button
                                        onClick={() => {
                                            compareFlyoutMenuVisible ? hideCompareFlyoutMenu() : showCompareFlyoutMenu()
                                            setMinimizedControlsDropDownVisible(false)
                                        }
                                        }
                                        className="flex flex-row items-center gap-2 px-4 py-2 cursor-pointer hover:bg-[#F2F2F2] dark:hover:bg-[#494949] min-h-12">
                                        <GitCompareArrows className="w-5"/>
                                        <p className="font-medium text-sm dark:text-[#F2F2F2]">{t('compare')}</p>
                                    </button>
                                    <button
                                        onClick={toggleDarkMode}
                                        className="flex flex-row items-center gap-2 px-4 py-2 cursor-pointer hover:bg-[#F2F2F2] dark:hover:bg-[#494949] min-h-12">
                                        {darkMode ? <Sun className="w-5 mx-2"/> : <Moon className="w-5 mx-2"/>}
                                        <p className="font-medium text-sm dark:text-[#F2F2F2]">{darkMode ? t('lightmode') : t('darkmode')}</p>
                                    </button>
                                    <button
                                        onClick={() => {
                                            navigate("wishlist")
                                            setMinimizedControlsDropDownVisible(false)
                                        }}
                                        className="flex flex-row items-center gap-2 px-4 py-2 cursor-pointer hover:bg-[#F2F2F2] dark:hover:bg-[#494949] min-h-12">
                                        <img src="/resources/heart.svg" alt="heart icon"
                                             className={`w-5 mx-2 ${darkMode ? 'white_filter' : ''}`}/>
                                        <p className="font-medium text-sm dark:text-[#F2F2F2]">{t('wishlist')}</p>
                                        {unreadFavorite > 0 && (
                                            <div
                                                className="bg-[#E44826] text-white rounded-full px-2 py-1 text-xs font-medium">
                                                {unreadFavorite > 9 ? '9+' : unreadFavorite}
                                            </div>
                                        )}
                                    </button>
                                    <button
                                        style={{display: user ? Number(user?.role_id) === 4 ? "flex" : "none" : "flex"}}
                                        onClick={() => {
                                            navigate("/cart")
                                            setMinimizedControlsDropDownVisible(false)
                                        }}
                                        className="flex-row items-center gap-2 px-4 py-2 cursor-pointer hover:bg-[#F2F2F2] dark:hover:bg-[#494949] min-h-12">
                                        <img src="/resources/cart.svg" alt="cart icon"
                                             className={`w-5 mx-2 ${darkMode ? 'white_filter' : ''}`}/>
                                        <p className="font-medium text-sm dark:text-[#F2F2F2]">{t('cart')}</p>
                                        {unreadCount > 0 && (
                                            <div
                                                className="bg-[#E44826] text-white rounded-full px-2 py-1 text-xs font-medium">
                                                {unreadCount > 9 ? '9+' : unreadCount}
                                            </div>
                                        )}
                                    </button>
                                    <button
                                        onClick={handleShowReportModal}
                                        className="flex flex-row items-center gap-2 px-4 py-2 cursor-pointer hover:bg-[#F2F2F2] dark:hover:bg-[#494949] min-h-12">
                                        <Info className="w-5 mx-2"/>
                                        <p className="font-medium text-sm dark:text-[#F2F2F2]">Help Center</p>
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    {user ? (
                        <div ref={ref} className="relative w-fit h-fit">
                            <button onClick={() => setDropdownVisible(!dropdownVisible)}
                                    className="cursor-pointer h-[48px] w-[48px] flex items-center justify-center rounded-full overflow-hidden">
                                {renderUserProfilePicture()}
                            </button>
                            {dropdownVisible && (
                                <div
                                    className="z-50 w-[250px] h-fit bg-white dark:bg-[#333333] shadow-lg rounded-lg absolute top-1 right-0 border-border border dark:border-[#494949]"
                                    style={{transform: 'translateY(56px)'}}>
                                    <button
                                        onClick={() => {
                                            navigate("profile")
                                            setDropdownVisible(false)
                                        }}
                                        className="cursor-pointer flex flex-row items-center gap-2 p-4">
                                        <div className="h-10 min-w-10 overflow-hidden rounded-full">
                                            {renderUserProfilePicture()}
                                        </div>
                                        <div className="flex flex-col w-[170px] items-start">
                                            <p className="font-medium text-ellipsis whitespace-nowrap overflow-hidden dark:text-[#F2F2F2]">{user?.first_name} {user?.last_name}</p>
                                            <p className="text-sm text-borderDark dark:text-[#797979] text-ellipsis whitespace-nowrap overflow-hidden">{user?.email}</p>
                                        </div>
                                    </button>
                                    <div
                                        className="border-y-border dark:border-[#494949] border-y flex flex-col w-full pt-2">
                                        {
                                            user?.role_id !== 4 &&
                                            <button
                                                onClick={() => {
                                                    if (user?.role_id === 1 || user?.role_id === 2) {
                                                        navigate("/admin")
                                                    } else if (user?.role_id === 3) {
                                                        updateRenewPlan(false)
                                                        updateExpiredPopUpVisible(false)
                                                        navigate("/seller/" + user?.id)
                                                    }
                                                    setDropdownVisible(false)
                                                    updatePopUpVisible(false)
                                                    updateMenuVisible(false)
                                                }}
                                                className="flex flex-row items-center gap-2 px-4 py-2 cursor-pointer hover:bg-[#F2F2F2] dark:hover:bg-[#494949] min-h-12">
                                                <img src="/resources/dashboard.svg" alt="user icon"
                                                     className={`w-5 mx-2 ${darkMode ? 'white_filter' : ''}`}/>
                                                <p className="font-medium text-sm dark:text-[#F2F2F2]">{t('dashboard')}</p>
                                            </button>
                                        }
                                        <button
                                            onClick={() => {
                                                navigate("profile")
                                                setDropdownVisible(false)
                                            }}
                                            className="flex flex-row items-center gap-2 px-4 py-2 cursor-pointer hover:bg-[#F2F2F2] dark:hover:bg-[#494949] min-h-12">
                                            <img src="/resources/user.svg" alt="user icon"
                                                 className={`w-5 mx-2 ${darkMode ? 'white_filter' : ''}`}/>
                                            <p className="font-medium text-sm dark:text-[#F2F2F2]">{t('myAccount')}</p>
                                        </button>
                                        <button
                                            onClick={() => {
                                                navigate("mes_annonce")
                                                setDropdownVisible(false)
                                            }}
                                            className="flex flex-row items-center gap-2 px-4 py-2 cursor-pointer hover:bg-[#F2F2F2] dark:hover:bg-[#494949] min-h-12">
                                            <img src="/resources/car.svg" alt="car icon"
                                                 className={`w-5 mx-2 ${darkMode ? 'white_filter' : ''}`}/>
                                            <p className="font-medium text-sm dark:text-[#F2F2F2]">{t('myAds')}</p>
                                        </button>
                                        {user?.role_id === 4 &&
                                            <>
                                                <button
                                                    onClick={() => navigate("/my-orders")}
                                                    className="flex flex-row items-center gap-2 px-4 py-2 cursor-pointer hover:bg-[#F2F2F2] dark:hover:bg-[#494949] min-h-12">
                                                    <img src="/resources/orders.svg" alt="order icon"
                                                         className={`w-5 mx-2 ${darkMode ? 'white_filter' : ''}`}/>
                                                    <p className="font-medium text-sm dark:text-[#F2F2F2]">{t('myOrders')}</p>
                                                </button>
                                                <button
                                                    onClick={() => navigate("/my-reservations")}
                                                    className="flex flex-row items-center gap-2 px-4 py-2 cursor-pointer hover:bg-[#F2F2F2] dark:hover:bg-[#494949] min-h-12">
                                                    <img src="/resources/plans.svg" alt="reservation icon"
                                                         className={`w-5 mx-2 ${darkMode ? 'white_filter' : ''}`}/>
                                                    <p className="font-medium text-sm dark:text-[#F2F2F2]">{t('myReservations')}</p>
                                                </button>
                                            </>}
                                        {(user?.role_id === 3 && shop?.status === "unsubscribed") &&
                                            <button
                                                onClick={() => {
                                                    navigate("/seller/" + user.id)
                                                    updatePopUpVisible(false)
                                                    updateRenewPlan(true)
                                                    updateExpiredPopUpVisible(true)
                                                    setDropdownVisible(false)
                                                }}
                                                className="bg-[#F9CDC4] flex flex-row gap-2 items-center text-white px-4 py-2 hover:bg-[#E44826] cursor-pointer">
                                                <img src="/resources/navigate.svg" alt="navigate icon"
                                                     className="mx-2 w-5 white_filter"/>
                                                <p className="font-medium text-sm">{t('sellerSpace.subscription.expired.renewButton')}</p>
                                            </button>
                                        }
                                    </div>
                                    {
                                        user?.role_id === 4 &&
                                        <button
                                            onClick={() => {
                                                navigate("login?signup=true")
                                                setDropdownVisible(false)
                                                updatePopUpVisible(false)
                                                updateMenuVisible(false)
                                            }}
                                            className="flex flex-row items-center bg-[#E44826] gap-2 px-4 py-2 cursor-pointer hover:bg-[#F9CDC4] min-h-12 w-full">
                                            <img src="/resources/seller.svg" alt="user icon"
                                                 className="w-5 mx-2 white_filter"/>
                                            <p className="font-medium text-sm text-white">{t('becomePro')}</p>
                                        </button>
                                    }
                                    <div
                                        className="border-y-border dark:border-[#494949] border-y flex flex-col w-full">
                                        <button
                                            onClick={handleLogout}
                                            className="flex flex-row items-center gap-2 px-4 cursor-pointer hover:bg-[#F2F2F2] dark:hover:bg-[#494949] min-h-12">
                                            {logingOut ? <div className="w-5">
                                                    <div className="loader_white white_filter"></div>
                                                </div> :
                                                <img src="/resources/logout.svg" alt="logout"
                                                     className={`w-5 mx-2 ${darkMode ? 'white_filter' : ''}`}/>}
                                            <p className="font-medium text-sm dark:text-[#F2F2F2]">{t('logout')}</p>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <button onClick={() => navigate("login")}
                                className="cursor-pointer h-[48px] w-[48px] flex items-center justify-center gap-2 border-border dark:border-[#494949] rounded-full border md:w-fit md:border-none">
                            <img src="/resources/user.svg" alt="user icon" className={darkMode ? 'white_filter' : ''}/>
                            <p className="hidden md:block font-medium text-sm truncate dark:text-[#F2F2F2]">{t('login')}</p>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );

}

const Buttons = ({handleClick, children}) => (
    <button onClick={handleClick}
            className="h-[48px] w-[48px] flex items-center justify-center cursor-pointer hover:bg-[#F2F2F2] dark:hover:bg-[#494949] rounded-full border-border dark:border-[#494949] border">
        {children}
    </button>
);

Buttons.propTypes = {
    handleClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
}
