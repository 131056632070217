import {useEffect, useRef, useState} from "react";
import {createPopper} from "@popperjs/core";
import {AnimatePresence, motion} from "framer-motion";
import {useClickOutside} from "../../hooks/context/context";

export default function GenericDropDown({parent = null, children}) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const triggerRef = useRef(null);
    const container = useRef(null);

    const handleClickOutside = () => {
        setIsOpen(false);
    }

    useClickOutside(container, handleClickOutside);

    useEffect(() => {
        let popperInstance = null;

        if (isOpen && triggerRef.current && dropdownRef.current) {
            popperInstance = createPopper(triggerRef.current, dropdownRef.current, {
                placement: "bottom-start",
                modifiers: [
                    { name: "preventOverflow", options: { boundary: "viewport" } },
                    { name: "offset", options: { offset: [0, 10] } },
                    { name: "computeStyles", options: { adaptive: false, gpuAcceleration: false } },
                ]
            });
        }

        return () => {
            if (popperInstance) {
                popperInstance.destroy();
            }
        };
    }, [isOpen]);

    return (
        <div ref={container} className="dropdown cta relative">
            {parent === null ? (
                <button
                    ref={triggerRef}
                    onClick={() => setIsOpen(!isOpen)}
                    className="cta dropdown w-full max-w-[40px] h-10 px-4 py-2 bg-white dark:bg-[#333] cursor-pointer flex items-center justify-center rounded-lg border border-gray-200 dark:border-gray-500"
                >
                    <div className="cta dropdown flex flex-col items-start gap-0.5 justify-self-start">
                        <div className="cta dropdown w-1 h-1 rounded-full bg-gray-500 dark:bg-gray-300"></div>
                        <div className="cta dropdown w-1 h-1 rounded-full bg-gray-500 dark:bg-gray-300"></div>
                        <div className="cta dropdown w-1 h-1 rounded-full bg-gray-500 dark:bg-gray-300"></div>
                    </div>
                </button>
            ) : (
                <button
                    ref={triggerRef}
                    onClick={() => setIsOpen(!isOpen)}
                    className={"cursor-pointer"}
                >
                    {parent}
                </button>
            )}

            <AnimatePresence>
                {isOpen && (
                    <motion.ul
                        initial={{opacity: 0, scale: 0.95}}
                        animate={{opacity: 1, scale: 1}}
                        exit={{opacity: 0, scale: 0.95}}
                        transition={{duration: 0.3}}
                        ref={dropdownRef}
                        className="absolute z-50 w-fit mt-1 bg-white border border-gray-300 dark:border-gray-500 rounded-md shadow-lg overflow-hidden"
                        onClick={() => setIsOpen(false)}
                    >
                        {children}
                    </motion.ul>
                )}
            </AnimatePresence>
        </div>
    );
}