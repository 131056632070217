import React from 'react';
import { FormControl, Modal, useMediaQuery, useTheme} from '@mui/material';
import GenericSelect from "../../../../../components/GenericSelect/GenericSelect";
import {useTranslation} from 'react-i18next';

const   FilterModal = ({columns, rows, filtersOpen, selectedFilters, setSelectedFilters, handleCloseFilters}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {t} = useTranslation();
    const handleFilterChange = (selectedOptions, field) => {
        const values = selectedOptions.map(opt => opt.value);
        setSelectedFilters((prev) => ({
            ...prev,
            [field]: values,
        }));
    };
    const handleClearFilters = () => {
        const clearedFilters = {};
        columns.forEach((col) => {
            clearedFilters[col.field] = [];
        });
        setSelectedFilters(clearedFilters);
    };
    return (
        <Modal open={filtersOpen} onClose={handleCloseFilters}>
            <div
                className={`bg-white dark:bg-[#333333] rounded-lg p-6 w-full ${isMobile ? 'mx-4' : 'max-w-lg mx-auto'} shadow-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}>
                <h3 className="text-lg font-semibold text-center mb-4 dark:text-[#F2F2F2]">
                    {t('adminPages.dataTable.filterOptions')}
                </h3>
                <div className="mb-6">
                    {columns.map((col) =>
                            col.field !== 'actions' && (
                                <FormControl key={col.field} fullWidth margin="dense" className="mb-4">
                                    <label className="block text-gray-600 dark:text-[#CBCBCB] mb-1 text-sm font-light">{col.headerName}</label>
                                    <GenericSelect
                                        isMulti={true}
                                        inputs={rows.map((row) => ({value: row[col.field], label: row[col.field]}))}
                                        defaults={selectedFilters[col.field]?.map((filter) => ({
                                            value: filter,
                                            label: filter
                                        })) || []}
                                        handleChanges={(selectedOptions) => handleFilterChange(selectedOptions, col.field)}
                                        setWidth="100%"
                                        placeholder={`${t('adminPages.dataTable.select')} ${col.headerName}`}
                                    />
                                </FormControl>
                            )
                    )}
                </div>
                <div className="flex justify-end mt-4">
                    <button
                        onClick={handleClearFilters}
                        type="button"
                        className="text-[#E44826] font-medium mr-4"
                    >
                        {t('adminPages.dataTable.clear')}
                    </button>
                    <button
                        onClick={handleCloseFilters}
                        type="button"
                        className="px-6 py-2 rounded text-white bg-[#E44826] hover:bg-[#F9CDC4]"
                    >
                        {t('adminPages.dataTable.apply')}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default FilterModal;
