import CheckBox from "../../../../components/Checkbox";
import AddToCart from "../../../../components/AddToCart/AddToCart";
import {useEffect, useState} from "react";
import {useCompareContext} from "../../../../hooks/context/context";
import AddToFavorite from "../../../Marketplace/components/AddToFavorite";
import {TagsList} from "../../../Marketplace/components/Cards/ProductCard";
import {formatCurrency} from "../../../../utils/utils";
import {ImageWithFallback} from "../../../../components/ImageWithFallBack/ImageWithFallBack";
import {useNavigate} from "react-router";

export default function ProductCardSearchItem({product,id, name,serial_number, image, price, category, tags = [],brandLogo,average_rating,comments_count,quantity_stock}) {
    const [compare, updateCompare] = useState(false)

    const {
        compareProducts,
        addProductToCompare,
        removeProductFromCompare,
    } = useCompareContext();

    useEffect(() => {
        const element = compareProducts.find((p) => Number(p.id) === Number(id));
        if (element) {
            updateCompare(true);
        } else {
            updateCompare(false);
        }
    }, [compareProducts, id]);

    const handleAddToCompareProducts = () => {
        if (!compare && compareProducts.length < 4) {
            addProductToCompare({...product});
        } else {
            console.log("remove")
            removeProductFromCompare({...product});
        }
    }

    const navigate = useNavigate();

    return (
        <div
            onClick={(e)=>{
                if (e.target.classList.contains("wishlist")) return;
                if (e.target.parentElement.classList.contains("wishlist")) return;
                if (e.target.classList.contains("cta")) return;
                if (e.target.parentElement.classList.contains("cta")) return;
                navigate(`/product/${id}`)
            }}
            className="w-full h-fit p-2 gap-4 flex flex-col sm:flex-row items-center border border-border dark:border-[#494949] rounded-lg cursor-pointer dark:bg-[#232323]">
            <div className='flex object-cover sm:w-[200px] max-h-[200px] sm:max-h-full relative'>
                <ImageWithFallback src={image} alt={name} className="object-contain rounded-lg aspect-square"/>
                <TagsList tags={tags}/>
            </div>
            <div className="w-full h-fit flex flex-col gap-2 items-start relative">
                <div className="flex flex-col gap-2 w-full h-fit">
                    <div className="flex flex-row items-center gap-4">
                        <div className="flex flex-row gap-2 items-center">
                            <div className="flex flex-row gap-2 items-center">
                                <img src="/resources/star.svg" alt="star"/>
                                <p className="font-bold dark:text-[#F2F2F2]">{average_rating}</p>
                            </div>
                            <p className="font-medium text-[#9B9B9B] dark:text-[#797979] text-sm">( {comments_count} Avis)</p>
                        </div>
                        <p className="font-medium text-sm text-[#797979] dark:text-[#797979]">SKU : {serial_number}</p>
                    </div>
                    <p className="line-clamp-2 text-ellipsis overflow-hidden font-medium w-full dark:text-[#F2F2F2]">{name}</p>
                    <div className="flex flex-row gap-4 items-center">
                        <p className="px-4 py-1 border-border dark:border-[#494949] border rounded-lg text-sm flex items-center justify-center w-fit dark:text-[#F2F2F2]">{category}</p>
                        <div className="flex flex-row items-center gap-2">
                            <div className="h-14 flex items-center justify-center">
                                <img src={brandLogo} alt="marque" className="object-contain w-full h-full max-h-10 max-w-10"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-end gap-2">
                    <p className="font-bold text-2xl dark:text-[#F2F2F2]">{formatCurrency(price)}</p>
                </div>
                <div className="cta flex w-fit h-fit gap-4 items-center">
                    <div
                        onClick={handleAddToCompareProducts}
                        className={`cta h-12 flex px-2 py-3 flex-row items-center justify-center gap-2 rounded-lg border-border dark:border-[#494949] border cursor-pointer dark:bg-[#232323] ${
                            compare ? "bg-[#FFE9E4] border-[#FF7354]" : "bg-white border-[#D4D4D8]"
                        }`}>
                        <CheckBox checkedValueState={[compare, updateCompare]}
                                  handleCheck={handleAddToCompareProducts}/>
                        <p className={`cta font-medium text-[13px] dark:text-[#F2F2F2] ${
                            compare ? "text-[#FF7354]" : "text-black"
                        }`}>Comparer</p>
                    </div>
                    <AddToCart ids={[id]} quantity={quantity_stock}/>
                </div>
                <div className="absolute top-1 right-1">
                    <AddToFavorite id={Number(id)} ElementType={"product"}/>
                </div>
            </div>
        </div>
    )
}