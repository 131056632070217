import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendResetLink } from "../../../../services/AuthAndSignUp/API";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setError("");

        if (!email) {
            setError(t("email_required"));
            return;
        }

        setLoading(true);
        try {
            await sendResetLink(email);
            navigate("/reset-link-sent", { state: { email } });
        } catch (err) {
            setError(t("send_link_error"));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="w-full h-full flex flex-col items-center justify-center p-6 space-y-6">
            <h2 className="text-xl font-bold text-gray-800 dark:text-[#F2F2F2] text-center">
                {t("reset_password_title")}
            </h2>
            <p className="text-sm text-gray-600 dark:text-[#CBCBCB] text-center max-w-sm">
                {t("reset_password_instruction_email")}
            </p>
            <form onSubmit={handleFormSubmit} className="w-[80%] max-w-md space-y-4">
                <div className="flex flex-col">
                    <label
                        htmlFor="email"
                        className="text-sm font-medium text-gray-700 dark:text-[#CBCBCB] mb-2"
                    >
                        {t("email_address")}
                    </label>
                    <input
                        type="email"
                        id="email"
                        className={`w-full h-12 px-4 border rounded-lg shadow-sm focus:outline-none focus:ring-2 dark:bg-[#333333] dark:text-[#F2F2F2] dark:border-[#494949] ${
                            error
                                ? "border-red-500 focus:ring-red-500 dark:border-[#E44826] dark:focus:ring-[#E44826]"
                                : "focus:ring-primaryLight focus:border-primaryLight dark:focus:ring-[#F9CDC4] dark:focus:border-[#F9CDC4]"
                        }`}
                        placeholder={t("enter_email")}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setError("");
                        }}
                    />
                    {error && <p className="text-red-500 dark:text-[#E44826] text-sm mt-2">{error}</p>}
                </div>
                <button
                    type="submit"
                    disabled={loading}
                    className={`w-full h-12 bg-primaryLight text-white rounded-lg font-medium transition hover:bg-primaryDark dark:bg-[#E44826] dark:hover:bg-[#F9CDC4] ${
                        loading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                >
                    {loading ? t("sending") : t("send_link")}
                </button>
            </form>
        </div>
    );
};

export default ForgotPassword;
