import React, {useEffect, useState} from 'react';
import {getProfile, updateProfile} from "../../services/AuthAndSignUp/API";
import {useNonMarketPlaceRoutesContext} from "../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import {
    FaEye,
    FaEyeSlash,
    FaSave,
    FaEdit,
    FaCamera,
    FaUser,
    FaLock,
    FaMapMarkerAlt,
    FaPhone,
    FaEnvelope,
    FaBirthdayCake
} from "react-icons/fa";
import {toast} from "sonner";
import ToastSuccess from "../../components/Toasts/Success/ToastSuccess";
import {toastStyle} from "../../components/Toasts/Warn/ToastWarn";
import { useTranslation } from 'react-i18next';

const ProfilePage = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('account');
    const [userData, setUserData] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const {updateLoading} = useNonMarketPlaceRoutesContext();
    const [isLoading, setIsLoading] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);

    // Form states
    const [formData, setFormData] = useState({
        id: '',
        newPassword: '',
        confirmPassword: '',
        first_name: '',
        last_name: '',
        email: '',
        date_of_birth: '',
        phone_number: '',
        street: '',
        city: '',
        postal_code: '',
        country: ''
    });

    const updateUserData = (response)=>{
        const user = response.data.user;
        setUserData(user);
        setProfileImage(user.profile_photo || null);
        setFormData({
            ...formData,
            id: user.id || '',
            first_name: user.first_name || '',
            last_name: user.last_name || '',
            email: user.email || '',
            date_of_birth: user.date_of_birth || '',
            phone_number: user.contact?.phone_number || '',
            street: user.contact?.address?.street || '',
            city: user.contact?.address?.city || '',
            postal_code: user.contact?.address?.postal_code || '',
            country: user.contact?.address?.country || ''
        });
    }

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await getProfile();
                if (response.status === 200 && response.data?.user) {
                    updateUserData(response);
                }
                updateLoading(false);
            } catch (error) {
                console.log('Error fetching profile:', error);
                updateLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfileImage(file);
            setIsDisabled(false);
        }
    };

    const handleSave = async () => {
        const formDataToSend = new FormData();

        if (activeTab === 'account') {
            Object.keys(formData).forEach(key => {
                if (formData[key] && !['newPassword', 'confirmPassword'].includes(key)) {
                    formDataToSend.append(key, formData[key]);
                }
            });
            if (profileImage instanceof File) {
                formDataToSend.append('profile_photo_file', profileImage);
            }
        } else {
            formDataToSend.append('password', formData.newPassword);
        }

        setIsLoading(true);
        formDataToSend.append("_method", "PUT")
        updateProfile(formData.id, formDataToSend)
            .then((response) => {
                if (response.status === 200) {
                    getProfile().then((response) => {
                        if (response.status === 200){
                            updateUserData(response);
                        }
                    }).catch((error) => {})
                    toast.success(<ToastSuccess message={t('profile.updateSuccess')}/>, toastStyle);
                }
            })
            .catch((error) => {
            })
            .finally(() => {
                setIsEditing(false);
                setIsDisabled(true);
                setIsLoading(false);
            })
    };

    return (
        <div
            className="h-screen bg-gradient-to-br from-slate-100 to-white dark:from-[#232323] dark:to-[#494949] flex flex-col items-center py-6 px-4 sm:px-6 lg:px-8 max-h-[calc(100vh-50px)] overflow-y-auto">
            <div className="w-full max-w-7xl">
                <div className="flex flex-col lg:flex-row gap-6">
                    {/* Left Sidebar */}
                    <div className="w-full lg:w-1/3">
                        <div className="bg-white dark:bg-[#232323] rounded-xl shadow-sm p-6 hover:shadow-md transition-all duration-300">
                            <div className="flex flex-col items-center">
                                <div className="relative group">
                                    <div
                                        className="w-32 h-32 sm:w-40 sm:h-40 lg:w-48 lg:h-48 rounded-full overflow-hidden border-4 border-slate-100 dark:border-[#494949] shadow-lg transition-all duration-300 transform group-hover:scale-102">
                                        {profileImage ? (
                                            <img
                                                src={profileImage instanceof File ? URL.createObjectURL(profileImage) : profileImage}
                                                alt="Profile"
                                                className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                                            />
                                        ) : (
                                            <div
                                                className="w-full h-full bg-gradient-to-br from-[#E44826] to-[#F9CDC4] dark:from-[#595959] dark:to-[#797979] flex items-center justify-center">
                                                <span
                                                    className="text-white text-4xl sm:text-5xl lg:text-6xl font-semibold">
                                                    {userData?.username?.[0]?.toUpperCase() || '?'}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    {isEditing && (
                                        <label
                                            htmlFor="profile-upload"
                                            className="absolute bottom-2 right-2 bg-[#E44826] dark:bg-[#595959] p-3 rounded-full cursor-pointer hover:bg-[#F9CDC4] dark:hover:bg-[#797979] transition-all duration-300 transform hover:scale-105 shadow-md"
                                        >
                                            <FaCamera className="text-white text-lg"/>
                                            <input
                                                id="profile-upload"
                                                type="file"
                                                className="hidden"
                                                accept="image/*"
                                                onChange={handleImageUpload}
                                            />
                                        </label>
                                    )}
                                </div>
                                <h2 className="mt-4 text-2xl font-bold text-slate-800 dark:text-[#F2F2F2]">{userData?.username || t('profile.loading')}</h2>
                                <p className="text-slate-600 dark:text-[#CBCBCB] mt-2 flex items-center gap-2 text-sm">
                                    <FaEnvelope className="text-slate-500 dark:text-[#797979]"/>
                                    <span
                                        className="truncate max-w-[200px] sm:max-w-[300px]">{userData?.email || t('profile.loading')}</span>
                                </p>
                            </div>

                            <div className="mt-8">
                                <ProfileTabs activeTab={activeTab} setActiveTab={setActiveTab}/>
                            </div>

                            {/* Save Button for Mobile */}
                            <div className="mt-6 lg:hidden">
                                <button
                                    type="button"
                                    onClick={handleSave}
                                    className={`w-full flex items-center justify-center px-6 py-3 rounded-lg shadow-sm transition-all duration-300 text-sm ${
                                        isDisabled || (!isEditing && activeTab === 'account')
                                            ? 'bg-[#F9CDC4] dark:bg-[#494949] text-slate-500 dark:text-[#CBCBCB] cursor-not-allowed'
                                            : 'bg-[#E44826] dark:bg-[#595959] text-white hover:bg-[#F9CDC4] dark:hover:bg-[#797979] transform hover:-translate-y-0.5'
                                    }`}
                                    disabled={isDisabled || (!isEditing && activeTab === 'account') || isLoading}
                                >
                                    <FaSave className={`mr-2 w-4 h-4 ${isLoading ? 'animate-spin' : ''}`}/>
                                    {isLoading ? t('profile.saving') : saveSuccess ? t('profile.saved') : t('profile.saveChanges')}
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="w-full lg:w-2/3">
                        <div className="bg-white dark:bg-[#232323] rounded-xl shadow-sm p-6 hover:shadow-md transition-all duration-300">
                            <div className="flex justify-between items-center mb-6">
                                <h3 className="text-xl font-bold text-slate-800 dark:text-[#F2F2F2]">
                                    {activeTab === 'account' ? t('profile.personalInformation') : t('profile.securitySettings')}
                                </h3>
                                {activeTab === 'account' && (
                                    <button
                                        onClick={() => setIsEditing(!isEditing)}
                                        className="text-slate-600 dark:text-[#CBCBCB] hover:text-[#E44826] dark:hover:text-[#F9CDC4] transition-all duration-300 p-2.5 rounded-lg hover:bg-[#F9CDC4] dark:hover:bg-[#494949]"
                                    >
                                        <FaEdit className="w-5 h-5"/>
                                    </button>
                                )}
                            </div>

                            <div className="mt-6">
                                {activeTab === 'account' ? (
                                    <ProfileForm
                                        formData={formData}
                                        setFormData={setFormData}
                                        isEditing={isEditing}
                                        setIsDisabled={setIsDisabled}
                                    />
                                ) : (
                                    <PasswordForm
                                        formData={formData}
                                        setFormData={setFormData}
                                        setIsDisabled={setIsDisabled}
                                    />
                                )}
                            </div>

                            {/* Save Button for Desktop */}
                            <div className="hidden lg:flex justify-end mt-8 pt-4 border-t border-slate-200 dark:border-[#494949]">
                                <button
                                    type="button"
                                    onClick={handleSave}
                                    className={`flex items-center px-6 py-3 rounded-lg shadow-sm transition-all duration-300 text-sm ${
                                        isDisabled || (!isEditing && activeTab === 'account')
                                            ? 'bg-[#F9CDC4] dark:bg-[#494949] text-slate-500 dark:text-[#CBCBCB] cursor-not-allowed'
                                            : 'bg-[#E44826] dark:bg-[#595959] text-white hover:bg-[#F9CDC4] dark:hover:bg-[#797979] transform hover:-translate-y-0.5'
                                    }`}
                                    disabled={isDisabled || (!isEditing && activeTab === 'account') || isLoading}
                                >
                                    <FaSave className={`mr-2 w-4 h-4 ${isLoading ? 'animate-spin' : ''}`}/>
                                    {isLoading ? t('profile.saving') : saveSuccess ? t('profile.saved') : t('profile.saveChanges')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;


const PasswordForm = ({formData, setFormData, setIsDisabled}) => {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setPasswordError] = useState('');

    const validateForm = () => {
        if (!formData.newPassword || formData.newPassword.length < 8) {
            setPasswordError(t('profile.passwordErrors.tooShort'));
            return false;
        }
        if (formData.newPassword !== formData.confirmPassword) {
            setPasswordError(t('profile.passwordErrors.noMatch'));
            return false;
        }
        setPasswordError('');
        return true;
    };

    useEffect(() => {
        setIsDisabled(!validateForm());
    }, [formData]); // Run validation whenever formData changes

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    return (
        <form className="space-y-6 max-w-md mx-auto">
            <div className="space-y-2">
                <label className="block text-sm font-medium text-slate-700 dark:text-[#F2F2F2] flex items-center gap-2">
                    <FaLock className="text-slate-500 dark:text-[#797979]"/>
                    {t('profile.labels.newPassword')}
                </label>
                <div className="relative">
                    <input
                        type={showPassword ? 'text' : 'password'}
                        name="newPassword"
                        className="p-3 w-full border border-slate-300 dark:border-[#494949] dark:bg-[#232323] dark:text-[#F2F2F2] rounded-lg shadow-sm focus:ring-2 focus:ring-[#E44826] dark:focus:ring-[#595959] focus:border-[#E44826] dark:focus:border-[#595959] transition-all duration-300"
                        value={formData.newPassword}
                        onChange={handleInputChange}
                    />
                    <button
                        type="button"
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-slate-400 dark:text-[#797979] hover:text-[#E44826] dark:hover:text-[#F9CDC4] transition-colors"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? <FaEyeSlash className="w-4 h-4"/> : <FaEye className="w-4 h-4"/>}
                    </button>
                </div>
                <p className="text-sm text-slate-500 dark:text-[#CBCBCB] flex items-center gap-2">
                    <FaLock className="text-slate-400 dark:text-[#797979]"/>
                    {t('profile.passwordRequirements')}
                </p>
            </div>

            <div className="space-y-2">
                <label className="block text-sm font-medium text-slate-700 dark:text-[#F2F2F2] flex items-center gap-2">
                    <FaLock className="text-slate-500 dark:text-[#797979]"/>
                    {t('profile.labels.confirmPassword')}
                </label>
                <div className="relative">
                    <input
                        type={showPassword ? 'text' : 'password'}
                        name="confirmPassword"
                        className="p-3 w-full border border-slate-300 dark:border-[#494949] dark:bg-[#232323] dark:text-[#F2F2F2] rounded-lg shadow-sm focus:ring-2 focus:ring-[#E44826] dark:focus:ring-[#595959] focus:border-[#E44826] dark:focus:border-[#595959] transition-all duration-300"
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                    />
                    <button
                        type="button"
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-slate-400 dark:text-[#797979] hover:text-[#E44826] dark:hover:text-[#F9CDC4] transition-colors"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? <FaEyeSlash className="w-4 h-4"/> : <FaEye className="w-4 h-4"/>}
                    </button>
                </div>
            </div>

            {passwordError && (
                <div className="bg-red-50 dark:bg-[#494949] border-l-4 border-red-500 dark:border-[#E44826] p-4 rounded-lg">
                    <p className="text-red-700 dark:text-[#F9CDC4] text-sm flex items-center gap-2">
                        <FaLock className="text-red-500 dark:text-[#E44826]"/>
                        {passwordError}
                    </p>
                </div>
            )}
        </form>
    );
};

const ProfileForm = ({formData, setFormData, isEditing, setIsDisabled}) => {
    const { t } = useTranslation();
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        setIsDisabled(false);
    };

    const inputClasses = `mt-1 p-3 w-full border border-slate-300 dark:border-[#494949] rounded-lg shadow-sm focus:ring-2 focus:ring-[#E44826] dark:focus:ring-[#595959] focus:border-[#E44826] dark:focus:border-[#595959] transition-all duration-300 ${
        !isEditing ? 'bg-slate-50 dark:bg-[#494949]' : 'hover:border-[#E44826] dark:hover:border-[#595959] dark:bg-[#232323]'
    } dark:text-[#F2F2F2]`;

    return (
        <form className="space-y-6">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                <div className="space-y-2">
                    <label className="block text-sm font-medium text-slate-700 dark:text-[#F2F2F2] flex items-center gap-2">
                        <FaUser className="text-slate-500 dark:text-[#797979]"/>
                        {t('profile.labels.lastName')}
                    </label>
                    <input
                        type="text"
                        name="last_name"
                        className={inputClasses}
                        value={formData.last_name}
                        onChange={handleInputChange}
                        readOnly={!isEditing}
                    />
                </div>
                <div className="space-y-2">
                    <label className="block text-sm font-medium text-slate-700 dark:text-[#F2F2F2] flex items-center gap-2">
                        <FaUser className="text-slate-500 dark:text-[#797979]"/>
                        {t('profile.labels.firstName')}
                    </label>
                    <input
                        type="text"
                        name="first_name"
                        className={inputClasses}
                        value={formData.first_name}
                        onChange={handleInputChange}
                        readOnly={!isEditing}
                    />
                </div>

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-slate-700 dark:text-[#F2F2F2] flex items-center gap-2">
                        <FaBirthdayCake className="text-slate-500 dark:text-[#797979]"/>
                        {t('profile.labels.dateOfBirth')}
                    </label>
                    <input
                        type="date"
                        name="date_of_birth"
                        className={inputClasses}
                        value={formData.date_of_birth}
                        onChange={handleInputChange}
                        readOnly={!isEditing}
                    />
                </div>

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-slate-700 dark:text-[#F2F2F2] flex items-center gap-2">
                        <FaEnvelope className="text-slate-500 dark:text-[#797979]"/>
                        {t('profile.labels.email')}
                    </label>
                    <input
                        type="email"
                        name="email"
                        className={inputClasses}
                        value={formData.email}
                        onChange={handleInputChange}
                        readOnly={!isEditing}
                    />
                </div>

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-slate-700 dark:text-[#F2F2F2] flex items-center gap-2">
                        <FaPhone className="text-slate-500 dark:text-[#797979]"/>
                        {t('profile.labels.phoneNumber')}
                    </label>
                    <input
                        type="tel"
                        name="phone_number"
                        className={inputClasses}
                        value={formData.phone_number}
                        onChange={handleInputChange}
                        readOnly={!isEditing}
                    />
                </div>

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-slate-700 dark:text-[#F2F2F2] flex items-center gap-2">
                        <FaMapMarkerAlt className="text-slate-500 dark:text-[#797979]"/>
                        {t('profile.labels.postalCode')}
                    </label>
                    <input
                        type="text"
                        name="postal_code"
                        className={inputClasses}
                        value={formData.postal_code}
                        onChange={handleInputChange}
                        readOnly={!isEditing}
                    />
                </div>

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-slate-700 dark:text-[#F2F2F2] flex items-center gap-2">
                        <FaMapMarkerAlt className="text-slate-500 dark:text-[#797979]"/>
                        {t('profile.labels.city')}
                    </label>
                    <input
                        type="text"
                        name="city"
                        className={inputClasses}
                        value={formData.city}
                        onChange={handleInputChange}
                        readOnly={!isEditing}
                    />
                </div>

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-slate-700 dark:text-[#F2F2F2] flex items-center gap-2">
                        <FaMapMarkerAlt className="text-slate-500 dark:text-[#797979]"/>
                        {t('profile.labels.country')}
                    </label>
                    <input
                        type="text"
                        name="country"
                        className={inputClasses}
                        value={formData.country}
                        onChange={handleInputChange}
                        readOnly={!isEditing}
                    />
                </div>

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-slate-700 dark:text-[#F2F2F2] flex items-center gap-2">
                        <FaMapMarkerAlt className="text-slate-500 dark:text-[#797979]"/>
                        {t('profile.labels.streetAddress')}
                    </label>
                    <input
                        type="text"
                        name="street"
                        className={inputClasses}
                        value={formData.street}
                        onChange={handleInputChange}
                        readOnly={!isEditing}
                    />
                </div>
            </div>
        </form>
    );
};

const ProfileTabs = ({activeTab, setActiveTab}) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col space-y-2">
            <button
                className={`flex items-center gap-3 p-3 rounded-lg transition-all duration-300 text-sm ${
                    activeTab === 'account'
                        ? 'bg-[#F9CDC4] dark:bg-[#494949] text-[#E44826] dark:text-[#F9CDC4] font-medium shadow-sm'
                        : 'text-slate-600 dark:text-[#CBCBCB] hover:bg-[#F9CDC4] dark:hover:bg-[#494949] hover:text-[#E44826] dark:hover:text-[#F9CDC4]'
                }`}
                onClick={() => setActiveTab('account')}
            >
                <FaUser className={activeTab === 'account' ? 'text-[#E44826] dark:text-[#F9CDC4]' : 'text-slate-400 dark:text-[#797979]'}/>
                {t('profile.accountSettings')}
            </button>
            <button
                className={`flex items-center gap-3 p-3 rounded-lg transition-all duration-300 text-sm ${
                    activeTab === 'password'
                        ? 'bg-[#F9CDC4] dark:bg-[#494949] text-[#E44826] dark:text-[#F9CDC4] font-medium shadow-sm'
                        : 'text-slate-600 dark:text-[#CBCBCB] hover:bg-[#F9CDC4] dark:hover:bg-[#494949] hover:text-[#E44826] dark:hover:text-[#F9CDC4]'
                }`}
                onClick={() => setActiveTab('password')}
            >
                <FaLock className={activeTab === 'password' ? 'text-[#E44826] dark:text-[#F9CDC4]' : 'text-slate-400 dark:text-[#797979]'}/>
                {t('profile.passwordManagement')}
            </button>
        </div>
    );
};