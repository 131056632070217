import Select from "react-select";
import {categories, customStyles} from "../Header/GlobalSearch/GlobalSearch";
import {useState} from "react";
import {useNavigate} from "react-router";
import {useMarketplaceContext} from "../../pages/Marketplace";

export default function SearchPopUp(){


    const {updatePopupVisible, updateShowSearchBar} = useMarketplaceContext();
    const [selectedCategory, setSelectedCategory] = useState(categories[0].value);
    const [term, setTerm] = useState("");

    const navigate = useNavigate();

    const handleSearch = () => {
        if (selectedCategory && term.trim()) {
            navigate("/search", { state: { category: selectedCategory, term:term } });
            updatePopupVisible(false)
            updateShowSearchBar(false)
        } else {
            console.log("Please select a category and enter a search term.");
        }
    };

    // Trigger search when "Enter" is pressed
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };


    return (
        <div className="fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 z-[1000] w-full px-[11px]">
            <div
                className="w-full h-14 bg-[#ECECEC] dark:bg-[#333333] border-[#E5E7EB] dark:border-[#494949] border pr-4 flex flex-row items-center rounded-lg justify-between">
                <div className="flex flex-row items-center gap-2 w-full">
                    <div className="min-w-[140px]">
                        <Select
                            options={categories}
                            defaultValue={categories[0]}
                            styles={customStyles}
                            placeholder="Produit"
                            onChange={(selectedOption) => setSelectedCategory(selectedOption.value)}
                        />
                    </div>
                    <input
                        className="w-full h-9 bg-[#ECECEC] dark:bg-[#333333] border-none focus:outline-none"
                        placeholder="Recherche"
                        value={term}
                        onChange={(e) => setTerm(e.target.value)}
                        onKeyDown={handleKeyPress}
                    />
                </div>
                <button
                    onClick={() => {
                        handleSearch()
                        updatePopupVisible(false)
                        updateShowSearchBar(false)
                    }}
                >
                    <img
                        src="/resources/search.svg"
                        alt="search icon"
                        className="dark:invert"
                    />
                </button>
            </div>
        </div>
    )
}