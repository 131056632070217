import React, { useEffect, useState, useRef } from "react";
import { Send, X, UserCircle, Menu, Search, MoreVertical } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Conversations, StoreMessages, ConversationMessages } from "../../../../services/marketplace/ChatServices";
import Cookies from 'js-cookie';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

export default function InboxPage() {
    const { t } = useTranslation();
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [replyMessage, setReplyMessage] = useState("");
    const [isMobileConversationListOpen, setIsMobileConversationListOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const messagesEndRef = useRef(null);
    const inputRef = useRef(null);
    const [userId] = useState(() => {
        const user = Cookies.get('user');
        return user ? JSON.parse(user).id : null;
    });

    const echoRef = useRef(null);
    const channelRef = useRef(null);

    useEffect(() => {
        const initEcho = () => {
            const wsScheme = process.env.REACT_APP_PUSHER_SCHEME === 'https' ? 'wss' : 'ws';

            echoRef.current = new Echo({
                broadcaster: 'pusher',
                key: process.env.REACT_APP_PUSHER_APP_KEY,
                cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
                wsHost: process.env.REACT_APP_PUSHER_HOST,
                wsPort: process.env.REACT_APP_PUSHER_PORT,
                forceTLS: false,
                encrypted: true,
                enabledTransports: ["ws"]
            });
        };

        if (!echoRef.current) {
            initEcho();
        }

        return () => {
            if (echoRef.current) {
                echoRef.current.disconnect();
                echoRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        const subscribeToChannel = () => {
            if (!selectedConversation?.id || !echoRef.current) {
                return;
            }

            if (channelRef.current) {
                channelRef.current.stopListening('message.sent');
                echoRef.current.leave(`conversation.${selectedConversation.id}`);
            }

            channelRef.current = echoRef.current.channel(`conversation.${selectedConversation.id}`);


            channelRef.current.listen('.message.sent', async (e)=>{
                try {
                    console.log("Message Received : ",e);
                    const messageData = JSON.parse(e);
                    setSelectedConversation(prev => {
                        const newMessages = [...prev.messages];
                        newMessages.unshift(messageData);
                        return {
                            ...prev,
                            messages: newMessages
                        };
                    });
                } catch (error) {
                    const messagesData = await ConversationMessages(selectedConversation.id);
                    setSelectedConversation(prev => ({
                        ...prev,
                        messages: messagesData.data.data.data
                    }));
                }
            });
        };

        subscribeToChannel();

        return () => {
            if (channelRef.current) {
                channelRef.current.stopListening('message.sent');
                if (echoRef.current) {
                    echoRef.current.leave(`conversation.${selectedConversation?.id}`);
                }
                channelRef.current = null;
            }
        };
    }, [selectedConversation?.id]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
        if (selectedConversation && inputRef.current) {
            inputRef.current.focus();
        }
    }, [selectedConversation?.messages]);

    useEffect(() => {
        const loadConversations = async () => {
            try {
                const response = await Conversations();
                setConversations(response.data?.data || []);
            } catch (error) {
                console.error("Error loading conversations:", error);
            }
        };

        loadConversations();
    }, [userId]);

    const filteredConversations = conversations.filter(conv =>
        conv.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleConversationSelect = async (conversation) => {
        try {
            const messagesData = await ConversationMessages(conversation.id);
            setSelectedConversation({
                ...conversation,
                messages: messagesData.data.data.data
            });
            setReplyMessage("");
            setIsMobileConversationListOpen(false);
        } catch (error) {
            console.error("Error loading messages:", error);
        }
    };

    const handleReplySubmit = async (e) => {
        e.preventDefault();
        if (!replyMessage.trim()) return;

        try {
            const messageData = {
                sender_id: userId,
                conversation_id: selectedConversation.id,
                content: replyMessage.trim()
            };

            await StoreMessages(messageData);
            setReplyMessage("");

            const messagesData = await ConversationMessages(selectedConversation.id);
            setSelectedConversation(prev => ({
                ...prev,
                messages: messagesData.data.data.data
            }));

        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleReplySubmit(e);
        }
    };

    return (
        <div className="container mx-auto px-4 py-6 h-screen max-h-[calc(91vh-40px)] overflow-hidden">
            <h1 className="text-2xl font-semibold text-black dark:text-[#F2F2F2] mb-4 block md:hidden">{t('inbox.title')}</h1>

            <div className="flex flex-col md:flex-row gap-4 h-[calc(100%-60px)] max-w-full overflow-y-auto">
                <button
                    onClick={() => setIsMobileConversationListOpen(!isMobileConversationListOpen)}
                    className="md:hidden w-full bg-primaryDark dark:bg-[#E44826] text-white p-2 rounded-lg flex items-center justify-center mb-4"
                >
                    <Menu className="mr-2" />
                    {isMobileConversationListOpen ? t('inbox.toggleButton.hide') : t('inbox.toggleButton.show')}
                </button>

                <div className={`
                    w-full md:w-96 md:flex-shrink-0 
                    ${isMobileConversationListOpen ? 'block' : 'hidden md:block'}
                    fixed md:static inset-0 z-50 md:z-0 
                    md:relative bg-white dark:bg-[#333333] md:bg-transparent
                    overflow-y-auto md:h-full
                `}>
                    <div className="bg-white dark:bg-[#333333] rounded-lg shadow-md p-4 h-full overflow-y-auto">
                        <button
                            onClick={() => setIsMobileConversationListOpen(false)}
                            className="md:hidden absolute top-4 right-4 text-gray-600 dark:text-[#CBCBCB]"
                        >
                            <X className="w-6 h-6" />
                        </button>

                        <div className="relative mb-4">
                            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-[#797979] w-5 h-5" />
                            <input
                                type="text"
                                placeholder={t('inbox.search.placeholder')}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="w-full pl-10 pr-4 py-2 border border-gray-200 dark:border-[#494949] dark:bg-[#232323] dark:text-[#F2F2F2] rounded-lg focus:outline-none focus:ring-2 focus:ring-primaryLight dark:focus:ring-[#E44826]"
                            />
                        </div>

                        <ul className="space-y-2">
                            {filteredConversations.map((conversation) => (
                                <li
                                    key={conversation.id}
                                    onClick={() => handleConversationSelect(conversation)}
                                    className={`
                                        flex items-center p-3 rounded-lg cursor-pointer 
                                        hover:bg-gray-50 dark:hover:bg-[#494949] transition-all duration-200
                                        ${selectedConversation?.id === conversation.id
                                        ? 'bg-primaryExtraLight dark:bg-[#595959] hover:bg-primaryLight dark:hover:bg-[#494949] shadow-sm'
                                        : ''
                                    }
                                    `}
                                >
                                    <div className="relative mr-4">
                                        {conversation.seller?.profile_photo ? (
                                            <img
                                                src={conversation.seller.profile_photo}
                                                alt={conversation.name}
                                                className="w-12 h-12 rounded-full object-cover border-2 border-gray-200 dark:border-[#494949]"
                                            />
                                        ) : (
                                            <UserCircle className="w-12 h-12 text-gray-400 dark:text-[#797979]" />
                                        )}
                                    </div>
                                    <div className="flex-1 overflow-hidden">
                                        <div className="flex justify-between items-start">
                                            <p className="font-semibold text-gray-800 dark:text-[#F2F2F2] truncate">
                                                {conversation.name}
                                            </p>
                                            <span className="text-xs text-gray-500 dark:text-[#797979]">
                                                {new Date(conversation.updated_at).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                                            </span>
                                        </div>
                                        <p className="text-sm text-gray-500 dark:text-[#CBCBCB] truncate mt-1">
                                            {conversation.seller.username}
                                        </p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className={`
                    flex-1
                    ${selectedConversation || isMobileConversationListOpen ? 'block' : 'hidden md:block'}
                `}>
                    {selectedConversation ? (
                        <div className="bg-white dark:bg-[#333333] rounded-lg shadow-md h-full flex flex-col">
                            <div className="flex justify-between items-center p-4 border-b border-gray-200 dark:border-[#494949] bg-gray-50 dark:bg-[#333]">
                                <div className="flex items-center">
                                    <button
                                        onClick={() => setSelectedConversation(null)}
                                        className="mr-4 md:hidden text-gray-500 dark:text-[#CBCBCB] hover:text-gray-700 dark:hover:text-[#F2F2F2] transition-colors"
                                    >
                                        <X className="w-6 h-6" />
                                    </button>
                                    <div className="relative">
                                        {selectedConversation.seller?.profile_photo ? (
                                            <img
                                                src={selectedConversation.seller.profile_photo}
                                                alt={selectedConversation.name}
                                                className="w-10 h-10 rounded-full object-cover border-2 border-gray-200 dark:border-[#494949]"
                                            />
                                        ) : (
                                            <UserCircle className="w-10 h-10 text-gray-400 dark:text-[#797979]" />
                                        )}
                                    </div>
                                    <div className="ml-3">
                                        <h2 className="text-lg font-semibold text-gray-800 dark:text-[#F2F2F2]">
                                            {selectedConversation.name}
                                        </h2>
                                        <p className="text-xs text-gray-500 dark:text-[#CBCBCB]">
                                            {selectedConversation.seller.username}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center gap-2">
                                    <button className="p-2 hover:bg-gray-200 dark:hover:bg-[#494949] rounded-full transition-colors">
                                        <MoreVertical className="w-5 h-5 text-gray-600 dark:text-[#CBCBCB]" />
                                    </button>
                                    <button
                                        onClick={() => setSelectedConversation(null)}
                                        className="hidden md:block p-2 hover:bg-gray-200 dark:hover:bg-[#494949] rounded-full transition-colors"
                                    >
                                        <X className="w-5 h-5 text-gray-600 dark:text-[#CBCBCB]" />
                                    </button>
                                </div>
                            </div>

                            <div className="flex-1 overflow-y-auto p-4 space-y-3 bg-gray-50 dark:bg-[#333]">
                                {[...selectedConversation.messages].reverse().map((message, index) => (
                                    <div
                                        key={index}
                                        className={`
                                            flex ${message.sender_id === userId ? "justify-end" : "justify-start"}
                                        `}
                                    >
                                        <div className={`
                                            max-w-[90%] md:max-w-[70%] p-3 rounded-lg shadow-sm
                                            ${message.sender_id === userId
                                            ? "bg-primaryLight dark:bg-[#E44826] text-white rounded-br-none"
                                            : "bg-white dark:bg-[#494949] dark:text-[#F2F2F2] rounded-bl-none"
                                        }
                                        `}>
                                            <p className="text-sm whitespace-pre-wrap">{message.content}</p>
                                            <p className="text-xs opacity-70 mt-1 text-right">
                                                {new Date(message.created_at).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                                <div ref={messagesEndRef} />
                            </div>

                            <form
                                onSubmit={handleReplySubmit}
                                className="p-4 border-t border-gray-200 dark:border-[#494949] bg-white dark:bg-[#333333]"
                            >
                                <div className="flex items-center gap-2">
                                    <textarea
                                        ref={inputRef}
                                        value={replyMessage}
                                        onChange={(e) => setReplyMessage(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                        placeholder={t('inbox.messages.input.placeholder')}
                                        rows={1}
                                        className="flex-1 px-4 py-2 border border-gray-300 dark:border-[#494949] dark:bg-[#232323] dark:text-[#F2F2F2] rounded-lg focus:outline-none focus:ring-2 focus:ring-primaryLight dark:focus:ring-[#E44826] resize-none"
                                        style={{maxHeight: '120px', minHeight: '44px'}}
                                    />
                                    <button
                                        type="submit"
                                        disabled={!replyMessage.trim()}
                                        className={`
                                            p-3 rounded-lg transition-all duration-200
                                            ${replyMessage.trim()
                                            ? "bg-primaryDark dark:bg-[#E44826] text-white hover:bg-primaryLight dark:hover:bg-[#F9CDC4] hover:shadow-lg transform hover:scale-105"
                                            : "bg-gray-300 dark:bg-[#494949] text-gray-500 dark:text-[#797979] cursor-not-allowed"
                                        }
                                        `}
                                    >
                                        <Send className="w-5 h-5 rotate-45" />
                                    </button>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <div className="bg-white dark:bg-[#333333] rounded-lg shadow-md h-full flex flex-col items-center justify-center p-8">
                            <div className="bg-gray-100 dark:bg-[#232323] rounded-full p-6 mb-4">
                                <Send className="w-12 h-12 text-primaryDark dark:text-[#E44826]" />
                            </div>
                            <h3 className="text-xl font-semibold text-gray-800 dark:text-[#F2F2F2] mb-2">{t('inbox.messages.empty.title')}</h3>
                            <p className="text-gray-500 dark:text-[#CBCBCB] text-center">
                                {t('inbox.messages.empty.description')}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}