export default function ShopCardSkeleton() {
    return (
        <div className="min-w-[250px] max-w-[250px] 2xl:min-w-[300px] 2xl:max-w-[300px] w-full h-fit flex flex-col gap-2 dark:bg-gray-800 rounded-lg border border-gray-300 dark:border-gray-700 animate-pulse">
            <div className="flex flex-row items-center justify-center gap-3 w-fit h-fit p-2">
                <div className="w-10 h-10 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                <div className="flex flex-col gap-1 items-start">
                    <div className="w-16 h-4 bg-gray-300 dark:bg-gray-700 rounded"></div>
                    <div className="flex flex-row gap-2 items-center">
                        <div className="w-8 h-4 bg-gray-300 dark:bg-gray-700 rounded"></div>
                        <div className="w-4 h-4 bg-gray-300 dark:bg-gray-700 rounded"></div>
                    </div>
                </div>
            </div>

            <div className="relative p-2">
                <div className="w-[234px] 2xl:w-[284px] h-4 bg-gray-300 dark:bg-gray-700 rounded"></div>
            </div>

            <div className="flex flex-row items-center px-2 justify-end border-t border-gray-300 dark:border-gray-700 h-10 min-h-10">
                <div className="flex items-center justify-center px-4 py-1 bg-gray-300 dark:bg-gray-700 rounded w-20 h-8"></div>
            </div>
        </div>
    );
}
