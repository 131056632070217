import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {getSimilarCars, getSimilarProductUsingSubCategoryId, getVehicleById} from "../../services/marketplace/API";
import ProductPictures from "../ProductDetails/components/ProductPictures/ProductPictures";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import Carousel from "../Marketplace/components/Carousel/Carousel";
import ProductCard from "../Marketplace/components/Cards/ProductCard";

export default function VehicleDetailsPage() {
    const [vehicle, setVehicle] = useState({});
    const [width, setWidth] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [errorSubtitle, setErrorSubtitle] = useState(null);
    const {t} = useTranslation();

    useEffect(() => {
        setWidth(window.innerWidth)
        window.addEventListener('resize', () => {
            setWidth(window.innerWidth)
        })
        return () => {
            window.removeEventListener('resize', () => {
                setWidth(window.innerWidth)
            })
        }
    }, []);

    const {id} = useParams();

    useEffect(() => {
        setLoading(true)
        getVehicleById(id).then((response) => {
            if (response.status === 200) {
                setVehicle(response.data)
                setError(null)
            }
            setLoading(false)
        }).catch((e) => {
            console.log(e)
            if (e.response.status === 404) {
                setError("Vehicle not found.")
                setErrorSubtitle("We couldn't find the vehicle you're looking for. Please check the ID or try again later.")
            }
            setLoading(false)
        })
    }, [id]);

    const navigate = useNavigate();

    if (loading) {
        return (
            <div className="w-full h-full flex items-center justify-center">
                <div className="loader"></div>
            </div>
        )
    }
    if (error) {
        return (
            <div className="w-full h-full flex items-center justify-center flex-col gap-2">
                <img src="/images/undraw_no_data.svg" alt="No data" className="mb-4 max-w-[250px]"/>
                <p className="text-xl text-primaryDark dark:text-[#F2F2F2] font-semibold text-center">{error}</p>
                <p className="text-center text-gray-500 dark:text-[#797979] mb-4">{errorSubtitle}</p>
                <button
                    onClick={() => navigate("/")}
                    className="cta cursor-pointer flex px-4 py-3 max-h-[48px] min-h-[48px] flex-row items-center justify-center gap-2 rounded-lg bg-primaryLight">
                    <p className="font-medium text-white">{t("productDetails.error.returnToHome")}</p>
                </button>
            </div>
        )
    }
    return (
        <>
            <Helmet>
                <title>{vehicle.name} - Marketplace</title>
                <meta name="description"
                      content={`Achetez ${vehicle.name} sur notre marketplace. ${vehicle.description}`}/>
                <meta property="og:title" content={vehicle.name}/>
                <meta property="og:description" content={`${vehicle.description}`}/>
                <meta property="og:image" content={vehicle?.photos[0]}/>
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Product",
                        name: vehicle.name,
                        image: vehicle?.photos[0]?.link,
                        description: vehicle.description,
                        offers: {
                            "@type": "Offer",
                            url: window.location.href,
                            priceCurrency: "DZD",
                            price: vehicle.price,
                            itemCondition: "https://schema.org/NewCondition",
                            availability: "https://schema.org/InStock",
                        },
                    })}
                </script>
            </Helmet>

            <div
                className="w-full max-h-[calc(91vh-104px)] min-h-[calc(91vh-104px)] overflow-x-hidden overflow-y-auto p-2 sm:px-6 2xl:px-[11vw] flex flex-col sm:flex-row sm:py-8 gap-4">
                <div
                    className="flex flex-col items-center gap-8 w-full sm:w-[33vw] lg:w-[25vw] 2xl:w-[calc(19.5vw-4px)]">
                    <ProductPictures photos={vehicle.photos}/>
                    {width < 1024 && width > 640 ?
                        <VehiculeContactUser phone_number={vehicle?.user.contact.phone_number}
                                             email={vehicle?.user.email}
                        />
                        :
                        null
                    }
                </div>
                <div className="flex flex-col gap-8 w-full sm:w-[60vw] lg:w-[45vw] 2xl:w-[calc(39vw-8px)] h-fit">
                    <VehiculeGeneralInfo {...vehicle}/>
                    <VehiculeCharacteristics vehicule={vehicle}/>
                </div>
                {width > 1024 || width <= 640 ?
                    <div
                        className="h-full w-full sm:w-[33vw] lg:w-[22vw] 2xl:w-[calc(19.5vw-4px)] flex flex-col items-center">
                        <VehiculeContactUser phone_number={vehicle.user.contact.phone_number}
                                             email={vehicle.user.email}/>
                    </div>
                    :
                    null
                }
            </div>

        </>
    )
}

const VehiculeGeneralInfo = ({name, description, vehicle}) => {
    return (
        <div className="w-full h-fit flex flex-col gap-4 px-4 sm:px-0">
            <h1 className="text-3xl font-semibold line-clamp-2 overflow-hidden dark:text-white leading-none">
                {name}
            </h1>

            <div className="flex flex-wrap gap-2 text-green-800 font-medium w-fit">
                {vehicle?.vehicle_brand?.brand_name && <span className="bg-green-200 px-2 py-1 rounded text-sm">
                    {vehicle.vehicle_brand.brand_name}
                </span>}
                {vehicle?.vehicle_model?.name && <span className="bg-green-200 px-2 py-1 rounded text-sm">
                    {vehicle.vehicle_model.name}
                </span>}
                {vehicle.motorisation.name && (
                    <span className="bg-green-200 px-2 py-1 rounded text-sm">
                        {vehicle.motorisation.name}
                    </span>
                )}
                <span>-</span>
                {vehicle.vehicle_model.serie && (
                    <span className="bg-green-200 px-2 py-1 rounded text-sm">
                        {vehicle.vehicle_model.serie}
                    </span>
                )}
            </div>

            <div>
                <p className="font-semibold text-lg dark:text-white">Description:</p>
                {description ?
                    <div className="px-4 py-2 text-base text-gray-700 dark:text-gray-400 leading-8"
                         dangerouslySetInnerHTML={{__html: description}}></div>
                    :
                    <p className="text-base text-gray-700 dark:text-gray-400 leading-6">
                        No description provided.
                    </p>
                }
            </div>
        </div>
    );
};


const VehiculeCharacteristics = ({vehicule}) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const ref = useRef();
    const [equivalentProduct, updateEquivalentProduct] = useState([])
    const {id} = useParams();


    useEffect(() => {
        getSimilarCars(vehicule?.vehicle?.vehicle_model?.id, vehicule?.vehicle?.vehicle_brand?.id).then((response) => {
            if (response.status === 200) {
                updateEquivalentProduct(response.data.cars)
            }
        }).catch(() => {
        }).finally(() => {
        })
    }, []);

    return (
        <div className="flex flex-col 2xl:w-[calc(39vw-8px)] h-full">
            <div
                className="border border-border dark:border-[#595959] flex flex-row items-center w-full px-6 lg:px-16 h-16 min-h-16 rounded-2xl dark:bg-[#333333]">
                <button
                    onClick={() => setSelectedTab(0)}
                    style={{
                        borderBottom: selectedTab === 0 ? "2px solid #E44826" : "none",
                        color: selectedTab === 0 ? "#E44826" : "inherit"
                    }}
                    className="w-full h-full flex items-center justify-center cursor-pointer dark:text-[#F2F2F2]">
                    <p className="font-medium">Caractéristiques</p>
                </button>
                <button
                    onClick={() => setSelectedTab(1)}
                    style={{
                        borderBottom: selectedTab === 1 ? "2px solid #E44826" : "none",
                        color: selectedTab === 1 ? "#E44826" : "inherit"
                    }}
                    className="w-full h-full flex items-center justify-center cursor-pointer dark:text-[#F2F2F2]">
                    <p className="font-medium">Équivalences</p>
                </button>
            </div>
            <div ref={ref}
                 className="flex flec-row items-center py-4 max-w-[95vw] sm:max-w-[60vw] lg:max-w-[45vw] 2xl:max-w-[calc(39vw-8px)]">
                {selectedTab === 0 &&
                    <table
                        className="min-w-full bg-white dark:bg-[#333333] border border-gray-300 dark:border-[#595959] rounded-lg">
                        <thead
                            className="bg-gray-100 dark:bg-[#494949] sticky top-0 z-10 min-h-14 h-14 border-b border-border dark:border-[#595959] max-h-14">
                        <tr>
                            <th className="px-6 py-3 text-gray-500 dark:text-[#CBCBCB] text-left text-sm font-medium border-b border-gray-300 dark:border-[#595959] border-r">
                                Caractéristiques
                            </th>
                            <th className="px-6 py-3 text-gray-500 dark:text-[#CBCBCB] text-left text-sm font-medium border-b border-gray-300 dark:border-[#595959]">
                                Valeur
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {vehicule && vehicule.characteristics && Object.keys(vehicule.characteristics).length > 0 ? (
                            <>
                                {
                                    Object.keys(vehicule.characteristics).map((key) => (
                                        <tr key={key} className="border-b border-border dark:border-[#595959]">
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-[#F2F2F2] border-r border-gray-300 dark:border-[#595959]">
                                                {key}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-[#CBCBCB]">
                                                {vehicule.characteristics[key]}
                                            </td>
                                        </tr>
                                    ))
                                }
                                <tr key={"mileage"} className="border-b border-border dark:border-[#595959]">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-[#F2F2F2] border-r border-gray-300 dark:border-[#595959]">
                                        Kilométrage
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-[#CBCBCB]">
                                        {vehicule.mileage} km
                                    </td>
                                </tr>
                                <tr key={"year"} className="border-b border-border dark:border-[#595959]">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-[#F2F2F2] border-r border-gray-300 dark:border-[#595959]">
                                        Année
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-[#CBCBCB]">
                                        {vehicule.year}
                                    </td>
                                </tr>
                                <tr key={"gearbox"} className="border-b border-border dark:border-[#595959]">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-[#F2F2F2] border-r border-gray-300 dark:border-[#595959]">
                                        Boîte de vitesse
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-[#CBCBCB]">
                                        {vehicule.gearbox}
                                    </td>
                                </tr>
                                <tr key={"energie"} className="border-b border-border dark:border-[#595959]">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-[#F2F2F2] border-r border-gray-300 dark:border-[#595959]">
                                        Energie
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-[#CBCBCB]">
                                        {vehicule.energie}
                                    </td>
                                </tr>
                            </>
                        ) : (
                            <tr>
                                <td colSpan="2" className="px-6 py-4 text-center text-gray-500 dark:text-[#CBCBCB]">
                                    No characteristics available
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                }
                {selectedTab === 1 &&
                    (equivalentProduct.length === 0 ?
                            <p className="text-center font-medium dark:text-[#F2F2F2]">No equivalent product
                                available</p>
                            :
                            <Carousel ref={ref}
                                      renderItem={(product) => (<ProductCard {...product} itemType="vehicle"/>)}
                                      items={equivalentProduct}
                                      itemsWidth={300}
                            />
                    )
                }
            </div>
        </div>
    )
}

const VehiculeContactUser = ({phone_number, email,}) => {
    return (
        <div className="flex flex-col gap-4 w-full items-start px-4">
            <div
                style={{
                    borderBottom: "2px solid #E44826",
                    color: "#E44826",
                }}
                className="w-full h-full flex items-center justify-center cursor-pointer min-h-16">
                <p className="font-medium">Contactez le vendeur</p>
            </div>
            {phone_number && (
                <div
                    className="flex flex-row items-center gap-4 min-h-14 border border-border dark:border-[#595959] rounded w-full overflow-hidden dark:bg-[#333333]">
                    <img src="/resources/phone.svg" alt="phone"
                         className="py-5 px-4 border-r border-r-border dark:border-[#595959] bg-primaryExtraLight"/>
                    <a href={`tel:${phone_number}`} className="font-medium dark:text-[#F2F2F2]">{phone_number}</a>
                </div>
            )}

            {email && (
                <div
                    className="flex flex-row items-center gap-4 min-h-14 border border-border dark:border-[#595959] rounded w-full overflow-hidden dark:bg-[#333333]">
                    <img src="/resources/email.svg" alt="mail"
                         className="py-5 px-4 border-r border-r-border dark:border-[#595959] bg-primaryExtraLight"/>
                    <p className="font-medium dark:text-[#F2F2F2]">
                        <a href={`mailto:${email}`}>
                            {email}
                        </a>
                    </p>
                </div>
            )}

        </div>
    )
}