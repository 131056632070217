import {useMarketplaceContext} from "../../Marketplace";
import {useCompatibleVehicleContext} from "../../../components/CompatibleVehicules/CompatibleVehicles";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {Plus} from "lucide-react";

export default function CarBanner() {
    const {updatePopupVisible} = useMarketplaceContext();
    const {brand, model, motorisation, updateVisible} = useCompatibleVehicleContext();
    const location = useLocation();
    const {category} = location.state || {}
    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        const isDark = localStorage.getItem('darkMode') === 'true';
        setDarkMode(isDark);

        const handleDarkModeChange = (e) => {
            if (e.key === 'darkMode') {
                setDarkMode(e.newValue === 'true');
            }
        };

        window.addEventListener('storage', handleDarkModeChange);

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {
                    setDarkMode(document.documentElement.classList.contains('dark'));
                }
            });
        });

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['class']
        });

        return () => {
            window.removeEventListener('storage', handleDarkModeChange);
            observer.disconnect();
        };
    }, []);

    return (
        (brand === null && model === null && motorisation === null && category !== "boats" && category !== "engins") ?
            <div
                className="px-2 sm:px-8 h-16 bg-[#FFF8F6] dark:bg-[#232323] flex flex-row items-center justify-between border-b-border dark:border-[#494949] border-b min-h-16">
                <div className="flex flex-row items-center gap-2 sm:gap-6">
                    <div
                        className="min-w-12 min-h-12 rounded-lg flex items-center justify-center border-border dark:border-[#494949] border bg-white dark:bg-[#333333]">
                        <img src="/resources/warning_car.svg" alt='warning'
                             className={`w-6`}/>
                    </div>
                    <p className="text-sm text-primaryDark dark:text-[#F2F2F2] font-medium truncate max-w-[50vw]">Identifiez
                        votre véhicule
                        pour obtenir un
                        choix
                        de produits compatibles</p>
                </div>
                <button
                    onClick={() => {
                        updatePopupVisible(true)
                        updateVisible(true)
                    }}
                    className="inline-flex items-center gap-2 border border-border dark:border-[#494949] rounded-lg bg-white dark:bg-[#333333] h-12 px-4 w-fit">
                    <Plus className="w-4"/>
                    <span className="font-medium text-sm text-primaryDark dark:text-[#F2F2F2] w-fit">
        Ajouter <span className="hidden sm:inline">mon véhicule</span>
    </span>
                </button>
            </div>
            :
            <div style={{display: (category !== "boats" && category !== "engins") ? "flex" : "none"}}
                 className="px-8 h-16 bg-[#FFF8F6] dark:bg-[#232323] flex-row items-center justify-between border-b-border dark:border-[#494949] border-b min-h-16">
                <div className="flex flex-row items-center gap-6">
                    <div
                        className="min-w-12 min-h-12 rounded-lg flex items-center justify-center border-border dark:border-[#494949] border bg-white dark:bg-[#333333]">
                        <img src="/resources/success_car.svg" alt='success'/>
                    </div>
                    <p className="sm:text-sm text-primaryDark dark:text-[#F2F2F2] font-medium truncate max-w-[150px] sm:max-w-[450px]">{brand?.name} {model?.name} {motorisation?.name}</p>
                </div>
                <button
                    onClick={() => {
                        updatePopupVisible(true)
                        updateVisible(true)
                    }}
                    className="inline-flex items-center gap-2 border border-border dark:border-[#494949] rounded-lg bg-white dark:bg-[#333333] h-12 px-4 w-fit">
                    <img src="/resources/pen.svg" alt="add" className="primary_filter"/>
                    <span className="font-medium text-sm text-primaryDark dark:text-[#F2F2F2] w-fit">Modifier</span>
                </button>
            </div>
    )
}