import React, {useEffect, useState} from "react";
import CustomTabs from "../Tabs/Tabs";
import {
    Avatar,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    DialogContentText, Modal,
} from "@mui/material";
import DataTable from "../dataTable/DataTable";
import ToastError, {toastStyle} from "../../../../components/Toasts/Error/ToastError";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import {toast} from "sonner";
import {
    getProducts,
    getServices,
    getUsers,
    updateProductStatus,
    updateServiceStatus,
    changeUserStatus, getOffers, updateOfferStatus
} from "../../../../services/AdminSapce/API";
import {useNavigate} from "react-router";
import GenericDropDown from "../../../../components/GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../../../../components/GenericDropDown/GenericDropDownItem";
import {useTranslation} from 'react-i18next';

export default function Soumissions() {
    const {t} = useTranslation();

    const secondaryMenuItems = [
        {id: 'produit', label: t('adminPages.validation.secondaryMenuItems.product'), icon: '/resources/product.svg'},
        {id: 'service', label: t('adminPages.validation.secondaryMenuItems.service'), icon: '/resources/service.svg'},
        {id: 'sellers', label: t('adminPages.validation.secondaryMenuItems.sellers'), icon: '/resources/seller.svg'},
        {id: "offers", label: t('adminPages.validation.secondaryMenuItems.offers'), icon: "/resources/offer.svg"}
    ];

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentTab, setCurrentTab] = useState('produit');
    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [justification, setJustification] = useState('');
    const [rowsProduit, setRowsProduit] = useState([]);
    const [rowsService, setRowsService] = useState([]);
    const [rowsSellers, setRowsSellers] = useState([]);
    const [rowsOffers, setRowsOffers] = useState([]);
    const [selectedAll, setSelectedAll] = useState([]);
    const navigate = useNavigate();

    const offerColumns = [
        {
            field: 'Offer',
            headerName: t('adminPages.validation.columns.offer'),
            width: 300,
        },
        {
            field: 'description',
            headerName: t('adminPages.validation.columns.description'),
            width: 300,
        },
        {field: 'discount', headerName: t('adminPages.validation.columns.discount'), width: 150},
        {field: 'quantity', headerName: t('adminPages.validation.columns.quantity'), width: 150},
        {field: 'price', headerName: t('adminPages.validation.columns.price'), width: 150},
        {field: 'start_date', headerName: t('adminPages.validation.columns.product'), width: 150,
            renderCell: (params) => (
                <a onClick={() => navigate(`/product/${params.row.productId}`)} className="dark:text-[#F2F2F2] hover:dark:text-[#F9CDC4]"> {params.row.start_date} </a>
            )},
        {field: 'end_date', headerName: t('adminPages.validation.columns.shop'), width: 150,
            renderCell: (params) => (
                <a onClick={() => navigate(`/shop/${params.row.BoutiqueId}`)} className="dark:text-[#F2F2F2] hover:dark:text-[#F9CDC4]"> {params.row.end_date} </a>
            )
        },
        {
            field: 'actions',
            headerName: "",
            width: 150,
            renderCell: (params) => (
                <Box display="flex">
                    <GenericDropDown>
                        <GenericDropDownItem value={t('adminPages.validation.buttons.reject')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation();
                                                 handleReject(params.row)
                                             }}/>
                        <GenericDropDownItem value={t('adminPages.validation.buttons.approve')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation();
                                                 handleApprove(params.row)
                                             }}
                        />
                    </GenericDropDown>
                </Box>
            ),
        },
    ];

    const produitColumns = [
        {
            field: 'Produit',
            headerName: t('adminPages.validation.columns.product'),
            width: 300,
            renderCell: (params) => (
                <a onClick={() => navigate(`/product/${params.row.id}`)} className="dark:text-[#F2F2F2] hover:dark:text-[#F9CDC4]">
                    <Box display="flex" alignItems="center">
                        <Avatar alt={params.row.Produit} src={params.row.avatarUrl} style={{marginRight: 8}}/>
                        {params.row.Produit}
                    </Box>
                </a>
            ),
        },
        {
            field: 'Boutique',
            headerName: t('adminPages.validation.columns.shop'),
            width: 300,
            renderCell: (params) => (
                <a onClick={() => navigate(`/shop/${params.row.boutiqueId}`)} className="dark:text-[#F2F2F2] hover:dark:text-[#F9CDC4]">
                    <Box display="flex" alignItems="center">
                        <Avatar alt={params.row.Boutique} src={params.row.avatarBoutiqueUrl} style={{marginRight: 8}}/>
                        {params.row.Boutique}
                    </Box>
                </a>
            )
        },
        {field: 'dateSoumission', headerName: t('adminPages.validation.columns.submissionDate'), width: 200},
        {
            field: 'actions',
            headerName: "",
            width: 150,
            renderCell: (params) => (
                <Box display="flex">
                    <GenericDropDown>
                        <GenericDropDownItem value={t('adminPages.validation.buttons.reject')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation();
                                                 handleReject(params.row)
                                             }}/>
                        <GenericDropDownItem value={t('adminPages.validation.buttons.approve')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation();
                                                 handleApprove(params.row)
                                             }}
                        />
                    </GenericDropDown>
                </Box>
            ),
        },
    ];

    const serviceColumns = [
        {
            field: 'Service',
            headerName: t('adminPages.validation.columns.service'),
            width: 300,
            renderCell: (params) => (
                <a onClick={() => navigate(`/service/${params.row.id}`)} className="dark:text-[#F2F2F2] hover:dark:text-[#F9CDC4]">
                    <Box display="flex" alignItems="center">
                        <Avatar alt={params.row.Service} src={params.row.avatarUrl} style={{marginRight: 8}}/>
                        {params.row.Service}
                    </Box>
                </a>
            )
        },
        {
            field: 'Boutique',
            headerName: t('adminPages.validation.columns.shop'),
            width: 300,
            renderCell: (params) => (
                <a onClick={() => navigate(`/shop/${params.row.boutiqueId}`)} className="dark:text-[#F2F2F2] hover:dark:text-[#F9CDC4]">
                    <Box display="flex" alignItems="center">
                        <Avatar alt={params.row.Boutique} src={params.row.avatarBoutiqueUrl} style={{marginRight: 8}}/>
                        {params.row.Boutique}
                    </Box>
                </a>
            )
        },
        {field: 'dateSoumission', headerName: t('adminPages.validation.columns.submissionDate'), width: 200},
        {
            field: 'actions',
            headerName: "",
            width: 150,
            renderCell: (params) => (
                <Box display="flex">
                    <GenericDropDown>
                        <GenericDropDownItem value={t('adminPages.validation.buttons.reject')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation();
                                                 handleReject(params.row)
                                             }}/>
                        <GenericDropDownItem value={t('adminPages.validation.buttons.approve')} index={2}
                                             handleClick={(event) => {
                                                 event.stopPropagation();
                                                 handleApprove(params.row)
                                             }}
                        />
                    </GenericDropDown>
                </Box>
            ),
        }
    ];

    const sellerColumns = [
        {
            field: 'fullName',
            headerName: t('adminPages.validation.columns.fullName'),
            width: 200,
            renderCell: (params) => (
                <a onClick={() => navigate(`/admin/seller/${params.row.id}`)} className="dark:text-[#F2F2F2] hover:dark:text-[#F9CDC4]">
                    <Box display="flex" alignItems="center">
                        {params.row.fullName}
                    </Box>
                </a>
            )
        },
        {field: 'email', headerName: t('adminPages.validation.columns.email'), width: 250},
        {field: 'phone', headerName: t('adminPages.validation.columns.phone'), width: 150},
        {
            field: 'address',
            headerName: t('adminPages.validation.columns.address'),
            width: 300,
            renderCell: (params) => `${params.row.address.street}, ${params.row.address.city}, ${params.row.address.country}`
        },
        {
            field: 'actions',
            headerName: "",
            width: 150,
            renderCell: (params) => (
                <Box display="flex" onClick={(e) => e.stopPropagation()}>
                    <GenericDropDown>
                        <GenericDropDownItem value={t('adminPages.validation.buttons.reject')} index={2}
                                             handleClick={(e) => {
                                                 e.stopPropagation();
                                                 handleReject(params.row)
                                             }}/>
                        <GenericDropDownItem value={t('adminPages.validation.buttons.approve')} index={2}
                                             handleClick={(e) => {
                                                 e.stopPropagation();
                                                 handleApprove(params.row)
                                             }}
                        />
                    </GenericDropDown>
                </Box>
            ),
        },
    ];

    const dataFetchers = {
        produit: async () => {
            const response = await getProducts();
            if (!Array.isArray(response)) throw new Error('Invalid response format');
            return response
                .filter(product => product.status === "pending")
                .map(product => ({
                    id: product.id,
                    Produit: product.name,
                    Boutique: product.shop?.name || 'N/A',
                    boutiqueId: product.shop?.id,
                    dateSoumission: product.created_at ? new Date(product.created_at).toLocaleDateString() : 'N/A',
                    avatarUrl: product.brand?.logo || '',
                    avatarBoutiqueUrl: product.shop?.logo || '',
                }));
        },
        service: async () => {
            const response = await getServices();
            if (!response?.services || !Array.isArray(response.services)) throw new Error('Invalid response format');
            return response.services
                .filter(service => service.status === "pending")
                .map(service => ({
                    id: service.id,
                    Service: service.name,
                    Boutique: service.shop?.name || 'N/A',
                    dateSoumission: service.created_at ? new Date(service.created_at).toLocaleDateString() : 'N/A',
                    avatarUrl: service.photos.length > 0 ? service.photos[0].link : '',
                    avatarBoutiqueUrl: service.shop?.logo || '',
                    boutiqueId: service.shop?.id
                }));
        },
        sellers: async () => {
            const response = await getUsers();
            if (!Array.isArray(response)) throw new Error('Invalid response format');
            return response
                .filter(user => user.role_id === 3 && user.status === "pending")
                .map(seller => ({
                    id: seller.id,
                    fullName: `${seller.first_name} ${seller.last_name}`,
                    email: seller.email,
                    phone: seller.contact?.phone_number || 'N/A',
                    address: seller.contact?.address || {street: 'N/A', city: 'N/A', country: 'N/A'},
                    status: seller.status,
                }));
        },
        offers: async () => {
            const response = await getOffers();
            if (!Array.isArray(response)) throw new Error('Invalid response format');
            return response
                .filter(offer => offer.status === "pending")
                .map(offer => ({
                    id: offer.id,
                    Offer: offer.offer_name,
                    BoutiqueId: offer.shop?.id,
                    description: offer.description,
                    discount: offer.discount_percentage,
                    quantity: offer.offer_quantity,
                    price: offer.price,
                    start_date: offer.products[0]?.name,
                    end_date: offer.shop?.name,
                    productId: offer.products[0]?.id || '',
                }));
        }
    };

    const setters = {
        produit: setRowsProduit,
        service: setRowsService,
        sellers: setRowsSellers,
        offers: setRowsOffers
    };

    const fetchData = async () => {
        setLoading(true);
        setError(null);

        try {
            const fetcher = dataFetchers[currentTab];
            const setter = setters[currentTab];

            if (!fetcher || !setter) {
                throw new Error('Invalid tab selection');
            }

            const data = await fetcher();
            setter(data);
        } catch (error) {
            console.error('Fetch error:', error);
            toast.error(<ToastError message={t('adminPages.validation.toastMessages.fetchError')}/>, toastStyle);
            setError(t('adminPages.validation.toastMessages.fetchError'));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentTab]);

    const handleApprove = async (row) => {
        try {
            if (currentTab === 'produit') {
                await updateProductStatus([row.id], 'verified', "Votre produit est accepté");
                toast.success(<ToastSuccess
                    message={t('adminPages.validation.toastMessages.productApproved')}/>, toastStyle);
            } else if (currentTab === 'service') {
                await updateServiceStatus([row.id], 'verified', "Votre service est accepté");
                toast.success(<ToastSuccess
                    message={t('adminPages.validation.toastMessages.serviceApproved')}/>, toastStyle);
            } else if (currentTab === 'sellers') {
                await changeUserStatus([row.id], "active", "Votre compte est activé");
                toast.success(<ToastSuccess
                    message={t('adminPages.validation.toastMessages.sellerApproved')}/>, toastStyle);
            } else if (currentTab === 'offers') {
                await updateOfferStatus([row.id], 'verified', "Votre offre est acceptée");
                toast.success(<ToastSuccess
                    message={t('adminPages.validation.toastMessages.offerApproved')}/>, toastStyle);
            }
            await fetchData();
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.validation.toastMessages.validationError')}/>, toastStyle);
            setError('Failed to update status');
        }
    };

    const handleApproveAll = async (ids) => {
        try {
            if (currentTab === 'produit') {
                await updateProductStatus(ids, 'verified', "Votre produit est accepté");
                toast.success(<ToastSuccess
                    message={t('adminPages.validation.toastMessages.productApproved')}/>, toastStyle);
            } else if (currentTab === 'service') {
                await updateServiceStatus(ids, 'verified', "Votre service est accepté");
                toast.success(<ToastSuccess
                    message={t('adminPages.validation.toastMessages.serviceApproved')}/>, toastStyle);
            } else if (currentTab === 'sellers') {
                await changeUserStatus(ids, "active", "Votre compte est activé");
                toast.success(<ToastSuccess
                    message={t('adminPages.validation.toastMessages.sellerApproved')}/>, toastStyle);
            } else if (currentTab === 'offers') {
                await updateOfferStatus(ids, 'verified', "Votre offre est acceptée");
                toast.success(<ToastSuccess
                    message={t('adminPages.validation.toastMessages.offerApproved')}/>, toastStyle);
            }
            await fetchData();
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.validation.toastMessages.validationError')}/>, toastStyle);
            setError('Failed to update status');
        }
    };

    const handleReject = (row) => {
        setSelectedRow(row);
        setOpenRejectDialog(true);
    };

    const handleRejectAll = async (ids) => {
        try {
            if (currentTab === 'produit') {
                await updateProductStatus(ids, 'rejected', "votre produits est non conforme");
                toast.success(<ToastSuccess
                    message={t('adminPages.validation.toastMessages.productRejected')}/>, toastStyle);
            } else if (currentTab === 'service') {
                await updateServiceStatus(ids, 'rejected', "votre services est non conforme");
                toast.success(<ToastSuccess
                    message={t('adminPages.validation.toastMessages.serviceRejected')}/>, toastStyle);
            } else if (currentTab === 'sellers') {
                await changeUserStatus(ids, "rejected", "Votre compte est disactivé");
                toast.success(<ToastSuccess
                    message={t('adminPages.validation.toastMessages.sellerRejected')}/>, toastStyle);
            } else if (currentTab === 'offers') {
                await updateOfferStatus(ids, 'rejected', "votre offre est non conforme");
                toast.success(<ToastSuccess
                    message={t('adminPages.validation.toastMessages.offerRejected')}/>, toastStyle);
            }
            setJustification('');
            await fetchData();
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.validation.toastMessages.rejectionError')}/>, toastStyle);
            setError('Failed to update status');
        }
    };

    const handleRejectSubmit = async () => {
        if (!selectedRow) return;
        try {
            if (currentTab === 'produit') {
                await updateProductStatus([selectedRow.id], 'rejected', justification);
                toast.success(<ToastSuccess
                    message={t('adminPages.validation.toastMessages.productRejected')}/>, toastStyle);
            } else if (currentTab === 'service') {
                await updateServiceStatus([selectedRow.id], 'rejected', justification);
                toast.success(<ToastSuccess
                    message={t('adminPages.validation.toastMessages.serviceRejected')}/>, toastStyle);
            } else if (currentTab === 'sellers') {
                await changeUserStatus([selectedRow.id], "rejected", justification);
                toast.success(<ToastSuccess
                    message={t('adminPages.validation.toastMessages.sellerRejected')}/>, toastStyle);
            } else if (currentTab === 'offers') {
                await updateOfferStatus([selectedRow.id], 'rejected', justification);
                toast.success(<ToastSuccess
                    message={t('adminPages.validation.toastMessages.offerRejected')}/>, toastStyle);
            }
            setOpenRejectDialog(false);
            setJustification('');
            await fetchData();
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.validation.toastMessages.rejectionError')}/>, toastStyle);
            setError('Failed to update status');
        }
    };

    const handleCancel = () => {
        setOpenRejectDialog(false);
        setJustification('');
    };

    const handleTabChange = (tabId) => {
        setCurrentTab(tabId);
    };

    const columns = {
        produit: produitColumns,
        service: serviceColumns,
        sellers: sellerColumns,
        offers: offerColumns
    }[currentTab] || [];

    const rows = {
        produit: rowsProduit,
        service: rowsService,
        sellers: rowsSellers,
        offers: rowsOffers
    }[currentTab] || [];

    return (
        <Box display="flex" flexDirection="column" height="100%" width="100%" className="dark:bg-[#232323]">
            <Box flex={1} overflow="hidden" display="flex" flexDirection="row">
                <Box flex={1} padding={1}>
                    <CustomTabs menuItems={secondaryMenuItems} onTabChange={handleTabChange} />
                    <Box display="flex" flexDirection="column" height="100%">
                        {loading ? (
                            <div className="flex items-center justify-center w-full h-full dark:bg-[#232323]">
                                <div className="loader dark:border-[#F2F2F2]" />
                            </div>
                        ) : error ? (
                            <Typography color="error" className="dark:text-[#F9CDC4]">{error}</Typography>
                        ) : (
                            <DataTable
                                key={currentTab}
                                rows={rows}
                                columns={columns}
                                showActions={false}
                                showExportImport={false}
                                showAddButton={false}
                                onSelectionChange={(selected) => setSelectedAll(selected)}
                                handleApproveAll={() => handleApproveAll(selectedAll)}
                                handleRejectAll={() => handleRejectAll(selectedAll)}
                                tabLabel={secondaryMenuItems.find((item) => item.id === currentTab)?.label}
                                className="dark:bg-[#333333] dark:text-[#F2F2F2]"
                            />
                        )}
                    </Box>
                </Box>
            </Box>

            <Modal
                open={openRejectDialog}
                onClose={handleCancel}
                className="flex items-center justify-center"
            >
                <div className="bg-white dark:bg-[#333333] p-6 rounded-lg shadow-xl w-[500px] max-w-[90%]">
                    <h2 className="text-xl font-semibold mb-4 dark:text-[#F2F2F2]">
                        {t('adminPages.validationVehicles.dialogs.rejectTitle')}
                    </h2>
                    <div className="mb-6">
                        <p className="mb-4 dark:text-[#CBCBCB]">
                            {t('adminPages.validationVehicles.dialogs.rejectMessage')}
                        </p>
                        <textarea
                            value={justification}
                            onChange={(e) => setJustification(e.target.value)}
                            placeholder="Entrez la justification ici..."
                            rows={4}
                            className="w-full h-[115px] border border-border rounded px-4 py-2 dark:bg-[#494949] dark:text-[#F2F2F2] dark:border-[#595959]"
                        />
                    </div>
                    <div className="flex justify-end gap-4">
                        <Button onClick={handleCancel} variant="outlined" className="dark:text-[#F9CDC4] dark:border-[#F9CDC4]">
                            {t('adminPages.validationVehicles.dialogs.cancel')}
                        </Button>
                        <Button onClick={handleRejectSubmit} variant="contained" className="dark:bg-[#E44826] dark:text-[#F2F2F2]">
                            {t('adminPages.validationVehicles.dialogs.confirmReject')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </Box>
    );
}