import WidgetElement from "../../components/WidgetElement/WidgetElement";
import SellerProductTable from "../../../../components/SellerProductTable/SellerProductTable";
import {useNavigate} from "react-router";
import React, {useEffect, useRef, useState} from "react";
import {
    getDashboardStats,
    getPromoCodes,
    restockProduct,
    updateProductPrice,
    uploadProductFile
} from "../../../../services/SellerSpace/API";
import {toast} from "sonner";
import ToastError, {toastStyle} from "../../../../components/Toasts/Error/ToastError";
import {calculatePercentageChange, formatCurrency} from "../../../../utils/utils";
import {create} from "zustand";
import {useNonMarketPlaceRoutesContext} from "../../../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import {ImageWithFallback} from "../../../../components/ImageWithFallBack/ImageWithFallBack";
import Cookies from "js-cookie";
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, CircularProgress} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from "react-i18next";
import {AddProductPageContext} from "../AddProducts/AddProduct";

export default function Products() {
    const navigate = useNavigate();
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [importLoading, setImportLoading] = useState(false);
    const {t} = useTranslation();

    const onDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file && file.name.endsWith('.csv')) {
            await handleImportCSV(file);
        } else {
            toast.error(<ToastError message={t('sellerSpace.products.InvalidFormat')}/>, toastStyle);
        }
    };

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: {
            'text/csv': ['.csv']
        },
        multiple: false
    });

    const handleImportCSV = async (file) => {
        try {
            setImportLoading(true);
            const formData = new FormData();
            formData.append('csv_file', file);
            const response = await uploadProductFile(formData);
            if (response.status === 200) {
                toast.success("Import réussi");
                setIsUploadModalOpen(false);
            }
        } catch (error) {
            toast.error(<ToastError message={t('sellerSpace.products.ImportError')}/>, toastStyle);
        } finally {
            setImportLoading(false);
        }
    };

    const [stats, setStats] = useState([]);
    useEffect(() => {
        try {
            const stats = Cookies.get("stats")
            if (stats) {
                const data = JSON.parse(stats)
                setStats(data)
            }
        } catch (e) {
            getDashboardStats()
                .then((response) => {
                    if (response.status === 200) {
                        Cookies.set("stats", JSON.stringify(response.data));
                        setStats(response.data);
                    }
                }).catch((error) => {
                toast.error(<ToastError message={t('sellerSpace.products.StatsError')}/>, toastStyle);
            });
        }
    }, []);

    const {editPopUpVisible, token} = useSellerProductContext();

    const [shop, setShop] = useState({})
    useEffect(() => {
        try {
            const shopData = Cookies.get("shop")
            if (shopData) {
                setShop(JSON.parse(shopData))
            }
        } catch (e) {
            //TODO : handle error
        }
    }, []);

    const [promoCodes, setPromoCodes] = useState([])
    useEffect(() => {
        getPromoCodes().then((response) => {
            if (response.status === 200) {
                setPromoCodes(response.data.promotions)
            }
        }).catch((e) => {
            toast.error(<ToastError message={t('sellerSpace.products.ErrorPromoCode')}/>, toastStyle);
        })
    }, [token])

    const [showHidden, setShowHidden] = useState(false);

    return (
        <>
            <div
                className="w-full h-full flex flex-col items-start gap-4 px-1 sm:px-4 py-2 overflow-y-auto noScrollBar max-h-[calc(91vh-40px)]">
                <Dialog
                    open={isUploadModalOpen}
                    onClose={() => setIsUploadModalOpen(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>
                        {t('sellerSpace.products.ImportProducts')}
                    </DialogTitle>
                    <DialogContent>
                        <div
                            {...getRootProps()}
                            className={`w-full p-8 border-2 border-dashed rounded-lg text-center transition-all cursor-pointer
                                ${isDragActive ? 'border-primaryLight bg-primaryExtraLight' : 'border-gray-300 hover:border-primaryLight hover:bg-primaryExtraLight'}`}
                        >
                            <input {...getInputProps()} />
                            <div className="flex flex-col items-center justify-center space-y-4">
                                {importLoading ? (
                                    <>
                                        <CircularProgress style={{color: '#f97316'}}/>
                                        <Typography variant="h6" style={{color: '#f97316'}}>
                                            {t('adminPages.vehicleConfig.common.importing')}
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <CloudUploadIcon style={{fontSize: 48, color: '#f97316'}}/>
                                        <Typography variant="h6" style={{color: '#f97316'}}>
                                            {isDragActive
                                                ? t('adminPages.vehicleConfig.dropzone.active')
                                                : t('adminPages.vehicleConfig.dropzone.inactive')}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {t('adminPages.vehicleConfig.dropzone.click')}
                                        </Typography>
                                        <Typography variant="caption" color="textSecondary">
                                            {t('adminPages.vehicleConfig.dropzone.format')}
                                        </Typography>
                                    </>
                                )}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsUploadModalOpen(false)} color="primary">
                            {t('sellerSpace.products.Close')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <div className="w-full h-fit flex flex-row items-center justify-between">
                    <p className="hidden sm:block font-semibold text-ellipsis whitespace-nowrap overflow-hidden w-full text-2xl">
                        {t('sellerSpace.products.MyProducts')}
                    </p>
                    <div className="flex flex-row items-center justify-end w-full gap-4">
                        <div
                            onClick={() => setShowHidden(!showHidden)}
                            className="w-[140px] bg-primaryExtraLight flex items-center border-primaryLight border justify-center gap-2 px-6 py-3 sm:w-fit h-fit rounded cursor-pointer">
                            {showHidden ?
                                (<>
                                    <img src="/resources/eye.svg" className="primary_filter w-4" alt="add icon"/>
                                    <p className="hidden sm:flex text-sm font-semibold text-primaryLight text-ellipsis whitespace-nowrap overflow-hidden">
                                        {t('sellerSpace.products.VisibleProducts')}
                                    </p>
                                </>)
                                :
                                (<>
                                    <img src="/resources/eye-slash.svg" className="primary_filter w-4" alt="add icon"/>
                                    <p className="hidden sm:flex text-sm font-semibold text-primaryLight truncate">
                                        {t('sellerSpace.products.HiddenProducts')}
                                    </p>
                                </>)
                            }
                        </div>
                        <div
                            onClick={() => setIsUploadModalOpen(true)}
                            className="w-[140px] bg-primaryExtraLight flex items-center border-primaryLight border justify-center gap-2 px-6 py-3 sm:w-fit h-fit rounded cursor-pointer"
                        >
                            <img src="/resources/import_icon.svg" className="primary_filter w-4" alt="upload icon"/>
                            <p className="hidden sm:block text-sm font-semibold text-primaryLight text-ellipsis whitespace-nowrap overflow-hidden">
                                {t('sellerSpace.products.ImportProducts')}
                            </p>
                        </div>
                        <div
                            onClick={() => {
                                if (shop?.status === "subscribed") {
                                    navigate("./addProduct");
                                }
                            }}
                            title={shop?.status === "subscribed" ? "" : "Subscribe to add products"}
                            className={`w-[140px] ${
                                shop?.status === "subscribed" ? "bg-primaryLight cursor-pointer" : "bg-gray-500 cursor-not-allowed"
                            } flex items-center justify-center gap-2 px-6 py-3 sm:w-fit h-fit rounded`}
                        >
                            <img src="/resources/add.svg" className="white_filter" alt="add icon"/>
                            <p className="text-sm hidden sm:block font-semibold text-white text-ellipsis whitespace-nowrap overflow-hidden">
                                {t('sellerSpace.products.AddProduct')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row gap-4 w-full h-fit items-center flex-wrap sm:flex-nowrap">
                    <WidgetElement
                        title={t('sellerSpace.products.TotalStockValue')}
                        amount={formatCurrency(stats?.total_stock_value)}
                        percentage={calculatePercentageChange(
                            stats?.total_stock_value,
                            stats?.monthly_stock_value_stats?.length > 1
                                ? stats?.monthly_stock_value_stats[stats?.monthly_stock_value_stats?.length - 2]?.stock_value
                                : 0
                        )}
                    />
                    <WidgetElement
                        title={t('sellerSpace.products.TotalArticleInStock')}
                        amount={stats?.total_items_in_stock}
                        percentage={calculatePercentageChange(
                            stats?.total_items_in_stock,
                            stats?.yesterday_items_in_stock
                        )}
                    />
                    <WidgetElement
                        title={t('sellerSpace.products.ProductsOutOfStock')}
                        amount={stats?.out_of_stock_products}
                        percentage={
                            calculatePercentageChange(
                                stats?.out_of_stock_products,
                                stats?.yesterday_out_of_stock_count
                            )}
                    />
                </div>
                <SellerProductTable maxHeight={"62vh"} showOnlyHidden={showHidden}/>
            </div>
            {editPopUpVisible && <EditProductPopUp promoCodes={promoCodes}/>}
        </>
    )
}

const EditProductPopUp = () => {
    const {product, setEditPopUpVisible, updateToken} = useSellerProductContext();
    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();

    const [restockQuantity, setRestockQuantity] = useState(0);
    const [newPrice, setNewPrice] = useState(product.price || 0);
    const calculateNewTotalStock = () => product.stock + restockQuantity;
    const {clearProductInfo} = AddProductPageContext();

    const handleSaveChanges = async () => {
        setLoading(true);
        try {
            if (restockQuantity > 0) {
                await restockProduct(product.id, restockQuantity);
            }
            if (newPrice !== product.price) {
                await updateProductPrice(product.id, newPrice);
            }
            setLoading(false);
            setEditPopUpVisible(false);
            updatePopUpVisible(false);
            clearProductInfo();
            updateToken();
        } catch (error) {
            setLoading(false);
            toast.error(<ToastError message={t('sellerSpace.products.ErrorUpdateProduct')}/>, toastStyle);
        }
    };

    return (
        <div
            className="flex w-full sm:w-[40vw] h-fit bg-white dark:bg-[#333333] rounded z-[99] absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 flex-col">
            <div className="min-h-14 border-b border-b-border dark:border-b-[#494949] px-2 w-full flex flex-row items-center justify-between">
                <p className="font-medium dark:text-[#F2F2F2]">{t('sellerSpace.products.ManageProduct')}</p>
                <button
                    onClick={() => {
                        updatePopUpVisible(false);
                        setEditPopUpVisible(false);
                    }}
                    className="w-10 h-10 flex items-center justify-center hover:bg-gray-100 dark:hover:bg-[#494949] cursor-pointer rounded-full"
                >
                    <img src="/resources/close.svg" alt="fermer" className="w-3"/>
                </button>
            </div>
            <div className="w-full h-full flex flex-col items-start">
                <div className="flex flex-row w-full">
                    <div className="w-[40%] min-h-full border-r border-r-border dark:border-r-[#494949] p-2">
                        <div className="flex flex-col gap-2 items-center">
                            <ImageWithFallback src={product.image} alt="produit" className="w-36 h-32 object-contain"/>
                            <div className="flex flex-col gap-2 items-start">
                                <p className="font-medium text-ellipsis line-clamp-2 overflow-hidden dark:text-[#F2F2F2]">
                                    {product.name}
                                </p>
                                <p className="text-gray-500 dark:text-[#CBCBCB] text-sm font-medium">{product.sku}</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-[60%] min-h-full p-4 flex flex-col gap-4">

                        {/* Stock Update */}
                        <div className="flex flex-col gap-2">
                            <label className="font-medium dark:text-[#F2F2F2]">{t('sellerSpace.products.QuantityToBeRestocked')} :</label>
                            <input
                                type="number"
                                value={restockQuantity}
                                onChange={(e) => setRestockQuantity(Number(e.target.value))}
                                className="border border-gray-300 dark:border-[#494949] dark:bg-[#232323] dark:text-[#F2F2F2] p-2 rounded-md"
                                placeholder="Entrer la quantité à ajouter"
                            />
                            <p className="text-gray-500 dark:text-[#CBCBCB]">
                                {t('sellerSpace.products.ActualStock')}: {product.stock} + {t('sellerSpace.products.AddedQuantity')}: {restockQuantity} =
                                <span className="font-bold dark:text-[#F2F2F2]"> {calculateNewTotalStock()}</span>
                            </p>
                        </div>

                        {/* Price Update */}
                        <div className="flex flex-col gap-2">
                            <label className="font-medium dark:text-[#F2F2F2]">{t('sellerSpace.products.ModifyPrice')} :</label>
                            <input
                                type="number"
                                value={newPrice}
                                min={0}
                                onChange={(e) => setNewPrice(Number(e.target.value))}
                                className="border border-gray-300 dark:border-[#494949] dark:bg-[#232323] dark:text-[#F2F2F2] p-2 rounded-md"
                                placeholder="Entrer le nouveau prix"
                            />
                        </div>

                        {/* Save Changes Button */}
                        <button
                            onClick={handleSaveChanges}
                            className="bg-primaryLight dark:bg-[#E44826] min-h-10 px-4 flex flex-row gap-2 items-center justify-center rounded-lg hover:bg-primaryDark dark:hover:bg-[#F9CDC4] cursor-pointer"
                        >
                            {loading ? (
                                <div className="w-4">
                                    <div className="loader_white"></div>
                                </div>
                            ) : (
                                <img src="/resources/pen.svg" className="white_filter" alt="save icon"/>
                            )}
                            <p className="font-medium text-white leading-none truncate">
                                {t('sellerSpace.products.SaveModifications')}
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export const useSellerProductContext = create((set) => ({
    editPopUpVisible: false,
    product: null,
    token: 0,
    setEditPopUpVisible: (value) => set({editPopUpVisible: value}),
    setProduct: (value) => set({product: value}),
    updateToken: () => set({token: Math.floor(Math.random() * 100) + 1}),
}));