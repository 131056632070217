import {useEffect, useRef, useState} from "react";
import {
    getBoatById,
    getEnginById,
    getProductById,
    getServiceById,
    getVehicleById
} from "../../services/marketplace/API";
import ProductCard from "../Marketplace/components/Cards/ProductCard";
import Carousel from "../Marketplace/components/Carousel/Carousel";
import {useFavoriteContext} from "../../hooks/context/useFavoriteContext";
import {useTranslation} from "react-i18next";
export default function WishList() {
    const [items, setItems] = useState([]);
    const {t} = useTranslation();
    const {favoriteList,removeFavorite} = useFavoriteContext();
    const [loading, setLoading] = useState(true);
    const ref = useRef();

    useEffect(() => {
        const fetchItems = async () => {
            setLoading(true); // Start loading

            const fetchedItems = await Promise.all(
                favoriteList.map(async (element) => {
                    try {
                        let response;

                        if (element.elementType === "product") {
                            response = await getProductById(element.item);
                        } else if (element.elementType === "service") {
                            response = await getServiceById(element.item);
                        } else if (element.elementType === "vehicle") {
                            response = await getVehicleById(element.item);
                        } else if (element.elementType === "boat") {
                            response = await getBoatById(element.item);
                        } else if (element.elementType === "engin") {
                            response = await getEnginById(element.item);
                        }

                        // Check if the response is successful
                        if (response) {
                            return { data: response.data, type: element.elementType };
                        }
                    } catch (err) {
                        if (err.response.status === 404){
                            removeFavorite(Number(element.item),element.elementType);
                        }
                        console.error(`Failed to fetch ${element.elementType} with id ${element.item}:`, err);
                    }
                    return null; // Return null in case of error or unsuccessful response
                })
            );

            // Filter out null responses (in case of errors)
            setItems(fetchedItems.filter(item => item !== null));
            setLoading(false); // Stop loading after fetching is complete
        };

        if (favoriteList.length > 0) {
            fetchItems();
        } else {
            setItems([]); // Clear the list if there are no favorites
            setLoading(false); // Stop loading since there's nothing to fetch
        }
    }, [favoriteList]);


    return (
        <div ref={ref} className="w-full h-[62vh] px-6 lg:px-[11vw] py-8 flex flex-col gap-6 dark:bg-[#232323]">
            <p className='font-semibold text-2xl dark:text-[#F2F2F2]'>{t("myWishlist.title")} ({items.length})</p>
            {
                loading && <div className="w-full h-full flex items-center justify-center">
                    <div className="loader dark:border-[#F9CDC4]"></div>
                </div>
            }
            {
                !loading && (items.length === 0 ?
                        <p className="dark:text-[#CBCBCB]">{t("myWishlist.emptyWishlist")}</p>
                        :
                        <Carousel
                            ref={ref}
                            items={items}
                            renderItem={(item,index) => (<ProductCard {...item.data} first_photo={item.data?.photos && items.data?.photos.length > 0 ? item.data.photos[0] : item.data?.first_photo} itemType={items[index].type}/>)}
                            itemsWidth={300}
                        />
                )
            }
        </div>
    );
}