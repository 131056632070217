import React from "react";

export default function NumberInputFilter({titre,value,onChange,min,max,placeholder}) {
    return (
        <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row h-9 items-center justify-between">
                <p className="font-medium dark:text-[#F2F2F2]">{titre}</p>
            </div>
            <div className="flex flex-col gap-2">
                <input
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    name={titre}
                    placeholder={placeholder}
                    min={min}
                    max={max}
                    type="number"
                    className="w-full h-10 border border-border dark:border-[#494949] rounded px-4 dark:bg-[#232323] dark:text-[#F2F2F2] dark:placeholder-[#797979]"
                />
            </div>
        </div>
    )
}