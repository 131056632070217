import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent } from '@mui/material';
import DataTable from '../dataTable/DataTable';
import {AdminStats, getPromotions} from '../../../../services/AdminSapce/API';
import ToastError, { toastStyle } from "../../../../components/Toasts/Error/ToastError";
import { toast } from "sonner";
import { useNavigate } from 'react-router-dom';
import StatsCard from '../Dashboard/components/StatsCard';
import {useTranslation} from "react-i18next";

function getCurrentDate() {
    const options = { day: 'numeric', month: 'short' };
    return new Date().toLocaleDateString('fr-FR', options);
}
const PromotionDialog = ({ open, handleClose, products }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const columns = [
        {
            field: 'name',
            headerName: `${t("adminPages.promotion.name")}`,
            width: 200
        },
        {
            field: 'price',
            headerName: `${t("adminPages.promotion.price")}`,
            width: 120
        },
        {
            field: 'stock_quantity',
            headerName: `${t("adminPages.promotion.stock")}`,
            width: 100
        },
    ];

    const handleProductClick = (row) => {
        navigate(`/product/${row.id}`);
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle className="bg-gray-50 dark:bg-[#333333] border-b">
                <Typography variant="h6" className="text-gray-800 dark:text-[#F2F2F2]">
                    {t("adminPages.promotion.relatedProducts")}
                </Typography>
            </DialogTitle>
            <DialogContent className="mt-4 dark:bg-[#232323]">
                <DataTable
                    rows={products}
                    columns={columns}
                    showActions={false}
                    onRowClick={handleProductClick}
                    showAddButton={false}
                    showExportImport={false}
                    showApproveRejectButtons={false}
                    showSelectAll={false}
                />
            </DialogContent>
        </Dialog>
    );
};

export default function Promotions() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [stats, setStats] = useState({
        totalPromotions: 0,
        totalActivePromotions: 0,
        totalExpiredPromotions: 0,
        totalUsedPromotions: 0
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [promotions, setPromotions] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);

    const fetchStatsData = async () => {
        try {
            const data = await AdminStats();
            if (data) {
                setStats({
                    totalPromotions: data.totalPromotions || 0,
                    totalActivePromotions: data.totalActivePromotions || 0,
                    totalExpiredPromotions: data.totalExpiredPromotions || 0,
                    totalUsedPromotions: data.totalUsedPromotions || 0
                });
            }
        } catch (error) {
            toast.error(<ToastError message={t("adminPages.promotion.errorFetchingData")}  />, toastStyle);
        }
    };

    const fetchPromotions = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await getPromotions();
            setPromotions(data.map(promo => ({
                id: promo.id,
                code: promo.code,
                description: promo.description,
                discount: `${promo.discount_percentage}%`,
                discount_percentage: promo.discount_percentage,
                startDate: new Date(promo.start_date).toLocaleDateString(),
                endDate: new Date(promo.end_date).toLocaleDateString(),
                shop: promo.shop.name,
                shop_id: promo.shop.id,
                products: promo.products
            })));
        } catch (error) {
            toast.error(<ToastError message={t("adminPages.promotion.errorFetchingData")} />, toastStyle);
            setError('Erreur lors du chargement des promotions.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPromotions();
        fetchStatsData();
    }, []);

    const handleRowClick = (row) => {
        setSelectedProducts(row.products);
        setDialogOpen(true);
    };

    const getDiscountColor = (percentage) => {
        if (percentage < 50) return 'text-red-500 dark:text-[#F9CDC4]';
        if (percentage === 50) return 'text-orange-400 dark:text-[#E44826]';
        return 'text-blue-400 dark:text-[#F2F2F2]';
    };

    const columns = [
        {
            field: 'code',
            headerName: `${t("adminPages.promotion.promoCode")}`,
            width: isMobile ? 120 : 150,
            renderCell: ({row}) => (
                <div className="font-semibold dark:text-[#F2F2F2]">
                    {row.code}
                </div>
            )
        },
        {
            field: 'description',
            headerName: `${t("adminPages.promotion.description")}`,
            width: isMobile ? 150 : 200,
            hide: isMobile,
        },
        {
            field: 'discount',
            headerName: `${t("adminPages.promotion.discount")}`,
            width: isMobile ? 100 : 120,
            renderCell: ({ row }) => (
                <div className={`font-semibold ${getDiscountColor(row.discount_percentage)}`}>
                    {row.discount}
                </div>
            )
        },
        {
            field: 'startDate',
            headerName: `${t("adminPages.promotion.startDate")}`,
            width: isMobile ? 120 : 150,
            hide: isMobile,
        },
        {
            field: 'endDate',
            headerName: `${t("adminPages.promotion.endDate")}`,
            width: isMobile ? 120 : 150,
            hide: isMobile || isTablet,
        },
        {
            field: 'shop',
            headerName: `${t("adminPages.promotion.shop")}`,
            width: isMobile ? 120 : 150,
            renderCell: ({ row }) => (
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/shop/${row.shop_id}`);
                    }}
                    className="cursor-pointer text-blue-500 hover:text-blue-700 dark:text-[#F2F2F2] dark:hover:text-[#CBCBCB] transition-colors"
                >
                    {row.shop}
                </div>
            )
        }
    ];

    return (
        <Box className="min-h-screen bg-gray-50 dark:bg-[#232323] p-6">
            <Box className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
                <StatsCard
                    title={t("adminPages.promotion.totalPromotions")}
                    value={stats.totalPromotions}
                    ispercentage={false}
                    date={getCurrentDate()}
                />
                <StatsCard
                    title={t("adminPages.promotion.activePromotions")}
                    value={stats.totalActivePromotions}
                    ispercentage={false}
                    date={getCurrentDate()}
                />
                <StatsCard
                    title={t("adminPages.promotion.expiredPromotions")}
                    value={stats.totalExpiredPromotions}
                    ispercentage={false}
                    date={getCurrentDate()}
                />
                <StatsCard
                    title={t("adminPages.promotion.totalUsedPromotions")}
                    value={stats.totalUsedPromotions}
                    ispercentage={false}
                    date={getCurrentDate()}
                />
            </Box>

            <Box className="bg-white dark:bg-[#333333] rounded-lg shadow-sm">
                {loading ? (
                    <div className="flex items-center justify-center h-64">
                        <div className="loader"/>
                    </div>
                ) : error ? (
                    <Box className="flex items-center justify-center h-64">
                        <Typography className="text-red-500 dark:text-[#F9CDC4]">
                            {error}
                        </Typography>
                    </Box>
                ) : (
                    <>
                        <DataTable
                            rows={promotions}
                            columns={columns}
                            showActions={false}
                            onRowClick={handleRowClick}
                            showAddButton={false}
                            showExportImport={false}
                            showApproveRejectButtons={false}
                            showSelectAll={false}
                        />
                        <PromotionDialog
                            open={dialogOpen}
                            handleClose={() => setDialogOpen(false)}
                            products={selectedProducts}
                        />
                    </>
                )}
            </Box>
        </Box>
    );
}