import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const ResetLinkSent = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const email = location.state?.email || "";

    return (
        <div className="w-screen h-screen flex flex-col items-center justify-center p-6 space-y-6 bg-white dark:bg-[#232323]">
            <img src="/images/undraw_mail.svg" alt="undraw illustration" className="w-32 ml-8" />
            <h2 className="text-xl font-bold text-gray-800 dark:text-[#F2F2F2] text-center">
                {t("reset_link_sent_title")}
            </h2>
            <p className="text-sm text-gray-600 dark:text-[#CBCBCB] text-center max-w-sm">
                {t("reset_link_sent_message", { email })}
            </p>
            <button
                onClick={() => navigate("/")}
                className="w-full max-w-xs h-12 bg-primaryLight dark:bg-[#E44826] text-white rounded-lg font-medium hover:bg-primaryDark dark:hover:bg-[#F9CDC4] transition"
            >
                {t("return_home")}
            </button>
        </div>
    );
};

export default ResetLinkSent;