import CheckBox from "../../../../components/Checkbox";
import React, {useEffect, useMemo, useState} from "react";
import {create} from "zustand";
import {debounce} from "lodash";
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import TablePaginationNavigation from "../../../../components/TablePaginationNavigation/TablePaginationNavigation";
import {createPack, getSellerProducts} from "../../../../services/SellerSpace/API";
import {formatCurrency, formatDateForMySQL, getPhoto} from "../../../../utils/utils";
import {toast} from "sonner";
import ToastError, {toastStyle} from "../../../../components/Toasts/Error/ToastError";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import {ImageWithFallback} from "../../../../components/ImageWithFallBack/ImageWithFallBack";
import {useNavigate} from "react-router";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";

export default function AddPacks() {
    const {t} = useTranslation();
    const {
        reductionPercentage,
        packName,
        selectedProducts,
        duration,
        offer_quantity
    } = useAddPackContext();

    const [actionLoading, setActionLoading] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        try {
            const shopData = Cookies.get("shop")
            if (shopData) {
                if (shopData) {
                    const shopInfo = JSON.parse(shopData);
                    if (shopInfo?.status !== "subscribed") {
                        navigate(-1);
                    }
                }
            }
        } catch (e) {

        }
    }, [navigate]);

    const handleCreate = () => {
        setActionLoading(true)
        const pack = {
            offer_name: packName,
            description: "Get 25% off on all items",
            start_date: formatDateForMySQL(new Date()),
            duration: Number(duration),
            discount_percentage: reductionPercentage,
            type: "product",
            offer_quantity: offer_quantity,
            products: [
                ...selectedProducts.map(product => ({
                    id: product.id,
                    quantity: product.quantity || 1
                }))
            ]
        }
        createPack(pack).then(response => {
            setActionLoading(false)
            if (response.status === 201) {
                toast.success(<ToastSuccess message={t('sellerSpace.addPacks.toast.success')}/>, toastStyle);
            }
        }).catch((error) => {
            setActionLoading(false);
            let firstErrorMessage;
            let firstErrorKey;
            if (error.response.data?.errors) {
                firstErrorKey = Object.keys(error.response.data.errors)[0];
                firstErrorMessage = error.response.data.errors[firstErrorKey];
            } else if (error.response.data?.error) {
                firstErrorMessage = error.response.data.error;
            } else {
                firstErrorMessage = t('sellerSpace.addPacks.toast.error');
            }
            toast.error(<ToastError message={firstErrorMessage}/>, toastStyle);
        });
    }
    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        // Initial dark mode check
        const isDark = localStorage.getItem('darkMode') === 'true';
        setDarkMode(isDark);

        // Listen for dark mode changes
        const handleDarkModeChange = (e) => {
            if (e.key === 'darkMode') {
                setDarkMode(e.newValue === 'true');
            }
        };

        window.addEventListener('storage', handleDarkModeChange);

        // Watch for DOM class changes to detect dark mode toggle
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {
                    setDarkMode(document.documentElement.classList.contains('dark'));
                }
            });
        });

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['class']
        });

        // Cleanup listeners
        return () => {
            window.removeEventListener('storage', handleDarkModeChange);
            observer.disconnect();
        };
    }, []);
    return (
        <div className="w-full flex flex-col bg-white dark:bg-[#232323] relative">
            <div className="h-[6.4vh] flex flex-row items-center justify-between px-4 border-b border-border dark:border-[#333333]">
                <div className='flex flex-row gap-2 items-center'>
                    <div
                        onClick={() => navigate(-1)}
                        className="p-4 hover:bg-gray-100 dark:hover:bg-[#333333] cursor-pointer rounded flex items-center justify-center">
                        <img src="/resources/arrow_right.svg" className={`transform -rotate-180 ${darkMode ? 'white_filter' : ''}`} alt="back icon"/>
                    </div>
                    <p className='text-lg sm:text-xl md:text-2xl font-semibold truncate max-w-[70%] sm:max-w-full dark:text-[#F2F2F2]'>{t('sellerSpace.addPacks.title')}</p>
                </div>
                <button
                    disabled={actionLoading}
                    onClick={handleCreate}
                    className="bg-primaryLight dark:bg-[#E44826] disabled:bg-gray-500 dark:disabled:bg-[#595959] flex items-center justify-center gap-2 px-6 w-fit h-fit py-3 rounded cursor-pointer">
                    {
                        actionLoading ?
                            <div className="w-5">
                                <div className="loader_white"></div>
                            </div>
                            :
                            <img src="/resources/save.svg" alt="save icon" className=""/>
                    }
                    <p className="text-sm font-semibold text-white dark:text-[#F2F2F2]">{t('sellerSpace.addPacks.save')}</p>
                </button>
            </div>
            <div
                className='w-full min-h-[calc(84.6vh-40px)] max-h-[calc(84.6vh-40px)] overflow-y-auto flex flex-col sm:flex-row items-start'>
                <div
                    className="w-full sm:w-[35%] flex flex-col border-r border-border dark:border-[#333333] gap-8 p-4 items-start min-h-[calc(84.6vh-40px)] max-h-[calc(84.6vh-40px)]">
                    <PackGeneralInfo/>
                    <SelectedProductList/>
                </div>
                <div
                    className="w-full sm:w-[65%] flex flex-col gap-4 items-start min-h-[calc(84.6vh-40px)] max-h-[calc(84.6vh-40px)]">
                    <CreatePackInventoryListTable maxHeight="84.6vh"/>
                </div>
            </div>
        </div>
    )
}

const PackGeneralInfo = () => {
    const {t} = useTranslation();
    const [paiement, setPaiement] = useState(false);
    const {
        setReductionPercentage,
        setPackName,
        setDurationDays,
        reductionPercentage,
        duration,
        packName,
        offer_quantity,
        updateOfferQuantity
    } = useAddPackContext();

    const handleInputChange = (e) => {
        const value = e.target.value;

        if (value === "" || (/^\d+$/.test(value) && value <= 100)) {
            setReductionPercentage(value);
        }
    };
    return (
        <div className="flex flex-col gap-4 w-full">
            <p className="leading-none pb-4 border-b border-border dark:border-[#333333] font-medium text-xl dark:text-[#F2F2F2]">{t('sellerSpace.addPacks.generalInfo.title')}</p>
            <div className="flex flex-row gap-8  w-full h-fit">
                <div className="flex flex-col items-start justify-between w-full">
                    <p className="text-black dark:text-[#F2F2F2]">{t('sellerSpace.addPacks.generalInfo.packName')}</p>
                    <input type="text" onChange={(e) => setPackName(e.target.value)} placeholder={t('sellerSpace.addPacks.generalInfo.packNamePlaceholder')}
                           className="w-full h-10 border border-border dark:border-[#333333] dark:bg-[#232323] dark:text-[#F2F2F2] rounded px-4"
                           name={"packName"} value={packName}/>
                </div>
                <div className="flex flex-col items-start justify-between w-full">
                    <label htmlFor="reduction" className="text-black dark:text-[#F2F2F2] truncate">{t('sellerSpace.addPacks.generalInfo.reductionPercentage')}</label>
                    <div className="w-full flex items-center">
                        <input
                            id="reduction"
                            type="text"
                            className="w-full h-10 border border-border dark:border-[#333333] dark:bg-[#232323] dark:text-[#F2F2F2] rounded px-4"
                            value={reductionPercentage}
                            onChange={handleInputChange}
                            placeholder={t('sellerSpace.addPacks.generalInfo.reductionPercentagePlaceholder')}
                        />
                        <span className="ml-2 dark:text-[#F2F2F2]">%</span>
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-8 items-center w-full h-fit">
                <div className="flex flex-col items-start justify-between w-full">
                    <p className="text-black dark:text-[#F2F2F2]">{t('sellerSpace.addPacks.generalInfo.durationDays')}</p>
                    <input
                        type="number"
                        onChange={(e) => setDurationDays(e.target.value)}
                        name="duration_days"
                        value={duration}
                        className="w-full h-10 border border-border dark:border-[#333333] dark:bg-[#232323] dark:text-[#F2F2F2] rounded px-4"
                        min="1"
                    />
                </div>
                <div className="flex flex-col items-start justify-between w-full">
                    <p className="text-black dark:text-[#F2F2F2]">{t('sellerSpace.addPacks.generalInfo.offerQuantity')}</p>
                    <input type="number" min={1} name={"offer_quantity"} value={offer_quantity}
                           onChange={(e) => updateOfferQuantity(e.target.value)}
                           placeholder={1}
                           className="w-full h-10 border border-border dark:border-[#333333] dark:bg-[#232323] dark:text-[#F2F2F2] rounded px-4"/>
                </div>
            </div>
            <div className="flex flex-row gap-8 items-center w-full h-fit">
                <div className="flex flex-row items-center gap-2 w-full h-full">
                    <CheckBox checkedValueState={[paiement, setPaiement]}/>
                    <p className="dark:text-[#F2F2F2]">{t('sellerSpace.addPacks.generalInfo.onlinePayment')}</p>
                </div>
            </div>
        </div>
    )
}

const useAddPackContext = create((set) => ({
    packName: "",
    setPackName: (value) => set(() => ({packName: value})),
    reductionPercentage: 0,
    setReductionPercentage: (value) => set(() => ({reductionPercentage: value})),
    selectedProducts: [],
    pack_type: "product",
    updateProductType: (value) => set(() => ({pack_type: value})),
    duration: 1,
    setDurationDays: (value) => set(() => ({duration: value})),
    handleSelectedProducts: (product) => set((state) => {
        console.log(product)
        console.log(state.selectedProducts)
        const isSelected = state.selectedProducts.some(item => item.id === product.id); // Compare by product.id
        console.log("IS SELECTED : ", isSelected)
        if (isSelected) {
            return {selectedProducts: state.selectedProducts.filter((item) => item.id !== product.id)};
        }
        return {selectedProducts: [...state.selectedProducts, product]};
    }),
    setProductQuantity: (id, quantity) => set((state) => {
        return {
            selectedProducts: state.selectedProducts.map((product) => {
                if (product.id === id) {
                    if (quantity > product.stock) {
                        product.quantity = product.stock;
                        return product;
                    }
                    product.quantity = quantity;
                }
                return product;
            })
        }
    }),
    offer_quantity: 1,
    updateOfferQuantity: (value) => set(() => ({offer_quantity: value})),
}));

const ROW_HEIGHT = 81;

const calculatePageSize = (maxHeight) => {
    if (!maxHeight) return 6;

    if (maxHeight?.endsWith("vh")) {
        const vhValue = parseFloat(maxHeight);
        // row area height = table area - table header - pagination comp height - search comp height - title comp height
        maxHeight = (vhValue / 100) * window.innerHeight - 56 - 50 - 64;
    }

    return Math.floor(maxHeight / ROW_HEIGHT);
};

const CreatePackInventoryListTable = ({maxHeight}) => {
    const {t} = useTranslation();
    const {selectedProducts, handleSelectedProducts} = useAddPackContext();
    const [fetchedData, setFetchedData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchData = () => {
        setLoading(true);
        setError(null);
        getSellerProducts().then(response => {
            if (response.status === 200) {
                console.log(response.data)
                setFetchedData(response.data.filter(product => product.status === "verified"));
            } else {
                console.log(response.error);
            }
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            let firstErrorMessage;
            let firstErrorKey;
            setError(error);
            if (error.response.data?.errors) {
                firstErrorKey = Object.keys(error.response.data.errors)[0];
                firstErrorMessage = error.response.data.errors[firstErrorKey];
            } else if (error.response.data?.error) {
                firstErrorMessage = error.response.data.error;
            } else {
                firstErrorMessage = t('sellerSpace.addPacks.inventory.error');
            }
            toast.error(<ToastError message={firstErrorMessage}/>, toastStyle);
        });
    }

    useEffect(() => {
        fetchData()
    }, []);

    const data = useMemo(
        () =>
            fetchedData
                .filter(product => product.stock_quantity > 0)
                .map(product => ({
                    product: {
                        id: product.id,
                        image: product.photos[0]?.link,
                        name: product.name || "",
                        sku: product.serial_number || "",
                        stock: product.stock_quantity || 0,
                        price: product.price || 0,
                    },
                    sold: product.orders_count || 0,
                    category: product.sub_category.name || "",
                    stock: product.stock_quantity > 0 ? t('sellerSpace.addPacks.inventory.inStock') : t('sellerSpace.addPacks.inventory.outOfStock'),
                    price: formatCurrency(product.price) || "",
                })),
        [fetchedData, t]
    );

    const isSmallScreen = window.innerWidth <= 640;

    const columns = useMemo(() => {
        const allColumns = [
            {
                accessorKey: 'product',
                header: t('sellerSpace.addPacks.inventory.product'),
                cell: ({getValue}) => {
                    const value = getValue();
                    const isChecked = selectedProducts.includes(value);
                    return (
                        <div className="flex items-center flex-row gap-2">
                            <CheckBox checkedValueState={[isChecked, () => handleSelectedProducts(value)]}/>
                            <ImageWithFallback src={getPhoto({link: value.image, type: "img"})} alt={value.name}
                                               className="w-12 h-12 rounded mr-4"/>
                            <div className="flex-1 min-w-0 max-w-[100px] lg:max-w-[200px]">
                                <div className="font-medium truncate dark:text-[#F2F2F2]">{value?.name}</div>
                                <div className="text-sm text-gray-500 dark:text-[#CBCBCB] truncate">
                                    {t('sellerSpace.addPacks.inventory.sku')}: {value?.sku}
                                </div>
                            </div>
                        </div>
                    );
                },
            },
            {
                accessorKey: 'sold',
                header: t('sellerSpace.addPacks.inventory.sold'),
            },
            {
                accessorKey: 'stock',
                header: t('sellerSpace.addPacks.inventory.stock'),
                cell: ({getValue}) => <StockStatus status={getValue()}/>,
            },
            {
                accessorKey: 'price',
                header: t('sellerSpace.addPacks.inventory.price'),
                cell: ({getValue}) => <p className="font-medium truncate dark:text-[#F2F2F2]">{getValue()}</p>,
            },
        ]

        const essentialColumns = [t('sellerSpace.addPacks.inventory.product'), t('sellerSpace.addPacks.inventory.price'), t('sellerSpace.addPacks.inventory.sold')];
        return isSmallScreen ? allColumns.filter(col => essentialColumns.includes(col.header)) : allColumns;
    }, [handleSelectedProducts, isSmallScreen, selectedProducts, t]);

    const [filter, setFilter] = useState('');
    const [sort, setSort] = useState([]);

    const [pageSize, setPageSize] = useState(calculatePageSize(maxHeight));
    useEffect(() => {
        setPageSize(calculatePageSize(maxHeight));
    }, [maxHeight]);


    const handleFilterChange = useMemo(() => debounce((value) => {
        setFilter(value);
    }, 300), []);

    const table = useReactTable({
        data,
        columns,
        state: {
            globalFilter: filter,
            sorting: sort
        },
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onSortingChange: setSort,
        onGlobalFilterChange: setFilter,
        enableGlobalFilter: true,
        initialState: {pagination: {pageSize: pageSize}}
    });

    const [height, setHeight] = useState(0);
    useEffect(() => {
        setHeight(pageSize * ROW_HEIGHT);
    }, [pageSize]);

    if (loading) {
        return <div className="w-full h-full flex items-center justify-center">
            <div className="loader"></div>
        </div>
    }

    return (
        <div className="relative flex flex-col items-center justify-between overflow-x-hidden w-full min-h-full bg-white dark:bg-[#232323]">
            {
                error !== null ?
                    <div style={{minHeight: (height)}}
                         className="w-full h-full flex flex-col gap-2 items-center justify-center">
                        <div className="p-4 text-center text-gray-500 dark:text-[#CBCBCB]">
                            {t('sellerSpace.addPacks.inventory.error')}
                        </div>
                        <button
                            onClick={fetchData}
                            className="bg-primaryLight dark:bg-[#E44826] min-h-10 text-white px-6 py-2 rounded hover:bg-primaryDark dark:hover:bg-[#F9CDC4] transition duration-200 flex flex-row gap-2 items-center"
                        >
                            <img src="/resources/retry.svg" alt="retry icon" className="white_filter"/>
                            <p className="font-medium text-white dark:text-[#F2F2F2] leading-none">{t('sellerSpace.addPacks.inventory.retry')}</p>
                        </button>

                    </div>
                    :
                    data.length === 0 ? (
                        <div style={{minHeight: (height)}}
                             className="w-full h-full flex flex-col items-center justify-center">
                            <img src="/images/undraw_no_data.svg" alt="empty" className="w-[250px] mb-8 dark:invert"/>
                            <p className="p-4 text-center text-gray-500 dark:text-[#CBCBCB]">
                                {t('sellerSpace.addPacks.inventory.noProducts')}
                            </p>
                        </div>
                    ) : (
                        <div
                            className="relative flex flex-col items-center justify-between w-full">
                            <div className="flex flex-col w-full h-full">
                                <div
                                    className="flex flex-row items-center justify-end gap-4 border-b-border dark:border-[#333333] border-b pr-8 sm:pr-4 px-4 min-h-16 w-full">
                                    <div
                                        className="w-[30vw] sm:w-[15vw] h-10 bg-[#FCFCFC] dark:bg-[#333333] border-[#D4D4D4] dark:border-[#494949] border px-4 flex flex-row items-center rounded-lg gap-2">
                                        <div className="flex flex-row items-center gap-2">
                                            <input
                                                onChange={(e) => handleFilterChange(e.target.value)}
                                                className="w-[25vw] sm:w-[10vw] lg:w-[10vw] h-9 bg-[#FCFCFC] dark:bg-[#333333] dark:text-[#F2F2F2] border-none focus:outline-none"
                                                placeholder={t('sellerSpace.addPacks.inventory.search')}
                                            />
                                        </div>
                                        <img src="/resources/search.svg" alt="search icon" className="dark:invert"/>
                                    </div>
                                </div>
                                <div style={{minHeight: height,maxHeight:height}}
                                     className="w-full overflow-y-auto">
                                    <table
                                        className="min-w-full bg-white dark:bg-[#232323] border border-gray-200 dark:border-[#333333] rounded-lg table-fixed">
                                        <thead
                                            className="bg-gray-100 dark:bg-[#333333] sticky top-0 z-10 min-h-14 h-14 border-b border-border dark:border-[#494949] max-h-14">
                                        {table.getHeaderGroups().map(headerGroup => (
                                            <tr key={headerGroup.id} className="text-left">
                                                {headerGroup.headers.map(header => (
                                                    <th
                                                        key={header.id}
                                                        onClick={header.column.getToggleSortingHandler()}
                                                        className="px-3 sm:px-6 py-3 text-[#979797] dark:text-[#CBCBCB] text-sm font-medium border-b border-gray-200 dark:border-[#494949]"
                                                    >
                                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                                        {{asc: " ↑", desc: " ↓"}[header.column.getIsSorted() ?? null]}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                        </thead>
                                        <tbody>
                                        {table.getRowModel().rows.map(row => (
                                            <tr key={row.id} className="hover:bg-gray-50 dark:hover:bg-[#333333]">
                                                {row.getVisibleCells().map(cell => (
                                                    <td key={cell.id}
                                                        className="px-3 sm:px-6 py-4 border-b border-gray-200 dark:border-[#494949] font-medium max-h-14">
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <TablePaginationNavigation table={table}/>
                        </div>
                    )}
        </div>
    );
};

const StockStatus = ({status}) => {
    const {t} = useTranslation();
    return (
        <>
            {status === t('sellerSpace.addPacks.inventory.inStock') ? (
                <div
                    className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-[#DEFFF0] dark:bg-[#333333] border border-[#7EDCB0] dark:border-[#494949]'>
                    <p className="text-sm font-medium text-[#7EDCB0] dark:text-[#F2F2F2] truncate">{status}</p>
                </div>
            ) : (
                <div
                    className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-[#FFF1F1] dark:bg-[#333333] border border-[#FF1D20] dark:border-[#494949]'>
                    <p className="text-sm font-medium text-[#FF0004] dark:text-[#F2F2F2] truncate">{status}</p>
                </div>
            )}
        </>
    );
};

const SelectedProductList = () => {
    const {t} = useTranslation();
    const {selectedProducts, reductionPercentage, setProductQuantity} = useAddPackContext();
    const [totalPrice, updateTotalPrice] = useState(0);
    useEffect(() => {
        updateTotalPrice(selectedProducts.reduce((acc, item) => acc + (item.price * (item.quantity || 1)), 0));
    }, [selectedProducts]);
    return (
        <div className="w-full min-h-[calc(85vh-340px)] max-h-[calc(85vh-340px)] flex flex-col justify-between">
            <p className="font-medium text-xl mb-3 dark:text-[#F2F2F2]">{t('sellerSpace.addPacks.selectedProducts.title')}</p>
            <div className="flex flex-col gap-3 h-full min-h-full max-h-[40vh] overflow-y-auto">
                {selectedProducts.map((item) => (
                    <SelectedProductListItem item={item} key={item.id}
                                             onPackInstanceChange={
                                                 (id, quantity) => setProductQuantity(id, quantity)
                                             }/>
                ))}
            </div>
            <div className="pt-8 border-t border-border dark:border-[#333333] border-dashed flex flex-row items-center justify-between gap-4">
                <p className="text-2xl font-medium dark:text-[#F2F2F2]">{t('sellerSpace.addPacks.selectedProducts.price')}</p>
                <div className="flex flex-row items-center gap-2 w-full justify-end">
                    <p className="font-medium text-xl line-through dark:text-[#CBCBCB]">{formatCurrency(totalPrice)}</p>
                    <p className="font-semibold text-2xl dark:text-[#F2F2F2]">{formatCurrency(totalPrice - (reductionPercentage / 100) * totalPrice)}</p>
                </div>
            </div>
        </div>
    )
}
const SelectedProductListItem = ({item, onPackInstanceChange}) => {
    const {t} = useTranslation();
    const [quantity, setQuantity] = useState(item.quantity || 1);
    useEffect(() => {
        setQuantity(item.quantity || 1);
    }, [item.quantity]);
    return (
        <div className="w-full h-fit flex flex-row items-center justify-between px-2 py-1">
            <div className="flex flex-row items-center gap-1 w-[40%]">
                <ImageWithFallback src={item.image} alt={item.name} className="w-12 h-12 rounded mr-4"/>
                <div className="w-full max-w-[80px]">
                    <div className="font-medium text-ellipsis whitespace-nowrap overflow-hidden dark:text-[#F2F2F2]">{item.name}</div>
                    <div className="text-sm text-gray-500 dark:text-[#CBCBCB] text-ellipsis whitespace-nowrap overflow-hidden">
                        {t('sellerSpace.addPacks.inventory.sku')}: {item.sku}
                    </div>
                </div>
            </div>
            <div className="w-fit flex flex-row items-center gap-2">
                <div className="flex flex-col items-center">
                    <label htmlFor={`pack-instance-${item.id}`} className="text-sm text-gray-500 dark:text-[#CBCBCB]">{t('sellerSpace.addPacks.selectedProducts.itemsPerPack')}</label>
                    <input
                        type="number"
                        placeholder={1}
                        id={`pack-instance-${item.id}`}
                        value={item.quantity || 1}
                        onChange={(e) => onPackInstanceChange(item.id, e.target.value)}
                        min={1}
                        max={item.stock}
                        className="border border-border dark:border-[#333333] dark:bg-[#232323] dark:text-[#F2F2F2] rounded-lg p-2 w-16 text-center"
                    />
                    <p className="text-xs text-gray-500 mt-1">{t('sellerSpace.addPacks.selectedProducts.max')}: {item.stock}</p>
                </div>

            </div>
            <p className="font-medium">{formatCurrency(item.price * quantity)}</p>
        </div>
    );
};