import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="bg-[#303841] dark:bg-[#000] text-[#F2F2F2] w-full py-10 px-[6vw] sm:px-[11vw]">
            <div className="lg:flex lg:justify-between">
                {/* Left Section */}
                <div className="mb-8 lg:mb-0 lg:w-1/3">
                    <img src="/resources/white_Logo.svg" alt="white-logo" className="w-[130px]"/>
                    <p className="my-4 text-sm text-[#CBCBCB]">
                        {t('footer.description')}
                    </p>
                    <a href="mailto:contact@mecanext-dz.com" className="text-[#F2F2F2] underline">contact@mecanext-dz.com</a>
                </div>

                {/* Right Section */}
                <div className="lg:flex lg:gap-12">
                    {/* Informations */}
                    <div className="mb-6 lg:mb-0">
                        <h3 className="text-lg font-semibold mb-4 text-[#F2F2F2]">{t('footer.information.title')}</h3>
                        <ul className="space-y-2 text-sm">
                            <li><a href="/about-us" className="text-[#CBCBCB] hover:text-[#F2F2F2]">{t('footer.information.contact')}</a>
                            </li>
                            <li><a href="/signup" className="text-[#CBCBCB] hover:text-[#F2F2F2]">{t('footer.information.sellerSignup')}</a>
                            </li>
                            <li><a href="#privacy" className="text-[#CBCBCB] hover:text-[#F2F2F2]">{t('footer.information.privacy')}</a>
                            </li>
                            <li>
                                <a href="/about-us" className="text-[#CBCBCB] hover:text-[#F2F2F2]">
                                    {t('footer.information.aboutUs')}
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Service client */}
                    <div className="mb-6 lg:mb-0">
                        <h3 className="text-lg font-semibold mb-4 text-[#F2F2F2]">{t('footer.customerService.title')}</h3>
                        <ul className="space-y-2 text-sm">
                            <li><a href="#support" className="text-[#CBCBCB] hover:text-[#F2F2F2]">{t('footer.customerService.support')}</a>
                            </li>
                            <li><a href="/about-us" className="text-[#CBCBCB] hover:text-[#F2F2F2]">{t('footer.customerService.contact')}</a>
                            </li>
                            <li><a href="#claim"
                                   className="text-[#CBCBCB] hover:text-[#F2F2F2]">{t('footer.customerService.sellerClaim')}</a></li>
                        </ul>
                    </div>

                    {/* My Account */}
                    <div className="flex flex-row lg:flex-col justify-between">
                        <div className="flex flex-col lg:mb-10">
                            <h3 className="text-lg font-semibold mb-4 text-[#F2F2F2]">{t('footer.myAccount.title')}</h3>
                            <ul className="space-y-2 text-sm">
                                <li><a href="/login"
                                       className="text-[#CBCBCB] hover:text-[#F2F2F2]">{t('footer.myAccount.login')}</a>
                                </li>
                                <li><a href="#order"
                                       className="text-[#CBCBCB] hover:text-[#F2F2F2]">{t('footer.myAccount.trackOrder')}</a>
                                </li>
                                <li><a href="#help"
                                       className="text-[#CBCBCB] hover:text-[#F2F2F2]">{t('footer.myAccount.help')}</a>
                                </li>
                            </ul>
                        </div>
                        <button>
                            <img src="/images/fall-guys-android.svg" alt="android" className="w-[160px]"/>
                        </button>
                    </div>
                </div>
            </div>

            <div
                className="mt-8 border-t border-gray-800 dark:border-[#494949] pt-4 text-center w-full flex items-center justify-between">
                <p className="text-sm text-[#CBCBCB]">{t('footer.copyright.title')}</p>
                <a href="https://quadibyte.com" className="flex flex-row items-center gap-3">
                    <p className="hidden lg:block font-medium">Powered By</p>
                    <img src="/resources/quadiByte.svg" alt="QuadiByte Logo" className="w-24"/>
                </a>
            </div>
        </footer>
    );
};

export default Footer;