import React, {useState, useMemo, useEffect} from 'react';
import {
    useReactTable,
    getCoreRowModel,
    flexRender,
    getFilteredRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    filterFns,
} from '@tanstack/react-table';
import TablePaginationNavigation from "../TablePaginationNavigation/TablePaginationNavigation";
import {deleteProduct, getSellerProducts, hideProduct} from "../../services/SellerSpace/API";
import GenericDropDown from "../GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../GenericDropDown/GenericDropDownItem";
import {formatCurrency} from "../../utils/utils";
import {useNavigate} from "react-router";
import {useSellerProductContext} from "../../pages/SellerSpace/pages/Products";
import {useNonMarketPlaceRoutesContext} from "../../routes/NonMarketPlaceRoutes/NonMarketPlaceRoutes";
import {ImageWithFallback} from "../ImageWithFallBack/ImageWithFallBack";
import {useDeleteConfirmationContext} from "../DeleteConfirmation/DeleteConfirmation";
import {toast} from "sonner";
import ToastSuccess from "../Toasts/Success/ToastSuccess";
import ToastError, {toastStyle} from "../Toasts/Error/ToastError";
import CheckBox from "../Checkbox";
import {useSellerPromoCodesContext} from "../../pages/SellerSpace/pages/PromoCodes/PromoCodes";
import {useTranslation} from "react-i18next";

const ROW_HEIGHT = 81;

const calculatePageSize = (maxHeight) => {
    if (!maxHeight) return 6;

    if (maxHeight?.endsWith("vh")) {
        const vhValue = parseFloat(maxHeight);
        maxHeight = (vhValue / 100) * window.innerHeight - 185;
    }
    return Math.floor((maxHeight - ROW_HEIGHT) / ROW_HEIGHT);
};

const rowSizeOptions = [5, 10, 25, 50, 100];

const SellerProductTable = ({maxHeight, showOnlyHidden = false, showAction = true, showSelect = false}) => {
    const [loading, setLoading] = useState(true);
    const [fetchedData, setFetchedData] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sort, setSort] = useState([]);
    const [pageSize, setPageSize] = useState(calculatePageSize(maxHeight));
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const fetchData = () => {
        setError(null);
        setLoading(true)
        getSellerProducts().then(response => {
            if (response.status === 200) {
                console.log(response.data);
                setFetchedData(response.data);
            } else {
                setError(response.data);
            }
            setLoading(false);
        }).catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                navigate('/login')
            }
            setError(error);
            setLoading(false);
        });
    }

    const {setEditPopUpVisible, setProduct, token, updateToken} = useSellerProductContext();

    useEffect(() => {
        fetchData()
    }, [token]);

    const data = useMemo(
        () =>
            fetchedData
                .filter(product => product.is_hidden === (showOnlyHidden ? 1 : 0))
                .map(product => ({
                    id: product.id,
                    is_hidden: product.is_hidden,
                    product: {
                        id: product.id,
                        image: product.photos?.[0]?.link || "/images/brakes.png",
                        name: product.name || "",
                        sku: product.serial_number || "",
                        stock: product.stock_quantity || 0,
                        price: product.price || 0,
                        photos: product.photos,
                    },
                    created_at: new Date(product.created_at).toLocaleDateString('fr-FR'),
                    status: product.status,
                    visibility_reason: product?.visibility_reason,
                    marketplace_visibility: product?.marketplace_visibility,
                    sold: product.orders_count,
                    category: product.sub_category.name || "",
                    stock: product.stock_quantity,
                    price: formatCurrency(product.price) || "",
                })),
        [fetchedData, showOnlyHidden]
    );

    const {updatePopUpVisible} = useNonMarketPlaceRoutesContext();
    const handleEditProduct = (product) => {
        setProduct(product);
        updatePopUpVisible(true);
        setEditPopUpVisible(true);
    }

    const {updateDeletePopUpVisible, updateItemInfo, updateDeleteFunc} = useDeleteConfirmationContext();
    const handleDeleteProduct = (id, name) => {
        const func = (id) => deleteProduct(id).then((response) => {
            if (response.status === 200 || response.status === 204) {
                toast.success(<ToastSuccess message={"Produit supprimé avec succès"}/>, toastStyle);
                fetchData();
            } else {
                toast.error(<ToastError
                    message="Une erreur s'est produite lors de la suppression du produit"/>, toastStyle);
            }
        }).catch((error) => {
            toast.error(<ToastError
                message="Une erreur s'est produite lors de la suppression du produit"/>, toastStyle);
        }).finally(() => {
            updateDeletePopUpVisible(false);
            updatePopUpVisible(false);
            updateItemInfo("", "", "");
        })

        updateDeleteFunc(() => func(id));
        updateDeletePopUpVisible(true);
        updatePopUpVisible(true);
        updateItemInfo(name, "Produit", "Supprimer ce Produit");

    }

    const isSmallScreen = window.innerWidth <= 640;

    const columns = useMemo(() => {
        // Define all columns in the original order
        const allColumns = [
            {
                id: 'select-col',
                header: ({table}) => (
                    <div className="w-[50px] flex justify-center">
                        <CheckBox
                            checkedValueState={[table.getIsAllRowsSelected(),]}
                            handleCheck={table.getToggleAllRowsSelectedHandler()}
                            indeterminate={table.getIsSomeRowsSelected()}
                        />
                    </div>
                ),
                cell: ({row}) => (
                    <div className="w-[50px] flex justify-center">
                        <CheckBox
                            checkedValueState={[row.getIsSelected(),]}
                            handleCheck={row.getToggleSelectedHandler()}
                        />
                    </div>
                ),
                size: 50,
                minSize: 50,
                maxSize: 50,
                className: 'w-[50px]'
            },
            {
                accessorFn: row => row.product.name,
                id: 'productName',
                header: 'Produit',
                cell: ({getValue, row}) => {
                    const value = row.original.product;
                    return (
                        <div className="flex items-center">
                            <ImageWithFallback src={value?.image} alt={value?.name}
                                               className="h-10 w-10 sm:w-12 sm:h-12 rounded mr-4 object-contain"/>
                            <div className="flex-1 min-w-0 max-w-[140px] sm:max-w-[200px]">
                                <div className="font-medium truncate dark:text-[#F2F2F2]">{value?.name}</div>
                                <div className="text-sm text-gray-500 dark:text-[#797979] truncate">
                                    SKU: {value?.sku}
                                </div>
                            </div>
                        </div>
                    );
                },
                filterFn: 'includesString',
            },
            {
                accessorKey: 'sold',
                header: 'Vendu',
            },
            {
                accessorKey: 'price',
                header: 'Prix',
                cell: ({getValue}) => (
                    <span className="font-medium truncate dark:text-[#F2F2F2]">{getValue()}</span>
                ),
            },
            {
                accessorKey: 'category',
                header: 'Sous-Category',
                cell: ({getValue}) => (
                    <span
                        className="block px-4 py-1 bg-white dark:bg-[#333333] font-normal rounded-lg border border-border dark:border-[#494949] text-sm truncate dark:text-[#F2F2F2]">
                    {getValue()}
                </span>
                ),
            },
            {
                accessorKey: "status",
                header: 'Statut de vérification',
                cell: ({row}) => <VerificationStatus verification_status={row.original.status}
                                                     marketVisibility_status={row.original.marketplace_visibility}
                                                     reason={row.original.visibility_reason}/>,
            },
            {
                accessorKey: 'stock',
                header: 'État du stock',
                cell: ({getValue}) => <StockStatus stock={getValue()}/>,
            },
            {
                accessorKey: "created_at",
                header: "créé à",
            },
            {
                accessorKey: 'actions',
                header: 'Action',
                cell: ({row}) => (
                    <GenericDropDown>
                        <GenericDropDownItem value="Gérer" index={1}
                                             handleClick={() => handleEditProduct(row.original.product)}/>
                        <GenericDropDownItem value="Modifier" index={2}
                                             handleClick={() => navigate("./addProduct", {state: {id: row.original.id}})}/>
                        <GenericDropDownItem
                            value={row.original.sold > 0 ? row.original.is_hidden ? "Afficher" : "Cacher" : "Supprimer"}
                            index={3}
                            handleClick={() => {
                                if (row.original.sold > 0) {
                                    hideProduct(row.original.id, row.original.is_hidden === 0)
                                        .then(response => response.status === 200 && updateToken())
                                        .catch(console.log);
                                } else {
                                    handleDeleteProduct(row.original.id, row.original.product.name);
                                }
                            }}
                        />
                    </GenericDropDown>
                ),
            },
        ];

        // Filter for essential columns on smaller screens
        const essentialColumns = ['Produit', 'Prix', 'Action'];
        let afterHandlingSelection = showSelect ? allColumns : allColumns.filter(col => col.id !== "select-col")
        let afterHandlingShowActionColumns = showAction ? afterHandlingSelection : afterHandlingSelection.filter(col => col.header !== 'Action');
        return isSmallScreen ? afterHandlingShowActionColumns.filter(col => essentialColumns.includes(col.header)) : afterHandlingShowActionColumns;
    }, [handleDeleteProduct, handleEditProduct, isSmallScreen, showAction, showSelect, updateToken]);

    const {
        setPromoCode,
        promoCode,
        updateAssociateProductVisible,
        setEditPopUpVisible: setEditPromoCodePopUpVisible,
        selectedPromoCodes,
        updateSelectedProducts
    } = useSellerPromoCodesContext();

    const [rowSelection, setRowSelection] = useState({});

    useEffect(() => {
        if (promoCode?.products?.length) {

            const initialSelection = promoCode.products.reduce((acc, product) => {

                const rowIndex = data.findIndex(row => row.id === product.id);
                if (rowIndex !== -1) {
                    acc[rowIndex] = true;
                }
                return acc;
            }, {});

            setRowSelection(initialSelection);
        }
    }, [promoCode, data]);

    const table = useReactTable({
        data,
        columns,
        state: {
            globalFilter: globalFilter,
            sorting: sort,
            rowSelection
        },
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSort,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: filterFns.includesString,
        initialState: {
            columnVisibility: {
                "productSku": false
            },
            pagination:
                {pageSize: pageSize}
        }
    });

    const [height, setHeight] = useState(0);

    const handlePageSizeChange = (e) => {
        const newSize = Number(e.target.value);
        setPageSize(newSize);
        table.setPageSize(newSize);
    };

    useEffect(() => {
        setHeight(calculatePageSize(maxHeight) * ROW_HEIGHT + 170);
    }, [maxHeight, pageSize]);

    useEffect(() => {
        setPageSize(calculatePageSize(maxHeight));
    }, [maxHeight]);

    if (loading) {
        return <div className="w-full min-h-[calc(100vh-380px)] flex items-center justify-center">
            <div className="loader"></div>
        </div>
    }

    return (
        <div
            className="relative flex flex-col items-center justify-between w-full h-full border border-border dark:border-[#494949] rounded-lg bg-white dark:bg-[#333333]">
            {
                error !== null ?
                    <div style={{minHeight: (height)}}
                         className="w-full h-full flex flex-col gap-2 items-center justify-center">
                        <div className="p-4 text-center text-gray-500 dark:text-[#797979]">
                            Une erreur inattendue s'est produite, veuillez réessayer .
                        </div>
                        <button
                            onClick={fetchData}
                            className="bg-primaryLight min-h-10 text-white px-6 py-2 rounded hover:bg-primaryDark transition duration-200 flex flex-row gap-2 items-center"
                        >
                            <img src="/resources/retry.svg" alt="retry icon" className="white_filter"/>
                            <p className="font-medium text-white leading-none">Réessayer</p>
                        </button>

                    </div>
                    :
                    data.length === 0 ? (
                        <div style={{minHeight: maxHeight}}
                             className="w-full h-full flex flex-col items-center justify-center">
                            <img src="/images/undraw_no_data.svg" alt="empty" className="w-[250px] mb-8"/>
                            <p className="p-4 text-center text-gray-500 dark:text-[#797979]">
                                Aucun produit {showOnlyHidden ? "caché" : "visible"} trouvé.
                            </p>
                        </div>
                    ) : (
                        <div className="relative flex flex-col items-center justify-between w-full">
                            <div className="flex flex-col w-full h-full">
                                <div
                                    className="flex flex-row items-center justify-end gap-4 border-b-border dark:border-b-[#494949] border-b pr-8 sm:pr-4 px-4 min-h-16 w-full">
                                    {(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && (
                                        <button
                                            onClick={() => {
                                                if (selectedPromoCodes.length === 0) {
                                                    setPromoCode({
                                                        ...promoCode,
                                                        products: [
                                                            ...table.getSelectedRowModel().rows
                                                                .map(row => data.find(d => d.id === row.original.id))
                                                                .filter(product => product)
                                                                .map(product => ({
                                                                    id: product.id,
                                                                    name: product.product.name,
                                                                    sku: product.product.sku,
                                                                    price: product.product.price,
                                                                    stock: product.product.stock,
                                                                    imageUrl: product.product.image,
                                                                }))
                                                        ]
                                                    });
                                                    updateAssociateProductVisible(false);
                                                    setEditPromoCodePopUpVisible(true);
                                                } else {
                                                    updateAssociateProductVisible(false);
                                                    setEditPopUpVisible(false);
                                                    updatePopUpVisible(false);
                                                    updateSelectedProducts(
                                                        table.getSelectedRowModel().rows.map(row => row.original.id)
                                                    )
                                                    setRowSelection({});
                                                }
                                            }}
                                            className="px-4 py-2 bg-primaryLight text-white rounded font-medium text-sm">
                                            <p>Associer les produits</p>
                                        </button>
                                    )}
                                    <div
                                        className="w-[30vw] sm:w-[15vw] h-12 bg-[#FCFCFC] dark:bg-[#232323] border-[#D4D4D4] dark:border-[#494949] border rounded-lg px-2 sm:px-4 flex items-center justify-between">
                                        <input
                                            value={globalFilter}
                                            onChange={e => setGlobalFilter(e.target.value)}
                                            placeholder="Recherche"
                                            className="w-[calc(30vw-40px)] sm:w-[calc(15vw-56px)] h-9 bg-[#FCFCFC] dark:bg-[#232323] dark:text-[#F2F2F2] border-none focus:outline-none"
                                        />
                                        <img src="/resources/search.svg" alt="search"/>
                                    </div>
                                </div>
                                <div style={{minHeight: height, maxHeight: height}} className="w-full overflow-auto">
                                    <table
                                        className="min-w-full bg-white dark:bg-[#333333] border border-gray-200 dark:border-[#494949] rounded-lg table-fixed">
                                        <thead
                                            className="bg-gray-100 dark:bg-[#232323] sticky top-0 z-10 min-h-14 h-14 border-b border-border dark:border-[#494949] max-h-14">
                                        {table.getHeaderGroups().map(headerGroup => (
                                            <tr key={headerGroup.id} className="text-left">
                                                {headerGroup.headers.map(header => (
                                                    <th
                                                        key={header.id}
                                                        onClick={header.column.getToggleSortingHandler()}
                                                        className={`px-6 py-3 text-[#979797] dark:text-[#CBCBCB] text-sm font-medium border-b border-gray-200 dark:border-[#494949] ${header.column.id === "select-col" ? 'w-[50px] p-0' : ''}`}
                                                    >
                                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                                        {{asc: " ↑", desc: " ↓"}[header.column.getIsSorted() ?? null]}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                        </thead>
                                        <tbody>
                                        {table.getRowModel().rows.map(row => (
                                            <tr key={row.id} className="hover:bg-gray-50 dark:hover:bg-[#232323]">
                                                {row.getVisibleCells().map(cell => (
                                                    <td key={cell.id}
                                                        onClick={(e) => {
                                                            if (!e.target.classList.contains("cta") &&
                                                                !e.target.classList.contains("dropdown") &&
                                                                !e.target.parentNode.classList.contains("cta") &&
                                                                !e.target.parentNode.classList.contains("dropdown")) {
                                                                if (!showSelect) {
                                                                    navigate(`/product/${cell.row.original.id}`)
                                                                }
                                                            }
                                                        }
                                                        }
                                                        className={`cursor-pointer px-6 py-4 border-b border-gray-200 dark:border-[#494949] font-medium max-h-14 ${cell.column.id === "select-col" ? 'w-[50px] p-0' : ''}`}>
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="flex flex-row w-full gap-3 items-center justify-center">
                                <TablePaginationNavigation table={table}/>
                                <select
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                    className="px-4 py-2 rounded text-sm border border-gray-200 dark:border-[#494949] dark:bg-[#333333] dark:text-[#F2F2F2] h-full outline-none focus:outline-primaryLight font-medium">
                                    >
                                    {pageSize < 5 && <option key={0} className="font-medium dark:bg-[#333333]">{pageSize}</option>}
                                    {rowSizeOptions.map(size => (
                                        <option key={size} value={size} className="hover:bg-primaryExtraLight dark:hover:bg-[#232323] font-medium dark:bg-[#333333]">
                                            {size}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    )}
        </div>
    );
};

export default SellerProductTable;

const StockStatus = ({stock}) => {
    const [etat,] = useState(stock > 0 ? 'En stock' : 'Rupture de stock');
    return (
        <>
            {etat === "En stock" ? (
                    <div
                        className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-[#DEFFF0] dark:bg-[#232323] border border-[#7EDCB0] dark:border-[#494949]'>
                        <p className="text-sm font-medium text-[#7EDCB0] dark:text-[#F2F2F2] truncate">{etat} : {stock}</p>
                    </div>
                ) :
                (
                    <div
                        className='w-fit px-4 py-2 rounded-lg flex items-center justify-center bg-[#FFF1F1] dark:bg-[#232323] border border-[#FF1D20] dark:border-[#494949]'>
                        <p className="text-sm font-medium text-[#FF0004] dark:text-[#F2F2F2] truncate ">{etat} : {stock}</p>
                    </div>
                )
            }
        </>
    )
        ;
};

const VerificationStatus = ({verification_status, marketVisibility_status, reason}) => {
    const {t} = useTranslation();

    const statusStyles = {
        pending: {
            container: 'bg-orange-100 dark:bg-[#232323] border-red-500 dark:border-[#494949]',
            text: 'text-orange-500 dark:text-[#F2F2F2]'
        },
        rejected: {
            container: 'bg-green-100 dark:bg-[#232323] border-green-500 dark:border-[#494949]',
            text: 'text-green-500 dark:text-[#F2F2F2]'
        },
        verified: {
            container: 'bg-green-100 dark:bg-[#232323] border-green-500 dark:border-[#494949]',
            text: 'text-green-500 dark:text-[#F2F2F2]'
        },
        incomplete: {
            container: 'bg-red-100 dark:bg-[#232323] border-green-500 dark:border-[#494949]',
            text: 'text-red-500 dark:text-[#F2F2F2]'
        }
    };

    const getStatusConfig = () => {
        if (verification_status === "pending") {
            return {
                styles: statusStyles.pending,
                text: t('sellerSpace.productTable.status.pending')
            };
        }
        if (verification_status === "rejected") {
            return {
                styles: statusStyles.rejected,
                text: t('sellerSpace.productTable.status.rejected')
            };
        }
        if (verification_status === "verified" && marketVisibility_status === 1) {
            return {
                styles: statusStyles.verified,
                text: t('sellerSpace.productTable.status.verified')
            };
        }
        return {
            styles: statusStyles.incomplete,
            text: t('sellerSpace.productTable.status.incomplete')
        };
    };

    const translateToolTip = (message) => {
        switch (message) {
            case "missing Photos And Compatible Vehicles":
                return t('sellerSpace.productTable.status.tooltip.missingPhotosAndCompatibleVehicles');
            case "missing Photos":
                return t('sellerSpace.productTable.status.tooltip.missingPhotos');
            case "missing Compatible Vehicles":
                return t('sellerSpace.productTable.status.tooltip.missingCompatibleVehicles');
            default:
                return null;
        }
    }

    const {styles, text} = getStatusConfig();

    return (
        <div className={`w-fit px-4 py-2 rounded-lg flex items-center justify-center border ${styles.container}`}>
            <p
                title={reason ? translateToolTip(reason) : null}
                className={`text-sm font-medium truncate ${styles.text}`}>{text}</p>
        </div>
    );
};