import AddToFavorite from "../../../Marketplace/components/AddToFavorite";
import QuantityInput from "../../../../components/QuantityInput/QuantityInput";
import AddToCart from "../../../../components/AddToCart/AddToCart";
import BuyNowButton from "../../../../components/BuyNowButton/BuyNowButton";
import {useEffect, useState} from "react";
import {useCompareContext} from "../../../../hooks/context/context";
import {formatCurrency} from "../../../../utils/utils";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import {GitCompareArrows} from "lucide-react";

export default function PurchaseProduct({id, service, type}) {
    const {addProductToCompare, compareProducts, removeProductFromCompare} = useCompareContext();
    const [isCompared, setIsCompared] = useState(false);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        try {
            const userCookie = Cookies.get("user");
            if (userCookie) {
                const user = JSON.parse(userCookie);
                setIsClient(user?.role_id === 4);
            }
        } catch (e) {
            setIsClient(false);
        }
    }, []);

    useEffect(() => {
        if (compareProducts.find(p => Number(p.id) === Number(id))) {
            setIsCompared(true)
        } else {
            setIsCompared(false)
        }
    }, [compareProducts, id]);
    return (
        <div
            className="w-[90vw] sm:w-[33vw] lg:w-[22vw] 2xl:w-[calc(19.5vw-4px)] px-4 pt-4 pb-6 border-border dark:border-[#494949] rounded-lg border flex flex-col gap-4">
            <div className="flex flex-row w-full justify-between items-center">
                <button
                    style={{
                        backgroundColor: isCompared ? "#FFE9E4" : "white",
                        borderColor: isCompared ? "#FF7354" : "#d4d4d4"
                    }}
                    onClick={() => {
                        if (isCompared) {
                            removeProductFromCompare({id: id})
                        } else if (compareProducts.length < 4) {
                            addProductToCompare(service)
                        }
                    }}
                    className="cursor-pointer px-3 py-1 h-[40px] flex items-center justify-center border dark:border-[#494949] dark:bg-[#333333] gap-2 rounded-lg">
                    <GitCompareArrows className={`${isCompared && "primary_filter"}`}/>
                    <p style={{color: isCompared ? "#FF7354" : "black"}}
                       className="font-medium text-sm dark:text-[#F2F2F2]">{t("compare")}</p>
                </button>
                <AddToFavorite id={Number(id)} ElementType={"service"}/>
            </div>

            <div className="flex flex-row items-center justify-between pb-2 border-dashed border-b border-b-border dark:border-b-[#494949]">
                <p className="text-sm text-[#797979] dark:text-[#797979]">{t("price")}</p>
                <p className="font-semibold text-xl dark:text-[#F2F2F2]">{formatCurrency(service?.price)}</p>
            </div>
            {type === "service" ?
                <>
                    {isClient ? (
                        <button
                            onClick={() => navigate(`/services/${id}/schedule`)}
                            className="cta cursor-pointer flex px-4 py-3 max-h-[48px] min-h-[48px] flex-row items-center justify-center gap-2 rounded-lg bg-primaryLight dark:bg-[#E44826]"
                        >
                            <img src="/resources/card.svg" alt="cart" className="white_filter"/>
                            <p className="hidden sm:block font-medium text-[13px] text-white text-ellipsis whitespace-nowrap">
                                {t("addToCart.BookService")}
                            </p>
                        </button>
                    ) : (
                        <div
                            className="cta cursor-pointer flex px-4 py-3 max-h-[48px] min-h-[48px] flex-row items-center justify-center gap-2 rounded-lg bg-gray-500 dark:bg-[#595959]"
                        >
                            <img src="/resources/card.svg" alt="cart" className="white_filter"/>
                            <p className="hidden sm:block font-medium text-[13px] text-white text-ellipsis whitespace-nowrap">
                                {t("reservedForClients")}
                            </p>
                        </div>
                    )}
                </>
                :
                <>
                    <AddToCart ids={[id]}/>
                    <BuyNowButton/>
                </>
            }

        </div>
    )
}