import React, {useEffect, useState} from 'react';
import {MapContainer, Marker, Popup, TileLayer, useMapEvents, Circle} from 'react-leaflet';
import axios from 'axios';
import {create} from "zustand";
import "./MapTile.css"
import PropTypes from "prop-types";
import {Check, MoreHorizontal, SquareArrowOutUpRight, MapPin, Home, Search, X, Star} from "lucide-react";
import L from 'leaflet';
import {ImageWithFallback} from "../ImageWithFallBack/ImageWithFallBack";
import {useNavigate} from "react-router";

const customIcon = new L.Icon({
    iconUrl: '/images/marker-icon.svg',
    iconSize: [30, 30],
    iconAnchor: [15, 15],
    popupAnchor: [0, -30],
});

const currentLocationIcon = new L.Icon({
    iconUrl: '/images/marker-home.svg',
    iconSize: [30, 30],
    iconAnchor: [15, 15],
    popupAnchor: [0, -30],
})

const instance = axios.create({
    baseURL: "https://nominatim.openstreetmap.org",
    params: {
        format: "jsonv2",
    },
});

const HomeMarker = ({lat, lng}) => {
    const [position,] = useState({lat: lat, lng: lng});
    return position === null ? null : (
        <Marker position={position} icon={currentLocationIcon} autoPan={false}>
            <p>Home</p>
        </Marker>
    )
}

HomeMarker.propTypes = {
    lat: PropTypes.string.isRequired,
    lng: PropTypes.string.isRequired,
}

function DropDownMarker() {
    const [position, setPosition] = useState(null);
    const {updateName, updateLocation} = useGetCurrentLocationName();
    const map = useMapEvents({
        click(e) {
            setPosition(e.latlng)
            instance.get(`reverse?format=json&lat=${e.latlng.lat}&lon=${e.latlng.lng}`).then((response) => {
                const location = {
                    lat: e.latlng.lat,
                    lon: e.latlng.lng,
                    wilaya: response.data.address.state,
                    commune: response.data.address.county,
                    address: response.data.display_name
                }
                updateLocation(location)
                updateName(response.data.display_name);
            }).catch((error) => {
                console.error('Error fetching reverse geocoding data:', error);
            })
        },
    })

    return position ? (
        <Marker position={position} icon={currentLocationIcon}>
            <Popup>
                <div className='flex p-4 items-center justify-center rounded'>
                    <p>You are here</p>
                </div>
            </Popup>
        </Marker>
    ) : null;
}

function LocationMarker({lat, lng, shop}) {
    const [position,] = useState({lat: lat, lng: lng});

    const featuredProducts = shop?.products?.slice(0, 3);
    const hasMoreProducts = shop?.products?.length > 3;

    const navigate = useNavigate();

    const handleClick = (product, shop, index) => {
        if (
            index === 2 && hasMoreProducts
        ) {
            navigate("/shop/" + shop?.id)
        } else {
            navigate("/product/" + product.id)
        }
    }

    return position === null ? null : (
        <Marker position={position} icon={customIcon} autoPan={false}>
            <Popup className="custom-popup">
                <div className="w-72 bg-white dark:bg-[#333333] rounded-lg overflow-hidden">
                    <div className="p-4">
                        <div className="flex items-center justify-between">
                            <button
                                onClick={() => navigate("/shop/" + shop?.id)}
                                className="flex flex-row gap-2">
                                <div
                                    className="w-14 h-14 rounded-full border-2 border-gray-200 dark:border-[#494949] overflow-hidden flex-shrink-0 shadow-sm">
                                    <ImageWithFallback
                                        src={shop?.logo}
                                        alt={`${shop?.name} logo`}
                                        className="object-cover w-full h-full"
                                    />
                                </div>

                                <div className="flex-1 min-w-0">
                                    <div className="flex flex-row gap-2">
                                        <h3 className="text-lg font-semibold text-gray-900 dark:text-[#F2F2F2] truncate">
                                            {shop?.name}
                                        </h3>
                                        <SquareArrowOutUpRight className="w-4"/>
                                    </div>
                                    <div className="flex items-center gap-1.5 mt-1">
                                        <div
                                            className="flex items-center bg-yellow-50 dark:bg-[#494949] px-2 py-0.5 rounded-full">
                                            <p className="font-bold text-warning text-sm dark:text-[#F9CDC4]">
                                                {Number(shop?.rating || 0).toFixed(1)}
                                            </p>
                                            <Star className="w-4 h-4 ml-1"/>
                                        </div>
                                    </div>
                                </div>
                            </button>
                            <a href={`https://www.google.com/maps?q=${lat},${lng}`}
                               target="_blank"
                               className="flex flex-row gap-2 items-center"
                            >
                                <div
                                    className='flex items-center justify-center h-9 w-9 hover:bg-primaryExtraLight rounded-lg border border-primaryLight'>
                                    <MapPin className="w-4"/>
                                </div>
                            </a>

                        </div>
                    </div>

                    {featuredProducts?.length > 0 && (
                        <div className="px-4 pb-4">
                            <div className="flex justify-between items-center mb-2">
                                <div className="text-sm font-medium text-gray-500 dark:text-[#CBCBCB]">
                                    Featured Products
                                </div>
                                {hasMoreProducts && (
                                    <div className="text-xs text-gray-400 dark:text-[#797979]">
                                        +{shop?.products?.length - 3} more
                                    </div>
                                )}
                            </div>
                            <div className="grid grid-cols-3 gap-2">
                                {featuredProducts.map((product, index) => (
                                    <button
                                        onClick={() => handleClick(product, shop, index)}
                                        key={index}
                                        className="aspect-square rounded-lg border border-gray-200 dark:border-[#494949] overflow-hidden hover:border-gray-300 dark:hover:border-[#595959] transition-colors group relative"
                                    >
                                        <ImageWithFallback
                                            src={product.first_photo.link}
                                            alt={`Product ${index + 1}`}
                                            className="object-cover w-full h-full"
                                        />
                                        {index === 2 && hasMoreProducts && (
                                            <div
                                                className="absolute inset-0 bg-black/40 flex items-center justify-center group-hover:bg-black/50 transition-colors">
                                                <MoreHorizontal className="text-white w-6 h-6"/>
                                            </div>
                                        )}
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </Popup>
        </Marker>
    )
}

LocationMarker.propTypes = {
    lat: PropTypes.string,
    lng: PropTypes.string,
    shop: PropTypes.object,
}

export default function MapTile({
                                    handleClose,
                                    showClose = true,
                                    clickable = true,
                                    positions = [],
                                    shops = [],
                                    currentLocationCoordinates = [],
                                    currentLocationRangeRadius = 10,
                                }) {
    const [cityName, setCityName] = useState("");
    const [position, setPosition] = useState(
        positions?.length > 0 ? [positions[0].lat, positions[0].lng] : [36.36, 6.63]
    );
    const [markers, setMarkers] = useState([]);
    const {name, updateLocation} = useGetCurrentLocationName();
    const [loading, setLoading] = useState(false);
    const [tileKey, setTileKey] = useState(1);

    useEffect(() => {
        if (!clickable) {
            setMarkers((prev) => (
                positions?.length > 0 && shops?.length > 0 ?
                    [
                        ...prev.filter((marker) => marker.type === "HomeMarker")
                        ,
                        ...positions.map((position, index) => ({
                            type: "LocationMarker",
                            lat: position?.lat,
                            lng: position?.lng,
                            shop: shops[index],
                        }))
                    ]
                    :
                    prev.filter(marker => marker.type !== "LocationMarker")
            ));
        }
    }, [clickable, positions, shops]);

    useEffect(() => {
        if (currentLocationCoordinates?.length > 1) {
            setPosition([currentLocationCoordinates[0], currentLocationCoordinates[1]]);
            setMarkers((prev) => [
                ...prev.filter((marker) => marker.type === "LocationMarker"),
                {
                    type: "HomeMarker",
                    lat: currentLocationCoordinates[0],
                    lng: currentLocationCoordinates[1],
                },
            ]);
            setTileKey(prev => prev + 1);

        } else {
            setMarkers((prev) => [
                ...prev.filter((marker) => marker.type === "LocationMarker"),
            ])
            if (markers.length > 0) {
                setPosition([markers[0]?.lat, markers[0]?.lng])
            }
        }
    }, [currentLocationCoordinates]);


    const handleSearch = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await instance.get(`search.php?q=${cityName}`);
            if (response.data?.length > 0) {
                updateLocation(response.data[0]);
                const {lat, lon} = response.data[0];
                setPosition([lat, lon]);
                setTileKey((prevState) => prevState + 1);
            }
        } catch (error) {
            console.error("Error fetching geocoding data:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="w-full h-full relative">
            <div
                className="absolute top-[12px] left-[60px] z-[130] md:z-[110] h-[40px] flex flex-row items-center justify-between w-[calc(100%-80px)]">
                <form onSubmit={handleSearch} className="flex flex-row gap-2 relative">
                    <input
                        className="h-[40px] w-[200px] border border-gray-400 dark:border-[#494949] dark:bg-[#333333] dark:text-[#F2F2F2] px-[8px] rounded"
                        type="text"
                        placeholder="Entrez l'adresse"
                        value={cityName}
                        onChange={(e) => setCityName(e.target.value)}
                    />
                    <button type={"submit"} className="flex items-center justify-center absolute right-2 top-1/2 -translate-y-1/2">
                        <Search className="w-4 h-4"/>
                    </button>
                </form>
                <div className="flex flex-row gap-4">
                    {name && (
                        <div className="px-4 py-2 bg-white dark:bg-[#333333] rounded">
                            <p className="font-medium text-gray-800 dark:text-[#F2F2F2]">{name}</p>
                        </div>
                    )}
                    {showClose && (
                        <button
                            className="min-h-12 min-w-12 max-h-12 max-w-12 flex items-center justify-center rounded-full bg-white dark:bg-[#333333] hover:bg-gray-100 dark:hover:bg-[#494949]"
                            onClick={handleClose}>
                            <X className="w-6 h-6"/>
                        </button>
                    )}
                </div>
            </div>
            <div id="map" className="w-full h-full">
                <MapContainer
                    key={tileKey}
                    center={position}
                    zoom={13}
                    scrollWheelZoom={true}
                    className="w-full h-full z-[100]">
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {
                        !clickable ?
                            markers.map((marker, index) => {
                                if (marker?.type === "LocationMarker" && marker?.lat && marker?.lng) {
                                    return <LocationMarker key={index} lat={marker?.lat} lng={marker?.lng}
                                                           shop={marker?.shop}/>;
                                }
                                if (marker.type === "HomeMarker") {
                                    return <HomeMarker key={index} lat={marker.lat} lng={marker.lng}/>;
                                }
                                return null;
                            })
                            :
                            <DropDownMarker/>
                    }
                    {
                        markers.map((marker, index) => {
                            if (marker.type === "HomeMarker") {
                                return <Circle
                                    key={index}
                                    center={[marker.lat, marker.lng]}
                                    radius={currentLocationRangeRadius * 1000}
                                    pathOptions={{
                                        color: "#6874e6",
                                        fillColor: "#add8e6",
                                        fillOpacity: 0.4,
                                    }}
                                />
                            }
                            return null;
                        })
                    }
                </MapContainer>
            </div>
            <div
                style={{display: name && clickable ? "flex" : "none"}}
                className="absolute bottom-[12px] left-1/2 -translate-x-1/2 z-[110] h-[40px] flex-row items-center justify-between w-fit">
                <button
                    className="bg-primaryLight rounded text-white font-medium w-fit px-4 h-[40px] flex items-center justify-center gap-2"
                    onClick={() => {

                        handleClose()
                    }}>
                    <Check/>
                    <p>Confirmer l'adresse</p>
                </button>
            </div>
        </div>
    );
}

MapTile.propTypes = {
    handleClose: PropTypes.func.isRequired,
    showClose: PropTypes.bool,
    clickable: PropTypes.func,
    positions: PropTypes.arrayOf(PropTypes.shape({
        lat: PropTypes.string.isRequired,
        lng: PropTypes.string.isRequired,
    })),
    shops: PropTypes.array,
    currentLocationCoordinates: PropTypes.array.isRequired.length === 2,
    currentLocationRangeRadius: PropTypes.number.isRequired,
}

export const useGetCurrentLocationName = create((set) => ({
    name: "",
    updateName: (name) => set({name}),
    location: null,
    updateLocation: (location) => set({location: location})
}));
