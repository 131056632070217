import PropTypes from "prop-types";

export default function ProductCardSkeleton({ itemType = "product" }) {
    return (
        <article
            style={{
                height: (itemType === "vehicle" || itemType === "boat" || itemType === "engin") ? "450px" : "379px",
            }}
            className="w-full min-w-[300px] max-w-[300px] hover:bg-primaryXLight dark:hover:bg-[#333333] hover:shadow-md rounded-lg border-border dark:border-[#494949] border gap-4 flex flex-col cursor-pointer overflow-hidden dark:bg-[#232323] animate-pulse"
        >
            {/* Image Placeholder */}
            <div
                style={{
                    minHeight: (itemType === "vehicle" || itemType === "boat" || itemType === "engin") ? "50%" : "45%",
                    maxHeight: (itemType === "vehicle" || itemType === "boat" || itemType === "engin") ? "50%" : "45%",
                }}
                className="flex flex-row w-full justify-center items-center relative bg-gray-200 dark:bg-gray-700"
            ></div>

            {/* Content Placeholder */}
            <div className="flex flex-col items-end w-full h-full justify-between">
                <div className="flex flex-col gap-2 w-full h-fit p-2">
                    {/* Placeholder for SKU and Ratings */}
                    <div
                        style={{
                            display: (itemType === "vehicle" || itemType === "boat" || itemType === "engin" || itemType === "service") ? "none" : "flex",
                        }}
                        className="flex-row items-center justify-between w-full"
                    >
                        <div className="w-24 h-4 bg-gray-200 dark:bg-gray-700 rounded"></div>
                        <div className="flex flex-row gap-2 items-center">
                            <div className="w-16 h-4 bg-gray-200 dark:bg-gray-700 rounded"></div>
                        </div>
                    </div>
                    {/* Placeholder for Product Name */}
                    <div className="w-full h-6 bg-gray-200 dark:bg-gray-700 rounded"></div>
                </div>

                {/* Placeholder for Additional Info */}
                {(itemType === "vehicle" || itemType === "boat" || itemType === "engin") && (
                    <div className="flex flex-row flex-wrap gap-2 items-center justify-start w-full p-2">
                        <div className="w-20 h-6 bg-gray-200 dark:bg-gray-700 rounded"></div>
                        <div className="w-20 h-6 bg-gray-200 dark:bg-gray-700 rounded"></div>
                    </div>
                )}

                {/* Placeholder for Price */}
                <div className="flex flex-row items-center gap-2 w-full p-2">
                    <div className="w-24 h-8 bg-gray-200 dark:bg-gray-700 rounded"></div>
                </div>

                {/* Placeholder for Buttons */}
                <div
                    style={{
                        display: itemType === "product" || itemType === "service" ? "flex" : "none",
                    }}
                    className="w-full h-fit justify-end items-center gap-2 p-2"
                >
                    <div className="w-28 h-12 bg-gray-200 dark:bg-gray-700 rounded"></div>
                    <div className="w-28 h-12 bg-gray-200 dark:bg-gray-700 rounded"></div>
                </div>
            </div>
        </article>
    );
}

ProductCardSkeleton.prototype = {
    itemType : PropTypes.string,
}