import React, {useState, useRef, useEffect} from 'react';
import {Upload, RefreshCw, CheckCircle2} from 'lucide-react';
import {usePersonalInfoContext} from "../../Pages/SignUp";
import Cookies from "js-cookie";
import {ImageWithFallback} from "../../../../components/ImageWithFallBack/ImageWithFallBack";

import { useTranslation } from 'react-i18next';

const ProfilePhotoUpload = ({ handlePrevious, handleNext }) => {
    const { t } = useTranslation();
    const { addFieldValue, personalInfo } = usePersonalInfoContext();
    const [photo, setPhoto] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const fileInputRef = useRef(null);

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        validateAndSetPhoto(file);
    };

    useEffect(() => {
        let user = null;
        try {
            const userTemp = Cookies.get("user_temp");
            user = JSON.parse(userTemp);
        } catch (e) {}

        if (personalInfo.profile_picture) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(personalInfo.profile_picture);
            setPhoto(personalInfo.profile_picture);
        } else if (user) {
            if (user?.avatar) {
                setPreviewUrl(user.avatar);
                setPhoto(user.avatar);
                addFieldValue("profile_picture", user.avatar);
            }
        }
    }, []);

    const validateAndSetPhoto = (file) => {
        setError('');

        if (!file) {
            setError(t('profilePhotoUpload.errorMessages.selectPhoto'));
            return;
        }

        if (!file.type.startsWith('image/')) {
            setError(t('profilePhotoUpload.errorMessages.invalidFileType'));
            return;
        }

        if (file.size > 5 * 1024 * 1024) {
            setError(t('profilePhotoUpload.errorMessages.fileTooLarge'));
            return;
        }

        try {
            addFieldValue("profile_picture", file);
        } catch (err) {
            setError(t('profilePhotoUpload.errorMessages.uploadError'));
            console.error(err);
            return;
        }

        try {
            setPreviewUrl(URL.createObjectURL(file));
        } catch (err) {
            setError(t('profilePhotoUpload.errorMessages.readError'));
            console.error(err);
            return;
        }

        setPhoto(file);
    };

    return (
        <div className="w-full h-full flex flex-col items-center justify-center p-6 space-y-4 xl:space-y-6 relative">
            <h2 className="lg:text-xl xl:text-2xl 2xl:text-3xl font-bold text-gray-800 dark:text-[#F2F2F2] text-center">
                {t('profilePhotoUpload.title')}
            </h2>
            <p className="text-xs xl:text-sm text-gray-600 dark:text-[#CBCBCB] text-center max-w-lg">
                {t('profilePhotoUpload.description')}
            </p>

            {/* Preview Section */}
            {previewUrl && (
                <div className="flex flex-col items-center gap-4">
                    <div className="relative">
                        <div className="w-36 h-36 xl:w-48 xl:h-48 rounded-full overflow-hidden border-4 border-[#E44826]">
                            <ImageWithFallback
                                src={previewUrl}
                                alt={t('profilePhotoUpload.preview.altText')}
                                className="w-full h-full object-cover"
                                local={true}
                            />
                        </div>
                        <div className="absolute bottom-0 right-0 bg-[#E44826] p-2 rounded-full">
                            <CheckCircle2 className="w-6 h-6 text-white" />
                        </div>
                    </div>
                    <button
                        onClick={() => {
                            setPhoto(null);
                            setPreviewUrl(null);
                        }}
                        className="flex items-center gap-2 text-[#E44826] hover:text-[#232323] dark:hover:text-[#F2F2F2]"
                    >
                        <RefreshCw className="w-4 h-4 xl:w-5 xl:h-5" />
                        <p className="text-sm xl:text-normal">
                            {t('profilePhotoUpload.buttons.changePhoto')}
                        </p>
                    </button>
                </div>
            )}

            {!previewUrl && (
                <div className="grid md:grid-cols-1 gap-6 w-full max-w-lg">
                    <button
                        className="p-6 rounded-xl border-2 border-dashed bg-slate-50 dark:bg-[#494949] hover:border-[#E44826] transition-colors cursor-pointer"
                        onClick={() => fileInputRef.current.click()}
                    >
                        <input
                            type="file"
                            ref={fileInputRef}
                            className="hidden"
                            accept="image/*"
                            onChange={handleFileUpload}
                        />
                        <div className="flex flex-col items-center gap-4 w-full">
                            <Upload className="w-12 h-12 text-[#E44826]" />
                            <div className="text-center">
                                <p className="font-medium dark:text-[#F2F2F2]">{t('profilePhotoUpload.buttons.upload')}</p>
                                <p className="text-sm text-slate-500 dark:text-[#CBCBCB]">
                                    {t('profilePhotoUpload.fileRequirements.formats')} • {t('profilePhotoUpload.fileRequirements.maxSize')}
                                </p>
                            </div>
                        </div>
                    </button>
                </div>
            )}

            {error && (
                <div className="p-4 bg-red-50 dark:bg-[#494949] border border-red-200 dark:border-[#F9CDC4] rounded-lg">
                    <p className="text-red-600 dark:text-[#F9CDC4] text-center">{error}</p>
                </div>
            )}

            <div className="p-2 xl:p-4 bg-slate-50 dark:bg-[#494949] rounded-lg w-full max-w-lg">
                <h3 className="text-sm xl:text-normal font-medium mb-2 dark:text-[#F2F2F2]">
                    {t('profilePhotoUpload.uploadGuidelines.title')}
                </h3>
                <ul className="list-disc list-inside space-y-1 text-xs xl:text-sm text-slate-600 dark:text-[#CBCBCB]">
                    {t('profilePhotoUpload.uploadGuidelines.points', { returnObjects: true }).map((tip, index) => (
                        <li key={index}>{tip}</li>
                    ))}
                </ul>
            </div>

            <div className="flex justify-between absolute left-6 bottom-6 w-[calc(100%-48px)]">
                <button
                    onClick={handlePrevious}
                    className="px-4 xl:px-6 py-2 xl:py-2.5 rounded-lg border border-slate-300 dark:border-[#595959] text-slate-600 dark:text-[#CBCBCB] hover:bg-slate-100 dark:hover:bg-[#494949] transition-colors"
                >
                    <p className="text-sm xl:text-normal">
                        {t('profilePhotoUpload.buttons.previous')}
                    </p>
                </button>

                <button
                    onClick={handleNext}
                    disabled={!photo || loading}
                    className={`px-4 xl:px-6 h-10 xl:h-12 flex justify-center gap-2 items-center bg-[#E44826] text-white py-2 xl:py-3 rounded-lg font-medium hover:bg-primaryDark transition duration-300 gap-4
                        ${(!photo || loading) ? 'opacity-50 cursor-not-allowed' : ''} transition-colors`
                    }
                >
                    {
                        loading ? (
                            <div
                                className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                        ) : (
                            <>
                                <p className="text-sm xl:text-normal">{t('profilePhotoUpload.buttons.next')}</p>
                                <img src="/resources/arrow_right.svg" alt="Next" className="white_filter w-3 xl:w-4" />
                            </>
                        )
                    }
                </button>
            </div>
        </div>
    );
};

export default ProfilePhotoUpload;